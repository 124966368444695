
<div class="page-container">
    <div class="container-fluid min-100">
        <div class="page-heading">
            <div id="work_orders">
                <div class=" header">
                    <h2><i class="fa fa-file"></i> Work Orders</h2>
                </div>
            </div>
        </div>
        <div *ngIf="displayMode === 0" class="row grid-container min-100">
            <div class="col-12 mb-3">
                <button class="btn btn-success shadow mobile-display-none" routerLink="/add-work-order">
                    Create Work Order
                </button>
                <!-- NEED TO MAKE SURE THIS RETURNS THE CORRECT DATES -->
                <div class="pull-right displayed-date">Displaying Work Orders From: {{ query.startDate | date }} To: {{ query.endDate | date }}</div>
                <!-- Mobile Responsive Button -->
                <div class="row wo-table-filtering">
                    <div class="sm-btn-view">
                        <button class="btn btn-success shadow sm-view-yes" routerLink="/add-work-order">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <work-order-nav [serviceTypes]="serviceTypes"
                                [query]="query"
                                (updateSearchQuery)="navigationSearchQueries($event)"
                                (toggleDisplayDetail)="toggleDisplayDetail($event)"
                                (switchingOrderType)="displaySwitchOrderSuccessMessage($event)"></work-order-nav>
            </div>
            <div class="col-md-12 min-100">
                <work-order-table [workOrders]="workOrders"
                                  [headers]="headers"
                                  [sorting]="sorting"
                                  [query]="query"
                                  [maxSize]="maxSize"
                                  [totalItems]="totalItems"
                                  [sortColumn]="sortColumn"
                                  (changeStatus)="changeStatus($event)"></work-order-table>
                <div class="loading-overlay" *ngIf="searching">
                    <img src="../../../../assets/images/specialtylogo.png"/>
                    <i class="fa fa-spinner fa-spin fa-2x"></i>
                    <h4>Loading ...</h4>
                </div>
            </div>
        </div>

    </div>
</div>
