import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';

import { AuthService } from '../../authorization/auth.service';

@Component({
    selector: 'reset-password',
    templateUrl: 'reset-password.component.html',
    styleUrls: ['../login.component.css']
})

export class ResetPasswordComponent implements OnInit {
    hashed: string;
    saved = false;
    pass: string;
    creds: {oldPassHash: string, email: string, newPassword: string};
    alert: {open?: boolean, errorMessage?: string, successHeading?: string, successText?: string};



    constructor(
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        if (this.auth.loggedIn()) {
            this.router.navigate(['/dashboard']);
        }

        this.alert = {
            successHeading: null,
            successText: null,
            errorMessage: null,
            open: false
        };
    }

    ngOnInit() {
        this.creds = {
            oldPassHash: null,
            email: null,
            newPassword: null
        };
        this.route.queryParams.subscribe((params: Params) => {
            if (!params['hash']) {
                this.router.navigate(['/login']);
            }
            this.creds['oldPassHash'] = params['hash'];
        });
    }

    save() {
        return this.auth.saveNewPassword(this.creds)
            .then((res) => {
                if (res.error) {
                    this.alert = {
                        open: true,
                        errorMessage: 'There was an issue with resetting your password. Please ensure the email was correct and try again.'
                    };
                } else {
                    this.alert = {
                        open: true,
                        successHeading: 'You have successfully changed your password',
                        successText: 'Please return to the login screen and login using your new password'
                    };
                }
            });
        }

    cancel() {
        this.router.navigate(['/login']);
    }

    tryAgain() {
        this.alert = {
            open: false,
            errorMessage: null
        };
    }

    return() {
        this.alert['open'] = false;
        this.router.navigate(['/login']);
    }
}
