import { Component, ElementRef, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { PromptModalComponent } from '@shared/prompt-modal/prompt-modal.component';
import { LabService } from '@services/lab.service';
import { EmployeeService } from '@services/employee.service';
import { ToastService } from '@services/toast.service';
import { UserService } from '@services/user.service';

import { Lab } from '@models/lab';
import { Employee } from '@models/employee';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Address } from '@models/address';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from '../../client';
import { AuthService } from '@authorization/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { skip } from 'rxjs/operators';

@Component({
  selector: 'address-labwork',
  templateUrl: './address-labwork.component.html',
  styleUrls: ['./address-labwork.component.css']
})
export class AddressLabworkComponent implements OnDestroy {
  @Input() labWorks: any[];
  @Input() addressId: number = null;
  @Input() address: Address;
  @ViewChild('labDatePicker', { static: true }) private labDatePicker: PromptModalComponent;
  @ViewChild('clientInput') private clientInput: ElementRef;
  @ViewChild('clientList') private clientList: ElementRef;
  employees: Employee[];
  displayInfo = false;
  displayEdit = false;
  lab: Lab = new Lab();
  labDate: Date;
  labTime: Date;
  editType = '';
  ftp = false;
  isLabsPage = false;
  showClients = false;
  search = '';
  clientsDairies: Client[];
  isAdmin = this.auth.isAdmin();
  query = {
    fromDate: moment().subtract(30, 'days').toDate(),
    toDate: moment().add(1, 'days').toDate(),
    region: undefined,
    labTech: undefined,
    pageSize: 50,
    page: 1,
    sort: undefined
  };
  maxSize = 5;
  rotate = true;
  searching = false;
  serviceRegions: any[];
  labTechs: Employee[];
  sortColumn = 'date';
  totalItems = 0; // the data amount that is total in the database
  headers = [
    {
      'sortName': 'client',
      'className': 'td-10',
      'title': 'Client'
    }, {
      'sortName': 'labNum',
      'className': 'td-5',
      'title': 'Lab Num'
    }, {
      'sortName': 'date',
      'className': 'td-10',
      'title': 'Date'
    }, {
      'sortName': 'time',
      'className': 'td-10',
      'title': 'Time'
    }, {
      'sortName': 'location',
      'className': 'td-10',
      'title': 'Location'
    }, {
      'sortName': 'bathCycle',
      'className': 'td-10',
      'title': 'Bath Cycle'
    }, {
      'sortName': 'corrosive',
      'className': 'td-10',
      'title': 'Corrosive'
    }, {
      'sortName': 'hazardous',
      'className': 'td-10',
      'title': 'Hazardous'
    }, {
      'sortName': 'esh',
      'className': 'td-5',
      'title': 'ESH'
    }, {
      'sortName': 'notes',
      'className': 'td-20',
      'title': 'Notes'
    }];
  sorting = {
    client: {
      asc: true,
      properties: ['Address', 'alias'],
    }, labNum: {
      asc: true,
      properties: ['id'],
    }, date: {
      asc: false,
      properties: ['date'],
    }, time: {
      asc: true,
      properties: ['date'],
    }, location: {
      asc: true,
      properties: ['tankLocation'],
    }, bathCycle: {
      asc: true,
      properties: ['bathCycle'],
    }, corrosive: {
      asc: true,
      properties: ['isCorrosive'],
    }, hazardous: {
      asc: true,
      properties: ['isHazardous'],
    }, esh: {
      asc: true,
      properties: ['isESH'],
    }, notes: {
      asc: true,
      properties: ['note'],
    }
  };
  destroyed$ = new Subject();

  constructor(
    private labService: LabService,
    private employeeService: EmployeeService,
    private userService: UserService,
    private toastService: ToastService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    const { data, queryParams } = this.activatedRoute.snapshot;

    if (this.activatedRoute.snapshot.data['labs']) {
      this.labWorks = data.labs.rows;
      this.query.pageSize = queryParams.pageSize || 50;

      this.query.fromDate = queryParams.fromDate ? moment(queryParams.fromDate).toDate() : this.query.fromDate;
      this.query.toDate = queryParams.toDate ? moment(queryParams.toDate).toDate() : this.query.toDate;
      this.query.labTech = +queryParams.labTech || undefined;
      this.query.region = +queryParams.region || undefined;
      this.query.sort = queryParams.sort;

      this.totalItems = data.labs.count;
      this.clientsDairies = data.clientsDairies;
      this.serviceRegions = data.serviceRegions;
      this.labTechs = data.labTechs;
      this.displayInfo = true;
      this.isLabsPage = true;
    } else {
      this.headers.shift();
    }
    this.query.page = +queryParams.page || 1;

    if (typeof queryParams.sort === 'string') {
      this.sortColumn = queryParams.sort[0] === '-' ? queryParams.sort.slice(1) : queryParams.sort;
      const sortOptions = this.sorting[this.sortColumn];

      if (sortOptions) {
        sortOptions.asc = queryParams.sort[0] === '-' ? false : true;
      }
    }

    this.getEmployees();
    this.subscribeToRouter();
  }

  subscribeToRouter() {
    this.activatedRoute.queryParams
      .pipe(
        skip(1),
        takeUntil(this.destroyed$)
      )
      .subscribe((queryParams) => {
        this.query.page = +queryParams.page || 1;
        this.query.pageSize = +queryParams.pageSize || 50;
        this.query.sort = queryParams.sort;
        this.query.fromDate = queryParams.fromDate ? moment(queryParams.fromDate).toDate() : this.query.fromDate;
        this.query.toDate = queryParams.toDate ? moment(queryParams.toDate).toDate() : this.query.toDate;
        this.query.labTech = +queryParams.labTech || undefined;
        this.query.region = +queryParams.region || undefined;

        this.findLabs();
      });
  }

  sort(key: string) {
    const sortOptions = this.sorting[key];

    if (this.sortColumn === key) {
      sortOptions.asc = !sortOptions.asc;
    } else {
      this.sortColumn = key;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        sort: `${sortOptions.asc ? '' : '-'}${sortOptions.properties.join('.')}`
      },
      queryParamsHandling: 'merge'
    });
  }

  toggleInfo(): void {
    this.displayInfo = !this.displayInfo;

  }

  toggleEdit(): void {
    this.displayEdit = !this.displayEdit;
  }

  getEmployees(): void {
    this.employeeService.getEmployees().then((employees) => this.employees = employees);
  }

  selectLab(lab: Lab) {
    this.ftp = true;
    this.displayEdit = true;
    return this.labService.getLab(lab.id)
      .then((gotLab) => {
        this.lab = gotLab;
        this.labDate = moment(this.lab.date).toDate();
        this.labTime = moment(this.lab.date).toDate();
        this.ftp = false;
        if (this.isLabsPage) {
          this.address = lab.Address;
          this.addressId = lab.Address.id;
          this.search = lab.Address.alias;
        }
      });
  }

  saveLab(lab: Lab, form: UntypedFormGroup): void {
    if (form.valid) {
      lab.addressId = this.addressId;
      lab.date = moment(`${moment(this.labDate).format('YYYY-MM-DD')} ${moment(this.labTime).format('HH:mm')}`);
      this.ftp = true;
      lab.id ? lab.updatedById = this.userService.getEmployeeId() : lab.createdById = this.userService.getEmployeeId();

      const mailReq = {
        dairyAlias: `${this.address['alias']}`,
        dairyAddress: `${this.address['street']}, ${this.address['city']} ${this.address['state']} ${this.address['zip']}`
      };
      const fromEmployee = _.find(this.employees, { 'id': this.userService.getEmployeeId() });
      mailReq['employee'] = `${fromEmployee.firstName} ${fromEmployee.lastName}`;

      lab['mailReq'] = mailReq;
      this.labService.saveLabWork(lab)
        .then((savedLab) => {
          if (this.isLabsPage) {
            savedLab.Address = this.address;
          }
          if (lab.id) {
            _.merge(_.find(this.labWorks, { id: lab.id }), savedLab);
            this.ftp = false;
          } else {
            this.labWorks.unshift(savedLab);
            this.ftp = false;
          }
          this.clearLab();
        });
    } else {
      this.toastService.validate(form);
    }
  }

  deleteLab(lab: Lab) {
    this.ftp = true;
    this.labService.deleteLabWork(lab)
      .then(() => {
        this.labWorks.splice(this.labWorks.findIndex(nlab => nlab.id === lab.id), 1);
        this.ftp = false;
        this.clearLab();
      }, () => {
        this.ftp = false;
        this.toastService.toast({ message: 'Unable to delete lab', type: 'danger' });
      });
  }

  clearLab(): void {
    this.lab = new Lab();
    this.labDate = null;
    this.labTime = null;
    this.search = '';
  }

  setEditDate(type: string) {
    this.editType = type;
    this.labDatePicker.showPromptModal();
  }

  labTimeString() {
    if (!this.labTime) return '';
    return moment(this.labTime).format('hh:mm A');
  }

  setLabTime() {
    if (!this.labTime) {
      this.labTime = new Date();
    }
    this.labDatePicker.hidePromptModal();
  }

  isSelected(lab: Lab) {
    return lab.id === this.lab.id;
  }

  @HostListener('window:touchend', ['$event.target'])
  @HostListener('window:mouseup', ['$event.target'])
  closeDropdown(target: HTMLElement) {
    if (this.showClients) {
      const clientInput = this.clientInput.nativeElement;
      const clientList = this.clientList.nativeElement;

      if (target !== clientInput && !clientList.contains(target)) {
        if (this.address) {
          this.search = this.address.alias;
        }
        this.showClients = false;
      }
    }
  }

  setAddress(address: Address) {
    this.address = address;
    this.addressId = address.id;
    this.showClients = false;
    this.search = address.alias;
  }

  findLabs() {
    this.labService.findLabs(this.query).then(labs => {
      this.labWorks = labs.rows;
      this.totalItems = labs.count;
    });
  }


  setFilters() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: Object.assign({}, this.query, {
        page: 1,
        fromDate: moment(this.query.fromDate).format('YYYY-MM-DD'),
        toDate: moment(this.query.toDate).format('YYYY-MM-DD')
      }),
      queryParamsHandling: 'merge'
    });
  }

  pageChanged({ page, itemsPerPage }) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { page, pageSize: +itemsPerPage },
      queryParamsHandling: 'merge'
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
