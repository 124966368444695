import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { ServiceType } from '../../models/service-type';
import { environment } from '../../../environments/environment';

@Injectable()
export class ServiceTypeService {
    constructor(
        private authHttp: HttpClient
    ) { }

    private apiUrl = `${environment.apiUrl}service-type`;  // URL to web api

    getAll(): Promise<ServiceType[]> {
        return this.authHttp.get(this.apiUrl)
            .toPromise()
            .then(res => res as ServiceType[]);
    }
}
