
<preview-image *ngIf="canViewLargeImg"
               (cancelPreview)=cancelPreview($event)
               [largeImage]="largeImage"
               [address]="address"
               [employees]="employees"
               [docTypes]="documentTypes"
               [images]="images"></preview-image>

<div class="document-uploads panel padding-15 margin-top-20">
    <!--Document Heading-->
    <div class="row document-heading">
        <div class="col-md-12">
            <h4> <i class="fa fa-file-image-o"></i> Files/Documents</h4>

            <div class="document-actions">
                <div class="btn btn-success address-btn" (click)="getAllDocs()">{{ showDocs ? 'Hide' : 'Show'}} Files</div>
                <div class="btn btn-primary address-btn" (click)="uploadNewDoc()">Upload File</div>
            </div>
        </div>
    </div>
    <!--End Document Heading-->

    <!--Upload A Document-->
    <div *ngIf="uploadDoc" class="row document-upload margin-top-20">
        <div class="col-md-6">
            <form #ngForm="ngForm" action="" class="form-group">
                <div class="form-group form-inline">
                    <input
                        class="inputfile"
                        type="file" id="file"
                        accept=".jpg,.jpeg,.pdf,.txt,.doc,.xls,.csv"
                        name="Document"
                        (change)="handleFile($event)"
                        [(ngModel)]="file.newfile"
                        placeholder="Upload File..."
                    >
                    <!--<label for="file">Choose a file</label>-->
                    <select #fileType id="type" [(ngModel)]="file.documentTypeId" name="Document Type" required class="form-control">
                        <option *ngFor="let type of documentTypes" [value]="type.id">{{type.name}}</option>
                    </select>
                </div>

                <div class="form-group margin-top-10">
                    <input #fileTitle
                              name="Title"
                              id="title"
                              class="form-control"
                              [(ngModel)]="file.title"
                              placeholder="Title"
                              required/>
                </div>
                <div class="form-group margin-top-10">
                    <textarea #fileDesc
                              name="Description"
                              id="description"
                              class="form-control"
                              rows="3"
                              [(ngModel)]="file.description"
                              placeholder="Description..."
                              required>
                    </textarea>
                </div>
                <div class="form-group">
                    <button class="btn btn-success" type="submit" (click)="uploadFile(file, ngForm.form)" >Upload</button>
                </div>
            </form>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3 pull-right-md">
            <div class="image-preview">
                <div class="btn btn-danger img-preview-btn pull-right" (click)="clearDoc()"><i class="fa fa-close"></i></div>
                <img *ngIf="file.base64 && ['jpg','jpeg'].indexOf(file.base64.replace(';', ':').replace('/',':').split(':')[2]) != -1" [attr.src]="file.base64">
                <i *ngIf="file.base64 && file.base64.replace(';', ':').replace('/',':').split(':')[2] == 'pdf'" class="fa fa-file-pdf-o preview" aria-hidden="true"></i>
                <i *ngIf="file.base64 && file.base64.replace(';', ':').replace('/',':').split(':')[2] == 'msword'" class="fa fa-file-word-o preview" aria-hidden="true"></i>
                <i *ngIf="file.base64 && file.base64.replace(';', ':').replace('/',':').split(':')[2] == 'vnd.ms-excel'" class="fa fa-file-excel-o preview" aria-hidden="true"></i>
                <i *ngIf="file.base64 && ['jpg','jpeg', 'pdf', 'msword', 'vnd.ms-excel'].indexOf(file.base64.replace(';', ':').replace('/',':').split(':')[2]) == -1" class="fa fa-file-text-o preview" aria-hidden="true"></i>
            </div>
        </div>
    </div>
    <!--End Upload Document-->

    <!--Show Documents-->
    <div id="documentoList" *ngIf="showDocs" class="row show-documents margin-top-20">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th class="td-15"><a href="javascript:void(0);" (click)="sortByDate()"><span class="notMobile">Date</span> Uploaded</a></th>
                    <th class="td-15"><a href="javascript:void(0);" (click)="sortByEmployee()"><span class="notMobile">Uploaded</span> By</a></th>
                    <th class="td-15"><a href="javascript:void(0);" (click)="sortByType()">File <span class="notMobile">Type</span></a></th>
                    <th class="td-25">Description</th>
                    <th class="td-20">Preview</th>
                    <th class="td-10">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let doc of documents">
                    <td class="td-15">{{doc.createdAt | date}}</td>
                    <td class="td-15">{{setEmployee(doc.uploadedById)}}</td>
                    <td class="td-15" *ngIf="!editMode">{{assignDocumentType(doc.documentTypeId)}}</td>
                    <td class="td-15" *ngIf="editMode">
                        <select id="type" [(ngModel)]="doc.documentTypeId" name="Document Type">
                            <option *ngFor="let type of documentTypes" [value]="type.id">{{type.name}}</option>
                        </select>
                    </td>
                    <td class="td-25" *ngIf="!editMode">Title: {{doc.title || ''}}<br/>{{doc.description || ''}}</td>
                    <td class="td-25" *ngIf="editMode">
                        <label for="docTitle">Title:</label>
                        <input type="text" [(ngModel)]="doc.title" id="docTitle"><br>
                        <label for="docDescription">Description:</label>
                        <input type="text" [(ngModel)]="doc.description" id="docDescription">
                    </td>
                    <td class="td-20">
                        <div (click)="viewLargeImg(doc.id)" class="larger-img">
                            <img *ngIf="isImage(doc.filePath)" [attr.src]="doc.filePath" alt=""/>
                            <i *ngIf="!isImage(doc.filePath) && doc.filePath.split('.')[doc.filePath.split('.').length - 1] == 'pdf'" class="fa fa-file-pdf-o preview" aria-hidden="true"></i>
                            <i *ngIf="!isImage(doc.filePath) && doc.filePath.split('.')[doc.filePath.split('.').length - 1] == 'doc'" class="fa fa-file-word-o preview" aria-hidden="true"></i>
                            <i *ngIf="!isImage(doc.filePath) && doc.filePath.split('.')[doc.filePath.split('.').length - 1] == 'xls'" class="fa fa-file-excel-o preview" aria-hidden="true"></i>
                            <i *ngIf="!isImage(doc.filePath) && ['pdf','doc','xls'].indexOf(doc.filePath.split('.')[doc.filePath.split('.').length - 1]) == -1" class="fa fa-file-text-o preview" aria-hidden="true"></i>
                        </div>
                    </td>
                    <td class="td-5"><button href="javascript:void(0);" class="btn btn-sm btn-danger" (click)="setDocument(doc); documentDelete.showPromptModal()"><i class="fa fa-trash"></i></button></td>
                    <td class="td-5" *ngIf="!editMode"><button class="btn btn-sm btn-info" (click)="editMode = true" ><i class="fa fa-pencil"></i></button></td>
                    <td class="td-5" *ngIf="editMode"><button class="btn btn-sm btn-success"  (click)="updateDocument(doc); editMode = false"><i class="fa fa-save"></i></button></td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--End Show Documents-->
</div>


<!--Confirm Modal-->

<prompt-modal
        #documentDelete
        size="small"
>
    <div class="modal-header">
        <h4 class="modal-title">Remove Document</h4>
    </div>

    <div class="modal-item">
        <div class="col-12">
            <p>Are you sure you would like to delete this document? This cannot be undone.</p>
        </div>
    </div>

    <div class="modal-buttons">
        <button (click)="removeDocument();documentDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-danger">Delete</button>
        <button type="button" class="btn btn-sm btn-warning" (click)="documentDelete.hidePromptModal(); clearDocument();">Cancel</button>
    </div>

</prompt-modal>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
