import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'
import { NgSelectModule } from "@ng-select/ng-select";

import { PdfsPanelComponent } from './pdfs-panel/pdfs-panel.component';
import { NotesPanelComponent } from './notes-panel/notes-panel.component';
import { TanksPanelComponent } from './tanks-panel/tanks-panel.component';
import { DropsPanelComponent } from './drops-panel/drops-panel.component';
import { WorkOrdersPanelComponent } from './work-orders-panel/work-orders-panel.component';
import { PipesModule } from "../pipes/pipes.module";

import { DatepickerModule } from 'ngx-bootstrap/datepicker';

import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ChartsModule } from 'ng2-charts';
import { RouterModule } from "@angular/router";


@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        CommonModule,
        PipesModule,
        SharedComponentsModule,
        DatepickerModule.forRoot(),
        ChartsModule,
        NgSelectModule
    ],
    exports: [
        PdfsPanelComponent,
        NotesPanelComponent,
        TanksPanelComponent,
        DropsPanelComponent,
        WorkOrdersPanelComponent
    ],
    providers: [],
    declarations: [
        PdfsPanelComponent,
        NotesPanelComponent,
        TanksPanelComponent,
        DropsPanelComponent,
        WorkOrdersPanelComponent
    ]
})

export class InfoPanelsModule { }
