<div class="document-uploads panel padding-15 margin-top-20">

    <!--Document Heading-->
    <div class="row document-heading">
        <div class="col-md-12">
            <h4> <i class="fa fa-tint"></i> Vapor Testing</h4>
            <div class="document-actions">
                <div class="btn btn-success address-btn" (click)="displayTests()"><span *ngIf="!showTests">Show</span><span *ngIf="showTests">Hide</span> Tests</div>
                <div class="btn btn-primary address-btn" *ngIf="!this.testForm" (click)="openTestForm()">Create Test</div>
                <div class="btn btn-primary address-btn" *ngIf="this.testForm" (click)="closeTestForm()">Close Form</div>
            </div>
        </div>
    </div>
    <!--End Document Heading-->

    <!--Vapor Test Form-->
    <div *ngIf="testForm" class="row document-upload margin-top-20">
        <div class="col-md-12">
            <form #testForm="ngForm">
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="technician">Technician</label>
                        <select #technician="ngModel" class="form-control" id="technician" name="technician" [(ngModel)]="vaporTest.technicianId">
                            <option *ngFor="let employee of employees" [value]="employee.id">{{employee.lastName}}, {{employee.firstName}}</option>
                        </select>
                    </div>
                    <div class="form-group col-sm-4" *ngIf="vaporTest.createdBy">
                        <div>Created By:</div>
                        <div>{{vaporTest.createdBy?.firstName}} {{vaporTest.createdBy?.lastName}} on {{vaporTest.createdAt | date}}</div>
                    </div>
                    <div class="form-group col-sm-4" *ngIf="vaporTest.updatedBy">
                        <div >Updated By: </div>
                        <div>{{vaporTest.updatedBy?.firstName}} {{vaporTest.updatedBy?.lastName}} on {{vaporTest.updatedAt | date}}</div>
                    </div>
                </div>

                <div class="form-row padding-top-15">
                    <div class="form-group col-sm-4 date-input">
                        <label for="longDate">Long Term Test Date</label>
                        <div class="input-group">
                            <input #longDate="ngModel"
                                   class="form-control"
                                   type="text"
                                   id="longDate"
                                   name="Long Term Test Date"
                                   [ngModel]="vaporTest.longTermTestDate | date"
                                   (click)="setEditDate('Long Term')" />
                            <div class="input-group-prepend">
                                <button type="button" (click)="setEditDate('Long Term')" class="btn btn-outline-secondary btn-calendar"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="longBadge">Long Term Badge #</label>
                        <input #longDate="ngModel" class="form-control"  type="text" id="longBadge" name="longBadge" [(ngModel)]="vaporTest.longTermBadge"/>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="longResult">Long Term Result</label>
                        <input #longResult="ngModel" class="form-control"  type="text" id="longResult" name="longResult" [(ngModel)]="vaporTest.longTermResult"/>
                    </div>
                </div>

                <div class="form-row padding-top-15">
                    <div class="form-group col-sm-4 date-input">
                        <label for="shortDate">Short Term Test Date</label>
                        <div class="input-group">
                            <input #shortDate="ngModel"
                                class="form-control"
                                type="text" id="shortDate"
                                name="Short Term Test Date"
                                [ngModel]="vaporTest.shortTermTestDate | date"
                                (click)="setEditDate('Short Term')" />
                            <button type="button" (click)="setEditDate('Short Term')" class="btn btn-outline-secondary btn-calendar"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="shortBadge">Short Term Badge #</label>
                        <input #shortDate="ngModel" class="form-control"  type="text" id="shortBadge" name="shortBadge" [(ngModel)]="vaporTest.shortTermBadge"/>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="shortResult">Short Term Result</label>
                        <input #shortResult="ngModel" class="form-control"  type="text" id="shortResult" name="shortResult" [(ngModel)]="vaporTest.shortTermResult"/>
                    </div>
                </div>
                <div class="form-row padding-top-15 input-group">
                    <div class="form-group col-sm-12 input-group-btn">
                        <div href="javascript:void(0);" class="btn btn-success" (click)="saveTest(vaporTest, testForm.form)">Submit</div>
                        <div href="javascript:void(0);" class="btn btn-warning" (click)="clear()">Clear</div>
                        <div href="javascript:void(0);" class="btn btn-danger" *ngIf="vaporTest.id" (click)="deleteTest = true;">Delete</div>
                    </div>
                    <div class="form-group col-md-12" *ngIf="deleteTest">
                        <div class="confirm-vp">
                            Are you sure you would like to delete this Vapor Test?
                            <a href="javascript:void(0);" (click)="deleteVaporTest(vaporTest.id)">Confirm</a>
                            <a href="javascript:void(0);" (click)="deleteTest = false;">Cancel</a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!--End Vapor Test Form-->

    <!--Show Tests-->
    <div id="documentoList" *ngIf="showTests" class="row show-documents margin-top-20">
        <table class="table table-striped">
            <thead>
            <tr>
                <th>Date Added</th>
                <th>Technician</th>
                <th>Long Term Date</th>
                <th>Long Term Badge #</th>
                <th>Long Term Results</th>
                <th>Short Term Date</th>
                <th>Short Term Badge #</th>
                <th>Short Term Result</th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let test of vaporTests" (click)="openTestForm(test)">
                    <td>{{test.createdAt | date}}</td>
                    <td>{{test.technicianId ? showTechnician(test.technicianId): '--'}}</td>
                    <td><span *ngIf="test.longTermTestDate">{{test.longTermTestDate | date}}</span> <span *ngIf="!test.longTermTestDate">--</span></td>
                    <td>{{test.longTermBadge ? test.longTermBadge : '--'}}</td>
                    <td>{{test.longTermResult ? test.longTermResult: '--'}}</td>
                    <td><span *ngIf="test.shortTermTestDate">{{test.shortTermTestDate | date}}</span> <span *ngIf="!test.shortTermTestDate">--</span></td>
                    <td>{{test.shortTermBadge ? test.shortTermBadge: '--'}}</td>
                    <td>{{test.shortTermResult ? test.shortTermResult: '--'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--End Show Documents-->
</div>

<prompt-modal #datePicker size="small" (onClosed)="resetDate()">
        <div class="modal-header">
            <h4 class="modal-title">{{dateType}} Test Date</h4>
        </div>

        <div class="modal-item row">
            <div class="col-12">
                <datepicker *ngIf="dateType == 'Long Term'"
                            [(ngModel)]="vaporTest.longTermTestDate"
                            class="date-modal"
                            name="Test Date"
                            datepickerMode="day"
                            [showWeeks]="false"
                            (selectionDone)="datePicker.hidePromptModal()">
                </datepicker>

                <datepicker *ngIf="dateType == 'Short Term'"
                            [(ngModel)]="vaporTest.shortTermTestDate"
                            class="date-modal"
                            name="Test Date"
                            datepickerMode="day"
                            [showWeeks]="false"
                            (selectionDone)="datePicker.hidePromptModal()">
                </datepicker>
            </div>
        </div>

        <div class="modal-buttons">
            <button type="button" class="btn btn-primary" (click)="datePicker.hidePromptModal()">OK</button>
        </div>

</prompt-modal>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
