import { NgModule } from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule } from '@angular/forms';

import {S3JSPdfService} from './s3-jspdf.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule],
    providers:[
        S3JSPdfService
    ]
})
export class S3JSPdfModule{
    constructor(){}
}