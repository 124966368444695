import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '../pipes/pipes.module';
import { EmployeesResolve } from '../resolvers/employees.resolver';
import { driversRouting } from './drivers.routing';
import { DriversComponent } from './drivers.component';
import { FormsModule } from '@angular/forms';
import { DriverTableComponent } from './driver-table/driver-table.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { ClientRoutesModule } from '../client-routes/client-routes.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';

@NgModule({
  imports: [
    ClientRoutesModule,
    CommonModule,
    PaginationModule,
    PipesModule,
    driversRouting,
    FormsModule,
    SharedComponentsModule,
    DatepickerModule.forRoot()
  ],
  declarations: [
    DriversComponent,
    DriverTableComponent
  ],
  providers: [EmployeesResolve]
})

export class DriversModule { }
