import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams
} from '@angular/common/http';
import saveAs from 'save-as';

import { EventService } from '../../shared-services/event-service/event.service';
import { environment } from '../../../environments/environment';


import {
  of as observableOf,
  Observable
} from 'rxjs';

import {
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError
} from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { get as _get } from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../services-module/services/toast.service';

interface RequestOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private apiUrl = environment.normalizeUrl;
  public userStatus = 'loggedOut';

  constructor(
    private http: HttpClient,
    private eventService: EventService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) {

  }

  // set endpoint(urlPath) {
  //   this.apiUrl = urlPath;
  // }

  // get endpoint() {
  //   return this.apiUrl;
  // }

  saveAs(fileName: string, data) {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${fileName}`);
  }

  get(url: string, options?: Object): Observable<any> {
    return this.http.get(this.apiUrl + url, <RequestOptions>this.authHeaders())
      .pipe(
        map((resp: any) => this.extractData(resp, options, 'get')),
        catchError(this.handleError.bind(this))
      );
  }

  post(url: string, body: any, options?: Object): Observable<any> {
    return this.http.post(this.apiUrl + url, body, <RequestOptions>this.authHeaders())
      .pipe(
        map((resp: any) => this.extractData(resp, options, 'post')),
        catchError(this.handleError.bind(this))
      );
  }

  put(url: string, body: any, options?: Object): Observable<any> {
    return this.http.put(this.apiUrl + url, body, <RequestOptions>this.authHeaders())
      .pipe(
        map((resp: any) => this.extractData(resp, options, 'update')),
        catchError(this.handleError.bind(this))
      );
  }

  del(url: string, body: any, options?: Object): Observable<any> {
    return this.http.delete(this.apiUrl + url, <RequestOptions>this.authHeaders())
      .pipe(
        map((resp: any) => this.extractData(resp, options, 'delete')),
        catchError(this.handleError.bind(this))
      );
  }

  private extractData(res: HttpResponse<any>, options, methodType) {
    const body = res.body;
    if (body['statusCode'] === 401 && this.userStatus === 'loggedIn') {
      this.logout();
    } else if ((body['statusCode'] === 401 && this.userStatus === 'loggingOut') ||
      (body['statusCode'] === 401 && this.userStatus === 'loggedOut')) {
      return null;
    } else {
      if (options && options['toast'].message) {
        this.toastService.toast({ message: options['toast'].message, type: 'success' });
      }
      return body || {};
    }
  }

  private handleError(error: any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      const body = error || '';
      const err = body['error'] || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    if (error.status === 401) {
      this.toastService.toast({ message: 'Session has expired!', type: 'warning' });
      localStorage.removeItem('n_token');
      localStorage.removeItem('id_token');
      this.userStatus = 'loggedOut';
      this.router.navigateByUrl('/login');
    }
    return Promise.resolve({ error: errMsg });
  }

  private fetchToken() {
    const token = localStorage.getItem('n_token');
    if (token) {
      return token;
    } else {
      return null;
    }
  }

  // applies authorization headers to server calls
  private authHeaders(userToken?: any) {
    const token = userToken != null ? userToken : this.fetchToken();
    const newHeaders = {
      'Content-Type': 'application/json',
      'ngsw-bypass': 'true'
    };
    if (token) {
      newHeaders['Authorization'] = 'Bearer ' + token;
    }
    const headers = new HttpHeaders(newHeaders);
    return { headers, observe: 'response', withCredentials: true };
  }

  // This method will log the user out
  public logout() {
    this.userStatus = 'loggingOut';
    // To log out, just remove the token
    // from local storage
    this.get('user/logout').subscribe(res => {
      localStorage.removeItem('n_token');
      // Send the user back to the login page after logout
      this.userStatus = 'loggedOut';
    });
  }

  public async login(creds): Promise<any> {
    const res = await this.post('user/login', creds).toPromise();
    if (res.token) {
      localStorage.setItem('n_token', res.token);
      return res;
    } else {
      if (res.statusCode === 500) {
        return { error: 'error logging in' };
      } else if (res.statusCode === 423) {
        return { error: 'error logging in', message: res.errorMessage };
      } else {
        return { error: true, message: 'An unknown error has occured' };
      }
    }
  }

  public async getSessionUser() {
    return await this.get('user/get-session-user').toPromise();
  }

}
