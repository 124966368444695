<div class="panel data-panel">
    <div class="panel-header">
        <div class="flex-container flex-space-between">
            <h5><i class="fa fa-file"></i> Work Orders</h5>
        </div>
    </div>
    <div class="panel-content">
        <div *ngFor="let order of workOrders | dateSorting: 'ServiceDate'" class="work-order">
            <div class="flex-container flex-space-between">
                <span class="order-number">{{order.id}}</span>
                <span [ngClass]="woStatus(order.workOrderStatusId)">{{order.workOrderStatusId | orderStatus}}</span>

            </div>
            <div class="flex-container flex-space-between">
                <div>{{ order.ServiceType?.serviceTypeName }}</div>
                <div>{{ order.createdAt | date }}</div>
            </div>
            <div>
                {{ order.note }}
            </div>
        </div>
        <div *ngIf="workOrders.length < 1">
            No Work Orders
        </div>
    </div>
    <div class="panel-footer">
        <button class="btn btn-work" (click)="showAll()">{{open ? 'Hide' : 'View'}} Work Orders</button>
    </div>
</div>
