import { Component,
         OnInit,
         Input,
         ViewEncapsulation,
         ViewChild,
         EventEmitter,
         Output }                       from '@angular/core';
import { Router }                       from '@angular/router';


import { Client }                       from '../../models/client';
import { ClientService }                from '../../services-module/services/client.service';
import * as _                           from 'lodash';
import { ClientPagination }             from 'src/app/models/client-pagination';

@Component({
    selector: 'client-list',
    templateUrl: 'client-list.component.html',
    styleUrls: ['../client.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class ClientListComponent implements OnInit {

    readonly headerHeight = 50;
    readonly rowHeight = 50;

    @Input() clientResults: ClientPagination;
    @Input() currentPage: number; // this is the current page of the data
    @Input() itemsPerPage: number; // items the user wants to show per page
    @Input() itemsDataSource: number; // the data amount that is total in the database
    @Input() maxSize:number;
    @Input() rotate: boolean;

    @Input() paginationValues: any; // pass in pagination values
    @Output() userSelectedPagination = new EventEmitter<any>();

    rows: ClientPagination;
    temp: ClientPagination;

    localPage:number = 0;
    localItemsPerPage: number = 0;
    localSort:string = 'asc';
    localProp:string = 'name';

    @ViewChild('mydatatable', { static: true }) table:any;

    val:string = '';

    constructor(private router: Router,
                private clientService: ClientService) {}

    ngOnInit() {
        this.table.offset = this.currentPage;
        this.localPage = this.currentPage;
        this.localItemsPerPage = this.itemsPerPage;
    }


    pageChanged(event) {
        this.localPage = event.offset;
        this.itemsPerPage = event.limit;

        const paginationValues = {
            isIndex: true,
            page: event.offset,
            itemsPerPage: event.limit,
            sort: this.localSort,
            prop: this.localProp
        };

        this.userSelectedPagination.emit(paginationValues);
    }

    onSort($event) {
        this.localSort = $event.sorts[0].dir;
        this.localProp = $event.sorts[0].prop;

        this.table.bodyComponent.updateOffsetY(this.localPage);
        this.table.offset = this.localPage;

        const paginationValues = {
            isIndex: true,
            page: this.localPage,
            itemsPerPage: this.localItemsPerPage,
            sort: $event.sorts[0].dir,
            prop: $event.sorts[0].prop
        };
        this.userSelectedPagination.emit(paginationValues);
    }

    gotoDetail(client:Client): void {
        let link = ['/clients', client.id];
        this.router.navigate(link);
    }

    getClientAddress(clientId: number) {
        return this.clientService.getSingleClient(clientId).then((res)=>{
            let origClient = _.find(this.clientResults['rows'], { id: clientId});
            if(res.hasOwnProperty("deliveryAddresses")) {
                _.merge(origClient, res);
            } else{
                console.log('No available client address');
            }
        });
    }

    toggleExpandRow(row:any) {
        this.table.rowDetail.toggleExpandRow(row);
        return this.getClientAddress(row.id);
    }

}
