import { NgModule } from '@angular/core';
import { CommonModule }   from '@angular/common';

import {PipesModule} from "../pipes/pipes.module";
import {EmployeesResolve} from "../resolvers/employees.resolver";
import {profileRouting} from "./profile.routing";
import {ProfileComponent} from "./profile.component";
import {FormsModule} from "@angular/forms";
import {EqualValidator} from "../directives/equal-validator.directive";
import {ConfirmValidator} from "../directives/confirm-password.directive";

@NgModule({
    imports: [
        CommonModule, PipesModule, profileRouting, FormsModule
    ],
    declarations: [
       ProfileComponent, ConfirmValidator
    ],
    providers: [EmployeesResolve]
})

export class ProfileModule { }