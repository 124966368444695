import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import { S3JSPdfService } from '../s3-jspdf/s3-jspdf.service';
import { RouteService } from '../services-module/services/route.service';
import { Route } from '../models/route';

@Injectable()
export class RouteSheetsService {
    constructor(
        private pdfService: S3JSPdfService,
        private routeService: RouteService
    ) {

        this.convertLogo();
    }
    logo: any = null;

    convertLogo() {
        let logo: any = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", '../../../assets/images/specialty-sales-transparent.jpg', true);
        xhr.responseType = "blob";
        xhr.onload = () => {
            var reader = new FileReader();
            reader.onload = (event) => {
                this.logo = event.target['result'];
            };
            var file = xhr.response;
            logo = reader.readAsDataURL(file);
            return logo;
        };
        xhr.send();
    }

    /* One Tank on this trailer can only have one liquid on this route */
    isDot12412(route: Route): boolean {
        if (route.Trailer) {
            return route.Trailer.name === '11' || route.Trailer.name === '6' || route.Trailer.name === 'Jenny';
        } else {
            return false;
        }
    }
    /* Two tanks on this trailer can have both liquids as part of this route */
    isDot12516(route: Route): boolean {
        if (route.Trailer) {
            return route.Trailer.name === '27' || route.Trailer.name === '28' || route.Trailer.name === '29';
        } else {
            return false;
        }
    }

    // Trailers 17 and 22 have nothing

    buildLoadSheet(route: Route) {

        route.deliveryOrders = _.orderBy(route.deliveryOrders, ['dropOrder'], ['asc']);
        let pdfName = `${route.routeName}.pdf`;

        //check if delNumbers have same product. if they do then merge and remove one of them
        let uniqProduct: any = [];
        let deliveryDict = {};
        let productDict = {};

        route.deliveryOrders.forEach(order => {
            productDict[order.productId] = order.Product.dot;
            if (!deliveryDict[order.deliveryNumber]) {
                deliveryDict[order.deliveryNumber] = [order];
            } else {
                deliveryDict[order.deliveryNumber].push(order);
            }
        });

        Object.keys(deliveryDict).forEach(key => {
           let orderDict = {};
           deliveryDict[key].forEach(order => {
               if (!orderDict[order.productId]) {
                   orderDict[order.productId] = order;
               } else {
                   orderDict[order.productId].quantityOrdered += order.quantityOrdered;
               }
           });
           Object.values(orderDict).forEach(value => {
               uniqProduct.push(value);
           });
        });

        const originalDefineProperty = Object.defineProperty;
        Object.defineProperty = function(args1, args2, args3) {
            return originalDefineProperty.call(this, args1, args2, Object.freeze(args3));
        };

        let pdf = this.pdfService.createNewPdf();
        let date = moment(route.dateOfRoute).format('MM/DD/YYYY');
        let variableY = 0;

        function editableText(text, x, y, w, h, options?) {
            const textField = new pdf.AcroFormTextField();
            textField.value = text;
            if (options && options.color) {
                textField.color = options.color;
            } if (options && options.multiline) {
                textField.multiline = options.multiline;
            }

            textField.fontSize = 9; // this has no effect
            textField.maxFontSize = 9;
            textField.Rect = [x, y, w, h];
            pdf.addField(textField);
        }

        editableText('', 0, 0, 0, 0); // This is needed otherwise the text is too small. Don't know why


        //row 1
        pdf.rect(8, 4, 100, 32);
        pdf.setFontSize(12);
        pdf.addImage(`${this.logo}`, 'JPEG', 13, 5, 90, 16);
        pdf.text(13, 25, '4672 E DRUMMOND AVE');
        pdf.text(13, 30, 'FRESNO, CA 93725');
        pdf.text(13, 35, 'PHONE: (559) 862-6611  FAX: (559) 268-2795');

        pdf.rect(108, 4, 100, 32);
        pdf.setFontSize(16);
        pdf.text(140, 12, 'LOAD SHEET');

        pdf.setFontSize(10);
        pdf.text(145, 20, 'Shipping Papers');
        pdf.text(133, 32, `DATE: ${date}`);

        //row 2
        pdf.rect(8, 36, 200, 6);
        pdf.text(25, 40, 'SHIPPER: Specialty Sales, LLC');
        pdf.setFillColor(247, 255, 0);
        pdf.rect(108, 36, 100, 6, "F");
        pdf.text(147, 40, `TRAILER: ${route.Trailer ? route.Trailer.name : 'N/A'}`);

        //row 3
        pdf.rect(8, 42, 100, 20);
        pdf.setFontSize(10);
        pdf.text(9, 46, 'COMPANY');
        pdf.text(9, 51, 'ADDRESS');
        pdf.text(9, 56, 'CITY STATE ZIP');
        pdf.text(9, 60, 'PHONE');
        pdf.text(40, 46, 'SPECIALTY SALES, LLC');
        pdf.text(40, 52, '4672 E DRUMMOND AVE');
        pdf.text(40, 57, 'FRESNO, CA 93725');
        pdf.text(40, 61, '(559) 862-6611');

        pdf.rect(108, 42, 100, 5);
        pdf.setFontSize(12);
        pdf.setTextColor(138, 7, 7);
        pdf.text(118, 46, 'EMERGENCY RESPONSE (800) 424-9300');

        pdf.rect(108, 47, 100, 15);
        pdf.setFontSize(10);
        pdf.setTextColor(68, 106, 46);
        pdf.text(127, 51, 'CHEMTRAC CONTRACT # CCN 203596');
        pdf.setTextColor(0, 0, 0);

        //row 4
        pdf.rect(8, 62, 200, 5);
        pdf.setFontSize(12);
        pdf.text(93, 66, 'DESCRIPTION');

        //row 5
        pdf.rect(8, 67, 135, 6);
        pdf.setFontSize(10);
        pdf.text(40, 71, '[ ] Delivered');
        pdf.text(87, 71, '[X] Gallons');

        pdf.rect(143, 67, 40, 6);

        pdf.rect(183, 67, 25, 6);
        pdf.text(190, 71, 'TOTAL');

        function drawRow(data, order?) {
            let col1 = '';
            let col2 = '';
            let col3 = '';
            if (order) {
                col1 = `${order['Tank'].Address.alias} - ${order['Tank'].Address.street}, ${order['Tank'].Address.city}, ${order['Tank'].Address.state} ${order['Tank'].Address.zip}`;
                col2 = `${order.deliveryNumber}`;
                col3 = `${order.quantityOrdered}`;
            }

            editableText(col1, 8,   data.variableY + data.item, 130, 5);
            editableText(col2, 138, data.variableY + data.item, 22, 5);
            editableText('', 160, data.variableY + data.item, 23, 5);
            editableText(col3, 183, data.variableY + data.item, 25, 5, {color: '#a9a9a9'});
            data.item += 5;
            data.count += 1;
        }

        let drawProductSection = (productType, dot) => {
            //row 6
            pdf.rect(8, variableY, 200, 15);
            pdf.setFontSize(8);

            pdf.text(10, variableY + 4, '[X] ' + dot, {maxWidth: 150});
            pdf.text(10, variableY + 13, `[${this.isDot12412(route) ? 'X' : ' '}] DOT-SP-12412`);
            pdf.text(85, variableY + 13, `[${this.isDot12516(route) ? 'X' : ' '}] DOT-SP-12516`);
            pdf.setFontSize(12);
            pdf.rect(183,  variableY, 25, 10);
            editableText(this.routeService.calculateDeliveryOrdersGallons(uniqProduct, productType).toString(), 183, variableY, 25, 10);

            //row 7
            pdf.rect(8, variableY + 15, 50, 5);
            pdf.setFillColor(255, 255, 255);
            pdf.setDrawColor(68, 106, 48);
            pdf.rect(58, variableY + 15, 15, 5, 'FD');
            pdf.rect(73, variableY + 15, 70, 5);
            pdf.setFontSize(10);
            pdf.text(87, variableY + 18, 'TOTAL IBC');
            pdf.setFillColor(255, 255, 255);
            pdf.setDrawColor(68, 106, 48);
            pdf.rect(143, variableY + 15, 25, 5, 'FD');
            pdf.rect(168, variableY + 15, 40, 5);
            pdf.text(170, variableY + 18, 'CARGO TANK');

            //row 8
            pdf.setDrawColor(0);
            pdf.setFillColor(220, 220, 220);
            pdf.rect(8, variableY + 20, 30, 5, 'FD');
            pdf.setFontSize(10);
            pdf.setTextColor(138, 7, 7);
            pdf.text(9, variableY + 24, 'BATCH #');
            pdf.setFillColor(255, 255, 255);
            pdf.setDrawColor(255, 0, 0);
            pdf.rect(38, variableY + 20, 45, 5, 'FD');
            pdf.setDrawColor(0);
            pdf.setFillColor(220, 220, 220);
            pdf.rect(83, variableY + 20, 55, 5, 'FD');
            pdf.setDrawColor(0);
            pdf.setFillColor(220, 220, 220);
            pdf.rect(138, variableY + 20, 22, 5, 'FD');
            pdf.setFontSize(8);
            pdf.setTextColor(0, 0, 0);
            pdf.text(143, variableY + 24, 'Delivery #');
            pdf.setDrawColor(0);
            pdf.setFillColor(220, 220, 220);
            pdf.rect(160, variableY + 20, 23, 5, 'FD');
            pdf.text(165, variableY + 24, 'ONBOARD');
            pdf.setDrawColor(0);
            pdf.setFillColor(220, 220, 220);
            pdf.rect(183, variableY + 20, 25, 5, 'FD');
            pdf.setFillColor(255, 255, 255);

            //row 9 / repeating
            pdf.setFontSize(9);
            variableY = variableY + 25;

            // populate grid with order info
            let data = {
                item: 0,
                count: 0,
                variableY: variableY
            };
            let lqaField = 0;

            uniqProduct.forEach((order: any) => {
                if (order.productId === productType) {
                    pdf.rect(8, variableY + lqaField, 130, 5);
                    pdf.rect(138, variableY + lqaField, 22, 5);
                    pdf.rect(160, variableY + lqaField, 23, 5);
                    pdf.rect(183, variableY + lqaField, 25, 5);
                    lqaField += 5;
                    drawRow(data, order);
                }
            });

            //row 10
            variableY = variableY + lqaField;
            pdf.rect(8, variableY, 130, 5);
            pdf.rect(138, variableY, 22, 5);
            pdf.setDrawColor(0);
            pdf.setFillColor(220, 220, 220);
            pdf.rect(160, variableY, 23, 5, 'FD');
            pdf.text(161, variableY + 4, 'Left On Board');
            pdf.setFillColor(255, 255, 255);
            pdf.rect(183, variableY, 25, 5);

            variableY = variableY + 5;
        };
        variableY = 73;


        const PRODUCT_ORDER = {
            1: 2,
            2: 1
        };

        const productKeys = Object.keys(productDict);
        // Set lqa first and hcho second;
        productKeys.sort((a, b) => {
            a = PRODUCT_ORDER[a] || a;
            b = PRODUCT_ORDER[b] || b;
            return parseInt(a) - parseInt(b);
        });

        productKeys.forEach((productType, index) => {
            //calculate if this product can fit on the same page or if we need a new one
            let height = 0;
            uniqProduct.forEach((order: any) => {
                if (order.productId === parseInt(productType)) {
                    height += 5;
                }
            });
            if (index === Object.keys(productDict).length - 1) {
                height += 51;
            } else {
                height += 30;
            }

            if ((variableY + height) > 270) {
                pdf.addPage();
                variableY = 10;
            }

            drawProductSection(parseInt(productType), productDict[productType]);
        });

        pdf.rect(8, variableY, 200, 15);
        pdf.setFontSize(10);
        pdf.text(9, variableY + 4, 'This is to certify that the above named materials are properly classified, described');
        pdf.text(9, variableY + 8, 'packaged, marked and labeled, and are in proper condition for transportation');
        pdf.text(9, variableY + 12, 'according to the Applicable Regulations of the Department of Transportation');
        pdf.setDrawColor(0);
        pdf.setFontSize(8);
        pdf.setFillColor(255, 255, 255);

        variableY = variableY + 15;
        pdf.setDrawColor(0, 0, 205);
        pdf.setFillColor(255, 255, 255);
        pdf.rect(8, variableY, 135, 10);
        pdf.setFontSize(10);
        pdf.text(9, variableY + 6, 'Name');
        pdf.text(70, variableY + 6, 'Signature');
        pdf.setDrawColor(0);
        pdf.setFillColor(220, 220, 220);
        pdf.rect(143, variableY, 40, 10, 'FD');
        pdf.text(146, variableY + 6, 'Left On Board Total');
        pdf.setFillColor(255, 255, 255);
        pdf.rect(183, variableY, 25, 10);

        /*========= Delivery Order Sheets ===========*/
        var ordersArray: any = [];
        var isSp12412 = this.isDot12412(route);
        var isSp12516 = this.isDot12516(route);

        route.deliveryOrders.forEach((o) => {
            ordersArray.push(o);
        });

        var locations: any = [];

        ordersArray = _.orderBy(ordersArray, ['dropOrder'], ['asc']);

        ordersArray.forEach((o: any) => {
            let location = locations.find(location => {
                return location.id === o.Tank.addressId;
            });

            if (!location) {
                locations.push({id: o.Tank.addressId, orders: [o]});
            } else {
                let order = location.orders.find(order => {
                    return order.productId === o.productId;
                });
                if (!order) {
                    location.orders.push(o);
                }
            }
        });

        let fullWidth = 162;

        let drawDeliveryProductSection = (yMargin, order, isOffice) => {
            //row 6
            pdf.rect(25, yMargin, fullWidth, 12);
            pdf.setFontSize(6);

            if (order && order.Product.dot) {
                pdf.text(29, yMargin + 3, `[X] ${order.Product.dot}`, {maxWidth: 100});
            }

            pdf.text(27, yMargin + 11, `[${isSp12412 && order ? 'X' : ' '}] DOT-SP-12412`);
            pdf.text(85, yMargin + 11, `[${isSp12516 && order ? 'X' : ' '}] DOT-SP-12516`);

            pdf.rect(167, yMargin, 20, 7);
            pdf.setFontSize(12);
            yMargin += 12;

            //row 7
            pdf.rect(25, yMargin, 37, 4);
            pdf.rect(62, yMargin, 24, 4);
            pdf.rect(86, yMargin, 45, 4);
            pdf.setFontSize(8);
            pdf.text(89, yMargin + 3, 'TOTAL IBCs');
            pdf.rect(131, yMargin, 20, 4);
            pdf.rect(151, yMargin, 36, 4);
            pdf.text(153, yMargin + 3, 'CARGO TANK');
            yMargin += 4;


            /*********** Delivery Alerts To Loop through **********/
            let deliveryAlertNote: string = "";

            //row 8
            if (isOffice && order && order.Tank.Address.Notes && order.Tank.Address.Notes.length > 0) {
                let deliveryAlerts = _.filter(order.Tank.Address.Notes, { notesTypeId: 2 });

                for (var i = 0; i < deliveryAlerts.length; i++) {
                    deliveryAlertNote = deliveryAlertNote.concat(deliveryAlerts[i]['content'] + ' | ');
                }

                deliveryAlertNote = deliveryAlertNote.slice(0, -3);

            }

            deliveryAlertNote = pdf.splitTextToSize(deliveryAlertNote, 95);

            pdf.rect(25, yMargin, 106, 16);
            editableText(deliveryAlertNote, 25, yMargin, 106, 16, {color: '#ff0000', multiline: true});
            for (let i = 0; i < 4; i++) {
                pdf.rect(131, yMargin + (4 * i), 14, 4);
                pdf.rect(145, yMargin + (4 * i), 22, 4);
                pdf.rect(167, yMargin + (4 * i), 20, 4);
            }
            pdf.setTextColor(0, 0, 0);
            yMargin += 16;
            return yMargin;

        };

        let drawDeliverySheet = (orders, yMargin, isOffice) => {
            let copy = isOffice ? 'OFFICE COPY' : 'CUSTOMER COPY';
            //row 1
            pdf.rect(25, yMargin, fullWidth, 28);
            pdf.addImage(`${this.logo}`, 'JPEG', 26, yMargin + 1, 70, 12);
            pdf.setFontSize(8);
            pdf.text(31, yMargin + 16, '4672 E DRUMMOND AVE');
            pdf.text(31, yMargin + 21, 'FRESNO, CA 93725');
            pdf.text(31, yMargin + 26, 'PHONE: (559) 862-6611  FAX: (559) 268-2795');
            pdf.setFontSize(10);
            pdf.text(132, yMargin + 11, 'DELIVERY TICKET');
            pdf.setFontSize(8);
            pdf.text(126, yMargin + 21, `DATE: ${date}`);
            pdf.text(121, yMargin + 26, `Delivery #: ${orders[0].deliveryNumber} `);
            yMargin += 28;

            //row 2
            pdf.setFillColor(247, 255, 0);
            pdf.rect(25, yMargin, fullWidth, 4, "FD");
            pdf.setFontSize(10);
            pdf.text(95, yMargin + 3, copy);
            yMargin += 4;

            //row 3
            pdf.rect(25, yMargin, fullWidth / 2, 18);
            pdf.setFontSize(8);
            pdf.text(28, yMargin + 4, `TO: ${orders[0].Tank.Address.alias}`);
            pdf.text(28, yMargin + 8, `ADDRESS: ${orders[0].Tank.Address.street}`);
            pdf.text(28, yMargin + 13, `CITY STATE ZIP: ${orders[0].Tank.Address.city}, ${orders[0].Tank.Address.state} ${orders[0].Tank.Address.zip}`);
            pdf.text(28, yMargin + 17, 'Phone');

            pdf.setFontSize(8);
            pdf.text(52, yMargin + 4, '');
            pdf.text(52, yMargin + 8, '');
            pdf.text(52, yMargin + 13, '');
            pdf.text(52, yMargin + 17, '');

            pdf.rect(106, yMargin, fullWidth / 2, 9);
            pdf.setFontSize(10);
            pdf.setTextColor(138, 7, 7);
            pdf.text(115, yMargin + 4, 'EMERGENCY RESPONSE (800) 424-9300');
            pdf.setFontSize(8);
            pdf.setTextColor(68, 106, 46);
            pdf.text(123, yMargin + 8, 'CHEMTREC CONTRACT # CCN 203596');
            pdf.setTextColor(0, 0, 0);

            pdf.rect(106, yMargin + 9, fullWidth / 2, 9);
            pdf.setFontSize(8);
            pdf.setTextColor(255, 0, 0);
            pdf.setFontStyle('bold');
            pdf.text(115, yMargin + 12, 'Formaldehyde tank & plumbing inspected and');
            pdf.text(115, yMargin + 16, 'found to be in compliant condition ________ (initial)');
            pdf.setTextColor(0, 0, 0);
            pdf.setFontStyle('normal');
            yMargin += 18;

            //row 4
            pdf.rect(25, yMargin, fullWidth, 4);
            pdf.text(97, yMargin + 3, 'DESCRIPTION');
            yMargin += 4;

            //row 5
            pdf.rect(25, yMargin, 107, 5);
            pdf.text(52, yMargin + 3, '[ ] Delivered');
            pdf.text(87, yMargin + 3, `[X] Gallons`);
            pdf.rect(132, yMargin, 35, 5);
            pdf.rect(167, yMargin, 20, 5);
            pdf.text(174, yMargin + 3, 'TOTAL');
            yMargin += 5;


            // Set lqa first and hcho second;
            orders.sort((orderA, orderB) => {
                let a = orderA.Product.id;
                let b = orderB.Product.id;
                a = PRODUCT_ORDER[a] || a;
                b = PRODUCT_ORDER[b] || b;
                return a - b;
            });

            orders.forEach(order => {
                yMargin = drawDeliveryProductSection(yMargin, order, isOffice);
            });

            if (orders.length === 1) {
                yMargin = drawDeliveryProductSection(yMargin, null, false);
            }

            // row 12
            pdf.rect(25, yMargin, 106, 5);
            pdf.text(26, yMargin + 4, 'RECEIVED BY:');
            pdf.text(66, yMargin + 4, 'SIGNATURE:');
            pdf.rect(131, yMargin, 36, 5);
            pdf.text(135, yMargin + 4, 'DATE:');
            pdf.rect(167, yMargin, 20, 5);

            yMargin += 15;

            return yMargin;
        };

        locations.forEach((location: any) => {
            let yMargin = 3;
            pdf.addPage();

            yMargin = drawDeliverySheet(location.orders, yMargin, true);
            if (location.orders.length > 2) {
                pdf.addPage();
                yMargin = 3;
            }
            drawDeliverySheet(location.orders, yMargin, false);
        });

        pdf.save(pdfName);
        Object.defineProperty = originalDefineProperty;
    }
}
