import * as _ from 'lodash';

import {TankType} from './tank-type';
import {Product} from './product';
import {Address} from './address';
import {TankDevice} from './tank-device';
import { Client } from '../clients/client';
import { DeliveryOrder } from "./delivery-order";

export class Tank{
    public id: number = null;
    public tankName: string = null;
    public addressId: number = null;
    public clientId: number = null;
    public tankTypeId: number = null;
    public productId: number = null;
    public serviceThreshholdAlert: number = null;
    public serviceThreshholdCritical: number = null;
    public expAvgDailyUse: number = null;
    public isActive: boolean | number = 1;
    public currentGallons: number = null;
    public TankType: TankType = null;
    public Product: Product = null;
    public gallonsToFull: number= null;
    public Address: Address = null;
    public address: Address = null;
    public TankDevice: TankDevice = null;
    public tankDeviceId: number = null;
    public dropDate:any = null;
    public routeId:any = null;
    public Client: Client;
    public updatedAt: string;
    public tankTypeName: string;
    public tankTypeAbbr: string;
    public modelNumber: string;
    public capacityVolume: number;
    public brandName: string;
    public tankTypeDescription: string;
    public deliveryOrders: DeliveryOrder[];

    constructor(data?: any){
        _.merge(this, data);
    }

}
