import { Component } from '@angular/core';
import { ChildrenOutletContexts, Router } from '@angular/router';
import { routeAnimation } from '../animations';
import { AuthService } from '../authorization/auth.service';

@Component({
  templateUrl: './admin.component.html',
  styleUrls: ['admin.component.css'],
  animations: [routeAnimation]
})
export class AdminComponent {
  isAdmin = this.auth.isAdmin();

  constructor(
    private router: Router,
    private auth: AuthService,
    private contexts: ChildrenOutletContexts
  ) { }

  getView() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.view;
  }
}
