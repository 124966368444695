import {Component} from '@angular/core';

import {TractorService} from '../../services-module/services/tractor.service';
import {TrailerService} from '../../services-module/services/trailer.service';
import {CargoService} from   '../../services-module/services/cargo.service';

import {Tractor} from '../../models/tractor';
import {Trailer} from '../../models/trailer';
import * as _ from 'lodash';

@Component({
    templateUrl: './vehicle.component.html',
    styleUrls:['vehicle.component.css']
})
export class VehicleComponent{
    tractors: Tractor[];
    selectedTractor: Tractor;
    trailers: Trailer[];
    selectedTrailer: Trailer;
    ftp:boolean = false;

    constructor(
        private tractorService: TractorService,
        private trailerService: TrailerService,
        private cargoService: CargoService
    ){}

    ngOnInit(): void{
        this.selectedTractor = new Tractor(null);
        this.selectedTrailer = new Trailer(null);
        this.getTrailers();
        this.getTractors();
    }

    //tractor controls
    getTractors(): void{
        this.tractorService.getTractors()
            .then((res)=>{
                this.tractors = res;
            });
    }

    setTractor(tractor: Tractor){
        this.selectedTractor = _.clone(tractor);
    }

    resetTractor(event: string) {
        this.selectedTractor = new Tractor(null);
    }

    saveTractor(tractor: Tractor){
        this.ftp = true;
        this.tractorService.saveTractor(tractor)
            .then((res)=>{
                let origTractor = _.find(this.tractors, {id: tractor.id});
                if(origTractor){
                    _.merge(origTractor, res);
                }else{
                    this.tractors.push(res);
                }
                this.ftp = false;
                this.selectedTractor = new Tractor(null);
            })
    }

    //Trailer controls
    getTrailers(): void{
        this.trailerService.getTrailers()
            .then((res)=>{
                this.trailers = res;
            });
    }

    setTrailer(trailer: Trailer){
        this.selectedTrailer = _.cloneDeep(trailer);
    }

    resetTrailer(event: string) {
        this.selectedTrailer = new Trailer(null);
    }

    saveTrailer(trailer: Trailer){
        this.ftp = true;
        this.trailerService.saveTrailer(trailer)
            .then((res)=>{
                let origTrailer = _.find(this.trailers, {id: trailer.id});
                if(origTrailer){
                    _.merge(origTrailer, res);
                }else{
                    this.trailers.push(_.merge(trailer, res));
                }
                this.selectedTrailer = new Trailer(null);

                trailer.Cargos.forEach((cargo, i )=> {
                    if (!cargo.trailerId) {
                        cargo.trailerId = res.id
                    }
                    this.cargoService.saveCargo(cargo)
                        .then((freshCargo) => {
                            if (origTrailer) {
                                origTrailer.Cargos[i] = freshCargo;
                            }
                        })
                });

                this.ftp = false;

            })
    }
}