
import { Pipe, PipeTransform } from '@angular/core';
import { Tank } from '@models/tank';
import { TankService } from '@services/tank.service';

@Pipe({ name: 'appTankStatus' })
export class TankStatusPipe implements PipeTransform {
  constructor(private tankService: TankService) { }

  transform(tank: Tank): any {
    return this.tankService.calculateTankStatus(tank);
  }
}
