import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {S3StatesService} from '../../../s3-states/s3-states.service';
import {FacilityService} from '../../../services-module/services/facility.service';
import {Facility} from '../../../models/facility';
import {ToastService} from "../../../services-module/services/toast.service";

@Component({
    templateUrl: './facility.component.html',
    styleUrls: ['facility.component.css'],
    selector: 'facility'
})
export class FacilityComponent implements OnInit{
    constructor(
        private facilityService: FacilityService,
        private toastService:ToastService,
        private s3StatesService: S3StatesService
    ){}
    states: any[] = this.s3StatesService.states;
    facilities: Facility[] = [];
    facility: Facility;
    saveSuccess: boolean = null;
    saveFail: boolean = null;
    ftp:boolean = false;

    ngOnInit(){
        this.facilityService.getFacilities()
            .then(res => this.facilities = res);
        this.facility = new Facility();
    }

    setFacility(facility: Facility): void{
        this.facility = new Facility(facility);
    }

    clearFacility():void{
        this.facility = new Facility();
    }

    saveFacility(facility: Facility): void{
        this.ftp = true;
        this.facilityService.saveFacility(facility)
            .then((res)=>{
                if(facility.id){
                    _.merge(_.find(this.facilities, {id: facility.id}), res)
                }else{
                    this.facilities.push(res);
                }
                this.ftp = false;
                this.toastService.toast({message:'Your changes have been saved', type:'success'});
            })
    }
}