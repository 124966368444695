import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { HttpService } from '../../../shared-services/http-service/http-service.service';
import { AuthService } from '../../../authorization/auth.service';
import { ToastService } from '../../../services-module/services/toast.service';

@Injectable()
export class NormAuthResolver implements Resolve<any[]> {
  constructor(
    private http: HttpService,
    private auth: AuthService,
    private toastService: ToastService
  ) { }

  async resolve(route: ActivatedRouteSnapshot) {
    const user = await this.http.getSessionUser();
    if (!user.errorMessage) {
      return user.data;
    } else {
      this.auth.logout();
      this.toastService.toast({message: 'Session has expired! Please login to view report.', type: 'warning'});
    }
  }
}
