import * as _ from "lodash";
import * as moment from "moment";

import { WorkOrder } from "./work-order";
import { DeliveryOrder } from "./delivery-order";
import { Trailer } from "./trailer";

export class Route {
  public deliveryOrders: DeliveryOrder[] = [];
  public workOrders: WorkOrder[] = [];
  public products: {
    id?: number;
    routeId?: number;
    productId?: number;
    loaded?: number;
  }[];
  public locations: any[] = [];
  public driverId: number = null;
  public tractorId: number = null;
  public trailerId: number = null;
  public Trailer: Trailer = null;
  public lqaLoaded: number = null;
  public hchoLoaded: number = null;
  public dateOfRoute: any = moment().format("YYYY-MM-DD");
  public id: number = null;
  public startLocationId: number = null;
  public endLocationId: number = null;
  public employeeId: number = null;
  public routeType: string = null;
  public routeName: string = null;
  public driverIds: number[] = [];
  public EmployeeRoutes: any = [];
  public completedAt: Date;
  constructor(data?: any) {
    _.merge(this, data);
  }
}
