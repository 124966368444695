<div *ngIf="!messages.open" class="view-name padding-bottom-10">
    <h6>Welcome! Please Login Below</h6>
</div>

<!--Alerts regarding login-->
<div *ngIf="messages.open" class="alert alert-danger">
    <a href="javascript:void(0);" (click)="closeMessages()" class="pull-right">x</a>
    <span>{{messages.message}}</span>
</div>
<!--Alerts regarding login End-->

<form #loginForm="ngForm" (submit)="submit(); loginForm.reset()">
    <div class="form-group">
        <label for="email">Email</label>
        <input id="email"
               name="email"
               [(ngModel)]="cred.email"
               required
               type="text"
               #credEmail="ngModel"
               placeholder="you@email.com"
               class="form-control">
        <!--Attribute for checking valid email-->
        <!--pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"-->

        <div *ngIf="credEmail.errors && (credEmail.dirty || credEmail.touched)"
             class="alert alert-danger">
            <div [hidden]="!credEmail.errors.required">
                Email is required
            </div>
        </div>
    </div>

    <div class="form-group">
        <label for="password">Password</label>
        <input id="password"
               name="password"
               [(ngModel)]="cred.password"
               type="password"
               required
               #credPass="ngModel"
               class="form-control">
        <div *ngIf="credPass.errors && (credPass.dirty || credPass.touched)"
             class="alert alert-danger">
            <div [hidden]="!credPass.errors.required">
                Password is required
            </div>
        </div>
    </div>

    <div class="post-form">
        <div class="forgot-text">
            <a (click)="forgot()">Forgot Password?</a>
        </div>
        <button type="submit" class="btn btn-primary pull-right">
            Login
        </button>
    </div>
</form>
