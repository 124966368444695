import * as _ from 'lodash';

export class Client {
    constructor(data?: any) {
        _.merge(this, data);
    }
    id: number = null;
    name: string = null;
    Addresses: any[] = [];
    mailingAddresses: any[] = [];
    deliveryAddresses: any[] = [];
    customerSince: any = null;
    image: any = [];
    salesRepId: number = null;
    updatedAt: any = null;
    profilePic: string = null;
    isActive = true;
    isClient = false;
}
