import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {ProductService} from '../../services-module/services/product.service';
import {Product} from '../../models/product';

@Component({
    templateUrl: './product.component.html',
    styleUrls:['product.component.css']
})
export class ProductComponent implements OnInit{
    products: Product[];
    selectedProduct: Product;
    ftp:boolean = false;

    constructor(private productService: ProductService){}

    ngOnInit(): void{
        this.selectedProduct = new Product();
        this.getProducts();
    }

    getProducts(): void{
        this.productService.getProducts()
            .then((res)=>{
                this.products = res;
            });
    }

    setProduct(product: Product){
        this.selectedProduct = _.clone(product);
    }

    resetProduct(event: string) {
        this.selectedProduct = new Product();
    }

    saveProduct(product: Product){
        this.ftp = true;
        this.productService.saveProduct(product)
            .then((res)=>{
                let origProduct = _.find(this.products, {id: product.id});
                if(origProduct){
                    _.merge(origProduct, res);
                }else{
                    this.products.push(res);
                }
                this.ftp = false;
                this.selectedProduct = new Product();
            })
    }

}

