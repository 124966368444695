import {Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable({providedIn: 'root'})
export class MultiSelectService{
    //containers is an array of possible containers to move items to and from
    containers: any[] = [];
    constructor(){}

    //checks an array of ids. If id does not exist and it, if it does remove it
    public storeId(id: number, arr: any[]): void{
        
        if(!arr.find((item: any)=>{
                return item === id;
            })){
            arr.push(id);
        }else{
            let index = arr.findIndex((item: any)=>{
                return item === id;
            });
            arr.splice(index, 1);
        }
    }

    //finds all object of an array that has ids that match ids in an array of ids set to be moved and returns a new
    //array of items
    public removeAndReturnItems(containerName: string, originalArr: any[]): any[]{
        let container = this.findContainer(containerName);
        let newArr:any[] = [];
        container.idsToMove.forEach((id: number)=>{
            newArr.push(_.find(container.targetArray, {id: id}));
            originalArr.splice(container.targetArray.findIndex((item: any)=>{
                return item.id === id;
            }), 1);
        });
        container.idsToMove = [];
        return newArr;
    }

    //adds selected item ids to array of ids and applies the appropriate class styling to the items
    selectLocation(event: any, target: any, containerName: string): void{
        let targetContainer = this.findContainer(containerName);
        this.addLocationToIdList(target['id'], targetContainer);
    }

    //deselects all selected items
    deselectAll(containerName: string):void{
        
        let container = this.findContainer(containerName);
        let parent = document.getElementById(container.containerParent);
        let children = parent.children;
        container.targetArray.forEach((item: any, index: number)=>{
            children[index].classList.remove(container.selectedClass);
        });
        container.idsToMove = [];
    }

    addLocationToIdList(id: number, container: any): void{
        
        this.storeId(id, container.idsToMove);
    }

    //adds a container to the containers array
    addContainer(container: any): void{
         
        this.containers.push(container);
    }

    //destroys a container
    removeContainer(containerName: string):void{
        
        _.remove(this.containers, (container)=>{
            return container.name === containerName;
        });
    }

    //find container for manipulation
    findContainer(name: string): any{
        
        return _.find(this.containers, {name: name});
    }

    //update the target array of a container
    updateContainerArray(containerName: string, targetArray: any[]){
        
        let container = this.findContainer(containerName);
        container.targetArray = targetArray;
    }

    updateContainer(name: string, container: any): void{
        
        this.removeContainer(name);
        this.addContainer(container);
    }

}