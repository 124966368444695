import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Route } from "../../models/route";
import { ToastService } from "../../services-module/services/toast.service";
import { RouteService } from "../../services-module/services/route.service";
import { Product } from "../../models/product";

@Component({
    selector: 'app-loaded-levels',
    templateUrl: 'loaded-levels.component.html',
    styleUrls: ['loaded-levels.component.css', '../route-single/route-single.component.css']
})
export class LoadedLevelsComponent implements OnInit {
    @Input() route: Route;
    @Input() productDetails: Product[] = [];
    @Output() onUpdate = new EventEmitter<boolean>();
    editProduct: Product = null;
    editLoaded = 0;
    ftp = false;

    constructor(private toastService: ToastService, private routeService: RouteService){}

    ngOnInit(){
    }

    editLevel(product:any){
        this.editProduct = product;
        this.editLoaded = product.level;
    }

    updateLoadedProduct(gals:any){
        if(gals < this.editProduct.ordered) {
            return this.toastService.toast({message:'Total Gallons cannot be less than the amount ordered', type:'danger'})
        }

        let product = this.route.products.find(product => {
            return product.productId === this.editProduct.id;
        });

        if (!product) {
            product = {routeId: this.route.id, productId: this.editProduct.id, loaded: gals};
        } else {
            product.loaded = gals;
        }

        this.ftp = true;
        this.routeService.updateProduct(product).then(newProduct => {
            this.toastService.toast({message:'You have successfully saved your changes', type:'success'});
            let index = this.route.products.findIndex(product => {
                return product.productId === this.editProduct.id;
            });
            if (index >= 0) {
                this.route.products[index] = newProduct;
            } else {
                this.route.products.push(newProduct);
            }
            this.editProduct = null;
            this.ftp = false;
            this.onUpdate.emit(true);
        });
    }
}
