import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { environment } from '@environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../authorization/auth.service';
import { UserService } from '../services-module/services/user.service';
import {
  isArray as _isArray
} from 'lodash';
import { ConfirmComponent } from '@shared/confirm/confirm.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'mobile-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.css']
})

export class MobileNavbarComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    public auth: AuthService,
    private userService: UserService,
    private modalService: BsModalService
  ) { }

  menuOpen = false;
  subMenu = false;
  isAdmin = this.auth.isAdmin();
  isSalesrepOutside = this.auth.isSalesrepOutside();
  driver = this.userService.getEmployeeId();
  version = environment.appVersion;
  appData = require('../../../ngsw-config.json').appData;
  versionModalRef: BsModalRef;
  employeeTypes = localStorage.getItem('employeeTypes') || '{}';
  destroyed$ = new Subject();

  ngOnInit() {
    this.subMenu = window.location.href.split('/')[4] === 'admin' ? true : false;

    this.router.events
    .pipe(takeUntil(this.destroyed$))
    .subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.employeeTypes = localStorage.getItem('employeeTypes');
      }
    });
  }
  logout() {
    this.auth.logout();
  }
  openMenu() {
    this.menuOpen = !this.menuOpen;
    if (this.menuOpen) {
      this.subMenu = window.location.href.split('/')[4] === 'admin' ? true : false;
    }
  }
  onResize() {
    this.menuOpen = false;
  }
  closeNav() {
    this.menuOpen = false;
    this.subMenu = false;
  }
  openSubMenu() {
    this.subMenu = !this.subMenu;
  }

  goToDriver() {
    const employeeId = this.userService.getEmployeeId();
    this.router.navigateByUrl(`/driver/${employeeId}`);
  }

  showVersion() {
    if (!this.versionModalRef) {
      const { version, notes } = this.appData as { version: string, notes: string[] },
        initialState = {
          confirmTitle: 'Latest Update Notes',
          itemList: _isArray(notes) ? notes as string[] : undefined,
          confirmMsg: `${version}:${_isArray(notes) ? '' : ' ' + notes} Latest update includes the following changes.`,
          ok: 'OK',
          cancel: '',
        };
      this.versionModalRef = this.modalService.show(ConfirmComponent, { initialState, class: 'confirm-modal' });
      this.modalService.onHidden
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => this.versionModalRef = undefined);
    }
    this.closeNav();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

/*
 * Method to filter view for employee role
 * ngIf="auth.employeeType(['Office','Lab-Tech','Sales Rep','Field Service']) || auth.isAdmin()"
 *
 * */
