<div id="profile-component">
    <div class=" header">
        <div class="container-fluid">
            <h2><i class="fa fa-user-circle"></i> User Profile</h2>
        </div>
    </div>

    <div class="row">
        <div class="container-fluid">
            <div class="col-12 col-lg-8">
                <div class="user-info-box">
                    <table class="table-bordered table-striped table-hover shadow" *ngIf="employee">
                        <thead>
                            <tr>
                                <th colspan="2">
                                    <h3><i class="fa fa-vcard"></i> Employee Information</h3>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Employee Name</th>
                                <td>{{employee.firstName}} {{employee.lastName}}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{{employee.email}}</td>
                            </tr>
                            <tr>
                                <th>Employee Role</th>
                                <td>| <span *ngFor="let role of employee.EmployeeEmployeeTypes">{{role.EmployeeType ? role.EmployeeType.employeeType: '--'}} | </span></td>
                            </tr>
                            <tr>
                                <th>Active Status</th>
                                <td>{{employee.isActive ? 'Active': 'Inactive'}}</td>
                            </tr>
                            <tr>
                                <th>Last Updated</th>
                                <td>{{employee.updatedAt | date}}</td>
                            </tr>
                            <tr>
                                <th>Password</th>
                                <td><a href="javascript:void(0);" (click)="changePass = true">Update</a></td>
                            </tr>
                            <tr *ngIf="alert.open && alert.successHeading">
                                <td colspan="2" class="error-message">
                                    <div class="alert alert-success" >
                                        <span>{{alert.successHeading}}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="alert.open && alert.errorMessage">
                                <td colspan="2" class="error-message">
                                    <div class="alert alert-danger">
                                        <span>{{alert.errorMessage}}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr class="changePassword" *ngIf="changePass">
                                <td colspan="2">
                                    <form #changePassword="ngForm" name="changePassword">
                                        <div class="form-group">
                                            <label for="currentPass">Current Password</label>
                                            <input type="password"
                                                #currentPassword="ngModel"
                                                id="currentPass"
                                                required
                                                name="currentPassword"
                                                [(ngModel)]="creds.oldPassword">
                                            <div *ngIf="currentPassword.errors && (currentPassword.dirty || currentPassword.touched)"
                                                class="alert alert-danger">
                                                <div [hidden]="!currentPassword.errors.required">
                                                    Please Enter a password
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="newPassword">New Password</label>
                                            <input type="password"
                                                #newPassword="ngModel"
                                                id="newPassword"
                                                required
                                                name="newPassword"
                                                [(ngModel)]="creds.newPassword">
                                            <div *ngIf="newPassword.errors && (newPassword.dirty || newPassword.touched)"
                                                class="alert alert-danger">
                                                <div [hidden]="!newPassword.errors.required">
                                                    Please Enter a password
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="confirm">Confirm New Password</label>
                                            <input type="password"
                                                #confirmPassword="ngModel"
                                                id="confirm"
                                                confirmEqual="newPassword"
                                                name="confirmPassword"
                                                [(ngModel)]="creds.confirmPassword">
                                            <div *ngIf="confirmPassword.errors && (confirmPassword.dirty || confirmPassword.touched)"
                                                class="alert alert-danger">
                                                <div [hidden]="!confirmPassword.errors.required">
                                                    Please Enter a password
                                                </div>
                                                <div [hidden]="confirmPassword.valid">
                                                    Password mismatch
                                                </div>
                                            </div>
                                        </div>

                                        <div class="input-group-btn">
                                            <button class="btn btn-success" [disabled]="!changePassword.form.valid" (click)="updatePassword(); changePassword.reset();">Update</button>
                                            <div class="btn btn-warning" (click)="close(); changePassword.reset(); ">Cancel</div>
                                        </div>
                                    </form>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
