import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { WorkOrder } from '@models/work-order';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'work-order-table',
  templateUrl: './work-orders-table.component.html',
  styleUrls: ['./work-orders-table.component.css']
})

export class WorkOrderTableComponent implements OnInit {

  @Input() workOrders: { rows: WorkOrder[], count: number } = { rows: [], count: 0 };
  @Input() query: any;
  @Input() maxSize: any;
  @Input() totalItems: any;
  @Input() sortColumn: any;
  @Input() headers: any;
  @Input() sorting: any;

  // old variables
  @Output() changeStatus = new EventEmitter<WorkOrder>();

  rowId: number = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
  ) {

  }

  ngOnInit(): void {

  }

  updateStatus(workOrder: WorkOrder) {
    this.changeStatus.emit(workOrder);
  }

  sort(key: string) {
    if (key) {
      const sortOptions = this.sorting[key];

      if (this.sortColumn === key) {
        sortOptions.asc = !sortOptions.asc;
      } else {
        this.sortColumn = key;
      }

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          sort: `${sortOptions.asc ? '' : '-'}${sortOptions.properties.join('.')}`
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  pageChanged({ page, itemsPerPage }) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { page, pageSize: +itemsPerPage },
      queryParamsHandling: 'merge'
    });
  }

  openRow(rowId: number): void {
    this.rowId = rowId;
  }

  closeRow(): void {
    this.rowId = null;
  }

  woStatus(val: any) {
    return this.utilsService.woStatus(val);
  }

}
