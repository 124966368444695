<div class="panel">
    <table class="table table-hover">
        <thead>
            <tr>
                <td>Number</td>
                <td><button (click)="active = !active;clearTractor()" type="button" class="btn btn-sm btn-light pull-right">Show {{active ? 'Inactive' : 'Active'}}</button></td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let tractor of scopedTractors()" (click)="selectTractor(tractor)" [ngClass]="{'selected':isSelected(tractor)}">
                <td>{{ tractor.number }}</td>
                <td><div [ngClass]="{'active':tractor.isActive}" class="tractor-status">{{tractor.isActive ? 'Active' : 'Inactive'}}</div></td>
            </tr>
        </tbody>
    </table>
</div>