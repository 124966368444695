<div class="client-detail-header"></div>
<div>
    <client-details [client]="client"
                    [clients]="clients"
                    [clientsDairies]="clientsDairies"
                    [currentIndex]="currentIndex"
                    *ngIf="!editClientMode"
                    (toggleEditClient)="changeEditMode($event)"></client-details>
    <client-edit [client]="client"
                 *ngIf="editClientMode"
                 (saveClient)="saveClient($event)"
                 (toggleEditMode)="changeEditMode($event)"></client-edit>
</div>
<div  class="row padding-top-20 margin-top-10">
    <div class="col-md-6 col-lg-3">
        <h4>Delivery Locations</h4>
        <div class="form-group form-inline">
            <select name="delivery locations"
                    id="deliveryLocations"
                    *ngIf="client && client.deliveryAddresses"
                    [(ngModel)]="selectedDeliveryAddress"
                    class="form-control location-select-input"
                    (ngModelChange)="changeUrl($event)">
                <option [ngValue]="location"
                        *ngFor="let location of client.deliveryAddresses"
                        >
                    <span *ngIf="location.isActive">{{ location.alias }} {{ location.isActive ? '' : '- Inactive' }}</span>
                </option>
            </select>
            <a href="javascript:;" (click)="createNewDelAddress()"> Add New</a>
        </div>
        <address-info [address]="selectedDeliveryAddress"
                      *ngIf="selectedDeliveryAddress?.id !== null && !editDelAddress"
                      (editAddress)="editDeliveryAddress()"></address-info>
        <address-edit *ngIf="editDelAddress"
                      [isDelivery]="true"
                      [states]="states"
                      [serviceRegions]="serviceRegions"
                      [address]="editableDeliveryAddress"
                      (cancelEdit)="cancelDelAddressEdit($event)"
                      (toMailing)="saveMailAddress($event)"
                      (saveAddress)="saveDelAddress($event)"></address-edit>
    </div>
    <div class="col-md-6 col-lg-3">
        <h4>Mailing Locations</h4>
        <div class="form-group form-inline">
            <select name="mailing locations"
                    id="mailingLocations"
                    [(ngModel)]="selectedMailingAddress"
                    class="form-control">
                <option [ngValue]="location"
                        *ngFor="let location of mailingAddresses">
                    {{ location.street }}
                </option>
            </select>
            <a href="javascript:;"
               (click)="createNewMailAddress()"> Add New</a>
        </div>
        <address-info [address]="selectedMailingAddress"
                      *ngIf="selectedMailingAddress && !editMailAddress"
                      (editAddress)="editMailingAddress()"></address-info>
        <address-edit *ngIf="editMailAddress"
                      [address]="editableMailingAddress"
                      [states]="states"
                      [serviceRegions]="serviceRegions"
                      (cancelEdit)="cancelMailAddressEdit($event)"
                      (saveAddress)="saveMailAddress($event)"></address-edit>
    </div>
    <div class="clearfix visible-md"></div>
    <div class="col-md-6 col-lg-3">
        <h4>Dairy Contacts</h4>
        <div class="form-group form-inline" *ngIf="clientContacts">
            <select name="clientContact"
                    class="form-control"
                    [(ngModel)]="selectedContact"
                    id="clientContact">
                <option *ngFor="let contact of clientContacts"
                        [ngValue]="contact">{{contact.name}}</option>
            </select>
            <a href="javascript:;"
               (click)="createNewContact()"> Add New</a>
        </div>
        <div class="form-group form-inline" *ngIf="!clientContacts">
            <!--<h5 style="font-style: italic; color: red">Select a Delivery Location</h5>-->
        </div>
        <contact-info *ngIf="selectedContact && !editContact"
                      [contact]="selectedContact"
                      (editContact)="editClientContact()"></contact-info>
        <contact-edit *ngIf="editContact"
                      (cancelEdit)="cancelContactEdit()"
                      (saveContact)="saveContact($event)"
                      [contact]="selectedContact"></contact-edit>
    </div>

    <div class="col-md-6 col-lg-3">
        <h4>Sales Representatives </h4>
        <div>
            <select name="sales rep"
                    id="salesRep"
                    #salesRepSelect="ngModel"
                    class="form-control"
                    [(ngModel)]="salesRep.salesRepId">
              <option value="">-- Select a Sales Representative --</option>
              <option [value]="salesRep.id" *ngFor="let salesRep of salesReps">
                  {{ salesRep.lastName }}, {{ salesRep.firstName }} ({{SALES_REP_TYPES[salesRep.salesRepType]}})
                </option>
            </select>
            <button (click)="addClientRep(salesRep)" [disabled]="!salesRep.salesRepId" class="btn btn-success btn-sm margin-top-10">Add</button>
        </div>
        <div class="form-group" *ngIf="clientReps.length > 0" style="margin-top: 10px;">
            <h5 class="border-bottom" style="color: #999;">Client Representatives</h5>
            <div class="row sales-rep" *ngFor="let rep of clientReps">
                <div class="col-12">
                    <div>{{rep?.Employee?.lastName}}, {{rep?.Employee?.firstName}} <a href="javascript:void(0)" (click)="clientToRemove(rep)">remove</a></div>
                    <div>{{rep?.Employee?.email}}</div>
                    <div class="separator"></div>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="clientReps.length == 0">
            <h5>No sales representatives to display</h5>
        </div>
    </div>
</div>

<address  *ngIf="selectedDeliveryAddress?.id" [currentAddress]="selectedDeliveryAddress" (setContacts)="setContacts($event)" [clientsDairies]="clientsDairies"></address>

<pdfs-panel [contact]="selectedContact" [address]="selectedMailingAddress" [deliveryAddress]="selectedDeliveryAddress" [client]="client"></pdfs-panel>

<prompt-modal
        #removeClientRepModal
        size="small">
    <div class="modal-header">
        <h4 class="modal-title">Remove Client Sales Rep? </h4>
    </div>

    <div class="modal-item">
        <div class="col-12">
            <p>Are you sure you would like to remove {{removeClient.Employee?.firstName}}  {{removeClient.Employee?.lastName}} as a Sales Rep?</p>
        </div>
    </div>

    <div class="modal-buttons">
        <button (click)="removeClientRep();removeClientRepModal.hidePromptModal()" type="submit" class="btn btn-sm btn-danger">Delete</button>
        <button type="button" class="btn btn-sm btn-warning" (click)="cancelRemove(); removeClientRepModal.hidePromptModal()">Cancel</button>
    </div>

</prompt-modal>



<!-- Loading Spinner -->
<div class="loading-overlay" *ngIf="ftp">
    <img src="/assets/images/specialtylogo.png" alt="logo"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
