import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PromptModalComponent } from "../prompt-modal/prompt-modal.component";

@Component({
  selector: 'app-prompt-modal-helper',
  templateUrl: './prompt-modal-helper.component.html',
  styleUrls: ['./prompt-modal-helper.component.css']
})
export class PromptModalHelperComponent implements OnInit {
  @ViewChild('modal', { static: true }) private modal:PromptModalComponent;
  @Input() header = 'Update';
  @Input() title = 'Are you sure you want to update?';
  @Input() confirm = 'Save';
  @Input() cancel = 'Cancel';
  onSave = () => {};

  constructor() {}

  ngOnInit() {}

  save() {
    this.onSave();
    this.modal.hidePromptModal();
  }

  showPromptModal(onSave) {
    this.onSave = onSave;
    this.modal.showPromptModal();
  }

}
