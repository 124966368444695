<div class="toast-container">
  <alert
    appZoom
    *ngFor="let toast of toasts; let i = index"
    [dismissOnTimeout]="toast.timeout"
    [type]="toast.type"
    dismissible="true"
    (onClose)="closeAlert(i)"
    ><i [class]="toastIcon(toast.type)" aria-hidden="true"></i>
    {{ toast.message }}</alert
  >
</div>
