import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from '../clients/client';
import { WorkOrder } from '@models/work-order';
import { Route } from '@models/route';
import { Lab } from '@models/lab';
import { ServiceType } from '@models/service-type';
import { Employee } from '@models/employee';
import { EmployeeService } from '../services-module/services/employee.service';
import { Subject } from 'rxjs';
import { pairwise, takeUntil } from 'rxjs/operators';
import { DashboardService } from '@services/dashboard.service';

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit, OnDestroy {
  clients: Client[];
  workOrders: WorkOrder[];
  routes: Route[];
  statusTypes: ServiceType[];
  labs: Lab[];
  maxSize = 5;
  itemsPerPage = 50;
  totalClients = 0;
  totalRoutes = 0;
  totalOrders = 0;
  totalLabs = 110;
  clientPage = 1;
  routePage = 1;
  orderPage = 1;
  labPage = 1;
  sortableOptions = {
    delay: 200,
    delayOnTouchOnly: false,
    onEnd: () => {
      this.employeeService.setDashboardOrder(this.employee);
    }
  };
  employee: Employee;
  destroyed$ = new Subject();

  constructor(
    private dashboard: DashboardService,
    private employeeService: EmployeeService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    const { data } = this.activatedRoute.snapshot;
    const { queryParams } = this.activatedRoute.snapshot;
    this.employee = data.employee;
    this.employee.dashboardOrder = this.employee.dashboardOrder || [1, 2, 3];
    this.clients = data.clients.rows;
    this.totalClients = data.clients.count;
    this.workOrders = data.workOrders.rows;
    this.totalOrders = data.workOrders.count;
    this.routes = data.routes.rows;
    this.totalRoutes = data.routes.count;
    this.statusTypes = data.statusTypes;
    this.labs = data.labs.rows;
    this.totalLabs = data.labs.count;
    this.clientPage = +queryParams.clientPage || 1;
    this.routePage = +queryParams.routePage || 1;
    this.orderPage = +queryParams.orderPage || 1;
    this.labPage = +queryParams.labPage || 1;
    this.subscribeToParams();
  }

  subscribeToParams() {
    this.activatedRoute.queryParams
      .pipe(
        pairwise(),
        takeUntil(this.destroyed$)
      )
      .subscribe(([prev, curr]) => {
        if (prev.clientPage !== curr.clientPage) {
          this.dashboard.getRecentClients({ clientPage: +curr.clientPage || 1, itemsPerPage: this.itemsPerPage })
            .then(data => {
              this.clients = data.rows;
              this.totalClients = data.count;
            });
        }

        if (prev.routePage !== curr.routePage) {
          this.dashboard.getRecentRoutes({ routePage: +curr.routePage || 1, itemsPerPage: this.itemsPerPage })
            .then(data => {
              this.routes = data.rows;
              this.totalRoutes = data.count;
            });
        }

        if (prev.orderPage !== curr.orderPage) {
          this.dashboard.getRecentWorkOrders({ orderPage: +curr.orderPage || 1, itemsPerPage: this.itemsPerPage })
            .then(data => {
              this.workOrders = data.rows;
              this.totalOrders = data.count;
            });
        }

        if (prev.labPage !== curr.labPage) {
          this.dashboard.getRecentLabs({ labPage: +curr.labPage || 1, itemsPerPage: this.itemsPerPage })
            .then(data => {
              this.labs = data.rows;
              this.totalLabs = data.count;
            });
        }
      });
  }

  pageChanged(pagination: { page: number, itemsPerPage: number }, entity: string) {
    const queryParams: {
      clientPage: number,
      routePage: number,
      orderPage: number,
      labPage: number
    } = {
      clientPage: this.clientPage,
      routePage: this.routePage,
      orderPage: this.orderPage,
      labPage: this.labPage
    };

    switch (entity) {
      case 'clients':
        this.clientPage = queryParams.clientPage = pagination.page;
        break;
      case 'routes':
        this.routePage = queryParams.routePage = pagination.page;
        break;
      case 'orders':
        this.orderPage = queryParams.orderPage = pagination.page;
        break;
      case 'labs':
        this.labPage = queryParams.labPage = pagination.page;
        break;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
