import {Component, Input, Output, EventEmitter} from '@angular/core';

import {ServiceRegion} from '../../../../models/service-region';
import * as _ from 'lodash';

@Component({
    templateUrl: './service-region-list.component.html',
    selector: 'service-region-list',
    styleUrls: ['./service-region-list.component.css']
})
export class ServiceRegionListComponent{
    @Input() serviceRegions: ServiceRegion[];
    @Input() selectedId:number;
    @Output() setServiceRegion = new EventEmitter<ServiceRegion>();
    @Output() resetServiceRegion = new EventEmitter<string>();
    active:number = 1;
    constructor(){}

    selectServiceRegion(serviceRegion: ServiceRegion):void{
        this.setServiceRegion.emit(serviceRegion);
    }

    isSelected(serviceRegion:ServiceRegion) {
        if (this.selectedId) {
            return serviceRegion.id == this.selectedId;
        }
        return false;
    }

    activeRegions() {
        return _.filter(this.serviceRegions, (r:ServiceRegion) => r.isActive == this.active );
    }

    clearRegion(){
        this.resetServiceRegion.emit('reset');
    }
}