<s3h-pagination [items]="columns" 
                [displayNumber]="10" 
                (filteredItems)="updateVisibleColumns($event)">
  <table class="table-lmnt paginate card-box-shadow">
    
    <thead>
      <tr>
        <td colspan="3">
          <h1 *ngIf="config && config.title" class="table-title">Data Dictionary {{ config.title ? 'for ' + config.title : '' }}</h1>
          <div class="table-description">
            <div class="col-md-1 no-padding-sides inline-block desc">Description:</div>
            <div class="col-md-11 no-padding-sides inline-block" [ngClass]="{'width-auto': !expandInput, 'width-full': expandInput}">
              <flex-input [config]="flexConfig"
                          [flexModel]="{key: 'description',
                                        value: config?.description,
                                        belongsToID: belongsToID,
                                        displayProp: 'schema_nice_name',
                                        belongsToTable: flexConfig.table}"
                          (onUpdateSuccess)="updateSuccess($event)"
                          (expandInput)="setDescWidth($event)"></flex-input>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="td-20">Attribute</td>
        <td class="td-60">Description</td>
        <td class="td-20">Data Type</td>
        <!--<td class="td-25">Default Value</td>
        <td class="td-10">Field Length</td>
        <td class="td-25">Constraint</td>-->
      </tr>
    </thead>
    <tbody class="tbody" [ngStyle]="{height: tbodyHeight}">
      <ng-container *ngIf="columns?.length == 0">
        <tr>
          <td class="td-100">No Data Found.</td>
        </tr>
      </ng-container>
      <ng-container *ngIf="columns?.length > 0">
        <tr *ngFor="let column of visibleColumns">
          <td class="td-20">
            <flex-input [config]="flexConfig"
                        [flexModel]="{key: 'nice_name',
                                      value: column.nice_name && column.nice_name !== '' ? column.nice_name : column.column_name ? startCase(column.column_name) : '-',
                                      belongsToID: belongsToID,
                                      displayProp: column.column_name,
                                      belongsToTable: flexConfig.table}"
                        (onUpdateSuccess)="updateSuccess($event)"></flex-input>
          </td>
          <td class="td-60">
            <flex-input [config]="flexConfig"
                        [flexModel]="{key: 'desc',
                                     value: column.desc,
                                     belongsToID: belongsToID,
                                     displayProp: column.column_name,
                                     belongsToTable: flexConfig.table}"
                        (onUpdateSuccess)="updateSuccess($event)"></flex-input>
          </td>
          <td class="td-20">{{column.data_type ? startCase(column.data_type) : '-'}}</td>
          <!--<td class="td-25">{{column.column_default ? column.column_default : 'null'}}</td>
          <td class="td-10">{{column.maxlength ? column.maxlength : '-'}}</td>
          <td class="td-25">{{column.column_key ? startCase(column.column_key) : 'null'}}
            <span *ngIf="column.column_key == 'foreign' && column.key_reference">
              (<span>Table&nbsp;:&nbsp;{{column.key_reference.table}}</span>,&nbsp;&nbsp;
              <span>Attribute&nbsp;:&nbsp;{{column.key_reference.foreignKey}}</span>)
            </span>
          </td>-->
        </tr>
      </ng-container>
    </tbody>
  </table>
</s3h-pagination>