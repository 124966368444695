<div class="route-locations-wrapper page-break">
	<div class="panel table-wrapper">
		<div class="row padding-top-15 green-white-bkg">
			<div class="col-md-12 flex-container flex-space-between">
				<h3 *ngIf="workOrder">Work Orders</h3>
				<h3 *ngIf="!workOrder">Delivery Orders</h3>
				<div *ngIf="deleteSuccessful" class="bg-success padding-15">
					Delivery Order Removed Successfully
				</div>
			</div>
		</div>
		<div class="delivery-wrapper">
			<div class="delivery-scroll">
				<div class="title-row row">
					<div class="col-sm-2" *ngIf="workOrder">Date Opened</div>
					<div class="col-sm-3" *ngIf="!workOrder">Tank Info</div>
					<div class="col-sm-3">Dairy Info</div>
					<div class="col-sm-3" *ngIf="!workOrder">Usage Info</div>
					<div class="col-sm-2" *ngIf="!workOrder">Quantity</div>
					<div class="col-sm-1" *ngIf="workOrder">Service Type</div>
					<div class="col-sm-4" *ngIf="workOrder">Details</div>
					<div class="col-sm-1 no-print">Sorting</div>
				</div>
				<div class="route-container"
					 id="route-container"
					 [sortablejs]="route.locations"
					[sortablejsOptions]="sortableOptions">
					<div id="route-location"
					     [class.hover]="!dragging"
						 class="location-container row"
						 *ngFor="let location of route.locations"
						 (dblclick)="selectLocation($event, location)">

						<div class="col-sm-2" *ngIf="workOrder">
							{{location.createdAt | date}}
						</div>

						<div class="col-sm-3" *ngIf="!workOrder">
							<span
								[ngClass]="{'hcho-green': location?.productId == 1, 'lqa-blue':location?.productId == 2}">{{
								location?.tankName}}</span>
							<br />Type: <span
								[ngClass]="{'hcho-green': location?.productId == 1, 'lqa-blue':location?.productId == 2}">{{
								location?.Product.abbr}}</span>
						</div>

						<div class="col-sm-3">
							<span (mouseenter)="showNotes(location.id)" (mouseleave)="closeNotes()"
								*ngIf="location.Notes?.length > 0" class="notes-box">
								<i class="fa fa-sticky-note" style="color:red"></i>
								<div class="widget-hover-2" *ngIf="showingNotes == location.id">
									<div class="text-center note-title">{{location.Address[0]?.alias}}</div>
									<ul class="note-content">
										<li *ngFor="let note of location.Notes">{{note.content}}</li>
									</ul>
								</div>
							</span>
							{{location?.Address[0].alias}}<br />
							{{ location?.Address[0].street }}<br />
							{{ location?.Address[0].city}}, {{ location?.Address[0].state }} {{ location?.Address[0].zip }}
						</div>

						<div class="col-sm-3" *ngIf="!workOrder">
							Level: <strong>{{ location?.currentGallons }}</strong> <br />
							Max: <strong>{{ location?.capacityVolume }}</strong> <br />
							Actual Avg/Daily: <b class="margin-left-10"
								[ngClass]="{'calc-daily-use':location?.calculatedDailyUse == 0 || null}"> {{
								dailyCalculatedDailyUse(location) }} </b><br />
							Projected Avg/Daily: <b class="margin-left-10"
								[ngClass]="{'calc-daily-use':location?.expAvgDailyUse == 0 || null}">{{
								dailyExpAvgDailyUse(location) }}</b>
						</div>

						<div class="col-sm-2" *ngIf="!workOrder">
							<input id="gallonsToFull" type="number" min="0" class="form-control"
								[(ngModel)]="location.gallonsToFull" (keydown)="notNegative($event)"
								(change)="updateQuantity()">
						</div>
						<div class="col-sm-1" *ngIf="workOrder">
							{{location.serviceTypeName}}
						</div>
						<div class="col-sm-4" *ngIf="workOrder">
							{{location.note}}
						</div>
						<div class="col-sm-1 no-print">
							<span>
								<i id="move-up" class="fa fa-arrow-circle-up"
									(click)="moveUp(location); mustSetManual()"></i>
							</span>
							<span>
								<i id="move-down" class="fa fa-arrow-circle-down"
									(click)="moveDown(location); mustSetManual()"></i>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="route_dragbar"></div>
		<route-details [route]="route" [workOrder]="workOrder" [drivers]="drivers" [facilities]="facilities"
			[totalGallons]="totalGallons" [tractors]="tractors" [setManual]="setManual" (saveRoute)="save($event)"
			(setManualOrder)="setManOrder($event)" (copyRoute)="copy($event)" (cancelCopyRoute)="cancelCopy()"
			(reloadRoute)="reloadRoute.emit(true)" [copying]="copying" [saving]="saving"
			[trailers]="trailers"></route-details>
		<div *ngIf="saveSuccessful" class="bg-success padding-15">
			Route saved successfully.
		</div>
		<div *ngIf="saveError" class="bg-danger padding-15">
			There was an error saving your route. Please try again.
		</div>
		<div *ngIf="copying" class="bg-success padding-15">
			Route copy in progress. Click save to save the new route.
			<!--<button class="btn btn-primary btn-sm pull-right" [routerLink]="['/edit-route/' + copyLink]" (click)="resetLink()">Go to copied route.</button>-->
		</div>
	</div>
</div>