<div bsModal
     #promptModal="bs-modal"
     class="modal fade"
     tabindex="-1"
     role="dialog"
     aria-labelledby="confirmModal"
     aria-hidden="true"
     (onHidden)="hide()">
    <div class="modal-dialog" [ngClass]="{'modal-sm': size=='small', 'modal-lg':size=='large'}">
        <div class="modal-content noselect">
            <ng-content select=".modal-header"></ng-content>
            <div *ngIf="promptTitle" class="modal-header">
                <h4 class="modal-title">{{promptTitle}}</h4>
            </div>
            <div class="modal-body">
                <div class="container-fuid">
                    <div class="row prompt-content">
                        <ng-content select=".modal-item"></ng-content>
                    </div>
                </div>
            </div>
            <hr class="modal-divider">
            <ng-content select=".modal-buttons"></ng-content>
        </div>
    </div>
</div>