import * as _ from 'lodash';

export class TankType{
    public defaultPercentCritical: number = null;
    public defaultPercentAlert: number = null;
    public PK_TankType: number = null;
    public id: number = null;
    public tankTypeName: string = null;
    public tankTypeAbbr: string = null;
    public tankTypeDescription: string = null;
    public brandName: string = null;
    public modelNumber: string = null;
    public capacityVolume: number = null;
    public isActive: boolean = true;

    constructor(data?: any){
        _.merge(this, data);
    }
}
