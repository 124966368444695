import {Component, Input, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import {Route} from "../../models/route";
import {UtilsService} from "../../services-module/services/utils.service";

@Component({
    selector: 'driver-route-table',
    templateUrl: 'driver-table.component.html',
    styleUrls: ['../drivers.component.css', './driver-table.component.css']
})

export class DriverTableComponent {
    @Input() routes: Route[];
    @Input() driverOption:any;
    @Input() workOrder:boolean;
    @Output() getNewRoute = new EventEmitter<number>();

    constructor(
        private router: Router,
        private utilsService: UtilsService){}

    getNumberOfDrops(route: Route):number{
        return route.deliveryOrders ? route.deliveryOrders.length : 0;
    }

    getNumberOfWorkOrders(route: Route):number{
        return route.workOrders ? route.workOrders.length : 0;
    }

    getRoute(route: Route){
        this.getNewRoute.emit(route.id);
    }

    activeStatus(completed:any, scheduled:any){
        return this.utilsService.activeStatus(completed, scheduled);
    }

}

