import * as _ from 'lodash';
export class Note{
    constructor(data?: any){
        _.merge(this, data);
    }

    public id: number = null;
    public content: string = null;
    public Note: string = null;
    public workOrderId: number = null;
    public clientId: number = null;
    public employeeId: number = null;
    public Employee: any= {};
    public notableId?: number = null;
    public isActive?: boolean = true;
    public employeeName?: string = '';
    public createdAt?: string = null;
    public notesTypeId?: string = null;
}
