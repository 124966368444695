import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ServiceType } from '@models/service-type';
import {
  Subject,
  Subscription
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';

@Component({
  templateUrl: 'work-order-nav.component.html',
  styleUrls: ['work-order-nav.component.css'],
  selector: 'work-order-nav'
})

export class WorkOrderNavComponent implements OnInit, OnDestroy {

  public searchWorkOrdersModelChanged: Subject<string> = new Subject<string>();
  private searchWorkOrdersModelChangeSubscription: Subscription;

  @Input() query: any;
  @Input() serviceTypes: ServiceType[];

  @Output() updateSearchQuery = new EventEmitter<any>();
  @Output() toggleDisplayDetail = new EventEmitter<any>();
  @Output() switchingOrderType = new EventEmitter<any>();

  orderTypesBindingList = [{
    name: 'All Orders',
    OrderType: 0
  }, {
    name: 'Open Orders',
    OrderType: 1
  }, {
    name: 'Closed Orders',
    OrderType: 2
  }, {
    name: 'My Orders',
    OrderType: 3
  }];

  detailsBindingList = [{
    name: 'More Details',
    DeatilType: 'more'
  }, {
    name: 'Less Details',
    DeatilType: 'less'
  }];

  searchByBindingList = [{
    value: '',
    text: 'Search By'
  }, {
    value: 'Client',
    text: 'Dairy Name'
  }, {
    value: 'Address',
    text: 'City'
  }, {
    value: 'createdBy',
    text: 'Opened By'
  }, {
    value: 'employeeTo',
    text: 'Assigned To'
  }, {
    value: 'orderNumber',
    text: 'Order #'
  }];

  constructor() {

  }

  ngOnInit(): void {
    this.searchWorkOrdersModelChangeSubscription = this.searchWorkOrdersModelChanged.pipe(
      debounceTime(2000),
      distinctUntilChanged()).subscribe(searchResult => {
        this.switchingOrderType.emit(false);
        this.query.searchStringValue = searchResult;
        this.UpdateSearchQuery();
      });
  }

  selectedStartDate(event: any) {
    this.switchingOrderType.emit(false);
    this.query.startDate = event;
    this.UpdateSearchQuery();
  }

  selectedEndDate(event: any) {
    this.switchingOrderType.emit(false);
    this.query.endDate = event;
    this.UpdateSearchQuery();
  }

  // Select Details
  selectedOrdersSearch(event: any) {
    this.switchingOrderType.emit(true);
    this.query.selectedOrderType = event;
    this.UpdateSearchQuery();
  }

  // Select Details
  toggleopenAll(event: any) {
    this.switchingOrderType.emit(false);
    this.query.openAll = event;
    this.ToggleDisplayDetail();
  }

  // service type
  searchServiceTypeValue(event: any) {
    this.switchingOrderType.emit(false);
    this.query.selectedServiceType = event;
    this.UpdateSearchQuery();
  }

  // Search By
  searchByValue(event: any) {
    if (event === '') {
      this.query.searchStringValue = null;
    }
    this.switchingOrderType.emit(false);
    this.query.selectedSearchBy = event;
    this.UpdateSearchQuery();
  }

  UpdateSearchQuery() {
    this.updateSearchQuery.emit(this.query);
  }

  ToggleDisplayDetail() {
    this.toggleDisplayDetail.emit(this.query);
  }

  ngOnDestroy() {
    this.searchWorkOrdersModelChangeSubscription.unsubscribe();
  }
}
