import { Routes, RouterModule } from "@angular/router";
import { ClientComponent } from "./client.component";
import { ClientSingleComponent } from "./client-single/client-single.component";
import { AuthGuard } from "../authorization/auth-guard.service";
import {
  ClientsResolve,
  DocumentsTypeResolve,
  ClientsSalesLeadsPaginationResolve,
  ClientsDairiesResolve,
  GetClientsPaginationResolve,
  ClientSingleResolve,
} from "../resolvers/client.resolver";
import {
  EmployeesResolve,
  LabTechResolve,
  SalesRepsResolve,
} from "../resolvers/employees.resolver";
import { AddressLabworkComponent } from "./address/address-labwork/address-labwork.component";
import { ServiceRegionResolve } from "../resolvers/route.resolver";
import { FindLabsResolve } from "../resolvers/lab.resolver";

const clientRoutes: Routes = [
  {
    path: "clients",
    component: ClientComponent,
    canActivate: [AuthGuard],
    data: {
      view: "clients",
    },
    resolve: {
      clients: GetClientsPaginationResolve,
    },
  },
  {
    path: "clients/sales-leads",
    component: ClientComponent,
    canActivate: [AuthGuard],
    data: {
      view: "sales-leads",
    },
    resolve: {
      clients: ClientsSalesLeadsPaginationResolve,
    },
  },
  {
    path: "clients/add",
    component: ClientSingleComponent,
    canActivate: [AuthGuard],
    data: {
      view: "clients-add",
    },
  },
  {
    path: "clients/:id",
    component: ClientSingleComponent,
    canActivate: [AuthGuard],
    data: {
      view: "client",
    },
    resolve: {
      clientSingle: ClientSingleResolve,
      documentTypes: DocumentsTypeResolve,
      employees: EmployeesResolve,
      salesReps: SalesRepsResolve,
      clients: ClientsResolve,
      clientsDairies: ClientsDairiesResolve,
      serviceRegions: ServiceRegionResolve,
    },
  },
  {
    path: "clients/:id/address/:addressId",
    component: ClientSingleComponent,
    canActivate: [AuthGuard],
    data: {
      view: "client-address",
    },
    resolve: {
      clientSingle: ClientSingleResolve,
      documentTypes: DocumentsTypeResolve,
      employees: EmployeesResolve,
      salesReps: SalesRepsResolve,
      clients: ClientsResolve,
      clientsDairies: ClientsDairiesResolve,
      serviceRegions: ServiceRegionResolve,
    },
  },
  {
    path: "labs",
    component: AddressLabworkComponent,
    canActivate: [AuthGuard],
    data: {
      view: "labs",
    },
    resolve: {
      clientsDairies: ClientsDairiesResolve,
      labs: FindLabsResolve,
      serviceRegions: ServiceRegionResolve,
      labTechs: LabTechResolve,
    },
  },
];

export const clientRouting = RouterModule.forChild(clientRoutes);
