<div class="admin-container">
    <div id="admin-topnav" class="admin-sidebar">
        <nav class="admin-menu">
            <ul>
                <li *ngIf="isAdmin" routerLink="/admin/employees" routerLinkActive="admin-active" class="side-pointer"><i class="fa fa-vcard"></i><br class="mobile-br" /> Employees</li>
                <li *ngIf="isAdmin" routerLink="/admin/vehicles" routerLinkActive="admin-active" class="side-pointer"><i class="fa fa-truck"></i><br class="mobile-br" /> Vehicles</li>
                <li *ngIf="isAdmin" routerLink="/admin/products" routerLinkActive="admin-active" class="side-pointer"><i class="fa fa-product-hunt"></i><br class="mobile-br" /> Product</li>
                <li *ngIf="isAdmin" routerLink="/admin/tanks" routerLinkActive="admin-active" class="side-pointer"><i class="fa fa-tasks"></i><br class="mobile-br" /> Tanks</li>
                <li *ngIf="isAdmin" routerLink="/admin/client-management" routerLinkActive="admin-active" class="side-pointer"><i class="fa fa-exchange"></i><br class="mobile-br" /> Group<span class="tablet"> Management</span></li>
                <li *ngIf="isAdmin" routerLink="/admin/inactive" routerLinkActive="admin-active" class="side-pointer"><i class="fa fa-tasks"></i><br class="mobile-br" /> Inactive</li>
                <li *ngIf="isAdmin" routerLink="/admin/misc" routerLinkActive="admin-active" class="side-pointer"><i class="fa fa-list"></i><br class="mobile-br" /> Misc</li>
                <li routerLink="/admin/reporting" routerLinkActive="reporting" class="side-pointer"><i class="fa fa-list"></i><br class="mobile-br" /> Reporting</li>
                <li routerLink="/admin/bovistride" routerLinkActive="bovistride" class="side-pointer"><i class="fa fa-list"></i><br class="mobile-br" /> Bovistride</li>
            </ul>
        </nav>
    </div>
    <div class="min-100">
        <div [@routerAnimation]="getView()" class="position-relative">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
