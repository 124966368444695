import {Component, OnInit, OnDestroy, Output, EventEmitter} from "@angular/core";
import {Subscription} from "rxjs";
import {SettingsService} from "../../services-module/services/settings.service";
import {Router, ActivatedRoute} from "@angular/router";
import { Employee } from "../../models/employee";
import { EmployeeService } from "../../services-module/services/employee.service";
import { UserService } from "../../services-module/services/user.service";
@Component({
    selector: 'settings',
    templateUrl:'./settings.component.html',
    styleUrls:['./settings.component.css']
})

export class SettingsComponent implements OnInit, OnDestroy{
    private subscription:Subscription;
    @Output() close = new EventEmitter<any>();

    constructor(private settingsService:SettingsService,
                private router:Router,
                private employeeService: EmployeeService,
                private userService: UserService,
                private activatedRoute:ActivatedRoute){}

    show:boolean = false;
    confirming:string;
    alert:boolean = false;
    landingUrl: string = '';

    ngOnInit(){
        this.subscription = this.settingsService.settings$.subscribe((value) => {
            this.show = value.show;
        });

        this.employeeService.getLanding().then(res => {
          this.landingUrl = res;
        });
    }

    ngOnDestroy(){
        this.subscription.unsubscribe();
    }

    closeSettings(){
        this.close.emit();
    }

    /*
    * Confirm the saving of this page as landing page
    *
    * */
    confirm(note:string){
        this.confirming = note;
    }

    /*
    * Cancel the saving of the settings
    *
    * */
    cancel(){
        this.confirming = null;
    }

    update() {
      this.employeeService.setLanding(this.landingUrl).then(() => {
        this.confirming = null;
        this.showAlert();
      });
    }

    /*
    * Save this page as the landing page
    *
    * */
    saveAsLanding(){
      this.landingUrl = this.router.url;
      this.update();
    }

    /*
    * Remove page as landing page
    *
    * */
    removeLandingPage(){
        this.landingUrl = '';
        this.update();
    }

    showAlert(){
        this.alert = true;
        setTimeout(()=>{
            this.alert = false;
        }, 3000);
    }
}
