<div class="route-view-wrapper">
    <div class=" header no-print">
        <div class="container-fluid">
            <h2><i class="fa fa-map"></i> Route</h2>
        </div>
    </div>

    <div class="row no-print">
        <div class="col-md-12">
            <!--<div class="pull-right no-print">-->
                <!--<button *ngIf="auth.isAdmin() && location.path() !== '/new-route'" class="btn btn-primary margin-left-10 new-btn" routerLink="/new-route" (click)="ngOnInit()">-->
                    <!--New Route-->
                <!--</button>-->
                <!--<button *ngIf="!searching" class="btn btn-primary margin-left-10 search-btn" (click)="routeOptions.search()"><i class="fa fa-search"></i></button>-->
                <!--<button *ngIf="searching" type="button" class="btn btn-primary margin-left-10 search-btn" disabled><i class="fa fa-spin fa-refresh"></i></button>-->
                <!--<button class="btn btn-warning cancel-btn" (click)="cancelRoute()">Cancel</button>-->
                <!--<button class="btn btn-danger margin-left-10 search-btn" title="Clear Search" (click)="routeOptions.clearParams()"><i class="fa fa-repeat"></i></button>-->
            <!--</div>-->
            <route-options #routeOptions
                           [route]="route"
                           [workOrder]="workOrder"
                           [cities]="cities"
                           [states]="states"
                           [query]="query"
                           [routeParams]="routeParams"
                           [serviceRegions]="serviceRegions"
                           [products]="products"
                           (cancelRoute)="cancelRoute()"
                           (newRoute)="newRoute()"
                           (savePrefs)="savePrefs()"
                           (clearPrefs)="clearPrefs()"
                           (searchLocations)="searchLocations($event)"></route-options>
        </div>
    </div>
    <div class="padding-top-15 no-print">
        <client-locations [clientLocations]="clientLocations"
                          [workOrder]="workOrder"
                          [route]="route"
                          (saveRoute)="saveRoute($event)"
                          (moveToRoute)="moveToRoute($event)"></client-locations>
        <div class="padding-top-20 page-break">
            <route-overview [route]="route"
                            [drivers]="drivers"
                            [facilities]="facilities"
                            [totalGallons]="totalGallons"
                            [tractors]="tractors"
                            (saveRoute)="saveRoute($event)"
                            (copyRoute)="copyRoute($event)"
                            (cancelCopyRoute)="cancelCopy()"
                            [trailers]="trailers"
                            [saveSuccessful]="saveSuccessful"
                            [saveError]="saveError"
                            [copying]="copying"
                            [saving]="saving"
                            [setManual]="setManual"
                            (setManualOrder)="setManualOrder($event)"
                            (mustSetOrder)="mustSetOrder()"
                            (moveLocationDown)="moveLocationDown($event)"
                            (moveLocationUp)="moveLocationUp($event)"
                            [deleteSuccessful]="deleteSuccessful"
                            (updateQuantities)="updateQuantities($event)"
                            (reloadRoute)="ngOnInit()"
                            [workOrder]="workOrder"
                            (removeFromRoute)="removeFromRoute($event)"></route-overview>
        </div>
    </div>
    <div id="route-map">
        <!--<button class="btn btn-warning" (click)="setManualOrder()">manual order</button>-->
      <s3-route-map [directions]="directions"
                    [targetId]="'map-1'"
                    [route]="route"
                    (mapRoute)="mapRoute($event)"
                    (optimizeRoute)="getOptimizedRoute($event)"
                    (reverseOrder)="reverseRoute()"
      ></s3-route-map>
    </div>
    <work-order-print [route]="route" [orders]="route.locations" *ngIf="workOrder"></work-order-print>
</div>

<div class="loading-overlay" *ngIf="searching">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
