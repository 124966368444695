import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthGuard } from '../authorization/auth-guard.service';
import { CurrentEmployeeResolve } from '../resolvers/employees.resolver';
import { ProfileComponent } from './profile.component';

const profileRoutes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: {
      view: 'profile'
    },
    resolve: {
      employee: CurrentEmployeeResolve
    }
  }
];

export const profileRouting = RouterModule.forChild(profileRoutes);
