<div class="panel">
    <table class="table table-hover">
        <thead>
        <tr>
            <td>Number</td>
            <td>Weight Limit</td>
            <td><button (click)="active = !active;clearTrailer()" type="button" class="btn btn-sm btn-light pull-right">Show {{active ? 'Inactive' : 'Active'}}</button></td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let trailer of scopedTrailers()" (click)="selectTrailer(trailer)" [ngClass]="{'selected':isSelected(trailer)}">
            <td>{{ trailer.name }}</td>
            <td>{{ trailerCargo(trailer)}}</td>
            <td><div [ngClass]="{'active':trailer.isActive}" class="trailer-status">{{trailer.isActive ? 'Active' : 'Deleted'}}</div></td>
        </tr>
        </tbody>
    </table>
</div>