import { Component, OnDestroy, ViewChild, ElementRef, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { AuthService } from '@authorization/auth.service';
import { skip, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-confirm',
  styleUrls: ['./confirm.component.scss'],
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements OnInit, OnDestroy {
  confirmTitle!: string;
  confirmMsg!: string;
  itemList?: string[];
  record: any;
  ok = 'OK';
  cancel = 'Cancel';
  confirmInput: UntypedFormControl = new UntypedFormControl('');
  @ViewChild('okBtn') okBtn!: ElementRef;
  @ViewChild('cancelBtn') cancelBtn!: ElementRef;
  destroyed$ = new Subject();

  cb: (record: any) => void = () => { };
  cancelCb?: () => void = () => { };
  close: () => void = () => { };

  constructor(
    public bsModalRef: BsModalRef,
    private auth: AuthService
  ) {
  }

  ngOnInit() {
    this.auth.loggedIn$
      .pipe(
        skip(1),
        takeUntil(this.destroyed$)
      )
      .subscribe((loggedIn: boolean) => {
        this.doCancel();
      });
  }

  confirm(doCB: boolean = true) {
    if (doCB) {
      this.cb(this.record || true);
    }

    this.hide();
  }

  doCancel() {
    this.cancelCb();
    this.hide();
  }

  hide() {
    this.bsModalRef?.hide();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
