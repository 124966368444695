<div class="panel padding-15">
    <form action="" name="trailerForm" #trailerForm="ngForm">
        <div class="row">
            <div class="form-group col-md-12">
                <input type="text"
                       id="name"
                       class="form-control"
                       placeholder="Trailer Name"
                       [(ngModel)]="trailer.name"
                       required
                       name="name">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                Tanks
                <button
                    type="button"
                    class="btn btn-sm btn-light btn-add"
                    (click)="addTank()"
                    tooltip="Add tank to trailer"
                >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
            </div>
            <div *ngFor="let cargo of trailer.Cargos; let i = index"
                 class="col-md-12 form-inline margin-top-10">
                <div class="form-group">
                    <input type="number"
                           id="maxLoad-{{i}}"
                           class="form-control"
                           name="Max Load {{i}}"
                           placeholder="Max Qty Gal"
                           [(ngModel)]="cargo.maxLoad">
                </div>
                <div class="form-group">
                    <input type="text"
                           id="description-{{i}}"
                           class="form-control"
                           name="Description {{i}}"
                           placeholder="Description"
                           [(ngModel)]="cargo.description">
                </div>
            </div>
        </div>
        <div class="margin-top-10 flex-align-end">
            <button *ngIf="trailer.id && trailer.isActive" (click)="trailerDelete.showPromptModal()" type="button" class="btn-danger btn">Inactive</button>
            <button *ngIf="trailer.id && !trailer.isActive" (click)="trailerDelete.showPromptModal()" type="button" class="btn-success btn">Restore</button>
            <button type="button" class="btn-warning btn" (click)="clearTrailer()">Clear</button>
            <button class="btn btn-primary" [disabled]="!trailerForm.form.valid" (click)="save(trailer)">Save</button>
        </div>
    </form>
</div>

<prompt-modal
    #trailerDelete
    size="small"
>
        <div class="modal-header">
            <h4 class="modal-title">Set Trailer {{trailer.isActive ? 'Inactive' : 'Active'}}</h4>
        </div>

        <div class="modal-item">
            <div class="col-12">
                <p *ngIf="trailer.isActive">Are you sure you want to set trailer "{{trailer.name}}" to inactive?</p>
                <p *ngIf="!trailer.isActive">Would you like to restore trailer "{{trailer.name}}"?</p>
            </div>
        </div>

        <div class="modal-buttons">
            <button *ngIf="trailer.isActive" (click)="trailer.isActive = false;save(trailer);trailerDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-danger">Inactive</button>
            <button *ngIf="!trailer.isActive" (click)="trailer.isActive = true;save(trailer);trailerDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-success">Restore</button>
            <button type="button" class="btn btn-sm btn-light" (click)="trailerDelete.hidePromptModal()">Cancel</button>
        </div>

</prompt-modal>
