import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Address } from "../../../models/address";
import { FileUploadService } from "../../../services-module/services/file-upload.service";
import { Employee } from "../../../models/employee";
import { DomSanitizer } from '@angular/platform-browser';
// import { type } from "os";
import * as _ from 'lodash';
//Variables for rotate and zoom in on uploaded image
let rotate_factor: any = 0;
let zoom_factor: any = 0;
let rotate_angle: any = 0;
let zoom_amount: any = 0;

@Component({
    templateUrl: './preview-image.component.html',
    styleUrls: ['./address-documents.component.css'],
    selector: 'preview-image'
})
export class PreviewImageComponent {
    @Input() largeImage: any;
    @Input() images: any[];
    @Input() address: Address;
    @Input() employees: Employee[];
    @Input() docTypes: Object[];
    @Output() cancelPreview = new EventEmitter<void>();

    constructor(
        private fileUploadService: FileUploadService,
        private sanitizer: DomSanitizer,
    ) { }


    closePreview(): void {
        this.cancelPreview.emit();
    }

    setAsImage(imageId: number) {
        this.fileUploadService.getDocument(this.address.id, imageId)
            .then(res => {
                this.largeImage = res[0];
            });
    }

    setEmp(employeeId: number) {
        let employee = _.find(this.employees, { 'id': employeeId });
        return employee.firstName + ' ' + employee.lastName;
    }

    setType(typeId: number) {
        let docType = _.find(this.docTypes, { 'id': typeId });
        return docType ? docType['name'] : '';
    }

    isImage(url: string) {
        const splitUrl = url.split('.');
        return ['jpg', 'png', 'jpeg'].indexOf(splitUrl[splitUrl.length - 1]) != -1;
    }

    pdfUrl(url: any) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    rotateImage() {
        if (rotate_factor >= 3) {
            rotate_factor = -1;
            let thisImage = document.getElementById("img-to-rotate");
            thisImage.className = `zoom-${zoom_amount} rotate-0`;
        }
        rotate_factor += 1;
        rotate_angle = (90 * rotate_factor) % 360;
        let thisImage = document.getElementById("img-to-rotate");
        thisImage.className += ` rotate-${rotate_angle}`;
    }

    zoomIn() {
        if (zoom_factor >= 3) {
            zoom_factor = -1;
            let thisImage = document.getElementById("img-to-rotate");
            thisImage.className = ` rotate-${rotate_angle} zoom-0`;
        }
        zoom_factor += 1;
        zoom_amount = (90 * zoom_factor) % 450;
        let thisImage = document.getElementById("img-to-rotate");
        thisImage.className += ` zoom-${zoom_amount}`;
    }
}
