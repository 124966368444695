import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name : 'searchPipe',
    pure: false
})
export class SearchPipe implements PipeTransform {
    public transform(value:any, keys: string, term: string) {
        if (!value) {
            return;
        }
        return value.filter((item:any) => {
            const splitKeys = keys.split(',');
            for (let i = 0; i < splitKeys.length; i++ ) {
                let target = _.get(item, splitKeys[i]);
                if (target) {
                    if (term) {
                        let regExp = new RegExp('\\b' + term, 'gi');
                        if (regExp.test(target)) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
              }
            return false;
        });
    }
}
