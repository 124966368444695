import { Component, Input, Output, EventEmitter} from '@angular/core';

import {ServiceRegion} from '../../../../models/service-region';

@Component({
    templateUrl: 'service-region-single.component.html',
    selector: 'service-region-single'
})
export class ServiceRegionSingleComponent{
    @Input()
    serviceRegion: ServiceRegion;
    @Input()
    saveSuccess: boolean = false;
    @Input()
    saveFail: boolean = false;
    @Input()
    states: any[];
    @Output()
    resetServiceRegion = new EventEmitter<string>();
    @Output()
    saveServiceRegion = new EventEmitter<ServiceRegion>();
    constructor(){}

    clearServiceRegion(){
        this.resetServiceRegion.emit('reset');
    }

    save(serviceRegion: ServiceRegion){
        this.saveServiceRegion.emit(serviceRegion);
    }


}