import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name : 'dairyFilter',
    pure: false
})
export class DairyFilterPipe implements PipeTransform {
    public transform(value: any, objKey: string, property: string, term: string) {
        if (!objKey) {
            return value;
        } else {
            return value.filter((item:any) => {
                if (item.hasOwnProperty(objKey) && item[objKey] !== null) {
                    if (term) {
                        let regExp = new RegExp('\\b' + term, 'gi');
                        return regExp.test(item[objKey][0][property]);
                    } else return true;
                } else {
                    return false;
                }
            });
        }
    }
}
