import {AuthService} from "../../authorization/auth.service";
import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'forgot-password',
    templateUrl: 'forgot-password.component.html',
    styleUrls: ['../login.component.css']
})

export class ForgotPasswordComponent {
    @Output() returned = new EventEmitter();
    @Output() cancelForgot = new EventEmitter();
    creds: any;
    submitted:boolean = false;

    constructor(
        private router: Router,
        private auth:AuthService
    ) {
        this.creds = {email:null, content:null, contentHeading:null, success:null};
    }

    return(email:string) {
        this.returned.emit();
    }
    newPassword(creds: any){
        this.auth.requestNewPassword(creds)
            .then(res=>{
                if(res.error){
                    this.creds['success'] = false;
                    this.creds['contentHeading'] = 'Error!';
                    this.creds['content'] = 'There was an error with the email provided, please try again. If the problem persists please contact your administrator immediately.'
                    this.submitted = true;
                }else{
                    this.creds['success'] = true;
                    this.creds['contentHeading'] = 'Thank you!';
                    this.creds['content'] = 'Submission completed. Please check your email and follow the instructions provided to begin resetting your password.';
                    this.submitted = true;
                }
            });
    }
    tryAgain(){
        this.creds = {email:null, content:null, contentHeading:null, success:null};
        this.submitted = false;
    }
    cancel(){
        this.cancelForgot.emit();
    }
}
