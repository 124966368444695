<div class="panel padding-15">
    <form action="" name="productForm" #tankForm="ngForm">
        <div class="row">
            <div class="form-group col-md-6">
                <label for="name">Tank Name</label>
                <input type="text"
                       id="name"
                       class="form-control"
                       [(ngModel)]="tank.tankTypeName"
                       required
                       name="name">
            </div>
            <div class="form-group col-md-6">
                <label for="abbr">Tank Abbreviation</label>
                <input type="text"
                       class="form-control"
                       [(ngModel)]="tank.tankTypeAbbr"
                       name="abbr"
                       id="abbr">
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <label for="model">Model Number</label>
                <input type="text"
                       class="form-control"
                       [(ngModel)]="tank.modelNumber"
                       name="model"
                       id="model">
            </div>
            <div class="form-group col-md-4">
                <label for="capacity">Capacity</label>
                <input type="number"
                       class="form-control"
                       [(ngModel)]="tank.capacityVolume"
                       name="capacity"
                       required
                       id="capacity">
            </div>
            <div class="form-group col-md-4">
                <label for="brand">Brand Name</label>
                <input type="text"
                       class="form-control"
                       placeholder="Brand Name"
                       [(ngModel)]="tank.brandName"
                       name="brand"
                       id="brand">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="description">Description</label>
                <textarea name="description"
                          id="description"
                          class="form-control"
                          cols="30"
                          rows="10"
                          [(ngModel)]="tank.tankTypeDescription"></textarea>
            </div>
        </div>
        <div class="margin-top-10">
            <div class="form-group">
                <button
                    (click)="active['active'] = !active['active'];clearTank()"
                    type="button" class="btn btn-light"
                >
                     {{active['active'] ? 'Show Inactive Tanks' : 'Show Active Tanks'}}
                </button>
            </div>
        </div>
        <div class="margin-top-10 flex-align-end">
            <button *ngIf="tank.id && tank.isActive" (click)="tankDelete.showPromptModal()" type="button" class="btn-danger btn">Inactive</button>
            <button *ngIf="tank.id && !tank.isActive" (click)="tankDelete.showPromptModal()" type="button" class="btn-success btn">Restore</button>
            <button type="button" class="btn-warning btn" (click)="clearTank()">Clear</button>
            <button class="btn btn-primary" [disabled]="!tankForm.form.valid" (click)="save(tank)">Save</button>
        </div>
    </form>
</div>

<prompt-modal
    #tankDelete
    size="small"
>
        <div class="modal-header">
            <h4 class="modal-title">Set Tank {{tank.isActive ? 'Inactive' : 'Active'}}</h4>
        </div>

        <div class="modal-item">
            <div class="col-12">
                <p *ngIf="tank.isActive">Are you sure you want to set tank "{{tank.tankTypeName}}" inactive?</p>
                <p *ngIf="!tank.isActive">Would you like to restore tank "{{tank.tankTypeName}}"?</p>
            </div>
        </div>

        <div class="modal-buttons">
            <button *ngIf="tank.isActive" (click)="tank.isActive = false;save(tank);tankDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-danger">Inactive</button>
            <button *ngIf="!tank.isActive" (click)="tank.isActive = true;save(tank);tankDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-success">Restore</button>
            <button type="button" class="btn btn-sm btn-light" (click)="tankDelete.hidePromptModal()">Cancel</button>
        </div>

</prompt-modal>
