import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { environment } from '@environment';
import { ConfirmComponent } from '@shared/confirm/confirm.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { concat, interval, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { isArray as _isArray } from 'lodash';

@Injectable({ providedIn: 'root' })
export class UpdateService {
  confirmModalRef?: BsModalRef;
  closed$ = new Subject();

  constructor(
    appRef: ApplicationRef,
    private updates: SwUpdate,
    private modalService: BsModalService
  ) {
    if (environment.production) {
      // Allow the app to stabilize first, before starting
      // polling for updates with `interval()`.
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      // check for updates every 15 minutes.
      const everySixHours$ = interval(15 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());

      let initialState;

      modalService.onHide.subscribe((reason: string | any) => {
        this.confirmModalRef = undefined;
      });

      updates.available.subscribe((evt: UpdateAvailableEvent) => {
        const { version, notes } = evt.available.appData as { version: string, notes: string };
        initialState = {
          confirmTitle: 'Site Updated',
          itemList: _isArray(notes) ? notes : undefined,
          confirmMsg: `${version}:${_isArray(notes) ? '' : ' ' + notes} Refresh page to update.`,
          ok: 'Refresh',
          cb: this.refresh,
          cancelCb: this.cancel
        };
        this.confirmModalRef = this.modalService.show(ConfirmComponent, { initialState, class: 'confirm-modal' });
      });
    }
  }

  refresh = () => {
    this.updates.activateUpdate().then(() => document.location.reload());
    this.confirmModalRef = undefined;
  }

  cancel = () => {
    this.confirmModalRef = undefined;
  }
}
