import { Injectable } from '@angular/core';

@Injectable()
export class CoreConfigService {

  navbarPosition: string = 'left';
  headerTitle: string = '';
  queueTitle: string = '';
  headerBreadcrumbs: string = '';
  notificationsActive: boolean = false;
  userMenuActive: boolean = false;
  adminMenuActive: boolean = false;
  modalActive: boolean = false;

  constructor() { }

  toggleNotifications() {
    this.notificationsActive = !this.notificationsActive;
  }

  toggleUserMenu() {
    this.userMenuActive = !this.userMenuActive;
  }

  toggleModal() {
    this.modalActive = !this.modalActive;
  }

  setHeaderTitle(title: string, breadcrumbs?: string) {
    this.headerTitle = title;
    this.headerBreadcrumbs = breadcrumbs;
  }

  toggleAdminMenu(){
    this.adminMenuActive = !this.adminMenuActive;
  }

}
