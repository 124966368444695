<div class="modal-header">
  <h4 class="modal-title pull-left">{{ confirmTitle || "Confirm" }}</h4>
</div>
<div class="modal-body">
  <div>
    {{ confirmMsg }}
  </div>
  <ng-container *ngIf="itemList?.length">
    <br />
    <ol>
      <li *ngFor="let item of itemList">{{ item }}</li>
    </ol>
  </ng-container>
</div>
<div class="modal-footer">
  <div class="confirm-options">
    <button
      #cancelBtn
      *ngIf="!!cancel"
      (click)="hide()"
      [appFocus]="!ok"
      class="btn btn-default"
      type="button"
    >
      {{ cancel }}
    </button>
    <button
      #okBtn
      *ngIf="!!ok"
      [appFocus]="!!ok"
      (click)="confirm()"
      class="btn btn-primary ms-2"
      type="button"
    >
      {{ ok }}
    </button>
  </div>
</div>
