<!-- <header-lmnt [config]="headerConfig"></header-lmnt> -->
<div class="base-container reporting row">
	<div class="col-md-2 no-padding-left">
    	<div class="card-box-shadow hidden-overflow">
      		<div class="panel panel-default report-nav-container no-margin-bottom no-padding" [style.height]="navHeight">
				<accordion>
          			<accordion-group heading="Saved Queries" [isOpen]="true" *ngIf="savedQueries.length">
              			<div *ngIf="loading">
                			<div class="col-md-12 flex-container flex-around margin-top-10">
                  				<div>&nbsp;<i class="fa fa-cog fa-spin fa-3x fa-fw fa-primary"></i>&nbsp;
                    				<p class="loading-text">Loading...</p>
                  				</div>
                			</div>
              			</div>
						<div class="row" *ngIf="!loading">
							<div class="col-md-12">
								<table class="table table-hover no-margin-bottom">
									<tbody>
										<tr *ngFor="let report of savedQueries; let i = index"
											[ngClass]="{'selected-report': (selectedCustomQuery && selectedCustomQuery.report_name == report.report_name), 'disabled': report.access_level != 'user' && access <= 1}"
											(click)="onCustomClicked(report)">
											<td class="td-100 text-left">
											{{report.report_name}}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</accordion-group>
					<accordion-group heading="Report Entities" [isOpen]="true" [ngClass]="{'disabled':access <= 1}">
						<div *ngIf="loading">
							<div class="col-md-12 flex-container flex-around margin-top-10">
								<div>
									&nbsp;<i class="fa fa-cog fa-spin fa-3x fa-fw fa-primary"></i>&nbsp;
									<p class="loading-text">Loading...</p>
								</div>
							</div>
						</div>
						<div class="row" *ngIf="!loading" [ngClass]="{'disabled':access <= 1}">
							<div class="col-md-12">
								<table class="table table-hover no-margin-bottom">
									<tbody>
										<tr *ngFor="let table of tables; let i = index"
											[ngClass]="{'selected-report': (selectedTable && selectedTable.table_name == table.table_name)}"
											(click)="onItemClicked(table)">
											<td class="td-100 text-left">
												<flex-input [config]="flexConfig"
												[flexModel]="{key: 'schema_nice_name',
												value: table.schema_nice_name,
												belongsToID: table.id,
												displayProp: 'schema_nice_name',
												belongsToTable: flexConfig.table}"
												(onUpdateSuccess)="updateSuccess($event)"></flex-input>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</accordion-group>
        		</accordion>
      		</div>
    	</div>
  	</div>

	<div class="col-md-10 pr-0" *ngIf="!selectedTable" [style.height]="navHeight">
		<div class="card-box-shadow flex-align-items-center flex-center height-100">
			<div class="text-center">
				<h4>Data Export (CSV)</h4>
				<p>Select an Entity, then select the fields to be exported.</p>
			</div>
		</div>
	</div>

	<div class="col-md-10 pr-0" *ngIf="selectedTable">
		<div class="card-box-shadow">
			<div class="panel panel-default">
				<div class="panel-heading">
					<div class="flex-container flex-between">
						Select the fields to be exported from {{selectedTable.schema_nice_name}}
						<span>
							<button (click)="addConditional(selectedTable.table_name)" class="edit-icon export-icon tooltip-hover">
								<svg viewBox="0 0 24 24">
									<path d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z" />
								</svg>
								<div class="tooltip-text move-down grow-big show">Add Filter</div>
							</button>
							<button (click)="onCloseClicked(selectedTable)" class="edit-icon export-icon pull-right tooltip-hover">
								<svg viewBox="0 0 24 24">
									<path d="M6,2C4.89,2 4,2.9 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M13,3.5L18.5,9H13M8.93,12.22H16V19.29L13.88,17.17L11.05,20L8.22,17.17L11.05,14.35" />
								</svg>
								<div class="tooltip-text move-down show">Export</div>
							</button>
							<button (click)="selectDeselectAll($event)" class="edit-icon select-all-icon pull-right tooltip-hover">
								<svg viewBox="0 0 24 24" *ngIf="!allSelected">
									<path d="M9,9H15V15H9M7,17H17V7H7M15,5H17V3H15M15,21H17V19H15M19,17H21V15H19M19,9H21V7H19M19,21A2,2 0 0,0 21,19H19M19,13H21V11H19M11,21H13V19H11M9,3H7V5H9M3,17H5V15H3M5,21V19H3A2,2 0 0,0 5,21M19,3V5H21A2,2 0 0,0 19,3M13,3H11V5H13M3,9H5V7H3M7,21H9V19H7M3,13H5V11H3M3,5H5V3A2,2 0 0,0 3,5Z" />
								</svg>
								<svg viewBox="0 0 24 24" *ngIf="allSelected">
									<path d="M15,16H19V18H15V16M15,8H22V10H15V8M15,12H21V14H15V12M3,18A2,2 0 0,0 5,20H11A2,2 0 0,0 13,18V8H3V18M14,5H11L10,4H6L5,5H2V7H14V5Z" />
								</svg>
								<div class="tooltip-text move-down grow-big show" *ngIf="!allSelected">Select All</div>
								<div class="tooltip-text move-down grow-big show" *ngIf="allSelected">Deselect All</div>
							</button>
						</span>
					</div>
				</div>
				<div class="panel-body scroll-overflow-y column-select">
					<div class="select-button-container" *ngFor="let col of selectedTable.columns; let i = index">
						<div>
							<label [ngClass]="{'prop-selected': col.selected}"
								   (click)="onColumnSelected(col, null)"
								   ngDefaultControl
								   class="btn select-button"
								   [(ngModel)]="col.selected"
								   name="checkbox-{{i}}"
								   btnCheckbox>{{col.nice_name && col.nice_name !== '' ? col.nice_name : col.column_name ? startCase(col.column_name) : '-'}}</label>
						</div>
					</div>
				</div>
				<!-- cond start -->
				<report-filter *ngIf="tables[getTableIndex(selectedTable.table_name)].conditions"
							   [tables]="tables"
							   [selectedTable]="selectedTable"></report-filter>
				<!-- end -->
				</div>
			</div>
		<div>
			<data-dictionary [columns]="selectedTable.columns"
							 [config]="config"
							 [belongsToID]="selectedTable.id"
							 [tbodyHeight]="tbodyHeight"
							 (onUpdateSuccess)="updateSuccess($event)"></data-dictionary>
		</div>
	</div>
  	<div class="clearfix"></div>
</div>
<report-modal #rm="reportModal" (optionsSaved)="updateReport($event)"></report-modal>