import { Component,
         OnInit }           from '@angular/core';
import { Router,
         ActivatedRoute }   from '@angular/router';

import { Client }           from '../models/client';
import { ClientPagination } from '../models/client-pagination';
import { ClientService }    from '../services-module/services/client.service';
import { UserService }      from '../services-module/services/user.service';

@Component({
    templateUrl: './client.component.html',
    styleUrls: ['client.component.css']
})

export class ClientComponent implements OnInit {
    employeeId: number;
    employeeTypeId: number;

    clientResults: ClientPagination; // pagination model for clients
    salesLeads: boolean;
    add: boolean;
    displayMode: DisplayModeEnum;
    search:string = '';
    ftp:boolean = false;

    paginationValues: any;
    currentPage: number = 0; // this is the current page of the data
    itemsPerPage: number = 75; // items the user wants to show per page
    itemsDataSource: number = 0; // the data amount that is total in the database
    maxSize:number = 5;
    rotate:boolean = true;
    isIndex:boolean = false;
    sort:string = 'asc';
    prop: string = 'name';

    query:any;

    constructor(private router: Router,
                private clientService: ClientService,
                private userService: UserService,
                private activatedRoute: ActivatedRoute){}

    ngOnInit() {
        this.getEmployeeInfo();
        this.add = false;
        this.displayMode = DisplayModeEnum.Grid;
        this.clientResults = this.activatedRoute.snapshot.data['clients']; // setting the results returns from resolve to the data
        this.itemsDataSource = this.clientResults['count'];

        this.checkLeads();
    }

    async getEmployeeInfo()  {
        this.employeeId = await this.userService.getEmployeeId();
        this.employeeTypeId = await this.userService.getEmployeeTypeId();
    }

    checkLeads(){
        if(this.activatedRoute.routeConfig.path == 'clients/sales-leads') {
           return this.salesLeads = true;
        }else{
            return this.salesLeads = false;
        }
    }

    // search field value when user is typing the data
    setTerm(term: string) {
        if(this.isIndex) {
            this.currentPage = 0;
        } else {
            this.currentPage = 1;
        }

        this.search = term;
        this.updateQueryParams();
    }

    updateQueryParams() {
        const query = {
            page: this.currentPage,
            itemsPerPage: this.itemsPerPage,
            search: this.search,
            isIndex: this.isIndex,
            sort: this.sort,
            prop: this.prop
        };

        if(this.salesLeads) {
            this.getListOfSalesLeads(query);
        } else {
            this.getListOfClients(query);
        }


    }

    changePaginationData(event) {
        this.prop = event.prop ? event.prop : 'name';
        this.sort = event.sort ? event.sort : 'asc';
        this.isIndex = event.isIndex;
        this.currentPage = event.page;
        this.itemsPerPage = event.itemsPerPage
        this.updateQueryParams();
    }

    getListOfClients(query: any) {
        this.clientService.getEmployeeClientsPaginationService(this.employeeTypeId, this.employeeId, query).then((clients) => {
            this.itemsDataSource = clients['count'];
            this.clientResults = clients;
        });
    }

    getListOfSalesLeads(query: any) {
        this.clientService.getSalesLeadsPaginationService(query).then((clients) => {
            this.itemsDataSource = clients['count'];
            this.clientResults = clients;
        });
    }

    saveClient(client: Client) {
        // we should not allow the user create another client with the same name?
        this.clientService.saveClient(client).then((res)=>{
            if(res.id){
                let link = ['/clients', res.id];
                this.router.navigate(link);
            }else{
                console.log(res); // why is there a console.log(res) here?
            }
        })
    }

    changeDisplayMode(mode: number) {
        this.currentPage = mode === 0 ? this.currentPage + 1 : this.currentPage - 1;

        this.displayMode = mode;
    }

    toggleClientType(bool:boolean): void{
        if(bool){
            this.toSalesLeads();
        }else{
            this.toClients();
        }
    }

    toSalesLeads(){
        this.router.navigateByUrl(`/clients/sales-leads`);
    }
    toClients(){
        this.router.navigateByUrl(`/clients`);
    }
}

enum DisplayModeEnum {
    Grid = 0,
    List = 1,
}

