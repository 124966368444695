import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Facility } from '../../models/facility';
import { environment } from '../../../environments/environment';

@Injectable()
export class FacilityService {
    private apiUrl = environment.apiUrl;

    constructor(
        private authHttp: HttpClient
    ) { }

    getFacilities(): Promise<Facility[]> {
        return this.authHttp.get(`${this.apiUrl}facility`)
            .toPromise()
            .then((res) => {
                return res as Facility[];
            });
    }

    saveFacility(facility: Facility): Promise<Facility> {
        if (facility.id) {
            return this.updateFacility(facility);
        } else {
            return this.createFacility(facility);
        }
    }

    createFacility(facility: Facility): Promise<Facility> {
        return this.authHttp.post(`${this.apiUrl}facility`, facility)
            .toPromise()
            .then((res) => {
                return res as Facility;
            });
    }

    updateFacility(facility: Facility): Promise<Facility> {
        return this.authHttp.put(`${this.apiUrl}facility/${facility.id}`, facility)
            .toPromise()
            .then((res) => {
                return res as Facility;
            });
    }
}
