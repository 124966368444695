<div id="notes" class="panel margin-top-20">
    <div class="panel-header">
        <div class="flex-container flex-space-between">
            <h4> <i class="fa fa-sticky-note"></i> Dairy Notes</h4>
            <button class="btn btn-sm btn-danger" (click)="hide()">X</button>
        </div>
    </div>
    <div class="panel-content">
        <div class="margin-top-20">
            <form class="form">
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="type">Note Type</label>
                            <select name="type"
                                    id="type"
                                    class="form-control"
                                    [(ngModel)]="note.notesTypeId">
                                <option value="" selected>--</option>
                                <option [value]="type.id" *ngFor="let type of noteTypes">
                                    {{ type.notesTypeName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="form-group">
                            <label for="content">Note Content</label>
                            <textarea name="content"
                                      id="content"
                                      class="form-control"
                                      rows="1"
                                      [(ngModel)]="note.content"
                                      placeholder="Note text"></textarea>
                        </div>
                    </div>
                </div>
                <button class="btn btn-primary"
                        [disabled]="!note.notesTypeId || !note.content"
                        (click)="save(note)">{{note.id ? 'Save Note' : 'Add Note'}}</button>
                <button class="btn btn-danger" (click)="cancel()">Clear</button>
            </form>
        </div>
        <table class="table table-striped margin-top-20">
            <thead>
                <tr>
                    <td width="15%">Employee</td>
                    <td width="15%">Date</td>
                    <td width="10%">Note Type</td>
                    <td width="60%">Note</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let note of notes" (click)="editNote(note)"  [ngClass]="{'selected':isSelected(note.id),'deleting':deleting(note.id)}">
                    <td width="15%">{{ note.employee?.firstName}} {{ note.employee?.lastName}}</td>
                    <td width="15%">{{ note.createdAt | date }}</td>
                    <td width="10%">{{ note.notesType?.notesTypeName }}</td>
                    <td width="60%" class="note-content">{{ note.content }}
                        <button
                            *ngIf="role() == 'Admin'"
                            class="btn btn-sm btn-danger delete-btn"
                            tooltip="Delete Note"
                            placement="left"
                            (click)="!!setDeleteNote(note);$event.stopPropagation()"
                        >
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </td>
                </tr>
                <tr  *ngIf="notes.length === 0" width="100%">
                    <td>No Notes</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<prompt-modal
    #noteModal
    size="small"
    (onClosed)="dNote = null"
>
        <div class="modal-header">
            <h4 class="modal-title">Delete Note</h4>
        </div>

        <div class="modal-item">
            <div class="col-12">
                <p>Are you sure you want to delete note "{{dNote?.content}}"?</p>
            </div>
        </div>

        <div class="modal-buttons">
            <button type="submit" class="btn btn-sm btn-danger"(click)="deleteNote()">Delete</button>
            <button type="button" class="btn btn-sm btn-light" (click)="noteModal.hidePromptModal()">Cancel</button>
        </div>

</prompt-modal>
