import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Address } from '../../../models/address';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
    templateUrl: './address-details.component.html',
    styleUrls: ['../address.component.css'],
    selector: 'address-details'
})
export class AddressDetailsComponent {
    @Input()
    address: Address;
    clone: Address;
    @Output()
    saveAddress = new EventEmitter<Address>();
    sortOrders: Object[];
    editMode: boolean;
    constructor() {
        this.editMode = false;
    }

    ngOnInit() {
        this.sortOrders = _.orderBy(this.address['WorkOrders'], ['serviceDate'], ['desc']);
    }

    ngOnChanges() {
        this.sortOrders = _.orderBy(this.address['WorkOrders'], ['serviceDate'], ['desc']);
    }

    editAddress() {
        this.clone = _.cloneDeep(this.address);
        this.editMode = true;
    }

    cancelEdit() {
        Object.assign(this.address, this.clone);
        this.editMode = false;
    }

    save(address: Address): void {
        this.saveAddress.emit(address);
        this.editMode = false;
    }

    lastLabDate() {
        if (this.address['VaporTests'] && this.address['VaporTests'].length > 0) {
            let sortLabs = _.orderBy(this.address['VaporTests'], ['createdAt'], ['desc']);
            return moment(sortLabs[0]['createdAt']).format('MM/DD/YYYY');
        }
        return '';
    }

    lastWorkOrdersDate() {
        if (this.address['WorkOrders'] && this.address['WorkOrders'].length > 0) {
            if (this.sortOrders.length > 0 && this.sortOrders[0]['serviceDate']) {
                return moment(this.sortOrders[0]['serviceDate']).format('MM/DD/YYYY');
            }
        }
        return '';
    }

    lastOrderId() {
        if (this.address['WorkOrders'] && this.address['WorkOrders'].length > 0) {
            if (this.sortOrders.length > 0 && this.sortOrders[0]['id']) {
                return this.sortOrders[0]['id'];
            }
        }
        return '';
    }

    lastServiceType() {
        if (this.address['WorkOrders'] && this.address['WorkOrders'].length > 0) {
            if (this.sortOrders.length > 0 && this.sortOrders[0]['ServiceType']) {
                return this.sortOrders[0]['ServiceType']['serviceTypeName'];
            }
        }
        return '';
    }

}