import { Routes, RouterModule } from "@angular/router";
import {
  AuthGuard,
  AdminCheck,
  SalesManagerCheck,
} from "../authorization/auth-guard.service";

import { AdminComponent } from "./admin.component";
import { VehicleComponent } from "./vehicle-panel/vehicle.component";
import { ProductComponent } from "./product-panel/product.component";
import { EmployeeComponent } from "./employee-panel/employee.component";
import { TankComponent } from "./tank-panel/tank.component";
import { MiscellaneousComponent } from "./misc-panel/miscellaneous.component";
import { InactiveComponent } from "./inactive/inactive.component";
import { InactiveClientPanelComponent } from "./inactive/inactive-client-panel/inactive-client.component";
import { InactiveTankPanelComponent } from "./inactive/inactive-tank-panel/inactive-tank.component";
import { InactiveDairyPanelComponent } from "./inactive/inactive-dairy-panel/inactive-dairy.component";
import { ClientGroupManagementComponent } from "./client-group-management/client-group-management.component";
import { ClientsResolve } from "../resolvers/client.resolver";

import { ReportingComponent } from "./reporting/reporting/reporting.component";

import { TablesResolver } from "./reporting/resolvers/tables.resolver";
import { SavedReportsResolver } from "./reporting/resolvers/saved-reports.resolver";
import { NormAuthResolver } from "./reporting/resolvers/norm-auth.resolver";
import { ServiceRegionResolve } from "../resolvers/route.resolver";

const adminRoutes: Routes = [
  {
    path: "admin",
    component: AdminComponent,
    children: [
      {
        path: "",
        redirectTo: "/employees",
        pathMatch: "full",
      },
      {
        path: "employees",
        component: EmployeeComponent,
        data: {
          view: "employees",
        },
        resolve: {
          serviceRegions: ServiceRegionResolve,
        },
        canActivate: [AuthGuard, AdminCheck],
      },
      {
        path: "vehicles",
        component: VehicleComponent,
        canActivate: [AuthGuard, AdminCheck],
        data: {
          view: "vehicles",
        },
      },
      {
        path: "products",
        component: ProductComponent,
        canActivate: [AuthGuard, AdminCheck],
        data: {
          view: "products",
        },
      },
      {
        path: "tanks",
        component: TankComponent,
        canActivate: [AuthGuard, AdminCheck],
        data: {
          view: "tanks",
        },
      },
      {
        path: "inactive",
        component: InactiveComponent,
        canActivate: [AuthGuard, AdminCheck],
        data: {
          view: "inactive",
        },
        children: [
          {
            path: "",
            redirectTo: "inactive-clients",
            pathMatch: "full",
            // outlet: 'inactive'
          },
          {
            path: "inactive-clients",
            component: InactiveClientPanelComponent,
            canActivate: [AuthGuard],
            outlet: "inactive",
            data: {
              view: "inactive-clients",
            },
          },
          {
            path: "inactive-tanks",
            component: InactiveTankPanelComponent,
            canActivate: [AuthGuard],
            outlet: "inactive",
            data: {
              view: "inactive-tanks",
            },
          },
          {
            path: "inactive-dairies",
            component: InactiveDairyPanelComponent,
            canActivate: [AuthGuard],
            outlet: "inactive",
            data: {
              view: "inactive-dairies",
            },
          },
        ],
      },
      {
        path: "client-management",
        component: ClientGroupManagementComponent,
        resolve: {
          clients: ClientsResolve,
        },
        canActivate: [AuthGuard, AdminCheck],
        data: {
          view: "client-management",
        },
      },
      {
        path: "misc",
        component: MiscellaneousComponent,
        canActivate: [AuthGuard, AdminCheck],
        data: {
          view: "misc",
        },
      },
      {
        path: "reporting",
        component: ReportingComponent,
        data: {
          view: "reporting",
        },
        resolve: {
          tables: TablesResolver,
          savedReports: SavedReportsResolver,
          user: NormAuthResolver,
        },
        canActivate: [AuthGuard, SalesManagerCheck],
        // loadChildren: './reporting/reporting.module#ReportingModule',
        // canActivate: [AuthGuard]
      },
    ],
  },
];

export const adminRouting = RouterModule.forChild(adminRoutes);
