import * as _ from 'lodash';
export class ClientContact {
  constructor(data?: any) {
    _.merge(this, data);
  }

  public id: number = null;
  public name: string = null;
  public description: string = null;
  public email: string = null;
  public clientId: number = null;
  public addressId: number = null;
  public PhoneNumbers: any[] = [];
  public Employee: any = {};

}
