import { Component, Input, Output, EventEmitter} from '@angular/core';

import {Product} from '../../../models/product';
import * as _ from 'lodash';

@Component({
    templateUrl: 'product-list.component.html',
    styleUrls: ['product-list.component.css'],
    selector: 'product-list'
})
export class ProductListComponent{
    @Input() products: Product[];
    @Input() selectedProductId: number;
    @Output() setProduct = new EventEmitter<Product>();
    @Output() resetProduct = new EventEmitter<string>();
    active:boolean = true;

    constructor(){}

    selectProduct(product: Product){
        this.setProduct.emit(product);
    }

    isSelected(product:Product) {
        if (this.selectedProductId) {
            return product.id == this.selectedProductId;
        }
        return false;
    }

    activeProducts() {
        return _.filter(this.products, (p:Product) => p.isActive == this.active );
    }

    clearProduct(){
        this.resetProduct.emit('reset');
    }

}