<div class="orders-table">
  <table class="table table-striped">
    <thead>
      <tr>
        <th
          *ngFor="let header of headers"
          [ngClass]="{
            sorted: sortColumn === header.sortName && header.sortName !== null,
            asc: sorting[header.sortName].asc && header.sortName !== null,
            desc: !sorting[header.sortName].asc && header.sortName !== null
          }"
          scope="col"
          class="clickable {{ header.className }}"
          (click)="sort(header.sortName)"
        >
          {{ header.title }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let order of workOrders.rows"
        [ngClass]="{ lightGreen: order.id === rowId }"
      >
        <div class="work-order-info">
          <td class="td-5">
            <div
              *ngIf="rowId == null"
              [ngClass]="{
                rotate: order.id === rowId || query.openAll == 'more'
              }"
              (click)="openRow(order.id)"
              class="rowCarat"
            >
              <i class="fa fa-caret-right"></i>
            </div>
            <div
              *ngIf="rowId !== null"
              [ngClass]="{
                rotate: order.id === rowId || query.openAll == 'more'
              }"
              (click)="closeRow()"
              class="rowCarat"
            >
              <i class="fa fa-caret-right"></i>
            </div>
          </td>
          <td [routerLink]="['/work-orders', order.id]" class="td-10 point">
            {{ order.id }}
          </td>
          <td [routerLink]="['/work-orders', order.id]" class="td-10 point">
            {{ order.createdAt | date }}
          </td>
          <td [routerLink]="['/work-orders', order.id]" class="td-15 point">
            <span>{{
              order.Client ? order.Client.name : "No Dairy Associated"
            }}</span>
          </td>
          <td [routerLink]="['/work-orders', order.id]" class="td-20 point">
            <span *ngIf="order.Address"
              >{{ order.Address.alias }}<br />{{ order.Address.street }},<br />
              {{ order.Address.city }} {{ order.Address.state }}
              {{ order.Address.zip }}</span
            >
            <span *ngIf="!order.Address">No Locations Associated</span>
          </td>
          <td class="td-10">
            <span
              class="shadow point"
              [ngClass]="woStatus(order.workOrderStatusId)"
              (click)="updateWorkOrder.showPromptModal(order)"
              >{{ order.workOrderStatusId | orderStatus }}</span
            >
          </td>
          <td [routerLink]="['/work-orders', order.id]" class="td-15 point">
            <span *ngIf="order.employeeId"
              >{{ order.createdBy.firstName }}
              {{ order.createdBy.lastName }}</span
            >
          </td>
          <td [routerLink]="['/work-orders', order.id]" class="td-15 point">
            {{ order.employeeTo ? order.employeeTo.firstName : "-" }}
            {{ order.employeeTo ? order.employeeTo.lastName : "-" }}
          </td>
        </div>
        <!-- openAll is based on navigation select Details -->
        <div
          class="work-order-detail"
          *ngIf="order.id == rowId || query.openAll == 'more'"
        >
          <h4 class="work-order-detail-heading">
            <div>Details</div>
            <div class="sep"></div>
          </h4>
          <td class="td-40">
            <div style="font-weight: 600">Work Order:</div>
            <div>{{ order.note }}</div>
          </td>
          <td class="td-20">
            <div style="font-weight: 600">Service Type:</div>
            <div *ngIf="order.ServiceType">
              {{ order.ServiceType.serviceTypeName }}
            </div>
            <div *ngIf="!order.ServiceType">--</div>
          </td>
          <td class="td-15">
            <div style="font-weight: 600">Date Opened</div>
            <span>{{ order.createdAt | date }}</span>
          </td>
          <td class="td-15">
            <div style="font-weight: 600">Date Closed</div>
            <div *ngIf="order.completionDate">
              {{ order.completionDate | date }}
            </div>
            <div *ngIf="!order.completionDate">--</div>
          </td>
          <td class="td-10">
            <div style="font-weight: 600">Sales Lead</div>
            <div class="client-tags">
              <span
                class="btn btn-sm"
                [ngClass]="{
                  'btn-primary': order.Client?.isClient,
                  'btn-warning': !order.Client?.isClient
                }"
                >{{ order.Client?.isClient ? "Client" : "Sales Lead" }}</span
              >
            </div>
          </td>
        </div>
      </tr>
    </tbody>
  </table>
</div>
<div class="pagination">
  <pagination
    *ngIf="workOrders.count > query.pageSize"
    [totalItems]="workOrders.count"
    [itemsPerPage]="query.pageSize"
    [(ngModel)]="query.page"
    (pageChanged)="pageChanged($event)"
    [maxSize]="maxSize"
    [rotate]="rotate"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"
    [boundaryLinks]="true"
  ></pagination>
</div>

<!--********** Confirm Modal **********-->
<app-update-work-order #updateWorkOrder></app-update-work-order>
