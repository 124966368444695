import * as _ from 'lodash';
export class Trailer{
    constructor(
        data?: any
    ){
        _.merge(this, data);
    }

    public id: number = null;
    public Cargos: any[] = [];
    public name: string = null;
    public isActive: boolean = true;
}