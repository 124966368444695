<div class="header">
    <h4><i class="fa fa-vcard"></i> Employees</h4>
</div>
<div class="row">
    <div class="col-xl-12">
        <employee-list [employees]="employees"
                        (setEmployee)="setEmployee($event)"
                        [employeeId]="selectedEmployee?.id"
                        (resetEmployee)="resetEmployee($event)"></employee-list>
    </div>
    <div class="col-xl-12">
        <employee-single [employee]="selectedEmployee"
                            [employeeTypes]="employeeTypes"
                            (resetEmployee)="resetEmployee($event)"
                            (saveEmployee)="saveEmployee($event)"></employee-single>
    </div>
</div>



<div class="margin-top-20" style="display:none;">
    <div class="header">
        <h4><i class="fa fa-vcard"></i> Employees Type</h4>
    </div>
    <div class="row margin-top-20 padding-bottom-20">
        <div class="col-md-6">
            <employee-type-list [employeeTypes]="employeeTypes" (setEmployeeType)="setEmployeeType($event)"></employee-type-list>
        </div>
        <div class="col-md-6">
            <employee-type-single [employeeType]="selectedEmployeeType"
                                  (resetEmployeeType)="resetEmployeeType($event)"
                                  (saveEmployeeType)="saveEmployeeType($event)"></employee-type-single>
        </div>
    </div>
</div>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
