import * as _ from 'lodash';
import { Address } from "./address";

export class Lab {
    constructor(data?: any){
        _.merge(this, data);
    }
    public id: number = null;
    public date: any = null;
    public time: number = null;
    public tankLocation: any= null;
    public bathCycle: number = null;
    public isCorrosive: number = null;
    public isESH: number = null;
    public isHazardous: number = null;
    public createdAt: string = null;
    public updatedAt: string = null;
    public deletedAt: string = null;
    public addressId: number = null;
    public Address: Address = null;
    public note: string = null;
    public updatedById:number = null;
    public createdById:number = null;
    public createdBy: {firstName: string, lastName: string} = null;
    public updatedBy: {firstName: string, lastName: string} = null;
}
