import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable()
export class SettingsService {
    constructor(private router: Router) { }
    private _settings = new Subject<any>();
    settings$ = this._settings.asObservable();

    showSettings(value: any) {
        this._settings.next({ show: value });
    }

}
