import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { ClientPagination } from 'src/app/models/client-pagination';

import { Client } from '../../models/client';
// import {SearchPipe} from "../../pipes/search.pipe";

@Component({
    selector: 'client-grid',
    templateUrl: 'client-grid.component.html',
    styleUrls: ['../client.component.css']
})

export class ClientGridComponent implements OnInit, OnDestroy {
    @Input() clientResults: ClientPagination;
    image: Object;
    @Input() searching: string;
    aIndex: number = 0;
    timer: any;

    @Input() paginationValues: any; // pass in pagination values
    @Output() userSelectedPagination = new EventEmitter<any>();

    @Input() currentPage: number; // this is the current page of the data
    @Input() itemsPerPage: number; // items the user wants to show per page
    @Input() itemsDataSource: number; // the data amount that is total in the database
    @Input() maxSize:number;
    @Input() rotate: boolean;

    localPage:number = 1;
    localItemsPerPage: number = 1;

    constructor(private router: Router) {
        this.image = {
            defaultLogo: '../../../../assets/images/default_client_logo.png',
            defaultLogo2: "url('../../../../assets/images/default_client_logo.png')",
            backgroundImage: "url('../../../../assets/images/specialty-sales-client-banner.png')"
        };
    }

    ngOnInit() {
        this.localPage = this.currentPage;
        this.localItemsPerPage = this.itemsPerPage;

        this.timer = setInterval(() => {
            this.aIndex++;
            if (this.aIndex >= 10000) this.aIndex = 0;
        }, 5000);
    }

    routeToAddress(client: Number, address: Number, $event: any) {
        this.router.navigateByUrl(`/clients/${client}/address/${address}`);
        $event.stopPropagation();
    }

    routeToClient(client: Number, event: any) {
        this.router.navigateByUrl(`/clients/${client}`);
    }

    setBackground(path: string) {
        return "url(" + path + ")";
    }

    ngOnDestroy() {
        clearTimeout(this.timer);
    }

    pageChanged($event) {
        this.localPage = $event.page;
        this.localItemsPerPage = $event.itemsPerPage;

        $event['isIndex'] = false;
        this.userSelectedPagination.emit($event);
    }
}