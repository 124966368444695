import {Component, Input, Output, EventEmitter} from '@angular/core';

import {Facility} from '../../../../models/facility';
import * as _ from 'lodash';

@Component({
    styleUrls: ['./facility-list.component.css'],
    templateUrl: './facility-list.component.html',
    selector: 'facility-list'
})
export class FacilityListComponent{
    @Input() facilities: Facility[];
    @Input() selectedFacilityId:number;
    @Output() setFacility = new EventEmitter<Facility>();
    @Output() resetFacility = new EventEmitter<string>();
    active:boolean = true;

    constructor(){}

    selectFacility(facility: Facility):void{
        this.setFacility.emit(facility);
    }

    isSelected(facility:Facility) {
        if (this.selectedFacilityId) {
            return facility.id == this.selectedFacilityId;
        }
        return false;
    }

    activeFacilities() {
        return _.filter(this.facilities, (f:Facility) => f.isActive == this.active );
    }

    clearFacility(){
        this.resetFacility.emit('reset');
    }

}