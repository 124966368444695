
import { Pipe, PipeTransform } from '@angular/core';
import { Tank } from '@models/tank';
import { TankService } from '@services/tank.service';

@Pipe({ name: 'appTankColor' })
export class TankColorPipe implements PipeTransform {
  constructor(private tankService: TankService) { }

  transform(tank: Tank): any {
    const status = this.getTankStatus(tank).toLowerCase();
    const colors: any = [];
    switch (status) {
      case 'critical':
        colors.push({ backgroundColor: '#d52100', hoverBackgroundColor: '#333' });
        break;
      case 'low':
        colors.push({ backgroundColor: '#e1c403', hoverBackgroundColor: '#333' });
        break;
      case 'good':
        colors.push({ backgroundColor: '#177E00', hoverBackgroundColor: '#333' });
        break;
      default:
        colors.push({ backgroundColor: '#ccc', hoverBackgroundColor: '#333' });
    }
    return colors;
  }

  getTankStatus(tank: Tank): string {
    return this.tankService.calculateTankStatus(tank);
  }
}
