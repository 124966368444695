<div id="single-route">
    <div class=" header" *ngIf="!driverView">
        <h2><i class="fa fa-map"></i> Update Route</h2>
    </div>

    <div class="padding-15 shadow">
        <div class="row">
            <div class="col-12 col-md-4 single-route-details">
                <div>
                    <strong>Name:</strong> <span>{{ route.routeName }}</span>
                    <small *ngIf="!driverView" style="font-size: 12px;"><a href="javascript:void(0);" [routerLink]="['/edit-route', route.id]">Edit Route</a></small>
                </div>
                <div>
                    <strong>Scheduled:</strong>  <span>{{route.dateOfRoute | date}}</span>
                </div>
                <div>
                    <strong>Type:</strong>  <span>{{ route.routeType }}</span>
                </div>
                <div>
                    <strong>Status:</strong> <span class="active-status" [ngClass]="activeStatus(route.completedAt, route.dateOfRoute)">{{activeStatus(route.completedAt, route.dateOfRoute)}}</span>
                </div>
            </div>

            <div class="col-12 col-md-4">
                <ng-container *ngIf="!driverView">
                    <div class="mobile-right"><button class="btn btn-sm btn-warning" (click)="viewLoadSheets()">Load Sheets</button></div>
                    <div class="single-route-form mobile-right">
                        <ng-select [multiple]="true"
                                   id="drivers"
                                   name="Drivers"
                                   [(ngModel)]="route.driverIds"
                                   placeholder="Select Employees">
                            <ng-option *ngFor="let driver of driverOptions" [value]="driver.id">{{driver.name}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="padding-top-10 mobile-right">
                        <button class="btn btn-primary" (click)="updateRoute()">
                            Update Driver
                        </button>
                    </div>
                </ng-container>
            </div>

                <div class="col-12 col-md-4 text-md-right">
                    <div class="pull-right text-left no-mobile-pull">
                        <app-loaded-levels [route]="route" [productDetails]="productDetails" (onUpdate)="updateTotalGallons()"></app-loaded-levels>
                    </div>
                </div>
            </div>
    </div>


    <div class="margin-top-20">
        <div class="padding-10 shadow route-content">
            <div class="row mobile-hidden strong-font">
                <div class="col-lg-2">Dairy Details</div>
                <div class="col-lg-2">Tank Details</div>
                <div class="col-lg-2">Delivery Details</div>
                <div class="col-lg-1">Qty Dropped</div>
                <div class="col-lg-1">Post Drop Level</div>
                <div class="col-lg-1">Para Inches</div>
                <div class="col-lg-1">Delivered</div>
                <div class="col-lg-1"></div>
                <div class="col-lg-1" *ngIf="isOffice">Invoiced</div>
            </div>
            <form #routeForm="ngForm" *ngFor="let order of route.deliveryOrders" class="margin-top-25 margin-bottom-5 route-form">
                <div class="row">
                    <div class="col-12 col-lg-2 flex-column flex-space-between">
                        <span class="mobile-display">Dairy Details:</span>
                        <div class="driver-route-container">
                            <address>
                                <a *ngIf="order.Tank" href="javascript:void(0);" [routerLink]="['/clients', order.Tank.Address.Client.id]">{{ order?.Tank?.Address?.Client?.name }}</a><br/>
                                {{order.Tank?.Address?.alias}}<br/>
                                {{ order?.Tank?.Address?.street }}, <br/>
                                {{ order?.Tank?.Address?.city}} {{ order?.Tank?.Address?.state }} {{ order?.Tank?.Address?.zip }}
                            </address>
                            <div class="button-row">
                                <button type="button" (click)="getAddressTanks(order.Tank?.addressId);" class="btn btn-sm btn-primary">View Dairy Tanks</button>
                                <button *ngIf="order.Tank?.Address?.Notes.length > 0" class="btn btn-sm btn-warning" (click)="showDeliveryNotes(order.Tank?.Address)">View Dairy Alerts</button>
                                <button class="btn btn-sm btn-danger" (click)="setOrderToRemove(order)" *ngIf="!driverView">Cancel Order</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-2 flex-column flex-space-between">
                        <span class="mobile-display">Tank Details:</span>
                        <div class="driver-route-container">
                            <div>
                                <span class="tank-details">Name: </span> <span [ngClass]="{'hcho-green': order.Tank?.productId == 1, 'lqa-blue':order.Tank?.productId == 2}">{{ order?.Tank?.tankName }}</span>
                            </div>
                            <div>
                                <span class="tank-details">Product: </span> <span [ngClass]="{'hcho-green': order.Tank?.productId == 1, 'lqa-blue':order.Tank?.productId == 2}">{{ order?.Product?.name }}</span>
                            </div>
                            <div>
                                <span class="tank-details">Levels: </span>{{ tankService.displayTankLevel(order.Tank) }} / <span class="tank-max-dull">{{ order.Tank?.TankType?.capacityVolume }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-2 flex-column flex-space-between">
                        <span class="mobile-display">Delivery Details:</span>
                        <div class="driver-route-container">
                            <div><span class="tank-details">Number: </span> {{ order?.deliveryNumber ? order.deliveryNumber : 'No Delivery Number'}}</div>
                            <div><span class="tank-details">Ordered: </span>{{ order?.quantityOrdered }} Gals </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-1">
                        <span class="mobile-display">Dropped:</span>
                        <input type="number" [max]="order.Tank?.TankType?.capacityVolume" name="Qty Dropped" class="form-control" [(ngModel)]="order.quantityDropped" required>
                    </div>
                    <div class="col-12 col-lg-1">
                        <span class="mobile-display">Tank Level:</span>
                        <input type="number" [max]="order.Tank?.TankType?.capacityVolume" name="Post Drop Level" class="form-control" [(ngModel)]="order.tankLevelPostDrop"
                            (blur)="orderLimit(order)" required>
                    </div>
                    <div class="col-12 col-lg-1">
                        <span class="mobile-display">Para Inches:</span>
                        <input type="text" name="Para Inches" class="form-control" [(ngModel)]="order.paraInches">
                    </div>

                    <div class="col-6 col-lg-1">
                        <button type="button"
                                class="btn btn-sm delivery-button"
                                [disabled]="route.completedAt"
                                [style.width]="'90px'"
                                [ngClass]="{'btn-success': order.delivered, 'btn-light': !order.delivered}"
                                (click)="save(order, routeForm.form)">
                            <i class="fa" [ngClass]="{'fa-check': order.delivered, 'fa-truck': !order.delivered}"></i> {{order.delivered ? 'Done' : 'Deliver'}}
                        </button>
                        <a class="pull-right"><i (click)="order.saved = false" class="fa fa-check green saved" *ngIf="order.saved"></i></a>
                    </div>

                    <div class="col-6 col-lg-1">
                        <div class="pull-right">
                            <ng-container *ngIf="order.delivered">
                                <button class="btn btn-sm btn-primary" [disabled]="route.completedAt" *ngIf="!route.completedAt || !isOffice" (click)="updateOrder(order)">
                                    <i class="fa fa-floppy-o"></i>
                                </button>
                                <button class="btn btn-sm btn-primary" *ngIf="route.completedAt && isOffice" (click)="updateClosedOrder(order)">
                                    <i class="fa fa-floppy-o"></i>
                                </button>
                            </ng-container>
                            <button class="btn btn-sm btn-success" (click)="openMap(order)">
                                <i class="fa fa-map"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-lg-1" *ngIf="isAdmin">
                        <span class="mobile-display">Invoiced:</span>
                        <input type="checkbox" name="Invoiced" class="form-check-input" [(ngModel)]="order.invoiced">
                    </div>
                    <hr>
                </div>
            </form>

            <div class="container-fluid">
                <div class="row route-separator-top">
                    <div class="col-sm-12">
                        <app-loaded-levels [route]="route" [productDetails]="productDetails" (onUpdate)="updateTotalGallons()"></app-loaded-levels>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row margin-top-10 driver-route route-separator">
                    <div class="col-sm-12">
                        <div class="actions pull-right">
                            <button class="btn" [ngClass]="{'btn-success':!route.completedAt, 'btn-danger':route.completedAt}" (click)="completedRouteModal.showPromptModal()"><span>{{route.completedAt ? 'Closed':'Close'}}</span> Route</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<prompt-modal
        #updateInventory
        size="large">

    <div class="modal-header">
        <h4 class="modal-title"><span *ngIf="tanks.length > 0">Inventory for {{tanks[0].Client?.name}} - {{tanks[0].Address ? tanks[0].Address?.alias: 'Client'}}</span></h4>
    </div>

    <div class="modal-item">
        <div class="row">
                <div class="col-12 col-sm-6 padding-bottom-10">
                    <div class="tank-detail row">
                        <div class="container-fluid">
                            <div class="col-12 col-sm-6">
                                <h5><i class="fa fa-circle tank-link" *ngIf="updateTank.TankDevice?.SerialNumber"></i> {{updateTank.id ? updateTank.tankName : 'No Tank'}}</h5>
                                <div><strong>ID:</strong> <span>{{updateTank.id}}</span></div>
                                <div><strong>Product:</strong> <span *ngIf="updateTank.Product">{{updateTank.Product?.name}}</span></div>
                                <div><strong>ID:</strong> <span>{{updateTank.id}}</span></div>
                                <div><strong>Inventory:</strong> <span *ngIf="updateTank.TankType">{{updateTank.currentGallons}}/{{updateTank.TankType?.capacityVolume}}</span></div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="tank-percent" *ngIf="updateTank.id">
                                    {{ getTankPercent(updateTank) | percent: '1.0-0' }}
                                </div>
                                <div class="tank-percent" *ngIf="!updateTank.id">
                                    N/A
                                </div>
                                <div *ngIf="updateTank.id" class="tank-status  {{'tank-' + getTankStatus(updateTank).toLowerCase()}}">
                                    {{ getTankStatus(updateTank) }}
                                </div>
                                <div *ngIf="!updateTank.id" class="tank-status tank-null">
                                    No Data
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 padding-bottom-10">
                    <form #updateTankLevels="ngForm" class="updateTankForm">
                        <div class="row">
                            <div class="form-group">
                                <label for="gallonsDropped">Gallons Ordered</label>
                                <input type="number" id="gallonsOrdered"  min="0" name="Gallons Ordered" [(ngModel)]="addOrder.quantityOrdered" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group">
                                <label for="gallonsDropped">Gallons Dropped</label>
                                <input type="number" id="gallonsDropped" name="Gallons Delivered" [(ngModel)]="addOrder.quantityDropped" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group">
                                <label for="tankLevelPostDrop">Tank Level Post Drop</label>
                                <input type="number" min="0" [max]="updateTank.TankType?.capacityVolume" id="tankLevelPostDrop" name="Tank Level Post Drop"
                                       [(ngModel)]="addOrder.tankLevelPostDrop" (blur)="currentLimit(addOrder)" required>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group">
                                <label for="paraInches">Para Inches</label>
                                <input type="number" id="paraInches" name="Para Inches" [(ngModel)]="addOrder.paraInches" required>
                            </div>
                        </div>

                        <div class="row">
                            <div class="">
                                <button class="btn btn-success btn-sm" (click)="canUpdate(addOrder, updateTankLevels.form)" [disabled]="!updateTank.id || checkForRoute(updateTank)">Save</button>
                                <button class="btn btn-danger btn-sm" (click)="cancelUpdate(); updateTankLevels.reset()">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>

        </div>
        <div class="col-12 padding-bottom-10 overflow-auto">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Tank Name</th>
                        <th>Product</th>
                        <th>Current Gallons</th>
                        <th>Tank Capacity</th>
                        <th>Last Updated</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tk of tanks" (click)="addToUpdate(tk)" [ngClass]="{'bg-red': checkForRoute(tk)}">
                        <td><i class="fa fa-circle tank-link" *ngIf="tk.TankDevice?.SerialNumber"></i> {{tk.tankName}}</td>
                        <td>{{tk.Product?.name}}</td>
                        <td>{{tk.currentGallons}}</td>
                        <td>{{tk.TankType?.capacityVolume}}</td>
                        <td>{{tk.updatedAt | date}}</td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>

    <div class="modal-buttons">
        <!--<button (click)="updateInventory.hidePromptModal()" type="submit" class="btn btn-sm btn-success">Update</button>-->
        <button type="button" class="btn btn-sm btn-light" (click)="closeModal()">Close</button>
    </div>

</prompt-modal>

<!-- Cancel Delivery Modal -->
<prompt-modal
        #cancelDelivery
        size="medium">

    <div class="modal-header">
        <h4 class="modal-title">Undo Delivery</h4>
    </div>

    <div class="modal-item">
       <p>Are you sure you want to Undo the delivery for {{order?.Tank?.tankName}}?</p>
    </div>

    <div class="modal-buttons">
        <button (click)="cancel()" type="submit" class="btn btn-sm btn-danger">Yes</button>
        <button type="button" class="btn btn-sm btn-light" (click)="cancelDelivery.hidePromptModal()">No</button>
    </div>

</prompt-modal>

<prompt-modal
        #completedRouteModal
        size="medium">

    <div class="modal-header">
        <h4 class="modal-title">Update Your Route</h4>
    </div>

    <div class="modal-item">
        <p>Are you sure you want to {{route.completedAt ? 'Open':'Close'}} this route?</p>
    </div>

    <div class="modal-buttons">
        <button (click)="route.completedAt ? openRoute(): closeRoute(); completedRouteModal.hidePromptModal();" type="submit" class="btn btn-sm btn-success">{{route.completedAt ? 'Open':'Close'}}</button>
        <button type="button" class="btn btn-sm btn-light" (click)="completedRouteModal.hidePromptModal()">Cancel</button>
    </div>

</prompt-modal>


<prompt-modal
        #deliveryNotesModal
        size="medium">

    <div class="modal-header text-center">
        <h4 class="modal-title">{{tankAddress.alias}}</h4>
    </div>

    <div class="modal-item delivery-notes">
        <ul>
            <li *ngFor="let note of tankAddress.Notes">
                <div class="margin-bottom-5 note-heading"><strong>{{note.employeeName ? note.employeeName:'Specialty Sales'}}</strong> wrote on <strong>{{note.createdAt | date}}</strong></div>
                <div class="padding-left-20 note-content">{{note.content}}</div>
            </li>
        </ul>
    </div>

    <div class="modal-buttons">
        <button type="button" class="btn btn-primary" (click)="closeDeliveryNotes()">Ok</button>
    </div>

</prompt-modal>

<prompt-modal
        #removeOrderModal
        size="medium">

    <div class="modal-header">
        <h4 class="modal-title">Cancel Order from Route</h4>
    </div>

    <div class="modal-item">
        <p *ngIf="_order.delivered == false">Are you sure you want to cancel this delivery order of {{_order.quantityOrdered}} gallons of {{_order.Product?.name}} to {{_order?.Tank?.Address?.alias}}?</p>
        <p *ngIf="_order.delivered == true">This order has already been delivered. You cannot remove it from the route once it has been delivered. Please undo your delivery changes to cancel this order from the route.</p>
    </div>

    <div class="modal-buttons">
        <button (click)="removeFromRoute(); removeOrderModal.hidePromptModal();" type="submit" class="btn btn-sm btn-danger" *ngIf="_order.delivered == false">Remove</button>
        <button type="button" class="btn btn-sm btn-light" (click)="removeOrderModal.hidePromptModal()">Cancel</button>
    </div>

</prompt-modal>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
