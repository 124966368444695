import { Component, Input, Output, EventEmitter} from '@angular/core';

import {Tractor} from '../../../models/tractor';
import * as _ from 'lodash';

@Component({
    templateUrl: './tractor-list.component.html',
    styleUrls:['tractor-list.component.css'],
    selector: 'tractor-list'
})
export class TractorListComponent{
    @Input()
    tractors: Tractor[];
    @Input() selectedTractor:Tractor;
    @Output()
    setTractor = new EventEmitter<Tractor>();
    @Output()
    resetTractor = new EventEmitter<string>();
    active:boolean = true;

    constructor(){}

    selectTractor(tractor: Tractor): void{
        this.setTractor.emit(tractor)
    }

    isSelected(tractor:Tractor) {
        if (this.selectedTractor) {
            return tractor.id == this.selectedTractor.id;
        }
        return false;
    }

    scopedTractors() {
        return _.filter(this.tractors, (t:Tractor) => t.isActive == this.active );
    }

    clearTractor(){
        this.resetTractor.emit('reset');
    }

}