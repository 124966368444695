<div id="driver-route-table" class="routes-table-container shadow" *ngIf="routes.length != 0;">
    <table class="table table-striped">
        <thead>
            <tr>
                <th class="td-10">Date</th>
                <th class="td-20">Route</th>
                <th class="td-10" *ngIf="workOrder">Work Orders</th>
                <th class="td-10" *ngIf="!workOrder">Drops</th>
                <th class="td-10" *ngIf="!workOrder">Trailer</th>
                <th class="td-10">Status</th>
                <th class="td-20">Completed</th>
                <th class="td-20">Route Options</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let route of routes">
                <td class="td-10">{{ route.dateOfRoute | date}}</td>
                <td class="td-20">{{ route.routeName }}</td>
                <td class="td-10" *ngIf="workOrder">{{ getNumberOfWorkOrders(route) }}</td>
                <td class="td-10" *ngIf="!workOrder">{{ getNumberOfDrops(route) }}</td>
                <td class="td-10" *ngIf="!workOrder">{{ route.trailer?.name}}</td>
                <td class="td-10"><span class="active-status" [ngClass]="activeStatus(route.completedAt, route.dateOfRoute)">{{activeStatus(route.completedAt, route.dateOfRoute)}}</span></td>
                <td class="td-20"><span *ngIf="route.completedAt">{{route.completedAt | date}}</span> <span *ngIf="!route.completedAt">--</span></td>
                <td class="td-20">
                    <button class="btn btn-primary" [ngClass]="{'btn-sm': tablet}" (click)="getRoute(route)">View Route</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="row" *ngIf="routes.length == 0;">
    <div class="container-fluid">
        <div class="col-sm-12">
            <h2 style="color: red;">No {{driverOption == 1 ? 'Active': '' || driverOption == 2 ? 'Completed':'' || driverOption == 3 ? 'Upcoming':''}} Routes To Display</h2>
        </div>
    </div>
</div>