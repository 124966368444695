<div id="sidebar" [class.smallSidebar]="smallSide" class="no-print">
    <div class="logo-img">
       <span> <img src="/assets/images/specialtylogo.png" alt="logo"/></span>
        <a class="sidebar-btn" (click)="setSmall()">
            <i class="fa fa-bars"></i>
        </a>
    </div>
    <nav class="menu">
        <ul>
            <li #dashboard_e
                (mouseenter)="setTooltip('Dashboard', dashboard_e)"
                (mouseleave)="visible = false"
                routerLink="/dashboard"
                *ngIf="!isSalesrepOutside"
                routerLinkActive="sidebar-active grn-brdr"
                class="grn-hov"><i class="fa fa-fw fa-dashboard"></i> <span>Dashboard</span></li>


            <li #labs_e
                (mouseenter)="setTooltip('Labs', labs_e)"
                (mouseleave)="visible = false"
                routerLink="/labs"
                [queryParams]="labsParams"
                *ngIf="!isSalesrepOutside"
                routerLinkActive="sidebar-active grn-brdr"
                class="grn-hov"><i class="fa fa-fw fa-flask"></i> <span>Labs</span></li>


            <li #clients_e
                (mouseenter)="setTooltip('Clients', clients_e)"
                (mouseleave)="visible = false"
                routerLink="/clients"
                routerLinkActive="sidebar-active lblu-brdr"
                class=" lblu-hov"><i class="fa fa-fw fa-group"></i> <span>Clients</span></li>

            <li #routes_e
                (mouseenter)="setTooltip('Routes', routes_e)"
                (mouseleave)="visible = false"
                routerLink="/routes"
                *ngIf="!isSalesrepOutside"
                routerLinkActive="sidebar-active lgrn-brdr"
                class="lgrn-hov"><i class="fa fa-fw fa-map"></i> <span>Routes</span></li>

            <li #driver_e
                (mouseenter)="setTooltip('Driver', driver_e)"
                (mouseleave)="visible = false"
                [routerLink]="'/driver/' + driver"
                routerLinkActive="sidebar-active orng-brdr"
                *ngIf="isAdmin || (['Driver'] | appEmployeeTypes: employeeTypes)"
                class="orng-hov"><i class="fa fa-fw fa-truck lightgreen"></i> <span>Driver</span></li>

            <li #workorders_e
                (mouseenter)="setTooltip('Work Orders', workorders_e)"
                (mouseleave)="visible = false"
                routerLink="/work-orders"
                *ngIf="!isSalesrepOutside"
                routerLinkActive="sidebar-active lylw-brdr"
                class="lylw-hov"><i class="fa fa-fw fa-file"></i> <span>Work Orders</span></li>

            <li #work_order_routes_e
                (mouseenter)="setTooltip('Work Order Routes', work_order_routes_e)"
                (mouseleave)="visible = false"
                routerLink="/work-order-routes"
                *ngIf="!isSalesrepOutside"
                routerLinkActive="sidebar-active lgrn-brdr"
                class="lgrn-hov"><i class="fa fa-fw fa-map lightyellow"></i> <span>WO Routes</span></li>

            <li #work_order_driver_e
                (mouseenter)="setTooltip('Work Order Driver', work_order_driver_e)"
                (mouseleave)="visible = false"
                [routerLink]="'/work-order-driver/' + driver"
                routerLinkActive="sidebar-active orng-brdr"
                *ngIf="isAdmin || (['Driver'] | appEmployeeTypes: employeeTypes)"
                class="orng-hov"><i class="fa fa-fw fa-truck lightyellow"></i> <span>WO Driver</span></li>

            <li #profile_e
                (mouseenter)="setTooltip('Profile', profile_e)"
                (mouseleave)="visible = false"
                routerLink="/profile"
                routerLinkActive="sidebar-active grn-brdr"
                class="grn-hov"><i class="fa fa-fw fa-user-circle"></i> <span>Profile</span></li>

            <li #reporting_e
                (mouseenter)="setTooltip('Reporting', reporting_e)"
                (mouseleave)="visible = false"
                routerLink="/admin/reporting"
                routerLinkActive="sidebar-active orng-brdr"
                *ngIf="['Sales Manager'] | appEmployeeTypes: employeeTypes"
                class="orng-hov"><i class="fa fa-fw fa-list"></i> <span>Reporting</span></li>

            <li #admin_e
                (mouseenter)="setTooltip('Admin', admin_e)"
                (mouseleave)="visible = false"
                routerLink="/admin/employees"
                routerLinkActive="sidebar-active orng-brdr"
                *ngIf="isAdmin"
                class="orng-hov"><i class="fa fa-fw fa-user"></i> <span>Admin</span></li>

            <li #logout_e
                (mouseenter)="setTooltip('Logout', logout_e)"
                (mouseleave)="visible = false"
                (click)="logout()" class="lred-hov"><a><i class="fa fa-sign-out"></i> <span>Logout</span></a></li>

            <li #version_e
                (click)="showVersion()"
                (mouseenter)="setTooltip('v' + version, version_e)"
                (mouseleave)="visible = false"
                class="version"><a><i class="fa fa-code"></i> <span>v{{version}}</span></a></li>
        </ul>
    </nav>
</div>
<div
    *ngIf="smallSide"
    class="nav-tooltip"
    [style.top]="tooltipTop"
    [style.opacity]="visible ? '1' : '0'"
    [style.width]="visible ? '150px' : '0'"
>
    <p>{{navTooltip}}</p>
</div>
