import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { WorkOrder } from '../../models/work-order';
import {ServiceType} from "../../models/service-type";
import * as _ from 'lodash';
import {UtilsService} from "../../services-module/services/utils.service";

@Component({
    selector: 'recent-work-orders',
    templateUrl: './recent-work-orders.component.html',
    styleUrls: ['../dashboard.component.css']
})
export class RecentWorkOrdersComponent {

    @Input() workOrders: WorkOrder[];
    @Input() statusTypes: ServiceType[];

    constructor(
        private utilsService: UtilsService,
        private router: Router){}

    statusType(id:number){
        let type:string;
        _.forEach(this.statusTypes, (o)=>{
            if(o.id === id){
               return type = o.serviceTypeName;
            }else return;
        });
        return type;
    }

    woStatus(val: any){
        return this.utilsService.woStatus(val);
    }

}
