import { trigger, transition, style, query, animate, group } from '@angular/animations';

const fadeIn = [
  query(':leave', style({ position: 'absolute', opacity: 1, width: '100%' }), { optional: true }),
  query(':enter', style({ position: 'absolute', opacity: 0, width: '100%' }), { optional: true }),
  group([
    query(':leave', animate('200ms', style({ opacity: 0 })), { optional: true }),
    query(':enter', animate('200ms', style({ opacity: 1 })), { optional: true })
  ])
];

export const routeAnimation =
  trigger('routerAnimation', [
    transition('* <=> *', fadeIn)
  ]);
