
import { Pipe, PipeTransform } from '@angular/core';
import { Tank } from '@models/tank';
import { TankService } from '@services/tank.service';

@Pipe({ name: 'appTankChartLabel' })
export class TankChartLabelPipe implements PipeTransform {
  constructor(private tankService: TankService) { }

  transform(tank: Tank): any {
    const tankLevel = (this.getTankPercent(tank) * 100).toFixed(0);
    const status = this.getTankStatus(tank);
    return [`${status} - ${tankLevel}%`];
  }

  getTankPercent(tank: Tank): number {
    const percent = this.tankService.calculatePercent(tank);
    if (percent <= 0) {
      return 0;
    } else if (percent >= 100) {
      return 100;
    } else return percent;
  }

  getTankStatus(tank: Tank): string {
    return this.tankService.calculateTankStatus(tank);
  }
}
