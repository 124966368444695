import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import * as _ from 'lodash';

import { Route } from '../../../models/route';
import { Product } from '../../../models/product';
import { ServiceRegion } from '../../../models/service-region';
import { PromptModalComponent } from '../../../shared-components/prompt-modal/prompt-modal.component';
import { ServiceRegionService } from '../../../services-module/services/service-region.service';
import * as moment from 'moment';
import { AuthService } from '../../../authorization/auth.service';
import { ToastService } from '../../../services-module/services/toast.service';
import { RouteParams } from '../../../models/route-params';

@Component({
    selector: 'route-options',
    templateUrl: 'route-options.component.html',
    styleUrls: ['route-options.component.css']
})
export class RouteOptionsComponent {
    @Input()
    route: Route;
    // @Input()
    // counties: any[];
    @Input()
    workOrder: boolean;
    @Input()
    cities: any[];
    @Input()
    query: any;
    @Input()
    states: any[];
    @Input()
    products: Product[];
    @Input()
    serviceRegions: any[];
    @Input()
    routeParams: RouteParams;
    @Output()
    searchLocations = new EventEmitter();
    @Output()
    cancelRoute = new EventEmitter();
    @Output()
    newRoute = new EventEmitter();
    @Output()
    savePrefs = new EventEmitter();
    @Output()
    clearPrefs = new EventEmitter();
    @ViewChild('deliveryPrompt', { static: true }) private deliveryPrompt: PromptModalComponent;
    selectedRegions: ServiceRegion[] = [];
    modalDate: Date;
    public filterOption: any = null;
    isAdmin = this.auth.isAdmin();

    showRegionsPanel: boolean = false;

    constructor(
        private serviceRegionService: ServiceRegionService,
        private toastService: ToastService,
        private auth: AuthService,
        private location: Location
    ) { }

    ngOnInit(): void { }
    newR() {
        this.newRoute.emit();
    }

    prefsSet() {
        return localStorage.getItem('routePrefs');
    }

    onClickOutside(event: Object) {
        if (event && event['value'] == true) {
            this.filterOption = null;
        }
    }

    selectStates() {
        if (this.states) {
            return this.states.map(state => {
                return { id: state.state, name: state.state }
            });
        } else {
            return [];
        }
    }

    search(): void {
        this.searchLocations.emit(this.routeParams);
    }

    clearParams() {
        this.routeParams = new RouteParams();
        this.routeParams.selectedRegions = [];
        this.routeParams.states = [];
    }
    filterBy(filter: string) {
        this.filterOption = filter;
    }

    today() {
        return moment().format('YYYY-MM-DD');
    }

    modalToday() {
        return new Date();
    }

    editDate() {
        this.modalDate = moment(this.route.dateOfRoute).toDate();
        this.deliveryPrompt.showPromptModal();
    }

    setDate() {
        this.route.dateOfRoute = moment(this.modalDate).format('YYYY-MM-DD');
        this.modalDate = null;
    }

    inUse(state: any) {
        let truth = false;
        // return _.find(this.query.states, state);
        this.routeParams.states.forEach((s: any) => {
            if (s == state) {
                truth = true;
            }
        });
        return truth;
    }
    regionUse(reg: any) {
        let truth = false;
        this.routeParams.selectedRegions.forEach((s: any) => {
            if (s == reg) {
                truth = true;
            }
        });
        return truth;
    }
    regionName(regionId: any) {
        let regId = +regionId;
        let region = _.find(this.serviceRegions, { id: regId });
        return region ? region.name : '';
    }
    addToRouteParams(object: any, param: string) {

        //check the type to add to query
        //run a switch statement to do functionality
        switch (param) {
            case 'state':
                this.routeParams.states.push(object);
                break;
            case 'product':
                this.routeParams.productId = object;
                this.filterOption = null;
                break;
            case 'percent':
                this.routeParams.maxCriticalPercent = object;
                break;
            case 'city':
                this.routeParams.city = object;
                this.filterOption = null;
                break;
            case 'zip':
                this.routeParams.zip = object;
                break;
            case 'region':
                this.routeParams.selectedRegions.push(object);
                break;
            default:
                console.log('bad param');
        }
        //run the query
        // this.search();
    }

    removeFromRouteParams(object: any, param: string) {
        switch (param) {
            case 'state':
                this.remove(this.routeParams.states, object);
                break;
            case 'product':
                this.routeParams.productId = null;
                break;
            case 'percent':
                this.routeParams.maxCriticalPercent = null;
                break;
            case 'city':
                this.routeParams.city = null;
                break;
            case 'zip':
                this.routeParams.zip = null;
                break;
            case 'region':
                this.remove(this.routeParams.selectedRegions, object);
                break;
            default:
                console.log('bad param');
        }
        //run the query
        // this.search();
    }

    remove(arr: any, what: any) {
        _.remove(arr, function (n) {
            return n == what;
        });
    }

    productName(productId: any) {
        let prod = _.find(this.products, { id: productId });
        return prod ? prod.abbr : '';
    }

    cancel() {
        this.cancelRoute.emit();
    }

    save() {
        this.savePrefs.emit();
    }
    clear() {
        this.clearPrefs.emit();
    }
    closeOut() {
        this.filterOption = null;
    }
    renderDateOfRoute(date: any) {
        return moment(date).format('MM/DD/YYYY');
    }

}
