import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import * as _ from 'lodash';

import * as moment from 'moment';

import { Tank } from '../../models/tank';
import { TankDevice } from '../../models/tank-device';
import { environment } from '../../../environments/environment';

@Injectable()
export class TankService {
    private apiUrl = `${environment.apiUrl}`;
    constructor(public authHttp: HttpClient) { }

    getTanks(): Promise<Tank[]> {
        return this.authHttp.get(`${this.apiUrl}tank`)
            .toPromise()
            .then((response) => {
                return response as Tank[];
            });
    }

    getAddressTanks(addressId: number): Promise<Tank[]> {
        return this.authHttp.get(`${this.apiUrl}tank/address-tanks/${addressId}`)
            .toPromise()
            .then((response) => {
                return response as Tank[];
            });
    }

    getPercent(tank: Tank): string {
        const percent = this.calculatePercent(tank);
        if (!percent) return 'N/A';
        return `${percent} %`;
    }

    calculatePercent(tank: Tank): number {
        if (!tank.TankType) return -.01;
        return (tank.currentGallons / tank.TankType.capacityVolume);
    }

    calculateTankStatus(tank: Tank): string {
        const currentPercent = this.calculatePercent(tank) * 100;
        if (currentPercent <= (tank.serviceThreshholdCritical || (tank.TankType && tank.TankType.defaultPercentCritical))) {
            return 'Critical';
        } else if (currentPercent <= (tank.serviceThreshholdAlert || (tank.TankType && tank.TankType.defaultPercentAlert))) {
            return 'Low';
        } else {
            return 'Good';
        }
    }

    buildTank(tank: Tank, addressId: number, clientId: number): Tank {
        tank.addressId = addressId;
        tank.clientId = clientId;
        return tank;
    }

    saveTank(tank: Tank): Promise<Tank> {
        if (tank.id) {
            return this.updateTank(tank);
        } else {
            return this.createTank(tank);
        }
    }

    createTank(tank: Tank): Promise<Tank> {
        return this.authHttp.post(`${this.apiUrl}tank`, tank)
            .toPromise()
            .then((res) => {
                return res as Tank;
            });
    }
    updateTank(tank: Tank): Promise<Tank> {
        return this.authHttp.put(`${this.apiUrl}tank/${tank.id}`, tank)
            .toPromise()
            .then((res) => {
                return res as Tank;
            });
    }

    deleteTank(tank: Tank): Promise<Tank> {
        return this.authHttp.delete(`${this.apiUrl}tank/${tank.id}`)
            .toPromise()
            .then((res) => {
                return res as Tank;
            });
    }

    updateTanks(tanks: Tank[]): Promise<Tank[]> {
        return this.authHttp.post(`${this.apiUrl}tank/update-tanks`, tanks)
            .toPromise()
            .then((res) => {
                return res as Tank[];
            });
    }


    getTanksForDelivery(query: any): Promise<Tank[]> {
        return this.authHttp.post(`${this.apiUrl}tank/critical`, query)
            .toPromise()
            .then((res: any) => {
                return res as Tank[];
            });
    }

    getDifferenceInDays(date: string): number {
        const currentDate = moment().format();
        const selectedDate = moment(date);
        return selectedDate.diff(currentDate, 'days');
    }

    saveTankDevice(tank: Tank): Promise<Tank> {
        if (tank.TankDevice.id) {
            return this.updateTankDevice(tank.TankDevice)
                .then((res) => {
                    _.merge(tank.TankDevice, res);
                    return tank;
                });
        } else {
            return this.createTankDevice(tank.TankDevice)
                .then((res) => {
                    tank.tankDeviceId = res.id;
                    tank.TankDevice = res;
                    return this.saveTank(tank)
                        .then((resp) => {
                            _.merge(tank, resp);
                            return tank;
                        });
                });
        }
    }

    createTankDevice(tankDevice: TankDevice): Promise<TankDevice> {
        return this.authHttp.post(`${this.apiUrl}tank-device`, tankDevice)
            .toPromise()
            .then((res) => {
                return res as TankDevice;
            });
    }

    updateTankDevice(tankDevice: TankDevice): Promise<TankDevice> {
        return this.authHttp.put(`${this.apiUrl}tank-device/${tankDevice.id}`, tankDevice)
            .toPromise()
            .then((res) => {
                return res as TankDevice;
            });
    }

    getInactiveTanks(): Promise<Tank[]> {
        return this.authHttp.post(`${this.apiUrl}tank/inactive`, {})
            .toPromise()
            .then((res) => {
                return res as Tank[];
            });
    }

    /*
     * Method to handle displaying tank levels
     * returns 0 if tank level is negative or tankVolume if above current tank volume
     * */
    displayTankLevel(tank: Tank) {
        if (tank.currentGallons <= 0) {
            return 0;
        } else if (tank.currentGallons >= tank.TankType.capacityVolume) {
            return tank.TankType.capacityVolume;
        } else {
            return tank.currentGallons;
        }
    }

}
