
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { HttpService } from '../../../shared-services/http-service/http-service.service';

@Injectable()
export class TablesResolver implements Resolve<any[]> {
  constructor(private http: HttpService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.http.post('session/list-all-table', {}).pipe(
      map((resp) => {
        return resp && resp.data && resp.data.length > 0 ? resp.data : [];
      }));
  }
}
