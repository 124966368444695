<h5><i class="fa fa-group"></i> Recent Activity</h5>
<div class="shadow recent-box">
    <table id="recent-table" class="table table-striped table-hover">
        <thead>
            <tr>
                <th class="td-40">Client</th>
                <th class="td-30">Activity</th>
                <th class="td-30">Date</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let client of clients" [routerLink]="['/clients',client.id]">
                <td class="td-40">{{client.name}}</td>
                <td class="td-30">{{client.action}}</td>
                <td class="td-30"><em><span class="hide-mobile">{{client.updatedAt | date:'longDate'}}</span><span class="show-mobile"> {{client.updatedAt | date}}</span></em></td>
            </tr>
        </tbody>
    </table>
</div>