import * as _ from 'lodash';

import {WorkOrder} from './work-order';
import {Note} from './note';
import {Tank} from './tank';
import {ClientContact} from "./contact";
import {Client} from './client';
import { Lab } from "./lab";
import { ServiceRegion } from "./service-region";

export class Address{
    cowCount: number = null;
    bathCount: number = null;
    deliveryInstructions: string = null;
    id: number = null;
    deliveryOrders: any[] = [];
    Tanks: Tank[] = [];
    Notes: Note[] = [];
    ClientContacts: ClientContact[] = [];
    workOrders: WorkOrder[] = [];
    Customer_PK: number = null;
    clientId: number = null;
    street: string = null;
    city: string = null;
    state: string = null;
    isActive: boolean = true;
    longitude: string = null;
    latitude: string = null;
    zip: number = null;
    Client: Client = null;
    alias: string = null;
    Labs: Lab[];
    county: string;
    isDeliveryLocation: boolean;
    ServiceRegion: ServiceRegion;
    billingEmail: string;
    serviceRegionId: any;
    constructor(data?: any){
        _.merge(this, data);
    }
}
