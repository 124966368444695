import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { TankType } from '../../models/tank-type';
import { environment } from '../../../environments/environment';

@Injectable()
export class TankTypeService {
    private apiUrl = `${environment.apiUrl}tank-type`;  // URL to web api
    constructor(private authHttp: HttpClient) { }

    getAllTankTypes(): Promise<TankType[]> {
        return this.authHttp.get(this.apiUrl)
            .toPromise()
            .then((res) => {
                return res as TankType[];
            });
    }

    saveTankType(tankType: TankType): Promise<TankType> {
        if (tankType.id) {
            return this.updateTankType(tankType);
        } else {
            return this.createTankType(tankType);
        }
    }

    createTankType(tankType: TankType): Promise<TankType> {
        return this.authHttp.post(`${this.apiUrl}`, tankType)
            .toPromise()
            .then((res) => {
                return res as TankType;
            });
    }
    updateTankType(tankType: TankType) {
        return this.authHttp.put(`${this.apiUrl}/${tankType.id}`, tankType)
            .toPromise()
            .then((res) => {
                return res as TankType;
            });
    }
}
