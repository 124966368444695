import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { Title } from '@angular/platform-browser';
import { RouteService } from '@services/route.service';
import { RouteViewComponent } from '../client-routes/route-view/route-view.component';
import * as moment from 'moment';
import { EmployeeService } from '@services/employee.service';

import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();



@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService,
    private router: Router,
    private titleService: Title) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // If user is not logged in we'll send them to the homepage
    if (!this.auth.loggedIn()) {  // Now check if driver has a landing page preference
      this.router.navigate(['/login']);
      this.titleService.setTitle('Specialty Sales - Login');
      return false;
    } else {
      if (route.url.length !== 0) {
        this.titleService.setTitle(`Specialty Sales - ${route.url[0].path[0].toUpperCase()}${route.url[0].path.slice(1)}`);
      } else {
        this.titleService.setTitle('Specialty Sales');
      }
      return true;
    }
  }
}

@Injectable({ providedIn: 'root'})
export class RedirectGuard implements CanActivate {

  constructor(private auth: AuthService,
    private employeeService: EmployeeService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!helper.isTokenExpired(localStorage.getItem('id_token'))) {
      if ((window.performance && performance.navigation.type !== 1)) {
        this.employeeService.getLanding().then(res => {
          if (res) {
            this.router.navigate([res]);
          } else {
            this.router.navigate(['/dashboard']);
          }
        });
      } else {
        this.router.navigate(['/dashboard']);
      }
    } else {
      this.router.navigate(['/login']);
    }
    return true;
  }
}

// Admin guard to check if a user is an admin
@Injectable({ providedIn: 'root'})
export class AdminCheck implements CanActivate {

  constructor(private auth: AuthService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.auth.isAdmin()) {
      this.router.navigate(['/401']);
      return false;
    } return true;
  }
}

@Injectable({ providedIn: 'root'})
export class SalesManagerCheck implements CanActivate {

  constructor(private auth: AuthService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.auth.isAdmin() && !this.auth.employeeType(['Sales Manager'])) {
      this.router.navigate(['/401']);
      return false;
    } return true;
  }
}

// Save changes when leaving route edit page
@Injectable({ providedIn: 'root'})
export class DeactivateRouteview implements CanDeactivate<RouteViewComponent> {

  constructor(private routeService: RouteService) { }

  canDeactivate(
    component: RouteViewComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {

    let update: boolean;
    switch (true) {
      case component.route.locations.length > 0:
      case !!component.route.startLocationId:
      case !!component.route.endLocationId:
      case component.route.driverIds.length > 0:
      case !!component.route.trailerId:
        update = true;
        break;
      default:
        update = false;
        break;
    }
    if (this.routeService.hasChanges && update) {
      const confirm = window.confirm('Are you sure you want to leave the route edit page? Changes will be saved.');
      if (confirm) {
        if (!component.route.routeName) component.route.routeName = 'Saved-' + moment().format('YYYYMMDD hh:mm:ss');
        return component.saveRoute(component.route)
          .then(() => true);
      }
      return false;
    }
    return true;
  }
}
