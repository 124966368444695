import { Component, Input, Output, EventEmitter} from '@angular/core';

import {Tank} from '../../../models/tank';
import * as _ from 'lodash';

@Component({
    templateUrl: 'tank-list.component.html',
    styleUrls: ['tank-list.component.css'],
    selector: 'tank-list'
})
export class TankListComponent{
    @Input() tanks: Tank[];
    @Input() selectedTankId:number;
    @Output() setTank = new EventEmitter<Tank>();
    @Output() resetProduct = new EventEmitter<string>();
    @Input() active: Object;

    constructor(){}

    selectTank(tank: Tank){
        this.setTank.emit(tank);
    }

    isSelected(tank:Tank) {
        if (this.selectedTankId) {
            return tank.id == this.selectedTankId;
        }
        return false;
    }

    activeTanks() {
        return _.filter(this.tanks, (t:Tank) => t.isActive == this.active['active'] );
    }

    clearTank(){
        this.resetProduct.emit('reset');
    }
}