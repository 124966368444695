<div class="panel data-panel">
  <div class="panel-header">
    <div class="flex-container flex-space-between">
      <h5><i class="fa fa-database"></i> Tanks</h5>
    </div>
  </div>
  <div class="panel-content">
    <div *ngFor="let tank of tanks">
      <div class="container tank-container">
        <div class="row">
          <div class="col-6">
            <h5>
              <i
                class="fa fa-circle tank-link"
                *ngIf="tank.TankDevice?.SerialNumber"
              ></i>
              <span
                [ngClass]="{
                  'hcho-green': tank.productId == 1,
                  'lqa-blue': tank?.productId == 2
                }"
                >{{ tank.tankName }}</span
              >
            </h5>
            <div><span class="tank-prop">ID:</span> {{ tank.id }}</div>
            <div>
              <span class="tank-prop">Prod: </span
              ><span
                [ngClass]="{
                  'hcho-green': tank.productId == 1,
                  'lqa-blue': tank?.productId == 2
                }"
                >{{ tank.Product.name }}</span
              >
            </div>
            <div>
              <span class="tank-prop">Gal: </span
              >{{
                tank.currentGallons
                  | appTankLevel : tank.TankType?.capacityVolume
              }}/{{ tank.TankType?.capacityVolume }}
            </div>
            <div>
              <span class="tank-prop">Next Delivery: </span>

              <span *ngIf="!isSalesrepOutside">
                <a
                  *ngIf="
                    tank?.deliveryOrders?.length &&
                      tank.deliveryOrders[0].routeId;
                    else elseBlock
                  "
                  routerLink="{{
                    '/route-review/' + tank.deliveryOrders[0].routeId
                  }}"
                >
                  <i class="fa fa-truck" style="color: #ff2327"></i>
                  {{ tank.deliveryOrders[0].route.dateOfRoute }}
                </a>
              </span>

              <span *ngIf="isSalesrepOutside">
                <span
                  *ngIf="
                    tank?.deliveryOrders?.length &&
                      tank.deliveryOrders[0].routeId;
                    else elseBlock
                  "
                >
                  <i class="fa fa-truck" style="color: #ff2327"></i>
                  {{ tank.deliveryOrders[0].route.dateOfRoute }}
                </span>
              </span>
              <ng-template #elseBlock> None </ng-template>
            </div>
            <!--<div>-->
            <!--<span class="tank-prop">Last Updated:</span><br/>{{ tank.updatedAt | date }}-->
            <!--</div>-->
          </div>
          <div
            *ngIf="tank.TankType"
            class="col-6 flex-container-col flex-center"
          >
            <div class="tank-percent" *ngIf="(tank | appTankPercent) <= 0">
              {{ tank | appTankPercent | percent : "1.0-0" }}
            </div>
            <div
              class="tank-status  {{
                'tank-' + (tank | appTankStatus).toLowerCase()
              }}"
              *ngIf="(tank | appTankPercent) <= 0"
            >
              {{ tank | appTankStatus }}
            </div>

            <div style="display: block" *ngIf="(tank | appTankPercent) > 0">
              <canvas
                baseChart
                width="400"
                height="400"
                [datasets]="tank | appTankData"
                [labels]="tank | appTankChartLabel"
                [options]="barChartOptions"
                [colors]="tank | appTankColor"
                [legend]="barChartLegend"
                [chartType]="barChartType"
                (chartClick)="chartClicked($event)"
              ></canvas>
            </div>
          </div>
          <div class="col-12" *ngIf="tank.TankDevice?.SerialNumber">
            <span class="tank-updated"
              >Last read: {{ tank.updatedAt | dateFormatter }}</span
            >
          </div>
          <div
            *ngIf="!tank.TankType"
            class="col-6 flex-container-col flex-center missing-tank"
          >
            <div
              class="tank-status  {{
                'tank-' + (tank | appTankStatus).toLowerCase()
              }}"
            >
              Missing Tank Type
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="tanks.length === 0">No Tanks</div>
  </div>
  <div class="panel-footer">
    <button class="btn btn-tanks" (click)="showAll()">
      {{ open ? "Hide" : "View" }} All Tanks
    </button>
  </div>
</div>
