<div class="panel padding-15">
    <form action="" name="regionForm" #regionForm="ngForm">
        <div class="row">
            <div class="form-group col-md-7">
                <label for="region">Region</label>
                <input type="text"
                       id="region"
                       required
                       class="form-control"
                       [(ngModel)]="serviceRegion.serviceRegionName"
                       required
                       name="region-name">
            </div>
            <div class="form-group col-md-5">
                <label for="abbr">Abbr</label>
                <div class="form-group">
                    <input type="text"
                           id="abbr"
                           name="abbr"
                           class="form-control"
                           [(ngModel)]="serviceRegion.serviceRegionAbbr">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <label for='state'>State</label>
                <select name="state"
                        id="state"
                        class="form-control"
                        [(ngModel)]="serviceRegion.state">
                    <option value="" selected>State</option>
                    <option [value]="state.abbreviation"
                            *ngFor="let state of states">{{ state.name }}</option>
                </select>
            </div>
        </div>
        <div class="margin-top-10">
            <div class="bg-success" *ngIf="saveSuccess">
                Service Region saved.
            </div>
            <div class="bg-danger" *ngIf="saveFail">
                Service Region save unsuccessful. Try again or contact system admin.
            </div>
        </div>
        <div class="margin-top-10 flex-align-end">
            <button *ngIf="serviceRegion.id && serviceRegion.isActive" (click)="regionDelete.showPromptModal()" type="button" class="btn-danger btn">Inactive</button>
            <button *ngIf="serviceRegion.id && !serviceRegion.isActive" (click)="regionDelete.showPromptModal()" type="button" class="btn-success btn">Restore</button>
            <button type="button" class="btn-warning btn" (click)="clearServiceRegion()" [disabled]="!regionForm.form.valid">Clear</button>
            <button
                [disabled]="!regionForm.form.valid" class="btn btn-primary"
                (click)="save(serviceRegion)"
            >{{serviceRegion.id ? 'Update' : 'Create'}}</button>
        </div>
    </form>
</div>

<prompt-modal
    #regionDelete
    size="small"
>
        <div class="modal-header">
            <h4 class="modal-title">Set Region {{serviceRegion.isActive ? 'Inactive' : 'Active'}}</h4>
        </div>

        <div class="modal-item">
            <div class="col-12">
                <p *ngIf="serviceRegion.isActive">Are you sure you want to set region "{{serviceRegion.serviceRegionName}}" to inactive?</p>
                <p *ngIf="!serviceRegion.isActive">Would you like to restore region "{{serviceRegion.serviceRegionName}}"?</p>
            </div>
        </div>

        <div class="modal-buttons">
            <button *ngIf="serviceRegion.isActive" (click)="serviceRegion.isActive = 0;save(serviceRegion);regionDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-danger">Inactive</button>
            <button *ngIf="!serviceRegion.isActive" (click)="serviceRegion.isActive = 1;save(serviceRegion);regionDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-success">Restore</button>
            <button type="button" class="btn btn-sm btn-light" (click)="regionDelete.hidePromptModal()">Cancel</button>
        </div>

</prompt-modal>