<div id="driverComponent" class="min-100">
	<div class="row header-row">
		<div class="container-fluid">
      		<div class="col-12 driver-nav">
				<div class="header">
					<h2><i class="fa fa-truck"></i> Driver Routes</h2>
				</div>
				 <span>Hello, {{ employee.firstName }}!</span>
				<ul class="route-btn">
					<li [ngClass]="{ 'driver-option': driverOption == 1 }">
						<a href="javascript:void(0);" (click)="pageChanged({ option: 1 })"><i class="fa fa-star"></i> Active Routes</a>
					</li>
					<li [ngClass]="{ 'driver-option': driverOption == 2 }">
						<a href="javascript:void(0);" (click)="pageChanged({ option: 2 })"><i class="fa fa-list"></i> Completed Routes</a>
					</li>
					<li [ngClass]="{ 'driver-option': driverOption == 3 }">
						<a href="javascript:void(0);" (click)="pageChanged({ option: 3 })"><i class="fa fa-navicon"></i> Future Routes</a>
					</li>
				</ul>
      		</div>
    	</div>
	</div>
	<div class="mobile-driver-header container-fluid">
		<div class="row">
			<div class="col-12"><h3>Hello, {{ employee.firstName }}!</h3></div>
		</div>
	</div>
	<div class="row page-container" *ngIf="viewRoute">
		<div class="container-fluid mobile-padding">
			<div class="col-12">
				<work-route-single [route]="employeeRoute" *ngIf="workOrder"></work-route-single>
				<app-route-single *ngIf="!workOrder" [route]="employeeRoute"></app-route-single>
			</div>
		</div>
	</div>

	<div class="row page-container" *ngIf="!viewRoute">
		<div class="container-fluid min-100">
			<div class="col-12 min-100 mobile-padding">
				<driver-route-table (getNewRoute)="getNewRoute($event)"
									[driverOption]="driverOption"
									[workOrder]="workOrder"
									[routes]="routes"></driver-route-table>
				<div class="pagination shadow">
					<pagination *ngIf="totalRoutes > itemsPerPage"
								[totalItems]="totalRoutes"
								[itemsPerPage]="itemsPerPage"
								[(ngModel)]="page"
								(pageChanged)="pageChanged($event, 'routes')"
								[maxSize]="maxSize"
								[rotate]="rotate"
								previousText="&lsaquo;"
								nextText="&rsaquo;"
								firstText="&laquo;"
								lastText="&raquo;"
								[boundaryLinks]="true"></pagination>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="mobile-driver-options no-print">
	<div class="container-fluid">
    	<div class="row">
      		<div class="col-4">
        		<a href="javascript:void(0)"
				   [ngClass]="{ active: driverOption == 1 }"
				   (click)="pageChanged({ option: 1 })">Active</a>
      		</div>
			<div class="col-4">
        		<a href="javascript:void(0)"
				   [ngClass]="{ active: driverOption == 2 }"
          		   (click)="pageChanged({ option: 2 })">Completed</a>
      		</div>
			<div class="col-4">
				<a href="javascript:void(0)" [ngClass]="{ active: driverOption == 3 }" (click)="pageChanged({ option: 3 })">
					Upcoming
				</a>
			</div>
		</div>
	</div>
</div>

<div class="loading-overlay" *ngIf="ftp">
	<img src="../../../assets/images/specialtylogo.png" />
		<i class="fa fa-spinner fa-spin fa-2x"></i>
		<h4>Loading ...</h4>
</div>
