import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { startCase } from 'lodash';

@Component({
  selector: 'data-dictionary',
  templateUrl: './data-dictionary.component.html',
  styleUrls: ['./data-dictionary.component.scss']
})
export class DataDictionaryComponent implements OnInit {
  @Input() columns: any;
  @Input() tbodyHeight: string;
  @Input() config: any;
  @Input() belongsToID: number;
  @Output() onUpdateSuccess: EventEmitter<any> = new EventEmitter();
  expandInput = false;
  visibleColumns: any;
  flexConfig: any;

  constructor() { }

  ngOnInit() { 
    this.flexConfig = { sort: false, routing: false, table: 'schema' };
  }

  updateVisibleColumns(event) {
    if (<HTMLElement>document.getElementsByClassName('tbody')[0])
      (<HTMLElement>document.getElementsByClassName('tbody')[0]).scrollTop = 0;
    this.visibleColumns = event;
  }

  startCase(str: string) {
    return startCase(str);
  }

  updateSuccess(event) {
    if (event.data) {
      this.onUpdateSuccess.emit(event);
    }
  }

  setDescWidth(event) {
    this.expandInput = event;
  }
}
