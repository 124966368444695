<div class="client-locations-wrapper no-print">
    <div class="panel table-wrapper" id="resizable">
        <div class="row padding-top-15 table-title">
            <div class="col-md-12 flex-container flex-space-between">
                <h3 *ngIf="workOrder"><span class="hide-mobile">Work Order </span>Locations</h3>
                <h3 *ngIf="!workOrder"><span class="hide-mobile">Client </span>Locations</h3>
                <div class="flex-container flex-space-between form-inline">
                    <div class="form-group">
                        <label for="dairySearch">Dairy Name: &nbsp;</label>
                        <input type="text"
                               id="dairySearch"
                               name="Dairy Search"
                               class="form-control" [(ngModel)]="search">
                    </div>
                </div>
            </div>
        </div>

        <div class="locations-table-wrapper">
            <div class="locations-table-scroll">
                <div class="title-row row" *ngIf="!workOrder">
                    <div class="col-sm-3">
                        <a href="javascript:;" (click)="filter('tankName')">Tank Info</a></div>
                    <div class="col-sm-3">
                        <a href="javascript:;" (click)="orderByClients('alias')">Dairy Info</a>
                    </div>
                    <div class="col-sm-3">
                        <a href="javascript:;" (click)="filter('currentGallons')">Usage Info</a>
                    </div>
                    <div class="col-sm-2">
                        <a href="javascript:;" (click)="filter('percentCritical')">%</a>
                    </div>
                    <div class="column col-1">
                        Del Qty
                    </div>
                </div>
                <div class="title-row row"  *ngIf="workOrder">
                    <div class="col-sm-3">
                        <a href="javascript:;">Date Opened</a></div>
                    <div class="col-sm-3">
                        <a href="javascript:;" (click)="orderByClients('alias')">Dairy Info</a>
                    </div>
                    <div class="col-sm-1">
                        Service Type
                    </div>
                    <div class="col-sm-4">
                        Details
                    </div>
                </div>
                <div class="locations-container" id="location-container">
					<div id="client-location"
						 class="location-container row"
						 *ngFor="let location of clientLocations | dairyFilter: 'Address':'alias': search"
                         (dblclick)="(location?.dropDate && !workOrder)  ? doNothing() : selectLocation($event, location)">

						 <div *ngIf="location?.isActive == 1 || workOrder" class="row">
                            <div class="col-sm-3"  *ngIf="workOrder">{{ location.createdAt | date }}</div>

                            <div class="col-sm-3" *ngIf="!workOrder">
                                <span (mouseenter)="location?.dropDate ? showDetails = location?.id:doNothing()"
                                      (mouseleave)="location?.dropDate ? showDetails = null:doNothing()">
                                    <i class="fa fa-truck" (click)="toRoute(location?.routeId)" style="color:#ff2327" *ngIf="location?.dropDate && !isSalesrepOutside"></i>
                                    <i class="fa fa-truck" style="color:#ff2327" *ngIf="location?.dropDate && isSalesrepOutside"></i>
                                    <div class="widget-hover" *ngIf="showDetails == location?.id && !isSalesrepOutside">
                                        <small>This Tank is scheduled for a delivery on {{ location?.dropDate | date }}</small>
                                    </div>
                                </span>
                                <i class="fa fa-circle tank-link" *ngIf="!!location?.serialNumber"></i> <span [ngClass]="{'hcho-green': location?.productId == 1, 'lqa-blue':location?.productId == 2}">{{ location?.tankName }}</span>
                                <br/>Type: <span [ngClass]="{'hcho-green': location?.productId == 1, 'lqa-blue':location?.productId == 2}">{{ location?.Product.abbr}}</span>
                            </div>

                            <div class="col-sm-3">
                                <span (mouseenter)="showNotes(location.id)"
                                      (mouseleave)="closeNotes()"
                                      *ngIf="location.Notes?.length > 0" class="notes-box">
                                    <i class="fa fa-sticky-note" style="color:red"></i>
                                    <div class="widget-hover-2" *ngIf="showingNotes == location.id">
                                        <div class="text-center note-title">{{location.Address[0]?.alias}}</div>
                                        <ul class="note-content">
                                            <li *ngFor="let note of location.Notes">{{note.content}}</li>
                                        </ul>
                                    </div>
                                </span>
                                <a (click)="confirmNav(location?.clientId)" href="javascript:;">{{ location?.Address[0].alias }}</a><br/>
                                {{ location?.Address[0].street }}, <br/>{{ location?.Address[0].city }}, {{ location?.Address[0].state }} {{ location?.Address[0].zip}}
                            </div>
                            <div class="col-sm-3" *ngIf="!workOrder">
                                Level: <strong>{{ location?.currentGallons }}</strong> <br/>
                                Max: <strong>{{ location?.capacityVolume }}</strong> <br/>
                                Actual Avg/Daily: <b class="margin-left-10" [ngClass]="{'calc-daily-use':location?.calculatedDailyUse == 0 || null}">{{dailyCalculatedDailyUse(location)}}</b><br />
                                Projected Avg/Daily: <b class="margin-left-10" [ngClass]="{'calc-daily-use':location?.expAvgDailyUse == 0 || null}">{{dailyExpAvgDailyUse(location)}}</b>
                            </div>
                            <div class="col-sm-2" *ngIf="!workOrder" [ngClass]="{'alertFlag': location?.isAlert === 'True', 'criticalFlag': location?.isCritical === 'True'}">{{ location?.percentCritical | percent: '1.0-0' }}</div>
                            <div class="col-md-1" *ngIf="!workOrder">
                                <input (dblclick)="$event.preventDefault();$event.stopPropagation()"
                                	   (keydown)="notNegative($event)"
                                       id="gallonsToFull"
                                       type="number"
                                       min="0"
                                       class="form-control"
                                       [(ngModel)]="location.gallonsToFull">
                            </div>
                            <div class="col-md-1" *ngIf="workOrder">
                                {{location.serviceTypeName}}
                            </div>
                            <div class="col-md-4" *ngIf="workOrder">
                                {{location.note}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="dragbar"></div>
</div>

<prompt-modal #navPrompt>
	<div class="modal-header">
		<h4 class="modal-title">Leaving Route Edit Page</h4>
	</div>

	<div class="modal-item">
		<div class="col-12">
			<p>Are you sure you want to leave the route edit page? Changes will be saved.</p>
		</div>
	</div>

	<div class="modal-buttons">
		<button type="submit" class="btn btn-sm btn-primary" (click)="navigate()">Leave Page</button>
		<button type="button" class="btn btn-sm btn-light" (click)="navPrompt.hidePromptModal()">Cancel</button>
	</div>
</prompt-modal>
