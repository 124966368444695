import {Component, Input, Output, ViewChild, EventEmitter, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {UserService} from '../../services-module/services/user.service';
import {Note} from '../../models/note';
import {NoteService} from '../../services-module/services/notes.service';
import {PromptModalComponent} from '../../shared-components/prompt-modal/prompt-modal.component';

@Component({
    templateUrl: './notes-panel.component.html',
    styleUrls: ['./notes-panel.component.css'],
    selector: 'notes-panel'
})
export class NotesPanelComponent implements OnInit{
    @Input()
    notes: Note[];
    @Output()
    saveNote = new EventEmitter<Note>();
    @Output()
    hidePanel = new EventEmitter<void>();
    @ViewChild('noteModal', { static: true }) private noteModal:PromptModalComponent;
    note: Note;
    dNote: Note;
    noteTypes: any[];
    employeeId: number = this.userService.getEmployeeId();
    constructor(private userService: UserService, private noteService: NoteService){
        this.setNote();
    }

    ngOnInit(){
        this.getNoteTypes();
    }

    hide(): void{
        this.hidePanel.emit();
    }

    save(note: any):void{
        this.saveNote.emit(note);
        this.setNote();
    }

    setNote(): void{
        this.note = new Note();
    }

    editNote(note: Note):void{
        this.note = _.clone(note);
    }

    cancel():void{
        this.setNote();
    }


    getNoteTypes(): void{
        this.noteService.getAllNoteTypes()
            .then((res)=>{
                this.noteTypes = res;
            })
    }

    isSelected(id:number) {
        if (this.note && this.note.id) {
            return this.note.id == id;
        }
        return false;
    }

    deleting(id:number) {
        if (this.dNote && this.dNote.id) {
            return this.dNote.id == id;
        }
        return false;
    }

    setDeleteNote(note:Note) {
        this.dNote = note;
        this.noteModal.showPromptModal();
    }

    deleteNote() {
        this.noteService.deleteNote(this.dNote)
            .then((res) => {
                const index = this.notes.map(n => n.id).indexOf(this.dNote.id);
                this.setNote();
                this.notes.splice(index, 1);
                this.noteModal.hidePromptModal();
            });
    }

    role() {
        return this.userService.role();
    }
}