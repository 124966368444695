<div id="single-work-order-view">
    <div class=" header">
        <div class="container-fluid">
            <h2><i class="fa fa-file"></i> Work Orders</h2>
        </div>
    </div>

    <div id="single-work-order" *ngIf="workOrder" class="container-fluid">
        <div class="work-order-info">
            <!--Dairy Contact Information-->
            <div class="row">
                <div class="shadow  work-order-info-box col-12 col-md-6">
                    <h3>Dairy Information</h3>
                    <div *ngIf="workOrder.Address" class="dairy-info">
                        <h4 *ngIf="workOrder.Address?.Client"><a [routerLink]="['/clients', workOrder.Address.Client.id]">{{workOrder.Address?.Client.name}}</a></h4>
                        <div>
                            {{workOrder.Address?.alias}}<br/>
                            {{workOrder.Address?.street}}<span *ngIf="workOrder.Address.city && workOrder.Address.street">,</span><br/>
                            {{workOrder.Address?.city}}<span *ngIf="workOrder.Address.city && workOrder.Address.state">,</span>
                            {{workOrder.Address?.state}}
                            {{workOrder.Address?.zip}}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div *ngIf="isAdmin" (click)="editWorkOrder()" class="btn btn-success shadow btn-half">
                        <a style="color:inherit">Edit</a>
                    </div>
                    <div *ngIf="isAdmin" (click)="deleteOrder.showPromptModal();setForUpdate(workOrder)" class="btn btn-danger shadow btn-half">Delete</div>
                </div>
            </div>
            <!--End Dairy Contact Info-->

            <!--Work Order Details-->
            <div class="work-order-details shadow row">
                <h3>Work Order Information</h3>
                <!--Order Details-->
                <div class="row wo-detail">
                    <div class="col-6 col-md-4">
                        <div class="detail-card">
                            <div><strong>Work Order #</strong></div>
                            <div><span>{{workOrder.id}}</span></div>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="detail-card">
                            <div><strong>Service Type</strong></div>
                            <div><span>{{workOrder?.ServiceType?.serviceTypeName}}</span></div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="detail-card">
                            <div><strong>Status</strong></div>
                            <div>
                                <span style="cursor: pointer;" [ngClass]="woStatus(workOrder.workOrderStatusId)" (click)="updateWorkOrder.showPromptModal(workOrder);">{{workOrder.workOrderStatusId | orderStatus}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row wo-detail">
                        <div class=" col-sm-4">
                            <div class="detail-card">
                                <div><strong>Date Opened</strong></div>
                                <div><span>{{workOrder.createdAt | date}}</span></div>
                            </div>
                        </div>
                        <div class=" col-sm-4">
                            <div class="detail-card">
                                <div><strong>Completion Date</strong></div>
                                <div *ngIf="workOrder.completionDate"><span>{{workOrder.completionDate | date}}</span></div>
                                <div *ngIf="!workOrder.completionDate"><span>--</span></div>
                            </div>
                        </div>

                        <div class="col-6 col-md-4">
                            <div class="detail-card">
                                <div><strong>Opened By</strong></div>
                                <div *ngIf="workOrder.createdBy"><span>{{workOrder.createdBy.firstName}} {{workOrder.createdBy.lastName}}</span></div>
                                <div *ngIf="!workOrder.createdBy"><span>--</span></div>

                            </div>
                        </div>
                    </div>

                    <div class="row wo-detail">
                        <div class="col-6 col-md-4">
                            <div class="detail-card">
                                <div><strong>Assigned To</strong></div>
                                <div *ngIf="workOrder.assignedTo"><span>{{workOrder.assignedTo.lastName}}, {{workOrder.assignedTo.firstName}}</span></div>
                                <div *ngIf="!workOrder.assignedTo"><span>--</span></div>
                            </div>
                        </div>
                        <div class="col-6 col-md-4">
                            <div class="detail-card">
                                <div><strong>Updated By</strong></div>
                                <div><span>{{workOrder.updatedByEmployee ? workOrder.updatedByEmployee.firstName: '--'}} {{workOrder.updatedByEmployee ? workOrder.updatedByEmployee.lastName: ''}}</span></div>
                            </div>
                        </div>
                        <div class="col-6 col-md-4">
                            <div class="detail-card">
                                <div><strong>Closed By</strong></div>
                                <div><span>{{workOrder.closedByEmployee ? workOrder.closedByEmployee.firstName: '--'}} {{workOrder.closedByEmployee ? workOrder.closedByEmployee.lastName:''}}</span></div>
                            </div>
                        </div>
                    </div>

                    <div class="row wo-detail">
                        <div class="col-sm-12">
                            <div class="detail-note">
                                <div><strong>Work Order</strong></div>
                                <div><span class="wo-description">{{workOrder.note}}</span></div>
                            </div>
                        </div>
                    </div>
                <!--End Order Details-->

                <!--Order Notes-->
                    <div class="col-12">
                        <div class="notes-heading">
                            <h3>Service Records</h3>
                            <div class="btn btn-success" (click)="addNote()"><a style="color: inherit;"><i class="fa fa-plus"></i></a></div>
                        </div>
                        <div class="wo-notes">
                            <div class="col-md-12" *ngIf="workOrder.WorkOrderDetails !== 0">
                                <div class="row note-styles" *ngFor="let note of workOrder.WorkOrderDetails; let i = index" [ngClass]="{'note-min-height' : workOrder.WorkOrderDetails.length > 1, 'note-height': workOrder.WorkOrderDetails.length <= 1}">
                                    <div class="note-index col-12 col-sm-4 col-lg-1"><span class="shadow"># {{workOrder.WorkOrderDetails.length - i}}</span></div>
                                    <div class="note-date col-12 col-sm-4 col-lg-2"><strong>Date Created</strong><br/> <span>{{note?.createdAt | date}}</span></div>
                                    <div class="note-note col-12 col-sm-4 col-lg-2"><strong>Created By</strong><br/>{{setEmployee(note?.employeeId)}}</div>
                                    <div class="note-note col-12 col-lg-6"><strong>Record</strong><br/>{{note?.note}}</div>
                                    <div class="note-action col-12 col-lg-1"> <div class="btn btn-danger" (click)="deleteServiceRecord.showPromptModal();setRecordForDelete(note)"><i class="fa fa-trash"></i></div></div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="workOrder.WorkOrderDetails.length == 0">
                                <div class="row note-styles">
                                    <h5 style="color: red">-- No Notes to Display --</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                <!--End Order Notes-->
                </div>
            <!--End Work Order Details-->
            </div>
        </div>

    <!--Add Work Order Note-->
        <div id="addNoteTarget">
            <div class="add-work-order-note" *ngIf="addable">
                <div class="row">
                    <div class="col-md-12">
                        <add-note [employees]="employees"
                                  [workOrder]="workOrder"
                                  (cancelAdding)="cancelAdding($event)"
                                  (createNote)="createNote($event)"></add-note>
                    </div>
                </div>
            </div>
        </div>
    <!--End Add Work Order Note-->


    </div>

    <!--Edit a Work Order-->
    <div id="editTarget">
        <div class="edit-work-order container-fluid shadow" *ngIf="editable">
            <div class="row">
                <div class="col-md-12">
                    <edit-work-order [deliveryAddresses]="deliveryAddresses"
                                     [ogWorkOrder]="workOrder"
                                     [serviceTypes]="serviceTypes"
                                     (updateWorkOrder)="editingWorkOrder($event)"
                                     (cancelEdit)="cancelEdit($event)"
                                     [employees]="employees"></edit-work-order>
                </div>
            </div>
        </div>
    </div>
    <!--End Work Order Edit-->


</div>

<!--********** Confirm Modal **********-->

<app-update-work-order #updateWorkOrder (onUpdate)="updateStatus($event)"></app-update-work-order>

<!--********** Confirm Delete Modal **********-->

<prompt-modal
        #deleteOrder
        size="small">
    <div class="modal-header">
        <h4 class="modal-title">Delete Work Order {{orderForUpdate.id}} </h4>
    </div>

    <div class="modal-item">
        <div class="col-12">
            <p>Are you sure you would like to delete work order {{orderForUpdate.id}}? This cannot be undone.</p>
        </div>
    </div>

    <div class="modal-buttons">
        <button (click)="deleteWorkOrder(orderForUpdate);deleteOrder.hidePromptModal()" type="submit" class="btn btn-sm btn-danger">Delete</button>
        <button type="button" class="btn btn-sm btn-warning" (click)="deleteOrder.hidePromptModal(); removeFromUpdate();">Cancel</button>
    </div>

</prompt-modal>

<!--********** Confirm Delete Modal **********-->

<prompt-modal
        #deleteServiceRecord
        size="small">
    <div class="modal-header">
        <h4 class="modal-title">Delete Service Record {{recordForDelete.id}} </h4>
    </div>

    <div class="modal-item">
        <div class="col-12">
            <p>Are you sure you would like to delete this Service Record? This cannot be undone.</p>
        </div>
    </div>

    <div class="modal-buttons">
        <button (click)="deleteNote(recordForDelete);deleteServiceRecord.hidePromptModal()" type="submit" class="btn btn-sm btn-danger">Delete</button>
        <button type="button" class="btn btn-sm btn-warning" (click)="deleteServiceRecord.hidePromptModal(); removeRecordFromDelete();">Cancel</button>
    </div>

</prompt-modal>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
