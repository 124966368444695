import {Component, Input, Output, EventEmitter} from '@angular/core';
import * as _ from 'lodash';

@Component({
    templateUrl: './address-inventory.component.html',
    styleUrls: ['../address.component.css', './address-inventory.component.css'],
    selector: 'address-inventory'
})
export class AddressInventoryComponent{
    @Input()
    drops: any[];
    // @Input()
    // dropData:any[];
    // @Input()
    // labels:any[];
    @Input()
    open:boolean;
    @Output()
    showDropsPanel = new EventEmitter<void>();

    public lineChartData:Array<any> = [
        {data: [80, 81, 56, 55, 40], label: 'LQA'},
        {data: [40, 19, 86, 27, 90], label: 'HCHO'}
    ];
    public lineChartLabels:Array<any> = ['January', 'June', 'December'];
    public lineChartOptions:any = {
        responsive: true,
        elements: {
            line: {
                tension: 0, // disables bezier curves
            }
        }
    };
    public lineChartColors:Array<any> = [
        { // grey
            backgroundColor: 'rgba(148,159,177,0.2)',
            borderColor: 'rgba(148,159,177,1)',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        { // dark grey
            backgroundColor: 'rgba(77,83,96,0.2)',
            borderColor: 'rgba(77,83,96,1)',
            pointBackgroundColor: 'rgba(77,83,96,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(77,83,96,1)'
        },
    ];
    public lineChartLegend:boolean = true;
    public lineChartType:string = 'line';

    constructor(){}

    showAll(): void{
        this.showDropsPanel.emit();
    }
    public chartClicked(e:any):void {
        console.log(e); // why is this being console.log here?
    }

    public chartHovered(e:any):void {
        console.log(e); // why is this being console.log here?
    }
}