import {Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
    selector: 'prompt-modal',
    templateUrl: 'prompt-modal.component.html',
    styleUrls: ['prompt-modal.component.css']
})

export class PromptModalComponent {
    @Input() promptTitle: string;
    @Input() size:string;
    @ViewChild('promptModal', { static: true }) public prompt:ModalDirective;
    @Output() onClosed = new EventEmitter<string>();

    public showPromptModal = ():void => {
        this.prompt.show();
    }

    public hidePromptModal = () => {
        this.prompt.hide();
    }

    public hide() {
        this.onClosed.emit('closed');
    }

}
