<form #addAddressForm='ngForm' (submit)="save(address)" class="form">
    <div class="form-group row">
        <label for="alias" class="col-sm-4 col-form-label">Location Alias</label>
        <div class="col-sm-8">
            <input type="text"
                   name="alias"
                   id="alias"
                   class="form-control"
                   required
                   placeholder="Location Name"
                   [(ngModel)]="address.alias">
        </div>

    </div>

    <div class="form-group row">
        <label for="Street" class="col-sm-4 col-form-label">Street</label>
        <div class="col-sm-8">
            <input type="text"
                    name="street"
                    id="street"
                    class="form-control"
                    placeholder="Street Address"
                    [(ngModel)]="address.street">
        </div>
    </div>

    <div class="form-group row">
        <label for="city" class="col-sm-4 col-form-label">City</label>
        <div class="col-sm-8">
            <input type="text"
                    name="city"
                    id="city"
                    class="form-control"
                    placeholder="city"
                    [(ngModel)]="address.city">
        </div>
    </div>
    <div class="form-group row">
        <label for="state" class="col-sm-4 col-form-label">State</label>
        <div class="col-sm-8">
            <select name="state"
                    id="state"
                    class="form-control"
                    [(ngModel)]="address.state">
                <option [ngValue]="state.abbreviation" *ngFor="let state of states">
                    {{ state.abbreviation }}
                </option>
            </select>
        </div>
    </div>
    <div class="form-group row">
        <label for="zip" class="col-sm-4 col-form-label">Zip Code</label>
        <div class="col-sm-8">
            <input type="number"
                    name="zip"
                    id="zip"
                    maxlength="5"
                    min="0"
                    class="form-control"
                    placeholder="Zip"
                    (change)="searchCounty(address.zip)"
                    [(ngModel)]="address.zip">
        </div>
    </div>
    <div class="form-group row">
        <label for="County" class="col-sm-4 col-form-label">County</label>
        <div class="col-sm-8">
            <input type="text"
                    name="County"
                    id="County"
                    class="form-control"
                    placeholder="County"
                    [(ngModel)]="address.county">
        </div>
    </div>

    <div class="form-group row" *ngIf="!(isDelivery || address.isDeliveryLocation)">
        <label for="County" class="col-sm-4 col-form-label">Billing Email Address</label>
        <div class="col-sm-8">
            <input type="text"
                    name="Billing Email Address"
                    id="billing"
                    pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                    class="form-control"
                    placeholder="Billing Email Address"
                    [(ngModel)]="address.billingEmail">
        </div>
    </div>

    <div class="form-group row" *ngIf="isDelivery || address.isDeliveryLocation">
        <label for="region" class="col-sm-4 col-form-label">Region</label>
        <div class="col-sm-8">
            <select name="region"
                    id="region"
                    class="form-control"
                    [(ngModel)]="address.serviceRegionId">
                <option [ngValue]="region.id" *ngFor="let region of serviceRegions">
                    {{ region.serviceRegionName }} - {{ region.state }}
                </option>
            </select>
        </div>
    </div>
    <div class="form-group row" *ngIf="isDelivery || address.isDeliveryLocation">
        <label for="lat" class="col-sm-4 col-form-label">Latitude</label>
        <div class="col-sm-8">
            <input type="text" name="lat" id="lat"
                class="form-control"
                [(ngModel)]="address.latitude">
        </div>
    </div>
    <div class="form-group row" *ngIf="isDelivery || address.isDeliveryLocation">
        <label for="long" class="col-sm-4 col-form-label">Longitude</label>
        <div class="col-sm-8">
            <input type="text" name="long" id="long"
                class="form-control"
                [(ngModel)]="address.longitude">
        </div>
    </div>
    <div class="form-group row">
        <div class="checkbox ml-3">
            <label>
                <input type="checkbox"
                       name="Is Active"
                       [(ngModel)]="address.isActive"> Active Address
            </label>
        </div>
    </div>
    <div class="full-width flex-container flex-end">
        <i *ngIf="isDelivery"
           class="fa fa-address-card-o copy-address"
           aria-hidden="true"
           tooltip="Copy address to mailing locations"
           placement="right"
           (click)="cloneAddress.showPromptModal()"></i>
        <button class="btn btn-primary" type="submit" [disabled]="!addAddressForm.form.valid">Save</button>
        <button type="button" class="btn btn-danger" (click)="cancel($event)">Cancel</button>
    </div>
</form>

<prompt-modal
    #cloneAddress
    size="small"
>
        <div class="modal-header">
            <h4 class="modal-title">Copy To Mailing Locations</h4>
        </div>

        <div class="modal-item">
            <div class="col-12">
                <p>Would you like to copy:</p><p>{{address.alias}}<br>{{address.street}}, {{address.city}} {{address.state}} {{address.zip}}</p><p>to mailing locations?</p>
            </div>
        </div>

        <div class="modal-buttons">
            <button (click)="saveToMailing(address);cloneAddress.hidePromptModal()" type="submit" class="btn btn-sm btn-primary">Copy</button>
            <button type="button" class="btn btn-sm btn-light" (click)="cloneAddress.hidePromptModal()">Cancel</button>
        </div>

</prompt-modal>
