import {Component, Input, Output, EventEmitter} from '@angular/core';

import {EmployeeType} from '../../../models/employee-type';

@Component({
    templateUrl: './employee-type-list.html',
    styleUrls: ['./employee-type-list.css'],
    selector: 'employee-type-list'
})
export class EmployeeTypesListComponent{
    @Input()
    employeeTypes: EmployeeType[];
    @Output()
    setEmployeeType = new EventEmitter<EmployeeType>();
    constructor(){}

    selectEmployeeType(employeeType: EmployeeType): void{
        this.setEmployeeType.emit(employeeType)
    }
}