import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { WorkOrderService } from '@services/work-order.service';

@Injectable({ providedIn: 'root' })
export class WorkOrderLocationsResolve implements Resolve<any[]> {
  constructor(private workOrderService: WorkOrderService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.workOrderService.getOpenWorkOrderLocations({ routeId: route.params.id });
  }
}

