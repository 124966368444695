import { Component, Input, Output, EventEmitter, AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { Route } from '../../models/route';
import { S3MapService } from '../s3-map.service';

@Component({
    templateUrl: './s3-route-map.component.html',
    styleUrls: ['./s3-route-map.component.css'],
    selector: 's3-route-map'
})
export class S3RouteMapComponent implements AfterViewInit, OnDestroy {
    @Input()
        directions: any[] = [];
    @Input()
        targetId: string = null;
    @Input()
        route:Route;
    @Output()
        optimizeRoute = new EventEmitter();
    @Output()
        reverseOrder = new EventEmitter();
    @Output()
        mapRoute = new EventEmitter();
    @Output()


    map: any;
    constructor(private _ngZone: NgZone, private s3MapService: S3MapService){}

    ngAfterViewInit(){
      this._ngZone.runOutsideAngular(() => {
        // so weird that this variable is required because without it, the route directions won't display in the print preview
        let map = this.s3MapService.initMap(this.targetId);
        this.s3MapService.initMap(this.targetId + '-print');
      });
    }

    getOptimizedRoute():void{
        this.optimizeRoute.emit('optimize-route');
    }

    getRoute():void{
        this.mapRoute.emit('get-route');
    }

    reverseRoute():void{
        this.reverseOrder.emit();
    }

    print() {
        const element = document.getElementById(this.targetId + "-print");
              element.classList.remove("show-on-print");

        const map = document.getElementById("route-map");
              map.classList.remove("no-print");

        window.print();
    }

    ngOnDestroy(){
        this.s3MapService.destroyMap(this.targetId);
        this.s3MapService.destroyMap(this.targetId + '-print');
    }

}
