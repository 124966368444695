<div class="header">
    <h4><i class="fa fa-tasks"></i> Tanks</h4>
</div>
<div class="row tanks-container">
    <div class="col-md-6 min-100">
        <tank-list
            [tanks]="tanks"
            [selectedTankId]="selectedTank.id"
            (resetTank)="resetTank($event)"
            (setTank)="setTank($event)"
            [active]="active"
        ></tank-list>
    </div>
    <div class="col-md-6">
        <tank-single [tank]="selectedTank"
                     [active]="active"
                     (resetTank)="resetTank($event)"
                     (saveTank)="saveTank($event)"></tank-single>
    </div>
</div>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>


