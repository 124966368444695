import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Employee} from "../models/employee";
import {AuthService} from "../authorization/auth.service";

@Component({
    selector: 'profile',
    templateUrl: 'profile.component.html',
    styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit{
    employee: Employee;
    changePass: boolean = false;
    creds: any;
    alert: any;
    constructor(
        private router: Router,
        private auth: AuthService,
        private activatedRoute: ActivatedRoute){}

    ngOnInit(): void{
        this.alert = {};
        this.creds = {};
        this.employee = this.activatedRoute.snapshot.data['employee'];
    }

    updatePassword() {
        this.creds['email'] = this.employee.email;
        return this.auth.saveNewPassword(this.creds)
            .then((res)=>{
                if(res.error){
                    this.alert = {
                        open:true,
                        errorMessage: "There was an issue with resetting your password. Please ensure the information was correct and try again."
                    };
                }else {
                    this.alert = {
                        open: true,
                        successHeading: 'You have successfully changed your password',
                        successText: ''
                    };
                }
            });
    }

    close(){
        this.alert = {};
        this.changePass = false;
    }





}

