<div id="clients" class="min-100">
    <div class="row min-100">
        <div class="scroll-cont" *ngIf="clientResults.rows.length > 0">
            <div class="row">
                <div *ngFor="let client of clientResults.rows" class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="client-box shadow" (click)="routeToClient(client.id)" [style.background]="image.backgroundImage">
                        <span *ngIf="!client.isClient" class="sales-lead shadow">SL</span>
                        <div class="img-section">
                            <div class="profile_img shadow" [style.background]="client.image.length ? setBackground(client.image[0].filePath): image.defaultLogo2"></div>
                        </div>
                        <div class="info-section">
                            <h5>{{client.name}}</h5>
                            <ul *ngIf="client.Addresses?.length > 0" class="address-aliases">
                                <li *ngFor="let location of client.Addresses" (click)="routeToAddress(client.id, location.id, $event)">{{location.alias}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="pagination">
                    <pagination *ngIf="itemsDataSource > itemsPerPage"
                                [totalItems]="itemsDataSource"
                                [itemsPerPage]="itemsPerPage"
                                [(ngModel)]="currentPage"
                                (pageChanged)="pageChanged($event)"
                                [maxSize]="maxSize"
                                [rotate]="rotate"
                                previousText="&lsaquo;"
                                nextText="&rsaquo;"
                                firstText="&laquo;"
                                lastText="&raquo;"
                                [boundaryLinks]="true"></pagination>
                </div>
            </div>
        </div>
        <div class="col-12 no-clients" *ngIf="clientResults.rows.length == 0">
            <h3>No Clients to display at this time.</h3>
        </div>
    </div>
</div>