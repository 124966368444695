<div class="row">
    <div class="col-12">
        <nav class="navbar navbar-light work-order-nav">
            <form class="form-row">
				<div class="col-12 col-sm-4 col-md-3 col-lg-2 col-xl-2">
					<app-date-picker [(date)]="query.startDate"
								 	 title="Start Date"
									 class="date-picker"
								 	 (dateChange)="selectedStartDate($event)"></app-date-picker>
				</div>
				<div class="col-12 col-sm-4 col-md-3 col-lg-2 col-xl-2">
					<app-date-picker [(date)]="query.endDate"
								 	 title="End Date"
									 class="date-picker"
								 	 (dateChange)="selectedEndDate($event)"></app-date-picker>
				</div>
				<div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-1">
					<ng-select [items]="orderTypesBindingList"
							   [multiple]="false"
							   bindLabel="name"
							   [clearable]="false"
							   [searchable]="false"
							   [selectableGroup]="false"
							   [closeOnSelect]="false"
							   bindValue="OrderType"
							   [(ngModel)]="query.selectedOrderType"
							   (ngModelChange)="selectedOrdersSearch($event)"
							   name="OrderTypes"
							   id="OrderType"
							   name="OrderType"
							   class="custom"
							   placeholder="Selected Orders">
							<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
								<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" name="item-{{index}}" /> {{item.name}}
							</ng-template>
					</ng-select>
				</div>
				<div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-1">
					<ng-select [items]="detailsBindingList"
							   [clearable]="false"
							   [multiple]="false"
							   bindLabel="name"
							   [selectableGroup]="false"
							   [closeOnSelect]="false"
							   bindValue="DeatilType"
							   [(ngModel)]="query.openAll"
							   (ngModelChange)="toggleopenAll($event)"
							   name="Details"
							   id="Detail"
							   name="Detail"
							   class="custom"
							   [searchable]="false"
							   placeholder="Select Details">
						<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
							<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" name="item-{{index}}" /> {{item.name}}
						</ng-template>
					</ng-select>
				</div>
				<div class="col-12 col-sm-3 col-md-3 mt-md-2 col-lg-2 mt-lg-0 col-xl-1">
					<ng-select [items]="serviceTypes"
							   [multiple]="true"
							   bindLabel="serviceTypeName"
							   [selectableGroup]="true"
							   [closeOnSelect]="false"
							   bindValue="id"
							   [(ngModel)]="query.selectedServiceType"
							   (ngModelChange)="searchServiceTypeValue($event)"
							   name="ServiceType"
							   id="ServiceType"
							   name="ServiceType"
							   class="custom"
							   placeholder="Service Type">
						<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
							<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" name="item-{{index}}" /> {{ item.serviceTypeName }}
						</ng-template>
					</ng-select>
				</div>
				<div class="input-group col-12 col-sm-6 mt-md-2 col-lg-2 mt-lg-0 col-xl-3">
					<div class="input-group-btn">
						<ng-select [clearable]="false"
									[items]="searchByBindingList"
									bindLabel="text"
									bindValue="value"
									name="value"
									class="custom"
									[searchable]="false"
									[(ngModel)]="query.selectedSearchBy"
									(ngModelChange)="searchByValue($event)"
									placeholder="Search By">
						</ng-select>
					</div>
						<input type="text"
								class="form-control search-padding"
								aria-label="Text input with dropdown button"
								name="search"
								[ngModel]="query.searchStringValue"
								(ngModelChange)="searchWorkOrdersModelChanged.next($event)"
								placeholder="Key Words"
								[disabled]="query.selectedSearchBy === ''" />
				</div>
            </form>
        </nav>
    </div>
</div>