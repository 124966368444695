
<div class="container-fluid page-container" (window:resize)="setMobile()">
    <div class="page-heading">
        <div id="work_orders">
            <div class="header">
                <h2><i class="fa fa-map"></i> Routes</h2>
                <div class="driver-select">
                    <span *ngIf="!workOrder">
                        <ng-select [multiple]="true"
                                   (ngModelChange)="selectedStates($event)"
                                   name="state"
                                   class="select-state-input"
                                   [(ngModel)]="selectedState"
                                   placeholder="Select Location">
                                    <ng-option *ngFor="let state of statesSelect" [value]="state.text">{{state.text}}</ng-option>
                        </ng-select>
                    </span>
                    <span>
                        <ng-select [(ngModel)]="selectedDrivers"
                                   (ngModelChange)="selectedDriver($event)"
                                   [multiple]="true"
                                   name="Drivers"
                                   class="inline"
                                   bindLabel="name" 
                                   bindValue="id"
                                   [items]="driverOptions"                       
                                   placeholder="Select Drivers">
                        </ng-select>
                    </span>
                </div>
            </div>
        </div>

        <div class="flex-container flex-space-between flex-wrap">
            <div class="flex-container">
                <button *ngIf="isAdmin && !workOrder" class="btn btn-primary route-btn" routerLink="/new-route">
                    New Route
                </button>
                <button *ngIf="isAdmin && workOrder" class="btn btn-primary route-btn" routerLink="/new-work-route">
                    New Route
                </button>
                <form class="form-inline search-form">
                    <div class="form-group relative">
                        <input type="text"
                               class="form-control search-input"
                               id="Search"
                               name="Search Route"
                               placeholder="Search"
                               (ngModelChange)="searchRoutesModelChanged.next($event)"
                               [(ngModel)]="searchRoutes">
                        <button *ngIf="searchRoutes"
                                type="button"
                                (click)="searchRoutesModelChanged.next('')"
                                class="btn btn-light btn-cancel"><i class="fa fa-ban" aria-hidden="true"></i></button>
                    </div>
                </form>
            </div>
            <form class="form-inline flex-container flex-wrap">
                <div class="form-group relative margin-right-10">
                    <input type="text"
                           class="form-control"
                           id="fromDate"
                           name="Start Route"
                           placeholder="MM/DD/YYYY"
                           [ngModel]="query.fromDate | date"
                           (click)="setEditDate('Start Date')">
                    <button type="button"
                            (click)="setEditDate('Start Date')"
                            class="btn btn-primary btn-calendar"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                </div>
                <div class="form-group relative margin-right-10">
                    <input type="text"
                           class="form-control"
                           id="toDate"
                           name="End Route"
                           placeholder="MM/DD/YYYY"
                           [ngModel]="query.toDate | date"
                           (click)="setEditDate('End Date')">
                    <button type="button"
                            (click)="setEditDate('End Date')"
                            class="btn btn-primary btn-calendar"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                </div>
                <button *ngIf="!searching"
                        type="button"
                        class="btn btn-primary btn-search"
                        (click)="switchDates(query)"><i class="fa fa-search"></i></button>
                <button *ngIf="searching"
                        type="button"
                        class="btn btn-primary btn-search"
                        disabled><i class="fa fa-spin fa-refresh"></i></button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="setDefaultDate()">Default Date</button>
            </form>
        </div>
    </div>

    <div class="panel">
        <div class="panel-body">
            <div *ngIf="!currentRoutes.length" class="margin-top-10 padding-15">
                <h4>No routes for selected time frame</h4>
            </div>
            <table class="table table-striped table-responsive route-list-main-table">
                <thead>
                    <tr>
                        <ng-container *ngFor="let header of headers">
                            <td *ngIf="header.ShowOrHide" class="{{ header.width }}" (click)="sort(header)">
                                <!-- {{ header | json }} -->
                                {{ header.displayText }} <i class="fa" [ngClass]="header.sort === 'desc' ? 'fa-sort-asc' : 'fa-sort-desc'" aria-hidden="true" *ngIf="header.prop === sortColumn"></i>
                            </td>
                        </ng-container>
                        <td class="td-15">Route Options</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let route of currentRoutes">
                        <td class="td-10">{{ route.dateOfRoute | date }}</td>
                        <td class="td-20">{{ route.routeName }}</td>
                        <td class="td-10">{{ route.count }}</td>
                        <td class="td-15">{{ route.drivers }}</td>
                        <td class="td-10" *ngIf="!workOrder">{{ route.Trailer?.name }}</td>
                        <td class="td-10">
                            <span class="active-status" [ngClass]="route.status">{{ route.status }}</span>
                        </td>
                        <td class="td-10" *ngIf="isOffice">
                            <input [disabled]="submitting"
                                   type="checkbox"
                                   name="Billing Complete"
                                   [(ngModel)]="route.billingComplete"
                                   (change)="toggleBillingComplete($event, route)">
                        </td>
                        <td class="td-15" id="tablet-flex-column">
                            <button class="btn btn-success btn-sm" *ngIf="isAdmin" (click)="editRoute(route)">Edit</button>
                            <button class="btn btn-primary btn-sm"  (click)="updateRoute(route)">{{ isAdmin ? 'Update': 'View' }}</button>
                            <button class="btn btn-danger btn-sm" *ngIf="isAdmin" (click)="setDeleteRoute(route)">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="panel-footer">
            <div class="pagination">
                <pagination *ngIf="itemsDataSource > itemsPerPage"
                            [totalItems]="itemsDataSource"
                            [itemsPerPage]="itemsPerPage"
                            [(ngModel)]="currentPage"
                            (pageChanged)="pageChanged($event)"
                            [maxSize]="maxSize"
                            [rotate]="rotate"
                            previousText="&lsaquo;"
                            nextText="&rsaquo;"
                            firstText="&laquo;"
                            lastText="&raquo;"
                            [boundaryLinks]="true"></pagination>
            </div>
        </div>
    </div>
</div>

<prompt-modal
    #datePicker
    size="small"
    (onClosed)="resetDate()">
        <div class="modal-header">
            <h4 class="modal-title">{{dateType}}</h4>
        </div>

        <div class="modal-item row">
            <div class="col-12">
              <datepicker *ngIf="dateType == 'Start Date'"
                          [(ngModel)]="query.fromDate"
                          class="date-modal"
                          name="From Date"
                          datepickerMode="day"
                          [showWeeks]="false"
                          (selectionDone)="datePicker.hidePromptModal()">
                </datepicker>

                <datepicker *ngIf="dateType == 'End Date'"
                            [(ngModel)]="query.toDate"
                            class="date-modal"
                            name="Test Date"
                            datepickerMode="day"
                            [showWeeks]="false"
                            (selectionDone)="datePicker.hidePromptModal()">
                </datepicker>
            </div>
        </div>

        <div class="modal-buttons">
            <button type="button" class="btn btn-primary" (click)="datePicker.hidePromptModal()">OK</button>
        </div>

</prompt-modal>

<prompt-modal #routeDeleteModal>
        <div class="modal-header">
            <h4 class="modal-title">Delete Route</h4>
        </div>

        <div class="modal-item row">
            <div class="col-12">
                <p>Are you sure you want to delete {{routeToDelete?.routeName ? 'route "' + routeToDelete.routeName + '"' : 'this route'}}?</p>
            </div>
        </div>

        <div class="modal-buttons">
            <button type="button"
                    class="btn btn-danger"
                    (click)="deleteRoute()">Delete</button>
            <button type="button"
                    class="btn btn-light"
                    (click)="routeDeleteModal.hidePromptModal()">Cancel</button>
        </div>

</prompt-modal>

<div class="loading-overlay" *ngIf="searching">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>
    <h4>Loading ...</h4>
</div>
