<div id="SettingsComponent" class="settings-window">
    <div class="settings-backdrop" (click)="closeSettings()"></div>
    <div class="settings-container">
        <div class="container-fluid">
            <div class="row text-center">
                <div class="col-12">
                    <h2>System Preferences</h2>
                    <a href="javascript:void(0)" class="pull-right close-icon" (click)="closeSettings()">x</a>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <form class="settings-form">
                        <div class="form-group">
                            <span><i class="fa fa-link settings-icon"></i> Current Landing Page</span>
                            <span class="url-span" (click)="confirm('confirm-remove')">{{landingUrl ? '"' + landingUrl + '"' :''}}</span>

                            <div class="alert alert-danger confirm" *ngIf="confirming === 'confirm-remove'">
                                <span class="padding-right-10">Are you sure you would like to remove your landing page?</span>
                                <a href="javascript:void(0)" (click)="removeLandingPage()">Yes</a> | <a href="javascript:void(0)" (click)="cancel()">Cancel</a></div>
                        </div>

                        <div class="form-group">
                            <span><i class="fa fa-link settings-icon"></i> Set as Landing Page</span>
                            <a href="javascript:void(0)" class="pull-right btn btn-primary" (click)="confirm('confirm-set')">
                                Set
                            </a>
                            <div class="alert alert-info confirm" *ngIf="confirming === 'confirm-set'">
                                <span class="padding-right-10">Are you sure you would like to make this your landing page?</span>
                                <a href="javascript:void(0)" (click)="saveAsLanding()">Yes</a> | <a href="javascript:void(0)" (click)="cancel()">Cancel</a></div>
                        </div>
                        <div class="form-group" *ngIf="alert">
                            <div class="alert alert-success">You're changes have been saved successfully!</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
