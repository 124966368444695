<div class="page-container">
    <div class="page-heading">
        <div class="header">
            <div class="container-fluid">
                <h2><i class="fa fa-group"></i> Clients</h2>
            </div>
        </div>
        <clients-nav (saveClient)="saveClient($event)"
                     (searchTerm)="setTerm($event)"
                     (toggle)="toggleClientType($event)"
                     [salesLeads]="salesLeads"
                     (changeDisplayMode)="changeDisplayMode($event)"
                     [displayMode]=displayMode></clients-nav>
    </div>

    <client-grid (userSelectedPagination)="changePaginationData($event)"
                 [clientResults]="clientResults"
                 [currentPage]="currentPage"
                 [itemsPerPage]="itemsPerPage"
                 [itemsDataSource]="itemsDataSource"
                 [maxSize]="maxSize"
                 [paginationValues]="paginationValues"
                 [rotate]="rotate"
                 *ngIf="displayMode === 0"></client-grid>

    <client-list (userSelectedPagination)="changePaginationData($event)"
                 [clientResults]="clientResults"
                 [currentPage]="currentPage"
                 [itemsPerPage]="itemsPerPage"
                 [itemsDataSource]="itemsDataSource"
                 [maxSize]="maxSize"
                 [paginationValues]="paginationValues"
                 *ngIf="displayMode === 1"></client-list>
</div>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
