<div class="panel padding-15">
    <form action="" name="productForm" #productForm="ngForm">
        <div class="row">
            <div class="form-group col-md-4">
                <label for="name">Product Name</label>
                <input type="text"
                       id="name"
                       required
                       class="form-control"
                       [(ngModel)]="product.name"
                       name="name">
            </div>
            <div class="form-group col-md-4">
                <label for="abbr">Product Abbreviation</label>
                <input type="text"
                       class="form-control"
                       [(ngModel)]="product.abbr"
                       name="abbr"
                       id="abbr">
            </div>
            <div class="form-group col-md-4">
                <label for="desc">Product Description</label>
                <input type="text"
                       class="form-control"
                       [(ngModel)]="product.description"
                       name="desc"
                       id="desc">
            </div>
            <div class="form-group col-md-4">
                <label for="desc">Product DOT Information</label>
                <input type="text"
                       class="form-control"
                       [(ngModel)]="product.dot"
                       name="dot info">
            </div>
        </div>
        <div class="margin-top-10 flex-align-end">
            <button *ngIf="product.id && product.isActive" (click)="productDelete.showPromptModal()" type="button" class="btn-danger btn">Inactive</button>
            <button *ngIf="product.id && !product.isActive" (click)="productDelete.showPromptModal()" type="button" class="btn-success btn">Restore</button>
            <button type="button" class="btn-warning btn" (click)="clearProduct()">Clear</button>
            <button class="btn btn-primary" (click)="save(product)" [disabled]="!productForm.form.valid">Save</button>
        </div>
    </form>
</div>

<prompt-modal
    #productDelete
    size="small"
>
        <div class="modal-header">
            <h4 class="modal-title">Set Product {{product.isActive ? 'Inactive' : 'Active'}}</h4>
        </div>

        <div class="modal-item">
            <div class="col-12">
                <p *ngIf="product.isActive">Are you sure you want to set product  "{{product.name}}" to inactive?</p>
                <p *ngIf="!product.isActive">Would you like to restore product "{{product.name}}"?</p>
            </div>
        </div>

        <div class="modal-buttons">
            <button *ngIf="product.isActive" (click)="product.isActive = false;save(product);productDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-danger">Inactive</button>
            <button *ngIf="!product.isActive" (click)="product.isActive = true;save(product);productDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-success">Restore</button>
            <button type="button" class="btn btn-sm btn-light" (click)="productDelete.hidePromptModal()">Cancel</button>
        </div>

</prompt-modal>