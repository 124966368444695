import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

// Services
import { HttpService } from './http-service/http-service.service';
import { EventService } from './event-service/event.service';

@NgModule({
  imports: [
    CommonModule,
    // HttpModule,
    HttpClientModule
  ],
  declarations: [
  ],
  providers: [
    HttpService,
    EventService
  ],
  exports: [

  ]
})
export class SharedServicesModule { }
