import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { ClientService } from '@services/client.service';
import { AddressService } from '@services/address.service';
import { S3StatesService } from '../../s3-states/s3-states.service';

import { Client } from '@models/client';
import { Address } from '@models/address';
import { ClientContact } from '@models/contact';
import { ServiceRegion } from '@models/service-region';
import * as _ from 'lodash';
import { Employee, SALES_REP_TYPES } from '@models/employee';
import { ToastService } from '@services/toast.service';
import { PromptModalComponent } from '@shared/prompt-modal/prompt-modal.component';
import { skip, switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';


@Component({
  templateUrl: './client-single.component.html',
  styleUrls: ['./client-single.component.css']
})
export class ClientSingleComponent implements OnInit, OnDestroy {
  client: Client;
  currentClient: Client;
  clientsDairies: Client[];
  clients: Client[];
  clientContacts: ClientContact[];
  selectedContact: ClientContact;
  editableMailingAddress: any;
  editableDeliveryAddress: Address;
  previousContact: ClientContact;
  selectedDeliveryAddress: Address;
  selectedMailingAddress: any;
  mailingAddresses: any[];
  serviceRegions: ServiceRegion[];
  editClientMode = false;
  editDelAddress = false;
  editMailAddress = false;
  editContact = false;
  salesReps: Employee[];
  salesRep: any;
  clientReps: any[] = [];
  states: any[] = [];
  editSalesRep = false;
  address: Address[];
  ftp = false;
  removeClient: any;
  currentIndex: any;
  SALES_REP_TYPES = SALES_REP_TYPES;

  destroyed$ = new Subject();

  @ViewChild('removeClientRepModal', { static: true }) private removeClientRepModal: PromptModalComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private clientsService: ClientService,
    private addressService: AddressService,
    private s3StatesService: S3StatesService,
    private toastService: ToastService
  ) { }
  ngOnInit() {
    const { data, params } = this.route.snapshot;
    const { client, reps, deliveryAddresses, mailingAddresses } = data.clientSingle;
    this.salesRep = { salesRepId: null };
    this.removeClient = {};
    this.clientsDairies = data.clientsDairies;
    this.serviceRegions = data.serviceRegions;
    this.setStartingState();
    this.selectedDeliveryAddress = new Address();

    this.client = client;
    this.currentClient = client;
    this.salesReps = data.salesReps || [];
    this.currentIndex = _.findIndex(this.clients, { id: this.client.id });

    this.clientReps = reps || [];
    this.client.deliveryAddresses = deliveryAddresses || [];
    this.client.mailingAddresses = mailingAddresses || [];
    this.mailingAddresses = mailingAddresses || [];
    this.selectedMailingAddress = this.mailingAddresses[0];

    if (params.addressId) {
      this.selectedDeliveryAddress = _.find(this.client.deliveryAddresses, { id: +params.addressId });
    } else {
      this.selectedDeliveryAddress = this.client.deliveryAddresses[0];
    }

    this.subscribeToParams();

    this.states = this.s3StatesService.states;
    this.clients = (data.clients || []).map((c: Client) => ({ id: c.id, text: c.name }));
  }

  subscribeToParams() {
    this.route.params
      .pipe(
        skip(1),
        switchMap(params => forkJoin({
          client: this.clientsService.getClient(params.id),
          reps: this.clientsService.getClientReps(params.id),
          deliveryAddresses: this.addressService.getDeliveryAddresses(params.id),
          mailingAddresses: this.addressService.getMailingAddresses((params.id))
        })),
        takeUntil(this.destroyed$)
      )
      .forEach(({ client, reps, deliveryAddresses, mailingAddresses }) => {
        const { data, params } = this.route.snapshot;

        this.clientReps = reps || [];

        this.client = client;
        this.currentClient = client;
        this.salesReps = data.salesReps || [];
        this.currentIndex = _.findIndex<Client>(this.clients, (listClient) => listClient.id === this.client.id);

        this.client.deliveryAddresses = deliveryAddresses || [];

        if (params.addressId) {
          this.selectedDeliveryAddress = _.find(this.client.deliveryAddresses, { id: +params.addressId });
        } else {
          this.selectedDeliveryAddress = this.client.deliveryAddresses[0];
        }

        this.client.mailingAddresses = mailingAddresses || [];
        this.mailingAddresses = mailingAddresses || [];
        this.selectedMailingAddress = this.mailingAddresses[0];
      });
  }

  changeEditMode(event: any): void {
    this.editClientMode = !this.editClientMode;
  }

  setStartingState(): void {
    this.editClientMode = false;
    this.selectedDeliveryAddress = null;
    this.selectedMailingAddress = null;
    this.editDelAddress = false;
    this.editMailAddress = false;
  }

  createNewDelAddress(): void {
    this.editableDeliveryAddress = new Address();
    this.editDelAddress = true;
  }

  editDeliveryAddress(): void {
    this.editableDeliveryAddress = Object.assign({}, this.selectedDeliveryAddress);
    this.editDelAddress = true;
  }

  editMailingAddress(): void {
    this.editableMailingAddress = Object.assign({}, this.selectedMailingAddress);
    this.editMailAddress = true;
  }
  editClientContact(): void {
    this.editContact = true;
  }

  createNewMailAddress(): void {
    this.editableMailingAddress = {};
    this.editMailAddress = true;
  }
  createNewContact(): void {
    this.previousContact = this.selectedContact;
    this.selectedContact = new ClientContact();
    this.editContact = true;
  }

  saveDelAddress(event: any) {
    this.ftp = true;
    event.isDeliveryLocation = true;
    this.saveAddress(event)
      .then((res) => {
        if (!event.id) {
          this.client.deliveryAddresses.push(res);
          this.changeUrl(res);
          this.selectedDeliveryAddress = res;
        } else {
          this.selectedDeliveryAddress = this.findAndReplaceAddress(res, this.client.deliveryAddresses);
        }
        if (!res.isActive) {
          this.client.deliveryAddresses = this.client.deliveryAddresses.filter(address => {
            return address.isActive;
          });
          this.selectedDeliveryAddress = this.client.deliveryAddresses[0];
          if (this.selectedDeliveryAddress) {
            this.changeUrl(this.selectedDeliveryAddress);
          }
        }
        this.editDelAddress = false;
        this.ftp = false;
      });
  }

  saveMailAddress(event: any) {
    this.ftp = true;
    event.isDeliveryLocation = false;
    this.saveAddress(event)
      .then((res) => {
        if (!event.id) {
          this.client.mailingAddresses.push(res);
          this.selectedMailingAddress = res;
        } else {
          this.selectedMailingAddress = this.findAndReplaceAddress(res, this.client.mailingAddresses);
        }
        this.editMailAddress = false;
        this.ftp = false;
      });
  }

  saveAddress(event: any) {
    event.clientId = this.client.id;
    return this.addressService.saveAddress(event);
  }

  cancelDelAddressEdit(event: any): void {
    this.editDelAddress = false;
  }

  cancelMailAddressEdit(event: any): void {
    this.editMailAddress = false;
  }

  cancelContactEdit(): void {
    if (!this.selectedContact.id) {
      this.selectedContact = this.previousContact;
    }
    this.editContact = false;
  }

  saveClient(client: Client) {
    this.ftp = true;
    return this.clientsService.saveClient(client)
      .then((res) => {
        _.merge(this.client, res);
        this.toastService.toast({ message: `You have updated the details for: ${client.name}`, type: 'success' });
        this.editClientMode = false;
        this.ftp = false;
      });
  }

  updateSalesRep(client: Client) {
    this.ftp = true;
    return this.clientsService.updateClient(client)
      .then(res => {
        const salesRepId = +res.salesRepId;
        this.salesRep = _.find(this.salesReps, { id: salesRepId });
        this.editSalesRep = false;
        this.ftp = false;
      });
  }

  saveContact(contact: ClientContact) {
    this.ftp = true;
    contact.clientId = this.client.id;
    contact.addressId = this.selectedDeliveryAddress.id;
    this.clientsService.saveContact(contact)
      .then(res => {
        if (contact.id) {
          _.merge(this.selectedContact, res);
        } else {
          this.clientContacts.push(res);
          this.selectedContact = res;
        }
        this.editContact = false;
        this.ftp = false;
      });
  }

  setContacts(address: Address): void {
    this.clientContacts = address ? address.ClientContacts : [];
    this.selectedContact = this.clientContacts[0];
  }

  getClientReps() {
    this.clientsService.getClientReps(this.client.id)
      .subscribe((reps) => {
        this.clientReps = [];
        this.clientReps = reps;
        this.ftp = false;
      });
  }
  addClientRep(clientRep: any) {

    const data = {
      clientId: this.client.id,
      repId: +clientRep.salesRepId
    };
    this.ftp = true;
    this.clientsService.addClientRep(data)
      .then((rep) => {
        this.salesRep.salesRepId = null;
        this.getClientReps();
      });
  }

  clientToRemove(clientRep: any) {
    this.removeClient = clientRep;
    this.removeClientRepModal.showPromptModal();
  }
  cancelRemove() {
    this.removeClient = null;
  }

  removeClientRep() {
    this.ftp = true;
    this.clientsService.removeClientRep(this.removeClient)
      .then((res) => {
        this.removeClient = {};
        this.getClientReps();
      });
  }

  private findAndReplaceAddress(item: any, arr: Address[]) {
    return _.merge(_.find(arr, { id: item.id }), item);
  }

  // Change the URL statically if user toggles between dairies -RJH May 2017
  changeUrl(event: Event | Address) {
    this.location.go(`/clients/${event['clientId']}/address/${event['id']}`);
  }

  changeClient(str: string) {
    if (str === 'next') {
      // Change to next client


    } else {
      // change to previous client

    }

  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
