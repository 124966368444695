import { Injectable } from '@angular/core';

import { Cargo } from '../../models/cargo';
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";

@Injectable()
export class CargoService {
    private apiUrl = environment.apiUrl; // URL to web api
    constructor(public authHttp: HttpClient) { }

    saveCargo(cargo: Cargo): Promise<Cargo> {
        if (cargo.id) {
            return this.updateCargo(cargo);
        } else {
            return this.createCargo(cargo);
        }
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    createCargo(cargo: Cargo): Promise<Cargo> {
        return this.authHttp.post(`${this.apiUrl}cargo`, cargo)
            .toPromise()
            .then((res) => {
                return res as Cargo;
            });
    }

    updateCargo(cargo: Cargo) {
        return this.authHttp.put(`${this.apiUrl}cargo/${cargo.id}`, cargo)
            .toPromise()
            .then((res) => {
                return res as Cargo;
            });
    }

}
