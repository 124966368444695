import { Component, Input, Output, EventEmitter} from '@angular/core';
import {Tractor} from '../../../models/tractor';

@Component({
    templateUrl: 'tractor-single.component.html',
    styleUrls: ['tractor-single.component.css'],
    selector: 'tractor-single'
})
export class TractorSingleComponent {
    @Input() tractor: Tractor;
    @Output() resetTractor = new EventEmitter<string>();
    @Output() saveTractor = new EventEmitter<Tractor>();

    constructor() {}

    clearTractor() {
        this.resetTractor.emit('reset');
    }

    save(tractor: Tractor) {
        this.saveTractor.emit(tractor);
    }
}