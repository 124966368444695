import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Testing for requests to Normalize. Checking if url contains the normalize base url.
    // To authenticate with Normalize we need to attach the n_token instead of our normal id_token
    const normalizeUrl = environment.normalizeUrl;
    const authToken = req.url.includes(normalizeUrl) ? localStorage.getItem('n_token') : localStorage.getItem('id_token'),
      authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          'ngsw-bypass': 'true'
        }
      });

    return next.handle(authReq);
  }
}
