import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { DashboardService } from '@services/dashboard.service';
import { Route } from '@models/route';
import { WorkOrder } from '@models/work-order';
import { Lab } from '@models/lab';
import { Client } from '@models/client';

@Injectable()
export class RecentClientsResolve implements Resolve<{ rows: Client[], count: number}> {
    constructor(private dashboard: DashboardService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.dashboard.getRecentClients(route.queryParams);
    }
}

@Injectable()
export class RecentRoutesResolve implements Resolve<{ rows: Route[], count: number}> {
    constructor(private dashboard: DashboardService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.dashboard.getRecentRoutes();
    }
}

@Injectable()
export class RecentWorkOrdersResolve implements Resolve<{ rows: WorkOrder[], count: number}> {
    constructor(private dashboard: DashboardService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.dashboard.getRecentWorkOrders(route.queryParams);
    }
}

@Injectable()
export class RecentLabsResolve implements Resolve<{ rows: Lab[], count: number}> {
    constructor(private dashboard: DashboardService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.dashboard.getRecentLabs(route.queryParams);
    }
}
