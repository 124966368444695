import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import {FileUploadService} from '../../../services-module/services/file-upload.service';
import {FileUploadModel} from '../../../models/file-upload';
import {UserService} from '../../../services-module/services/user.service';
import {Address} from '../../../models/address';
import {ActivatedRoute} from '@angular/router';
import {Employee} from '../../../models/employee';
import {UntypedFormGroup} from '@angular/forms';
import {ToastService} from '../../../services-module/services/toast.service';
import * as _ from 'lodash';

@Component({
    templateUrl: './address-documents.component.html',
    styleUrls: ['./address-documents.component.css'],
    selector: 'address-documents'
})
export class AddressDocumentsComponent implements OnInit, OnChanges {
    ftp = false;

    @Input() address: Address;
    documentTypes: Object;
    file: FileUploadModel;
    documents: any[];
    document: Object;
    employees: Employee[];
    showDocs = false;
    uploadDoc = false;
    editMode = false;

    images: Object[];
    canViewLargeImg = false;
    largeImage: Object;
    sortOptions: Object;

    constructor(
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private fileUploadService: FileUploadService,
        private toastService: ToastService
    ) {}

    ngOnInit() {
        this.employees = this.activatedRoute.snapshot.data['employees'];
        this.documentTypes = this.activatedRoute.snapshot.data['documentTypes'];
        this.documentTypes['splice'](0, 1);
        this.file = new FileUploadModel();
        this.sortOptions = {
            byDate: 'asc',
            byEmployee: 'desc',
            byType: 'desc'
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['address'].currentValue && changes['address'].currentValue.id) {
            this.fileUploadService.getDocuments(this.address.id)
                .then((res) => {
                    this.documents = res;
                });
        }
    }

    uploadNewDoc() {
        this.uploadDoc = !this.uploadDoc;
    }

    getAllDocs() {
        if (this.showDocs === false) {
            this.showDocs = true;
            this.fileUploadService.getDocuments(this.address.id)
                .then((res) => {
                    this.documents = res;
                    this.scrollToElement(250, 'documentoList');
                });
        } else {
            this.showDocs = false;
        }
    }

    viewLargeImg(imageId: number) {
        this.fileUploadService.getDocument(this.address.id, imageId)
            .then(res => {
                this.largeImage = {};
                this.largeImage = res[0];
                this.fileUploadService.getDocuments(this.address.id)
                    .then((imgs) => {
                        this.images = [];
                        this.images = imgs;
                        this.canViewLargeImg = true;
                    });
            });
    }

    cancelPreview() {
        this.largeImage = {};
        this.images = [];
        this.canViewLargeImg = false;
    }

    handleFile(file: any) {
        // var file2 = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            this.file.base64 = e.target['result'] as string;
        };

        const coded = reader.readAsDataURL(file.target.files[0]);
    }

    uploadFile(file: FileUploadModel, form: UntypedFormGroup) {
        if (!this.file.base64) {
            this.toastService.toast({message: 'Select a file to upload.'});
        } else if (!form.valid) {
            this.toastService.validate(form);
        } else {
            this.ftp = true;
            const data = {
                clientId: this.address.clientId,
                addressId: this.address.id,
                uploadedById: this.userService.getEmployeeId(),
                description: file.description,
                title: file.title,
                documentTypeId: file.documentTypeId, // fix this...
                data: this.file.base64.split(',')[1],
                type: file.base64.split(',')[0]
            };

        // Send file to fileUploadService to save to database
            this.fileUploadService.uploadFile(new FileUploadModel(data))
                .then((res) => {
                    if (res['error']) {
                        this.toastService.toast({message: res['error']});
                    }
                    this.file = new FileUploadModel();
                    this.showDocs = false;
                    this.getAllDocs();
                    form.reset();
                    this.ftp = false;
                });
        }
    }

    updateDocument(file: any) {
        this.fileUploadService.updateDocument(file)
        .then((res) => {
            if (res['error']) {
                this.toastService.toast({message: res['error']});
            }
            this.getAllDocs();
            this.editMode = false;
            this.showDocs = true;
        });
    }

    clearDoc(): void {
        this.file = new FileUploadModel;
    }

    scrollToElement(duration: number, eleId: string): void {
        if (duration <= 0)return;
        const element = document.body;
        const to = document.getElementById(eleId).offsetTop;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            this.scrollToElement(duration - 10, eleId);
        }, 10);
    }

    assignDocumentType(typeId: number) {
        let type: string;
        _.forEach(this.documentTypes, (o) => {
            if (o.id === typeId) {
                return type = o.name;
            } else return;
        });
        return type;
    }

    setEmployee(employeeId: number) {
        const employee = _.find(this.employees, {'id': employeeId});
        return employee.firstName + ' ' + employee.lastName;
    }

    sortByDate(): void {
        if (this.sortOptions['byDate'] === 'desc') {
            this.documents = _.orderBy(this.documents, ['createdAt'], ['asc']);
            this.sortOptions['byDate'] = 'asc';
        } else {
            this.documents = _.orderBy(this.documents, ['createdAt'], ['desc']);
            this.sortOptions['byDate'] = 'desc';
        }
    }

    sortByEmployee(): void {
        if (this.sortOptions['byEmployee'] === 'desc') {
            this.documents = _.orderBy(this.documents, ['uploadedById'], ['asc']);
            this.sortOptions['byEmployee'] = 'asc';
        } else {
            this.documents = _.orderBy(this.documents, ['uploadedById'], ['desc']);
            this.sortOptions['byEmployee'] = 'desc';
        }
    }

    sortByType(): void {
        if (this.sortOptions['byType'] === 'desc') {
            this.documents = _.orderBy(this.documents, ['documentTypeId'], ['asc']);
            this.sortOptions['byType'] = 'asc';
        } else {
            this.documents = _.orderBy(this.documents, ['documentTypeId'], ['desc']);
            this.sortOptions['byType'] = 'desc';
        }
    }

    isImage(url: string) {
        const splitUrl = url.split('.');
        return  ['jpg', 'png', 'jpeg'].indexOf(splitUrl[splitUrl.length - 1]) !== -1;
    }
    setDocument(document: any) {
        this.document = document;
    }
    clearDocument() {
        this.document = {};
    }
    removeDocument() {
        this.fileUploadService.deleteDocument(this.document['id'])
            .then((data) => {
                this.showDocs = false;
                this.clearDocument();
                this.getAllDocs();
                this.toastService.toast({message: 'you have successfully deleted the file', type: 'success'});
            });

    }
}
