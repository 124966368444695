<div class="panel">
    <table class="table table-hover">
        <thead>
        <tr>
            <td>Name</td>
            <td>Abbr</td>
            <td><button
                #dButton="bs-tooltip"
                (click)="active = !active;clearProduct();dButton.hide()"
                type="button"
                class="btn btn-sm btn-light pull-right"
                [tooltip]="active ? 'Show inactive products' : 'Show active products'"
                placement="left"
            >{{active ? 'Inactive' : 'Active'}}</button>Description </td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let product of activeProducts()" (click)="selectProduct(product)" [ngClass]="{'selected':isSelected(product)}">
            <td>{{ product.name }}</td>
            <td>{{ product.abbr  }}</td>
            <td>{{ product.description }}</td>
        </tr>
        </tbody>
    </table>
</div>