//Angular 2 core modules
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

//Routes
import { adminRouting } from './admin.routing';

//Core Components
import { AdminComponent } from './admin.component';
import { VehicleComponent } from './vehicle-panel/vehicle.component';
import { ProductComponent } from './product-panel/product.component';
import { EmployeeComponent } from './employee-panel/employee.component';
import { TankComponent } from './tank-panel/tank.component';

//Sub components
import { EmployeeListComponent } from './employee-panel/employee-list/employee-list.component';
import { EmployeeSingleComponent } from './employee-panel/employee-single/employee-single.component';
import { EmployeeTypeSingleComponent } from './employee-panel/employee-type-single/employee-type-single.component';
import { EmployeeTypesListComponent } from './employee-panel/employee-type-list/employee-type-list.component';
import { ProductListComponent } from './product-panel/product-list/product-list.component';
import { ProductSingleComponent } from './product-panel/product-single/product-single.component';
import { TankListComponent } from './tank-panel/tank-list/tank-list.component';
import { TankSingleComponent } from './tank-panel/tank-single/tank-single.component';
import { TractorListComponent } from './vehicle-panel/tractor-list/tractor-list.component';
import { TractorSingleComponent } from './vehicle-panel/tractor-single/tractor-single.component';
import { TrailerListComponent } from './vehicle-panel/trailer-list/trailer-list.component';
import { TrailerSingleComponent } from './vehicle-panel/trailer-single/trailer-single.component';
import { MiscellaneousComponent } from './misc-panel/miscellaneous.component';
import { FacilityComponent } from './misc-panel/facility/facility.component';
import { FacilityListComponent } from './misc-panel/facility/facility-list/facility-list.component';
import { FacilitySingleComponent } from './misc-panel/facility/facility-single/facility-single.component';
import { ServiceRegionComponent } from './misc-panel/service-regions/service-region.component';
import { ServiceRegionListComponent } from './misc-panel/service-regions/service-region-list/service-region-list.component';
import { ServiceRegionSingleComponent } from './misc-panel/service-regions/service-region-single/service-region-single.component';
import { InactiveComponent } from './inactive/inactive.component';
import { InactiveClientPanelComponent } from './inactive/inactive-client-panel/inactive-client.component';
import { InactiveTankPanelComponent } from './inactive/inactive-tank-panel/inactive-tank.component';
import { InactiveDairyPanelComponent } from './inactive/inactive-dairy-panel/inactive-dairy.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ClientGroupManagementComponent } from './client-group-management/client-group-management.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { GroupToDairyComponent } from './client-group-management/group-to-dairy/group-to-dairy.component';
import { DairyToGroupComponent } from './client-group-management/dairy-to-group/dairy-to-group.component';
import { DairyAsGroupComponent } from './client-group-management/dairy-as-group/dairy-as-group.component';

import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ReportingModule } from './reporting/reporting.module';
import { PipesModule } from "../pipes/pipes.module";

@NgModule({
  imports: [
    adminRouting,
    CommonModule,
    FormsModule,
    NgSelectModule,
    DatepickerModule.forRoot(),
    SharedComponentsModule,
    TooltipModule.forRoot(),
    ReportingModule,
    PipesModule
  ],
    declarations: [
        AdminComponent,
        ClientGroupManagementComponent,
        GroupToDairyComponent,
        DairyToGroupComponent,
        DairyAsGroupComponent,
        EmployeeComponent,
        VehicleComponent,
        ProductComponent,
        TankComponent,
        EmployeeListComponent,
        EmployeeSingleComponent,
        EmployeeTypeSingleComponent,
        EmployeeTypesListComponent,
        MiscellaneousComponent,
        ProductListComponent,
        ProductSingleComponent,
        TractorListComponent,
        TractorSingleComponent,
        TrailerListComponent,
        TrailerSingleComponent,
        TankListComponent,
        TankSingleComponent,
        FacilityComponent,
        FacilityListComponent,
        FacilitySingleComponent,
        ServiceRegionComponent,
        ServiceRegionListComponent,
        ServiceRegionSingleComponent,
        InactiveComponent,
        InactiveClientPanelComponent,
        InactiveTankPanelComponent,
        InactiveDairyPanelComponent
    ],
    providers: [],
    exports: [
        RouterModule
    ]
})
export class AdminModule { }
