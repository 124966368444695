import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appEmployeeTypes' })
export class EmployeeTypesPipe implements PipeTransform {
  transform(employeeTypes: string[], typeString: string): boolean {
    const types = JSON.parse(typeString);

    if (!types) {
      localStorage.clear();
      location.reload();
      return false;
    }

    if (employeeTypes.map(t => types.hasOwnProperty(t)).indexOf(true) !== -1) {
      return true;
    } else return false;
  }
}
