<div class="header">
    <h4><i class="fa fa-truck"></i> Vehicles</h4>
</div>
<div class="row">
    <div class="col-md-6">
        <tractor-list
            [tractors]="tractors"
            [selectedTractor]="selectedTractor"
            (setTractor)="setTractor($event)"
            (resetTractor)="resetTractor($event)"
        ></tractor-list>
    </div>
    <div class="col-md-6">
        <tractor-single [tractor]="selectedTractor"
                        (resetTractor)="resetTractor($event)"
                        (saveTractor)="saveTractor($event)"></tractor-single>
    </div>
</div>

<div class="row margin-top-20">
    <div class="col-md-6">
        <trailer-list
            [trailers]="trailers"
            [selectedTrailer]="selectedTrailer"
            (setTrailer)="setTrailer($event)"
            (resetTrailer)="resetTrailer($event)"
        ></trailer-list>
    </div>
    <div class="col-md-6">
        <trailer-single [trailer]="selectedTrailer"
                        (resetTrailer)="resetTrailer($event)"
                        (saveTrailer)="saveTrailer($event)"></trailer-single>
    </div>
</div>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>

