import {
  Component,
  forwardRef,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  AfterViewInit,
  HostListener,
  ViewChild,
  ElementRef,
  Injector,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NgControl,
  AbstractControl,
  NgModel
} from '@angular/forms';
import { Subscription } from 'rxjs';

const noop = () => { };

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextareaComponent),
  multi: true
};

// appTextara usage

// <app-textarea [(ngModel)]='textAreaValue' name='Input Name'></app-textarea>

@Component({
  selector: 'app-textarea',
  styleUrls: ['./textarea.component.scss'],
  template: `<div (blur)='onBlur()' [appFocus]='focus' (focus)='onFocus($event)' #text contenteditable='true' class='form-control'>
             </div>
             <div *ngIf='!ngModel' class='placeholder'>{{placeholder}}</div>
             <div *ngIf='control?.touched && control?.errors?.required' class='error'>
                {{niceName}} is required.
             </div>
             <div #error [hidden]='control?.touched && control?.errors?.required' class='error'></div>`,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class TextareaComponent
  implements AfterViewInit, ControlValueAccessor, OnChanges {
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input() ngModel: string;
  @Input() maxlength = '1024';
  @Input() name: string;
  @Input() placeholder = '';
  @Input() required;
  @Input() focus: boolean | '';
  @Output() ngModelChange = new EventEmitter<string>();
  @ViewChild('text', { static: true }) private text: ElementRef;
  @ViewChild('error', { static: true }) private error: ElementRef;
  truncated: string = null;
  cancelled = false;
  niceName: string;
  control: AbstractControl;
  control_sub: Subscription;

  constructor(private injector: Injector) {
    this.niceName = this.setNiceName();
  }

  ngAfterViewInit() {
    const text = this.text.nativeElement;
    text.innerText = this.ngModel || '';
    const ngModel: NgModel = this.injector.get(NgControl, null) as NgModel;
    if (ngModel) {
      setTimeout(() => {
        this.control = ngModel.control;
      }, 0);
    }
  }

  // reset value if changed from parent
  ngOnChanges(changes: SimpleChanges) {
    const text = this.text.nativeElement;
    const { innerText } = this.text.nativeElement;
    this.niceName = this.setNiceName();
    if (innerText !== this.ngModel) {
      text.innerText = this.ngModel || '';
    }
  }

  // set touched on blur
  onBlur() {
    const selection = window.getSelection();
    selection.removeAllRanges();
    this.onTouchedCallback();
  }

  onFocus(event: FocusEvent) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(<HTMLElement>event.target);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  // output text on keyup
  @HostListener('keyup') onKeyup() {
    const text = this.text.nativeElement;
    const error = this.error.nativeElement;
    this.ngModel = text.innerText;
    const length = this.ngModel.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const maxlength = this.maxlength.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    this.ngModelChange.emit(this.ngModel);
    this.onChangeCallback(this.ngModel);
    if (!this.cancelled) {
      error.textContent = this.ngModel.length ? `Count: ${length}${maxlength ? ' / ' + maxlength : ''}` : '';
    }
  }

  // limit characters when typing
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    const error = this.error.nativeElement;
    const length = this.text.nativeElement.innerText.length;

    if (
      ((+this.maxlength && +this.maxlength <= length) && window.getSelection().toString().length === 0) &&
      [8, 9, 37, 38, 39, 40].indexOf(event.keyCode) === -1 &&
      !(event.metaKey || event.ctrlKey)
    ) {
      event.preventDefault();
      error.textContent = `${this.niceName || 'Value'} must be ${this.maxlength} characters or less.`;
      error.style.color = '#c50000';
      this.cancelled = true;
    } else {
      error.style.color = '#000';
      this.cancelled = false;
    }
  }

  // limit characters when pasting
  @HostListener('paste') onPaste() {
    setTimeout(() => {
      const text = this.text.nativeElement;
      const error = this.error.nativeElement;
      const maxlength = parseInt(this.maxlength, 10);
      const data = this.text.nativeElement.innerText;
      const truncated = data.slice(0, maxlength);
      this.ngModel = text.innerText = truncated;
      this.ngModelChange.emit(this.ngModel);
      this.onChangeCallback(this.ngModel);

      // error message if content over limit
      if (maxlength && data.length > maxlength) {
        error.textContent =
          `${this.niceName || 'Value'} must be ${maxlength} characters or less. ${this.niceName || 'Value'} has been truncated.`;
        error.style.color = '#c50000';
        this.cancelled = true;
      } else {
        const length = data.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const max = this.maxlength.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        error.textContent = length ? `Count: ${length}${max ? ' / ' + max : ''}` : '';
        error.style.color = '#000';
        this.cancelled = false;
      }
    }, 0);
  }

  setNiceName() {
    let words = (this.name || '').split(/(?=[A-Z\s_-])/);
    words = words.map(w => {
      w = w.replace(/[-_\s+]+/g, '');
      if (w[0]) {
        return w[0].toUpperCase() + w.slice(1);
      } else {
        return null;
      }
    })
    return words.filter(w => w !== null).join(' ');
  }

  // From ControlValueAccessor interface
  writeValue(value: any) { }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
