import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Client } from '../../models/client';
import * as _ from 'lodash';
import { ToastService } from '../../services-module/services/toast.service';
import { ClientService } from '../../services-module/services/client.service';
import { PromptModalComponent } from '../../shared-components/prompt-modal/prompt-modal.component';

@Component({
    selector: 'client-group-management',
    templateUrl: './client-group-management.component.html',
    styleUrls: ['./client-group-management.component.css']
})

export class ClientGroupManagementComponent implements OnInit {
    @ViewChild('confirmSave', { static: true }) private confirmSave: PromptModalComponent;
    @ViewChild('confirmGroupModal', { static: true }) private confirmGroupModal: PromptModalComponent;
    @ViewChild('dairyToGroupModal', { static: true }) private dairyToGroupModal: PromptModalComponent;


    clients: Client[];
    clientGroup: Client;
    groupToGroup: Client;
    dairyToGroup: any[];
    dairies: any[];
    option: number;
    defaultImage: string;
    newGroup: any;
    ftp: boolean = false;

    constructor(private activatedRoute: ActivatedRoute,
        private clientService: ClientService,
        private toastService: ToastService) { }

    ngOnInit() {
        this.clients = this.activatedRoute.snapshot.data['clients'];
        this.option = 1;
        this.dairies = [];
        this.dairyToGroup = [];
        this.defaultImage = 'url("../../../../assets/images/default_client_logo.png")';
        this.clients.forEach((client) => {
            this.dairies.push({
                id: client.id,
                text: client.name
            });
        });
    }

    /********** Common Methods **********/

    setAsOption(option: number) {
        this.clientGroup = null;
        this.groupToGroup = null;
        this.newGroup = null;
        this.option = option;
    }

    setGroup(dairy: any) {
        let group = _.find(this.clients, { id: dairy.id });
        this.clientGroup = group;
    }

    setToGroup(dairy: any) {
        let toGroup = _.find(this.clients, { id: dairy.id });
        this.groupToGroup = toGroup;
    }

    showModal() {
        this.confirmSave.showPromptModal();
    }

    /********** Dairy To Group Methods **********/
    pushDairiesToGroup(dairy: Client) {
        dairy['limbo'] = true;
        dairy['Addresses'].forEach((o: any) => {
            o['newDairy'] = true;
            this.groupToGroup['Addresses'].push(o);
        })
    }

    removeDairies() {
        this.groupToGroup['Addresses'].forEach((a: any) => {
            if (a.newDairy) {
                _.remove(this.groupToGroup['Addresses'], { id: a.id });
            }
        });
        this.clientGroup['limbo'] = null;
    }

    cancelActions() {
        this.removeDairies();
        this.groupToGroup = null;
        this.clientGroup = null;
    }

    saveToDairy() {
        let data: any = {
            client: this.groupToGroup.id,
            addresses: []
        };
        this.clientGroup['Addresses'].forEach((a: any) => {
            let address = {
                alias: a['alias'],
                clientId: a['clientId'],
                id: a['id']
            };
            data.addresses.push(address);
        });
        this.ftp = true;
        this.clientService.saveGroupToDairy(data)
            .then((res) => {
                this.afterGroupToDairy();
            });
    }

    afterGroupToDairy() {
        this.clientService.getClients()
            .then((res) => {
                this.clients = [];
                this.clients = res;
                this.dairies = [];
                this.clients.forEach((client) => {
                    this.dairies.push({
                        id: client.id,
                        text: client.name
                    });
                });
                const newGroup = _.find(res, { id: this.groupToGroup.id });
                this.cancelActions();
                this.clientGroup = newGroup;
                this.ftp = false;
                this.toastService.toast({ message: 'You have successfully changed a client group as a dairy', type: 'success' });
            })
    }

    /********** Group as Dairy Methods **********/
    setAsNewGroup(dairy: any) {
        dairy.toNewGroup = true;
        this.newGroup = {
            name: dairy.alias,
            Addresses: [dairy]
        }
    }
    addressToNewGroup(dairy: any) {
        dairy.toNewGroup = true;
        this.newGroup.Addresses.push(dairy);
    }

    createNewGroup() {
        let data: any = {
            name: this.newGroup.name,
            addresses: []
        };
        this.newGroup.Addresses.forEach((a: any) => {
            let dairy = {
                alias: a.alias,
                clientId: a.clientId,
                id: a.id
            };
            data.addresses.push(dairy);
        });
        this.ftp = true;

        this.clientService.createNewGroup(data)
            .then((res) => {
                this.afterNewGroup(res);
            })
    }

    cancelNewGroup() {
        this.clientGroup['Addresses'].forEach((a: any) => {
            if (a.toNewGroup) {
                a.toNewGroup = null;
            }
        });
        this.newGroup = null;
    }
    confirmNewGroup() {
        this.confirmGroupModal.showPromptModal();
    }

    afterNewGroup(data: any) {
        this.clientService.getClients()
            .then((res) => {
                this.clients = [];
                this.clients = res;
                this.dairies = [];
                this.clients.forEach((client) => {
                    this.dairies.push({
                        id: client.id,
                        text: client.name
                    });
                });
                this.clientGroup = _.find(res, { id: this.clientGroup.id });
                this.newGroup = _.find(res, { id: data['data']['toId'] });
                this.ftp = false;
                this.toastService.toast({ message: 'You have successfully added a new client group', type: 'success' });
            })
    }

    removeFromGroup(dairy: any) {
        dairy.toNewGroup = null;
        _.remove(this.newGroup.Addresses, { id: dairy.id });
    }

    /********** Group To Dairy Methods **********/
    addressToExistingGroup(dairy: any) {
        dairy.toNewGroup = true;
        this.dairyToGroup.push(dairy);
        this.groupToGroup['Addresses'].push(dairy);
    }

    removeFromExistingGroup(dairy: any) {
        dairy.toNewGroup = null;
        _.remove(this.groupToGroup['Addresses'], { id: dairy.id });
        _.remove(this.dairyToGroup, { id: dairy.id });
    }

    showDairyToGroupModal() {
        this.dairyToGroupModal.showPromptModal();
    }

    saveToGroup() {
        let data: any = {
            client: this.groupToGroup.id,
            addresses: []
        };
        this.dairyToGroup.forEach((a: any) => {
            let address = {
                alias: a['alias'],
                clientId: a['clientId'],
                id: a['id']
            };
            data.addresses.push(address);
        });
        this.ftp = true;
        this.clientService.saveGroupToDairy(data)
            .then((res) => {
                this.afterDairyToGroup();
            });
    }

    afterDairyToGroup() {
        this.clientService.getClients()
            .then((res) => {
                this.clients = [];
                this.clients = res;
                this.dairies = [];
                this.clients.forEach((client) => {
                    this.dairies.push({
                        id: client.id,
                        text: client.name
                    });
                });
                this.clientGroup = _.find(res, { id: this.clientGroup.id });
                this.groupToGroup = _.find(res, { id: this.groupToGroup.id });
                this.ftp = false;
                this.toastService.toast({ message: 'You have successfully added a new client group', type: 'success' });
            })

    }

    cancelDairyToGroup() {
        this.groupToGroup = null;
        this.clientGroup = null;
    }


}
