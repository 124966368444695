import * as _ from 'lodash';

export class EmployeeType{
    constructor(data?: any){
        _.merge(this, data);
    }

    public id: number = null;
    public employeeType: string = null;
    public isActive:boolean = true;
}