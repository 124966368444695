import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {UtilsService} from "../../../services-module/services/utils.service";

@Component({
    templateUrl: './address-work-orders.component.html',
    styleUrls: ['../address.component.css', './address-work-orders.component.css'],
    selector: 'address-work-orders'
})
export class AddressWorkOrdersComponent implements OnInit{
    @Input()
    workOrders: any[];
    @Input()
    open:boolean;
    @Output()
    showWorkOrdersPanel = new EventEmitter<void>();
    constructor(private utilsService: UtilsService){}

    ngOnInit():void{
        this.workOrders = [];
    }

    showAll(): void{
        this.showWorkOrdersPanel.emit();
    }

    woStatus(val: any){
        return this.utilsService.woStatus(val);
    }

}