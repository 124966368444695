import {Component, Input, Output, EventEmitter} from '@angular/core';

import {Address} from '../../../models/address';
import {UtilsService} from "../../../services-module/services/utils.service";


@Component({
    templateUrl: './address-info.component.html',
    styleUrls: ['../../client.component.css'],
    selector: 'address-info'
})
export class AddressInfoComponent{
    @Input()
    address: Address;
    @Output()
    editAddress = new EventEmitter<string>();
    constructor(public utilsService: UtilsService){}

    edit(): void{
        this.editAddress.emit('edit');
    }

    openMap(address: Address): void {
        this.utilsService.openMap(address);
    }

}