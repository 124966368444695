<div class="pane">
    <form #routeDetailsForm="ngForm">
    <div class="row" *ngIf="route.locations.length > 21">
        <div class="col-md-12">
            <p class="bg-danger">Too many locations selected</p>
        </div>
    </div>
    <div class="flex-container flex-end form-inline padding-15">
        <div>
            <ng-container *ngIf="!workOrder">
                <div class="form-group margin-left-10" *ngFor="let product of totalGallons">
                    <label>Total {{product.abbr}} Gal &nbsp;
                    <input type="text"
                           class="form-control"
                           [name]="product.name"
                           placeholder="Calculated Weight"
                           [(ngModel)]="product.ordered" disabled>
                    </label>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="flex-container flex-end side-padding-15 margin-right-10">
        <div class="form-inline route-details">
            <div class="form-group margin-right-10">
                <label for="routeName">*Route Name:</label>
                <input type="text"
                       id="routeName"
                       name="Route Name"
                       #routeName="ngModel"
                       required
                       class="form-control"
                       [(ngModel)]="route.routeName"
                       placeholder="Route Name">
            </div>
            <div class="form-group margin-right-10">
                <label for="startPoint">Start Location:</label>
                <select (change)="hasChanges()" name="StartPoint"
                        id="startPoint"
                        class="form-control"
                        [(ngModel)]="route.startLocationId">
                    <option value="">Select Facility</option>
                    <option [value]="facility.id"
                            *ngFor="let facility of facilities">{{ facility.city }}, {{ facility.state }}</option>
                </select>
            </div>
            <div class="form-group margin-right-10">
                <label for="startPoint">End Location:</label>
                <select (change)="hasChanges()" name="StartPoint" id="endPoint" class="form-control" [(ngModel)]="route.endLocationId">
                    <option value="">Select Facility</option>
                    <option [value]="facility.id"
                            *ngFor="let facility of facilities">{{ facility.city }}, {{ facility.state }}</option>
                </select>
            </div>
            <div class="form-group margin-right-10 flex-container flex-space-between">
                <label for="drivers">Driver:</label>
                <ng-select [multiple]="true"
                           (ngModelChange)="hasChanges()"
                           id="drivers"
                           name="Drivers"
                            class="inline"
                           [(ngModel)]="route.driverIds"
                           placeholder="Select Drivers">
                           <ng-option *ngFor="let driver of driverOptions" [value]="driver.id">{{driver.name}}</ng-option>
                </ng-select>
            </div>
            <div class="form-group margin-right-10" *ngIf="!workOrder">
                <label for="trailer">Trailer:</label>
                <select (change)="hasChanges()" name="Trailer" id="trailer" #trailerNumber="ngModel"  class="form-control" [(ngModel)]="route.trailerId">
                    <option value="0">--</option>
                    <option [value]="trailer.id"
                            *ngFor="let trailer of trailers">{{ trailer.name }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="flex-container flex-end side-padding-15 no-print">
        <div class="form-group margin-top-10">
            <button *ngIf="!copying && !workOrder"
                    class="btn btn-warning"
                    (click)="copy(route)"
                    [disabled]="!route.id">Copy</button>
            <button *ngIf="copying && !workOrder"
                    class="btn btn-danger"
                    (click)="cancelCopy()">Cancel Copy</button>
            <button class="btn btn-success"
                    (click)="save(route, routeDetailsForm.form)"
                    [disabled]="saving || route.locations.length === 0 || (setManual && !workOrder)">Save</button>
            <button class="btn btn-default"
                    *ngIf="!workOrder"
                    (click)="setManOrder(route)">Set Order</button>
            <button class="btn btn-primary"
                    *ngIf="!workOrder"
                    [disabled]="route.locations.length === 0 || !route.id"
                    (click)="renderPdf()">Load Sheets</button>
            <button class="btn btn-primary" (click)="printOrders()" *ngIf="workOrder">Print Orders</button>
            <button *ngIf="isAdmin && location.path() !== '/new-route' && !workOrder" class="btn btn-primary margin-left-10" routerLink="/new-route" (click)="reload()">
                New Route
            </button>
            <button *ngIf="isAdmin && location.path() !== '/new-work-route' && workOrder" class="btn btn-primary" routerLink="/new-work-route" (click)="reload()">
                New Route
            </button>
        </div>
    </div>
        <div class="row">
            <div class="container-fluid">
                <small style="color:red;padding-left: 15px;">* Required</small>
            </div>
        </div>
    </form>

</div>
