<div class="flex-container flex-around pagination-header secondary">
  <div class="flex-container flex-between page-controls">
    <a href="javascript:;"
       (click)="pageDown()">
      <svg width="18" height="18" viewBox="0 0 18 18"><path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/></svg>
    </a>
    <div style="font-size: 18px">
      {{ totalItems > 0 ? currentIndex + 1 : totalItems }}&nbsp;-&nbsp;{{ currentIndex + displayNumber >= totalItems ? totalItems : currentIndex + displayNumber }} of {{ totalItems }}
    </div>
    <a href="javascript:;"
       (click)="pageUp()">
      <svg width="18" height="18" viewBox="0 0 18 18">
        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>
      </svg>
    </a>
  </div>
</div>
<ng-content select=".paginate"></ng-content>
<div class="flex-container flex-around pagination-footer secondary">
  <div class="flex-container flex-between page-controls">
    <a href="javascript:;"
       (click)="pageDown()">
      <svg width="18" height="18" viewBox="0 0 18 18"><path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/></svg>
    </a>
    <div style="font-size: 18px">
      {{ totalItems > 0 ? currentIndex + 1 : totalItems }}&nbsp;-&nbsp;{{ currentIndex + displayNumber >= totalItems ? totalItems : currentIndex + displayNumber }} of {{ totalItems }}
    </div>
    <a href="javascript:;"
       (click)="pageUp()">
      <svg width="18" height="18" viewBox="0 0 18 18">
        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>
      </svg>
    </a>
  </div>
</div>