<div id="client-edit-form" class="flex-container flex-space-between" *ngIf="client">
    <form #clientEditForm="ngForm">
        <div class="form-inline">
            <div class="form-group client-group">
                <label for="name">Client: </label>
                <input type="text"
                       name="name"
                       class="form-control"
                       required
                       id="name" [(ngModel)]="client.name">
            </div>
            <div class="form-group client-group">
                <button
                    type="button"
                    name="Is Active Button"
                    class="btn"
                    [ngClass]="{'btn-primary': client.isActive, 'btn-light': !client.isActive}"
                    (click)="client.isActive = !client.isActive">
                    <i class="fa fa-fw" [ngClass]="{'fa-check-square-o': client.isActive, 'fa-square-o': !client.isActive}"  aria-hidden="true"></i>
                    Client Active
                </button>
                <button
                    type="button"
                    name="Is Client Button"
                    class="btn"
                    [ngClass]="{'btn-success': client.isClient, 'btn-light': !client.isClient}"
                    (click)="client.isClient = !client.isClient">
                    <i class="fa fa-fw" [ngClass]="{'fa-check-square-o': client.isClient, 'fa-square-o': !client.isClient}"  aria-hidden="true"></i>
                    Current Client
                </button>
            </div>
        </div>
        <div class="flex-container flex-end">
            <div class="form-group margin-top-10">
                <button class="btn-success btn" (click)="save(client)" [disabled]="!clientEditForm.form.valid">Save</button>
                <button class="btn-danger btn" (click)="cancelEdit()">Cancel</button>
            </div>
        </div>
    </form>
</div>
