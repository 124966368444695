import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { VaporTest } from '../../models/vapor-test';
import { environment } from '../../../environments/environment';

@Injectable()
export class VaporTestService {
    private apiUrl = `${environment.apiUrl}vapor-test`;  // URL to web api
    constructor(private authHttp: HttpClient) { }

    getAddressVaporTests(addressId: number): Promise<VaporTest[]> {
        return this.authHttp.post(`${environment.apiUrl}address/getLocationVaporTests`, { addressId: addressId })
            .toPromise()
            .then((res) => {
                return res as VaporTest[];
            });
    }

    getSingleTest(testId: number): Promise<VaporTest> {
        return this.authHttp.get(`${this.apiUrl}/${testId}`)
            .toPromise()
            .then((res) => {
                return res as VaporTest;
            });
    }

    createVaporTest(vaporTest: VaporTest): Promise<VaporTest> {
        return this.authHttp.post(`${this.apiUrl}`, vaporTest)
            .toPromise()
            .then((res) => {
                return res as VaporTest;
            });
    }
    updateVaporTest(vaporTest: VaporTest): Promise<VaporTest> {
        return this.authHttp.put(`${this.apiUrl}/${vaporTest.id}`, vaporTest)
            .toPromise()
            .then((res) => {
                return res as VaporTest;
            });
    }

    deleteVaporTest(vaporTestId: any) {
        return this.authHttp.delete(`${this.apiUrl}/${vaporTestId}`, vaporTestId)
            .toPromise()
            .then((res) => {
                return res;
            });
    }
}
