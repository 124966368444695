<div class="panel">
    <table class="table table-hover">
        <thead>
        <tr>
            <td>Region Name</td>
            <td>Abbr</td>
            <td><button
                #srButton="bs-tooltip"
                (click)="active = active ? 0 : 1;clearRegion();srButton.hide()"
                type="button"
                class="btn btn-sm btn-light pull-right"
                [tooltip]="active ? 'Show inactive regions' : 'Show active regions'"
                placement="left"
            >{{active ? 'Inactive' : 'Active'}}</button>State </td>
        </tr>
        </thead>
        <tbody>
            <tr *ngFor="let region of activeRegions()" (click)="selectServiceRegion(region)" [ngClass]="{'selected':isSelected(region)}">
                <td>{{ region.serviceRegionName }}</td>
                <td>{{ region.serviceRegionAbbr }}</td>
                <td>{{ region.state }}</td>
            </tr>
        </tbody>
    </table>
</div>
