import * as _ from 'lodash';
export class WorkOrderDetail{
    constructor(data?: any){
        _.merge(this, data);
    }

    public id: number = null;
    public content: string = null;
    public note: string = null;
    public workOrderId: number = null;
    public clientId: number = null;
    public employeeId: number = null;
    public Employee: any= {};
    public mailOrder: any;
}
