<div class=" header">
    <div class="container-fluid">
        <h2><i class="fa fa-file"></i> Work Orders</h2>
    </div>
</div>


<div id="add-work-order" class="shadow">
    <div class="row">
        <div class="col-md-12">
            <h3 style="font-style: italic; border-bottom: 1px solid">Add a Work Order</h3>
        </div>
    </div>
    <form class="form" #addOrderForm="ngForm">
        <div class="row">
            <div class="form-group col-sm-6">
                <label for="client">*Select a dairy</label>
                <ng-select [clearable]="true"
                           [items]="clientsSelect"
                           bindLabel="text"
                           bindValue="id"
                           (change)="findAddresses($event)"
                           id="client"
                           name="client"
                           placeholder="No Dairy selected">
                </ng-select>
            </div>
            <div class="form-group col-sm-6">
                <label for="address">*Location</label>
                <select class="form-control" required #clientAddress="ngModel" id="address" [(ngModel)]="order.addressId" name="address">
                    <option *ngFor="let address of deliveryAddresses" [value]="address.id" name="addressId">{{address.alias}} - {{address.street}}, {{address.city}}, {{address.state}}</option>
                </select>
                <div *ngIf="clientAddress.errors && (clientAddress.dirty || clientAddress.touched)"
                     class="alert alert-danger">
                    <div [hidden]="!clientAddress.errors.required">
                        Location is required
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-6">
                <label for="service">*Service Type</label>
                <select #orderType="ngModel" class="form-control" required id="service" [(ngModel)]="order.serviceTypeId" name="serviceType">
                    <option *ngFor="let type of serviceTypes" [value]="type.id">{{type.serviceTypeName}}</option>
                </select>
                <div *ngIf="orderType.errors && (orderType.dirty || orderType.touched)"
                     class="alert alert-danger">
                    <div [hidden]="!orderType.errors.required">
                        Service Type is required
                    </div>
                </div>
            </div>
            <div class="form-group col-sm-6" >
                <label for="employeeTo">Assigned To</label>
                <select class="form-control" id="employeeTo" [(ngModel)]="order.employeeToId" name="employeeTo">
                    <option *ngFor="let employee of employees | active: true" [value]="employee.id">{{employee.lastName}}, {{employee.firstName}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label for="description">Work Order</label>
                <textarea name="description" id="description" class="form-control" rows="3" [(ngModel)]="order.note"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <strong>Email Employees</strong>
                <ng-select [multiple]="true"
                           name="Employees"
                           id="employees"
                           class="email-select"
                           style="padding-left: 0px;"
                           [(ngModel)]="order.emailEmployees"
                           placeholder="Select Employees">
                    <ng-option *ngFor="let employee of employeeOptions" [value]="employee.id">{{employee.name}}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12 shift-right">
                <button class="btn btn-success"
                     [disabled]="!addOrderForm.form.valid"
                     (click)="saveWorkOrder(order)">Save</button>
                <div class="btn btn-danger" (click)="cancelAdd()">Cancel</div>
            </div>
        </div>
    </form>
    <div >
        <small style="color:red">* Required</small>
    </div>
</div>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
