<div class="row">
    <div class="col-12 col-md-4">
        <div class="select-dairy-card shadow">
            <div class="card-container">
                <div class="card-header">
                    <strong>Select Client Group</strong>
                    <ng-select [clearable]="false"
                               [editableSearchTerm]="true"
                               bindLabel="text"
                               bindValue="id"
                               [items]="dairies"
                               (change)="groupSet($event)"
                               id="client"
                               name="client"
                               class="client-select-dropdown"
                               style="display: inline-block; width: 201px;"
                               placeholder="Select Client Group">>
                    </ng-select>
                </div>
                <div class="card-body">
                    <div class="client-group" *ngIf="clientGroup">
                        <div class="client">
                            <div class="client-image shadow" [ngClass]="{'limbo-border':clientGroup.limbo}">
                                <div class="background-img" [style.background]="clientGroup.image.length > 0 ? setBG(clientGroup.image[0].filePath) : defaultImage"></div>
                            </div>
                            <div class="client-info">
                                <h3>{{clientGroup.name}}</h3>
                                <p><span>Dairy Group Since: </span>{{clientGroup.createdAt | date}}</p>
                            </div>
                        </div>
                        <div class="client-dairies" >
                            <div class="dairy" *ngFor="let dairy of clientGroup.Addresses; let i = index;">
                                <a href="javascript:void(0);" (click)="newGroup ? addressToGroup(dairy): setAsGroup(dairy)"><span class="dairy-no" [ngClass]="{'limbo-bg':dairy.toNewGroup}">{{i+1}}</span></a>
                                <div class="dairy-info">
                                    <div>{{dairy.alias}}</div>
                                    <span>{{dairy.street}}</span><br/>
                                    <span>{{dairy.city}}, {{dairy.state}} {{dairy.zip}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-8">
        <div class="new-group-card shadow">
            <div class="card-container">
                <div class="card-header">
                    <strong>Select A Dairy To Create a new Group</strong>
                </div>

                <div class="card-body">
                    <div class="client-group" *ngIf="newGroup">
                        <div class="client">
                            <div class="client-image shadow">
                                <div class="background-img" [style.background]="defaultImage"></div>
                            </div>
                            <div *ngIf="!newGroup.id" class="client-info ">
                                <div class="form-group">
                                    <label for="groupName">Group Name</label>
                                    <input type="text"
                                            id="groupName"
                                            #groupName="ngModel"
                                            name="groupName"
                                            [(ngModel)]="newGroup.name"
                                            class="form-control">
                                </div>
                            </div>
                            <div *ngIf="newGroup.id" class="client-info">
                                <h3>{{newGroup.name}}</h3>
                                <p><span>Dairy Group Since: </span>{{newGroup.createdAt | date}}</p>
                            </div>
                        </div>
                        <div class="client-dairies" >
                            <div class="dairy" *ngFor="let dairy of newGroup.Addresses; let i = index;">
                                <a href="javascript:void(0);" (click)="remove(dairy)"><span class="dairy-no" [ngClass]="{'limbo-bg':dairy.toNewGroup}">{{i+1}}</span></a>
                                <div class="dairy-info">
                                    <div>{{dairy.alias}}</div>
                                    <span>{{dairy.street}}</span><br/>
                                    <span>{{dairy.city}}, {{dairy.state}} {{dairy.zip}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer" *ngIf="newGroup">
                <button class="btn btn-danger" (click)="cancel()">Cancel</button>
                <button class="btn btn-success" (click)="create()" [disabled]="newGroup.id">Save</button>
            </div>
        </div>
    </div>
</div>
