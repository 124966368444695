import { Pipe, PipeTransform } from '@angular/core';
/*
 * Change the number value to a text string
 * Takes a number argument.
 * Usage:
 *   value | workOrderStatus
 * Example:
 *   {{ 1 |  workOrderStatus}}
 *   formats to text string 'Open'
 */
@Pipe({name: 'orderStatus'})
export class OrderStatus implements PipeTransform {
    transform(val: any) {
        switch(val) {
            case 2:
                val = 'Closed';
                break;
            case 1:
                val = 'Open';
                break;
            default:
                val = 'Insufficient Data';
        }
        return val;
    }
}

