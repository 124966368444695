import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {EmployeeService} from '../../services-module/services/employee.service';
import {EmployeeTypeService} from '../../services-module/services/employee-type.service';
import {Employee} from '../../models/employee';
import {EmployeeType} from '../../models/employee-type';
import {ToastService} from '../../services-module/services/toast.service';

@Component({
    templateUrl: './employee.component.html',
    styleUrls: ['employee.component.css']
})
export class EmployeeComponent implements OnInit{
    employees: Employee[];
    selectedEmployee: Employee;
    selectedEmployeeType: EmployeeType;
    employeeTypes: EmployeeType[];
    employeeType: EmployeeType;
    ftp:boolean = false;


    constructor(
        private employeeService: EmployeeService,
        private employeeTypeService: EmployeeTypeService,
        private toastService: ToastService
    ){}

    ngOnInit(): void{
        this.getEmployees();
        this.getEmployeeTypes();
        this.selectedEmployee = new Employee();
        this.selectedEmployeeType = new EmployeeType();
    }

    getEmployees(): void{
        this.employeeService.getEmployees()
            .then((response)=>{
                this.employees = response;
                this.employees.forEach((employee)=>{
                    this.getEmployeeTypeIds(employee);
                })
            });
    }

    getEmployeeTypeIds(employee: Employee){
        employee['role'] = employee.User ? employee.User.role: null;
        employee.employeeTypeIds = [];
        employee.EmployeeEmployeeTypes.forEach((type)=>{
            employee.employeeTypeIds.push(type['employeeTypeId']);
        });
    }

    getEmployeeTypes(): void{
        this.employeeTypeService.getEmployeeTypes()
            .then((response)=>{
                this.employeeTypes = response;
            });
    }

    setEmployee(employee: Employee){
        this.selectedEmployee = _.clone(employee);
    }

    resetEmployee(event: string) {
        this.selectedEmployee = new Employee();
    }

    saveEmployee(employee: Employee){
        this.ftp = true;
        this.employeeService.saveEmployee(employee)
            .then((res)=>{
                if (!res['error']) {
                    this.getEmployeeTypeIds(res);
                    let origEmployee = _.find(this.employees, {id: employee.id});
                    if(origEmployee){
                        let index = _.indexOf(this.employees, origEmployee);
                        this.employees[index] = res;
                    }else{
                        this.employees.push(res);
                    }
                    this.ftp = false;
                    this.selectedEmployee = new Employee();
                } else {
                    this.ftp = false;
                    this.toastService.toast({message: res['error']});
                }
            });
    }

    saveEmployeeType(employeeType: EmployeeType): void{
        this.ftp = true;
        this.employeeTypeService.saveEmployeeType(employeeType)
            .then((res)=>{
                let origEmployeeType = _.find(this.employeeTypes, {id: employeeType.id});
                if(origEmployeeType){
                    _.merge(origEmployeeType, res);
                }else{
                    this.employeeTypes.push(res);
                }
                this.selectedEmployeeType = new EmployeeType();
                this.ftp = false;
            })
    }

    setEmployeeType(employeeType: EmployeeType){
        this.selectedEmployeeType = _.clone(employeeType);
    }

    resetEmployeeType(event: string) {
        this.selectedEmployeeType = new EmployeeType();
    }

}
