<div class="panel data-panel">
    <div class="panel-header">
        <div class="flex-container flex-space-between">
            <h5><i class="fa fa-sticky-note"></i> Dairy Notes</h5>
        </div>
    </div>
    <div class="panel-content">
        <!--<div class="margin-top-10">-->
            <!--<div *ngIf="deliveryInstructions">-->
                <!--<h5>Delivery Instructions</h5>-->
                <!--<div class="note-content">-->
                    <!--{{ deliveryInstructions ? deliveryInstructions : "" }}-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
        <!--<div class="margin-top-10">-->
            <!--<h5>Dairy Notes</h5>-->
        <!--</div>-->
        <div *ngFor="let note of notes" class="margin-top-10">
            <div>
                <span class="user-name">{{ note.employee?.firstName}} {{ note.employee?.lastName}}</span>
                wrote on {{ note.createdAt | date }}:
            </div>
            <div class="note-content">
                <h5>{{note.notesType?.notesTypeName}}:</h5>
                <p>{{ note.content }}</p>
            </div>
        </div>
        <div *ngIf="notes.length === 0">
            No Notes
        </div>
    </div>
    <div class="panel-footer">
        <button class="btn btn-notes" (click)="showAll()">{{open ? 'Hide' : 'View'}} All Notes</button>
    </div>
</div>