import { Routes, RouterModule } from "@angular/router";

import {
  AuthGuard,
  DeactivateRouteview,
} from "../authorization/auth-guard.service";

import { RouteViewComponent } from "./route-view/route-view.component";
import { RouteListComponent } from "./route-list/route-list.component";
import { RouteSingleComponent } from "./route-single/route-single.component";
import { WorkRouteSingleComponent } from "./work-route-single/work-route-single.component";
import { TankResolve, ProductResolve } from "../resolvers/tank.resolver";
import { WorkOrderLocationsResolve } from "../resolvers/work-order.resolver";
import { DriversResolve } from "../resolvers/employee.resolver";
import {
  RouteResolve,
  ServiceRouteResolve,
  RouteRouteResolve,
  ServiceRegionResolve,
  RouteListResolve,
} from "../resolvers/route.resolver";
import { StatesResolve } from "../resolvers/states.resolver";

const ClientRoutes: Routes = [
  {
    path: "routes",
    component: RouteListComponent,
    canActivate: [AuthGuard],
    data: {
      view: "routes",
    },
    resolve: {
      routeList: RouteListResolve,
      drivers: DriversResolve,
      states: StatesResolve,
    },
  },
  {
    path: "work-order-routes",
    component: RouteListComponent,
    canActivate: [AuthGuard],
    data: {
      view: "work-order-routes",
      workOrder: true,
    },
    resolve: {
      drivers: DriversResolve,
      routeList: RouteListResolve,
    },
  },
  {
    path: "new-route",
    component: RouteViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [DeactivateRouteview],
    data: {
      view: "new-route",
    },
    resolve: {
      clientLocations: TankResolve,
      drivers: DriversResolve,
      products: ProductResolve,
      serviceRegions: ServiceRegionResolve,
    },
  },
  {
    path: "new-work-route",
    component: RouteViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [DeactivateRouteview],
    data: {
      view: "new-work-route",
      workOrder: true,
    },
    resolve: {
      clientLocations: WorkOrderLocationsResolve,
      drivers: DriversResolve,
      products: ProductResolve,
      serviceRegions: ServiceRegionResolve,
    },
  },
  {
    path: "edit-route/:id",
    component: RouteViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [DeactivateRouteview],
    data: {
      view: "edit-route",
    },
    resolve: {
      clientLocations: TankResolve,
      drivers: DriversResolve,
      route: RouteResolve,
      products: ProductResolve,
      routeParams: RouteRouteResolve,
      serviceRegions: ServiceRegionResolve,
    },
  },
  {
    path: "edit-work-route/:id",
    component: RouteViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [DeactivateRouteview],
    data: {
      view: "edit-work-route",
      workOrder: true,
    },
    resolve: {
      clientLocations: WorkOrderLocationsResolve,
      drivers: DriversResolve,
      route: ServiceRouteResolve,
      products: ProductResolve,
      routeParams: RouteRouteResolve,
      serviceRegions: ServiceRegionResolve,
    },
  },
  {
    path: "route-review/:id",
    component: RouteSingleComponent,
    canActivate: [AuthGuard],
    data: {
      view: "route-review",
    },
    resolve: {
      route: RouteResolve,
      drivers: DriversResolve,
    },
  },
  {
    path: "work-route-review/:id",
    component: WorkRouteSingleComponent,
    canActivate: [AuthGuard],
    data: {
      view: "work-route-review",
      workOrder: true,
    },
    resolve: {
      route: ServiceRouteResolve,
      drivers: DriversResolve,
    },
  },
];

export const ClientRoutesRouting = RouterModule.forChild(ClientRoutes);
