import { Injectable } from '@angular/core';
import {
  Subject,
  from as observableFrom
} from 'rxjs';
import { isArray as _isArray } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  events: any;
  listeners: any;
  eventsSubject: any;

  constructor() {
    this.listeners = {};
    this.eventsSubject = new Subject();

    this.events = observableFrom(this.eventsSubject);

    this.events.subscribe(({ name, args }) => {
      if (this.listeners[name]) {
        for (const listener of this.listeners[name]) {
          listener(...args);
        }
      }
    });
  }

  on(name: any, listener: any) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }
    this.listeners[name].push(listener);
  }

  broadcast(name, ...args) {
    if (_isArray(name)) {
      name.forEach(item => this.eventsSubject.next(item));
    } else {
      this.eventsSubject.next({ name, args });
    }
  }

}
