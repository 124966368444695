<div class="panel padding-15">
    <form action="" name="facilityForm" #facilityForm="ngForm">
        <div class="row">
            <div class="form-group col-md-7">
                <label for="street">Street</label>
                <input type="text"
                       id="street"
                       required
                       class="form-control"
                       [(ngModel)]="facility.street"
                       name="first-name">
            </div>
            <div class="form-group col-md-5">
                <label for="city">City</label>
                <div class="form-group">
                    <input type="text"
                           id="city"
                           required
                           name="city"
                           class="form-control"
                           [(ngModel)]="facility.city">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <label for='state'>State</label>
                <select name="state"
                        id="state"
                        class="form-control"
                        [(ngModel)]="facility.state">
                    <option value="" selected>State</option>
                    <option [value]="state.abbreviation"
                            *ngFor="let state of states">{{ state.name }}</option>
                </select>
            </div>
            <div class="form-group col-md-6">
                <div class="form-group">
                    <label for="zip">Zip</label>
                    <input type="text"
                           id="zip"
                           required
                           name="zip"
                           class="form-control"
                           [(ngModel)]="facility.zip">
                </div>
            </div>
        </div>
        <div class="margin-top-10">
            <div class="bg-success" *ngIf="saveSuccess">
                Facility saved.
            </div>
            <div class="bg-danger" *ngIf="saveFail">
                Facility save unsuccessful. Try again or contact system admin.
            </div>
        </div>
        <div class="margin-top-10 flex-align-end">
            <button *ngIf="facility.id && facility.isActive" (click)="facilityDelete.showPromptModal()" type="button" class="btn-danger btn">Inactive</button>
            <button *ngIf="facility.id && !facility.isActive" (click)="facilityDelete.showPromptModal()" type="button" class="btn-success btn">Restore</button>
            <button type="button" class="btn-warning btn " (click)="clearFacility()" [disabled]="!facilityForm.form.valid">Clear</button>
            <button
                [disabled]="!facilityForm.form.valid"
                class="btn btn-primary"
                (click)="save(facility)"
            >{{ facility.id ? 'Update' : 'Create'}}</button>
        </div>
    </form>
</div>

<prompt-modal
    #facilityDelete
    size="small"
>
        <div class="modal-header">
            <h4 class="modal-title">Set Facility {{facility.isActive ? 'Inactive' : 'Active'}}</h4>
        </div>

        <div class="modal-item">
            <div class="col-12">
                <p *ngIf="facility.isActive">Are you sure you want to set facility "{{facility.street}}" to inactive?</p>
                <p *ngIf="!facility.isActive">Would you like to restore facility "{{facility.street}}"?</p>
            </div>
        </div>

        <div class="modal-buttons">
            <button *ngIf="facility.isActive" (click)="facility.isActive = false;save(facility);facilityDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-danger">Inactive</button>
            <button *ngIf="!facility.isActive" (click)="facility.isActive = true;save(facility);facilityDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-success">Restore</button>
            <button type="button" class="btn btn-sm btn-light" (click)="facilityDelete.hidePromptModal()">Cancel</button>
        </div>

</prompt-modal>
