import { Component, Input, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpService } from '../../../shared-services/http-service/http-service.service';
import * as moment from 'moment';

@Component({
  selector: 'report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
  exportAs: 'reportModal'
})
export class ReportModalComponent implements OnInit {
  @ViewChild('reportModal', { static: true })
  public childModal: ModalDirective;
  @Output() optionsSaved = new EventEmitter();
  saveDisabled = false;
  saveResult: Object = null;
  opened = false;
  currentDateTime: string;
  options: any;
  regions: any = [];
  states: any = [];
  maxDate = new Date('2100-12-31');
  constructor(private http: HttpService) {
    // get current date/time to set min date on start datetime input
    this.currentDateTime = moment().toISOString().substring(0, 16);
  }

  async ngOnInit() {
    this.regions = await this.listAllRegions();
    this.fetchStates();
  }

  async fetchStates() {
    const resp = await this.http.post('address/get', {
      get: ['state'],
      distinct: 'state',
      sort: {
        state: 'ASC'
      }
    }).toPromise();
    this.states = resp.data || [];
    return this;
  }


  async listAllRegions() {
    const payload = {
      get: ['id', 'serviceRegionName'],
      where: {
        isActive: 1
      },
      group: ['serviceRegionName'],
      sort: {
        serviceRegionName: 'ASC'
      }
    };
    const resp: any = await this.http.post('serviceRegion/get', payload).toPromise();
    return resp.data || [];
  }

  public show(event?): void {
    this.opened = true;
    this.saveDisabled = false;
    this.options = event;
    this.childModal.show();
  }

  public hide(): void {
    setTimeout(() => {
      this.clearData();
      this.opened = false;
      this.options = null;
      this.childModal.hide();
    }, 200);
  }

  private clearData(): void {
    this.saveResult = null;
    this.saveDisabled = false;
  }

  private closeSuccessfully(res) {
    this.optionsSaved.emit(res);
    this.hide();
  }

  // form submit event handler
  save(event, options) {
    event.preventDefault();
    this.saveDisabled = true;
    this.closeSuccessfully(options);
  }

}



