<div>
    <div class="header">
        <h4><i class="fa fa-building"></i>Facilities</h4>
    </div>
    <div class="row">
        <div class="col-md-6">
            <facility-list [facilities]="facilities"
                           [selectedFacilityId]="facility.id"
                           (resetFacility)="clearFacility()"
                           (setFacility)="setFacility($event)"></facility-list>
        </div>
        <div class="col-md-6">
            <facility-single [facility]="facility"
                                [saveSuccess]="saveSuccess"
                                [saveFail]="saveFail"
                                [states]="states"
                                (resetFacility)="clearFacility()"
                                (saveFacility)="saveFacility($event)"></facility-single>
        </div>
    </div>
</div>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>

