import * as moment from 'moment';

export class Toast {
    public type?: string = 'danger';
    public message: string = '';
    public timeout?: number;

    constructor(toast:Toast) {
        Object.assign( this, toast);
        switch (true) {
            case this.timeout == 0:
                this.timeout = undefined;
                break;
            case this.timeout == undefined:
                this.timeout = 5000;
                break;
            default:
                break
        }
    }
}