<div class="panel margin-top-20">
    <div class="panel-content">
        <div class="flex-container flex-end" *ngIf="address.id">
            <button class="btn btn-warning"
                    *ngIf="!editMode"
                    (click)="editAddress()">Edit</button>
            <button class="btn btn-primary"
                    *ngIf="editMode"
                    (click)="save(address)">Save</button>
            <button class="btn btn-danger"
                    *ngIf="editMode"
                    (click)="cancelEdit()">Cancel</button>
        </div>
        <div class="row">
            <div class="col-md-2">
                <h5>Last Test Date</h5>
                <div class="form-group">{{lastLabDate()}}</div>
            </div>
            <div class="col-md-2">
                <h5>Last Service</h5>
                <a [routerLink]="'/work-orders/' + lastOrderId()"><div class="form-group">{{lastWorkOrdersDate()}}</div></a>
            </div>
            <div class="col-md-2">
                <h5>Service</h5>
                <div class="form-group">{{lastServiceType()}}</div>
            </div>
            <!--<div class="col-md-2">
                <h5>Pump</h5>
                <div class="form-group">Venturi</div>
            </div>-->
            <div class="col-md-2">
                <h5>Cows</h5>
                <div class="form-group" *ngIf="!editMode">{{address.cowCount}}</div>
                <div class="form-group" *ngIf="editMode">
                    <input type="number"
                           class="form-control"
                           id="cowCount"
                           name="cowCount"
                           [(ngModel)]="address.cowCount">
                </div>
            </div>
            <div class="col-md-2">
                <h5>Baths</h5>
                <div *ngIf="!editMode" class="form-group">{{address.bathCount}}</div>
                <div class="form-group" *ngIf="editMode">
                    <input type="number"
                           class="form-control"
                           id="bathCount"
                           name="bathCount"
                           [(ngModel)]="address.bathCount">
                </div>
            </div>
        </div>
        <!--<div class="row">-->
            <!--<div class="col-md-12">-->
                <!--<h5 *ngIf="editMode || address.deliveryInstructions">Delivery Instructions</h5>-->
                <!--<p *ngIf="!editMode && address.deliveryInstructions">{{ address.deliveryInstructions }}</p>-->
                <!--<div class="form-group" *ngIf="editMode">-->
                    <!--<input type="text"-->
                           <!--class="form-control"-->
                           <!--name="deliveryInstructions"-->
                           <!--id="deliveryInstructions" [(ngModel)]="address.deliveryInstructions">-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
    </div>
</div>