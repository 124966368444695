// core modules
import { NgModule } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { TextareaModalComponent } from './textarea-modal/textarea-modal.component';
import { FlexInputComponent } from './flex-input.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { TextareaComponent } from './textarea/textarea.component';
import { FocusDirective } from './directives/focus.directive';

// // bootstrap module

import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { HttpClientModule } from "@angular/common/http";
// import { ModalModule } from 'ngx-bootstrap';

// // components
// import { PromptModalComponent } from './prompt-modal/prompt-modal.component';
// import { DropdownComponent } from './dropdown/dropdown.component';
// import { ClickOutside } from '../directives/click-outside.directive';
// import { SettingsComponent } from './settings/settings.component';

// import { FlexInputComponent } from './flex-input/flex-input.component';
// import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
  imports: [
    DatepickerModule.forRoot(),
    // BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ColorPickerModule
  ],
  declarations: [
    TextareaModalComponent,
    FlexInputComponent,
    TextareaComponent,
    FocusDirective
  ],
  exports: [
    TextareaModalComponent,
    FlexInputComponent,
    TextareaComponent,
    FocusDirective
  ]
})
export class FlexInputModule { }
