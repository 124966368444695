import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "../authorization/auth-guard.service";
import { CurrentEmployeeResolve } from "@resolvers/employees.resolver";
import { DriversComponent } from "./drivers.component";
import {
  EmployeeActiveRoutesResolve,
  EmployeeActiveWorkRoutesResolve,
} from "../resolvers/route.resolver";

const driversRoutes: Routes = [
  {
    path: "driver/:id",
    component: DriversComponent,
    canActivate: [AuthGuard],
    data: {
      view: "driver",
    },
    resolve: {
      employee: CurrentEmployeeResolve,
      activeRoutes: EmployeeActiveRoutesResolve,
    },
  },
  {
    path: "work-order-driver/:id",
    component: DriversComponent,
    canActivate: [AuthGuard],
    data: {
      view: "work-order-driver",
      workOrder: true,
    },
    resolve: {
      employee: CurrentEmployeeResolve,
      activeRoutes: EmployeeActiveWorkRoutesResolve,
    },
  },
];

export const driversRouting = RouterModule.forChild(driversRoutes);
