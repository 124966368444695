<div id="dashboard">
    <div class="header">
        <div class="container-fluid">
            <h2><i class="fa fa-dashboard"></i> Dashboard</h2>
        </div>
    </div>
    <div [sortablejs]="employee.dashboardOrder" [sortablejsOptions]="sortableOptions">
        <div *ngFor="let item of employee.dashboardOrder">
            <div class="row" *ngIf="item === 1">
                <div class="col-sm-6 padding-top-20">
                    <recent-clients [clients]="clients"></recent-clients>
                    <div class="pagination shadow">
                      <pagination *ngIf="totalClients > itemsPerPage"
                                  [totalItems]="totalClients"
                                  [itemsPerPage]="itemsPerPage"
                                  [(ngModel)]="clientPage"
                                  (pageChanged)="pageChanged($event, 'clients')"
                                  [maxSize]="maxSize"
                                  [rotate]="rotate"
                                  previousText="&lsaquo;"
                                  nextText="&rsaquo;"
                                  firstText="&laquo;"
                                  lastText="&raquo;"
                                  [boundaryLinks]="true"></pagination>
                  </div>      
                </div>
                <div class="col-sm-6 padding-top-20">
                    <recent-routes [routes]="routes"></recent-routes>
                    <div class="pagination shadow">
                      <pagination *ngIf="totalRoutes > itemsPerPage"
                                  [totalItems]="totalRoutes"
                                  [itemsPerPage]="itemsPerPage"
                                  [(ngModel)]="routePage"
                                  (pageChanged)="pageChanged($event, 'routes')"
                                  [maxSize]="maxSize"
                                  [rotate]="rotate"
                                  previousText="&lsaquo;"
                                  nextText="&rsaquo;"
                                  firstText="&laquo;"
                                  lastText="&raquo;"
                                  [boundaryLinks]="true"></pagination>
                    </div>
                </div>
            </div>
            <div class="row padding-top-20 padding-bottom-20" *ngIf="item === 2">
                <div class="col-md-12">
                    <recent-work-orders [workOrders]="workOrders" [statusTypes]="statusTypes"></recent-work-orders>
                    <div class="pagination shadow">
                      <pagination *ngIf="totalOrders > itemsPerPage"
                                  [totalItems]="totalOrders"
                                  [itemsPerPage]="itemsPerPage"
                                  [(ngModel)]="orderPage"
                                  (pageChanged)="pageChanged($event, 'orders')"
                                  [maxSize]="maxSize"
                                  [rotate]="rotate"
                                  previousText="&lsaquo;"
                                  nextText="&rsaquo;"
                                  firstText="&laquo;"
                                  lastText="&raquo;"
                                  [boundaryLinks]="true"></pagination>
                    </div>
                </div>
            </div>
            <div class="row padding-top-20 padding-bottom-20" *ngIf="item === 3">
                <div class="col-md-12">
                    <recent-labs [labs]="labs"></recent-labs>
                    <div class="pagination shadow">
                      <pagination *ngIf="totalLabs > itemsPerPage"
                                  [totalItems]="totalLabs"
                                  [itemsPerPage]="itemsPerPage"
                                  [(ngModel)]="labPage"
                                  (pageChanged)="pageChanged($event, 'labs')"
                                  [maxSize]="maxSize"
                                  [rotate]="rotate"
                                  previousText="&lsaquo;"
                                  nextText="&rsaquo;"
                                  firstText="&laquo;"
                                  lastText="&raquo;"
                                  [boundaryLinks]="true"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
