<div class="panel padding-15" >
    <form action="" name="tractorForm" #tractorForm="ngForm">
        <div class="row">
            <div class="form-group col-md-4">
                <label for="tractor-number">Tractor Number</label>
                <input type="number"
                       id="tractor-number"
                       required
                       class="form-control"
                       [(ngModel)]="tractor.number"
                       name="tractor-number">
            </div>
        </div>
        <div class="margin-top-10 flex-align-end">
            <button *ngIf="tractor.id && tractor.isActive" (click)="tractorDelete.showPromptModal()" type="button" class="btn-danger btn">Inactive</button>
            <button *ngIf="tractor.id && !tractor.isActive" (click)="tractorDelete.showPromptModal()" type="button" class="btn-success btn">Restore</button>
            <button type="button" class="btn-warning btn" (click)="clearTractor()">Clear</button>
            <button class="btn btn-primary" [disabled]="!tractorForm.form.valid" (click)="save(tractor)">Save</button>
        </div>
    </form>
</div>

<prompt-modal #tractorDelete size="small">
    <div class="modal-header">
        <h4 class="modal-title">Set Tractor {{tractor.isActive ? 'Inactive' : 'Active'}}</h4>
    </div>

    <div class="modal-item">
        <div class="col-12">
            <p *ngIf="tractor.isActive">Are you sure you want to set tractor "{{tractor.number}}" to inactive?</p>
            <p *ngIf="!tractor.isActive">Would you like to restore tractor "{{tractor.number}}"?</p>
        </div>
    </div>

    <div class="modal-buttons">
        <button *ngIf="tractor.isActive" (click)="tractor.isActive = false;save(tractor);tractorDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-danger">Inactive</button>
        <button *ngIf="!tractor.isActive" (click)="tractor.isActive = true;save(tractor);tractorDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-success">Restore</button>
        <button type="button" class="btn btn-sm btn-light" (click)="tractorDelete.hidePromptModal()">Cancel</button>
    </div>
</prompt-modal>
