import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { environment } from '@environment';
import * as _ from 'lodash';
import { AuthService } from '../authorization/auth.service';
import { UserService } from '../services-module/services/user.service';
import {
  isArray as _isArray
} from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmComponent } from '@shared/confirm/confirm.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.css']
})

export class SidebarComponent implements OnInit, OnDestroy {
  public smallSide = true;
  constructor(
    public auth: AuthService,
    private router: Router,
    private userService: UserService,
    private modalService: BsModalService
  ) { }

  @Output()
  smallSidebar = new EventEmitter<boolean>();
  navTooltip = '';
  tooltipTop = '45px';
  visible = false;
  timeout: any;
  title = 'Specialty Sales LLC';
  isAdmin = this.auth.isAdmin();
  isSalesrepOutside = this.auth.isSalesrepOutside();
  driver = this.userService.getEmployeeId();
  labsParams = { sort: '-date' };
  version = environment.appVersion;
  appData = require('../../../ngsw-config.json').appData;
  versionModalRef: BsModalRef;
  employeeTypes = localStorage.getItem('employeeTypes') || '{}';
  destroyed$ = new Subject();

  ngOnInit() {
    this.smallSidebar.emit(true);
    this.router.events
    .pipe(takeUntil(this.destroyed$))
    .subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.employeeTypes = localStorage.getItem('employeeTypes');
      }
    });

  }

  setSmall() {
    this.smallSide = !this.smallSide;
    this.smallSidebar.emit(this.smallSide);
  }

  logout() {
    this.auth.logout();
  }

  setTooltip(text: string, e: HTMLElement) {
    this.navTooltip = text;
    this.visible = true;
    this.tooltipTop = e.offsetTop + 15 + 'px';
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.visible = false, 3000);
  }

  showVersion() {
    if (!this.versionModalRef) {
      const { version, notes } = this.appData as { version: string, notes: string[] },
      initialState = {
        confirmTitle: 'Latest Update Notes',
        itemList: _isArray(notes) ? notes : undefined,
        confirmMsg: `${version}:${_isArray(notes) ? '' : ' ' + notes} Latest update includes the following changes.`,
        ok: 'OK',
        cancel: '',
      };
      this.versionModalRef = this.modalService.show(ConfirmComponent, { initialState, class: 'confirm-modal' });
      this.modalService.onHidden
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => this.versionModalRef = undefined);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

/*
* Method to filter by employee role
* *ngIf="auth.employeeType(['Office','Lab-Tech','Sales Rep','Field Service']) || auth.isAdmin()"
 * */
