import { Component, OnInit, Input} from '@angular/core';
import { Router } from '@angular/router';
import { Route } from '../../models/route';

@Component({
    selector: 'recent-routes',
    templateUrl: './recent-routes.component.html',
    styleUrls: ['../dashboard.component.css']
})
export class RecentRoutesComponent {
     @Input()
     routes: Route[];
     constructor(){}
}
