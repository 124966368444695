import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from '@environment';
import { HttpService } from '../shared-services/http-service/http-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root'})
export class AuthService {
    private apiUrl = environment.apiUrl;  // URL to web api
    jwtHelper = new JwtHelperService();
    public loggedIn$ = new BehaviorSubject(!!localStorage['id_token']);


    constructor(
        private router: Router,
        private http: HttpClient,
        private titleService: Title,
        private httpService: HttpService
    ) {}
    private headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ngsw-bypass': 'true'
    });

    // login method sends user creds to api. If token returned then token and user saved to localStorage
    login(email: string, password: string) {
        return this.http.post(`${this.apiUrl}login`, JSON.stringify({ email, password }), { headers: this.headers }).pipe(
            map((response) => {
                if (response['resetPath'] || response['error']) {
                  return Promise.resolve(response);
                } else {
                    return this.httpService.login({ email, password }).then(() => {
                      return response;
                    });
                }
            }));
    }

    // This method will log the user out
    logout() {
        // To log out, just remove the token
        // from local storage
        localStorage.removeItem('id_token');
        localStorage.removeItem('employeeTypes');

        // norm logout
        this.httpService.logout();
        localStorage.removeItem('n_token');
        // Change Title to Logout
        this.titleService.setTitle('Specialty Sales - Logged Out');

        // Send the user back to the public deals page after logout
        this.router.navigateByUrl('/login');
        this.loggedIn$.next(false);
    }

    // this method will check to see if the user is logged in.
    // able to tell by checking to see if they have a token and whether that token is valid or not.
    loggedIn() {
        return !this.jwtHelper.isTokenExpired(localStorage.getItem('id_token'));
    }

    isAdmin() {
        const user = this.jwtHelper.decodeToken(localStorage.getItem('id_token'));
        if (user.role === 'Admin') {
            return true;
        } else return false;
    }

    isSalesrepOutside() {
      const user = this.jwtHelper.decodeToken(localStorage.getItem('id_token'));
      const employeeTypes = JSON.parse(localStorage.getItem('employeeTypes'));

      if (user.role === 'Limited' && employeeTypes['Sales Rep'] === 'Sales Rep' && user.Employee.salesRepType === 0) {
        return true;
      }
      return false;
    }

    employeeType(empTypes: string[]) {
        const types = JSON.parse(localStorage.getItem('employeeTypes'));
        if (!types) {
            localStorage.clear();
            location.reload();
            return false;
        }

        if (empTypes.map(t => types.hasOwnProperty(t)).indexOf(true) !== -1) {
            return true;
        } else return false;
    }

    saveNewPassword(creds: any): Promise<any>  {
        //    Will Save Password after receiving link with access key to update Password
        return this.http.post(`${this.apiUrl}user/reset-password`, creds, { headers: this.headers })
            .toPromise()
            .then((res) => {
                return res;
            });
    }

    requestNewPassword(creds: any): Promise<any> {
        return this.http.post(`${this.apiUrl}user/forgot-password`, creds, { headers: this.headers })
            .toPromise();
    }
}
