import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { EmployeeService } from '../services-module/services/employee.service';

import { Employee } from '../models/employee';
import { UserService } from '../services-module/services/user.service';

@Injectable()
export class EmployeesResolve implements Resolve<Employee[]> {
    constructor(private employeeService: EmployeeService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.employeeService.getEmployees();
    }
}

@Injectable()
export class CurrentEmployeeResolve implements Resolve<any> {
    constructor(private employeeService: EmployeeService,
        private userService: UserService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const employeeId = this.userService.getEmployeeId();
        return this.employeeService.getEmployee(employeeId);
    }
}

@Injectable()
export class SalesRepsResolve implements Resolve<Employee[]> {
    constructor(private employeeService: EmployeeService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.employeeService.getEmployeesByType('Sales Rep');
    }
}

@Injectable()
export class LabTechResolve implements Resolve<Employee[]> {
    constructor(private employeeService: EmployeeService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.employeeService.getEmployeesByType('Lab-Tech');
    }
}
