import * as _ from 'lodash';

export class FileUploadModel {
  constructor(data?: any) {
    _.merge(this, data);
  }
  public alt: string = null;
  public name: string = null;
  public base64: string = null;
  public id: number = null;
  public clientId: number = null;
  public title: string = null;
  public addressId: number = null;
  public uploadedById: number = null;
  public description: string = null;
  public documentTypeId: number = null;
  public data: string = null;
  public type: string = null;
  public newfile: File;

}
