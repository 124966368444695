import * as _ from 'lodash';

export const SALES_REP_TYPES = {
  null: 'Outside',
  0: 'Outside',
  1: 'Inside'
};

export class Employee {
  constructor(data?: any) {
    _.merge(this, data);
  }
  public firstName: string = null;
  public lastName: string = null;
  public email: string = null;
  public id: number = null;
  public EmployeeEmployeeTypes: any[] = [];
  public employeeTypes: number[] = [];
  public employeeTypeIds: number[] = [];
  public salesRepType = 0;
  public salesRepRegions = [1];
  public User: any = null;
  public isActive = true;
  public landingUrl: string = null;
  public dashboardOrder = [1, 2, 3];
  public state: string;
  public role: string;
  public updatedAt: string;
  public workOrderEmails: boolean;
}
