import { Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import{Location} from '@angular/common';
import {Trailer} from '../../../models/trailer';
import {Tractor} from '../../../models/tractor';
import {Facility} from '../../../models/facility';
import {Employee} from "../../../models/employee";
import { RouteSheetsService} from '../../route-sheets.service';
import {RouteService} from '../../../services-module/services/route.service';
import {AuthService} from '../../../authorization/auth.service';

import {Route} from '../../../models/route';
import {Tank} from '../../../models/tank';
import {UntypedFormGroup} from "@angular/forms";
import {Toast} from "../../../models/toast";
import {ToastService} from "../../../services-module/services/toast.service";
import { Product } from "../../../models/product";

@Component({
    selector: 'route-details',
    templateUrl: 'route-details.component.html',
    styleUrls: ['route-details.component.css']
})
export class RouteDetailsComponent implements OnInit{
    @Input()
    route: Route;
    @Input()
    tractors: Tractor[];
    @Input()
    trailers: Trailer[];
    @Input()
    drivers: Employee[];
    @Input()
    totalGallons: Product[];
    @Input()
    facilities: Facility[];
    @Input()
    copying: boolean;
    @Input()
    saving: boolean;
    @Output()
    optimizeRoute = new EventEmitter();
    @Output()
    saveRoute = new EventEmitter();
    @Output()
    copyRoute = new EventEmitter();
    @Output()
    cancelCopyRoute = new EventEmitter();
    @Output()
    mapRoute = new EventEmitter();
    @Output()
    reverseOrder = new EventEmitter();
    @Output()
    reloadRoute = new EventEmitter();
    @Input() setManual:boolean;
    @Input() workOrder:boolean;
    @Output() setManualOrder = new EventEmitter();

    isAdmin = this.auth.isAdmin();
    noValue:any = null;
    driverOptions: any = [];

    constructor(private sheetsService: RouteSheetsService,
                private toastService: ToastService,
                private routeService: RouteService,
                private auth:AuthService,
                public location:Location
                ){}

    ngOnInit(){
        this.drivers.forEach((driver)=>{
            let item = {
                id: driver.id,
                name: `${driver.lastName}, ${driver.firstName}`
            };
            this.driverOptions.push(item);
        });
    }
    setManOrder(route:Route){
        this.setManualOrder.emit(route);
    }

    save(route:Route, form:UntypedFormGroup): void{
        if(form.valid){
            this.saveRoute.emit(route);
        }else {
            let errMsg:string[] = [];
            Object.keys(form.controls).map(key => {
                let errors = form.controls[key]['errors'];
                if (errors) {
                    // using form input name in error message
                    errMsg.push(key);
                }
            });
            if (errMsg.length > 1) {
                errMsg[errMsg.length - 1] = 'and ' + errMsg[errMsg.length - 1];
                this.toastService.toast({message:`${errMsg.join(', ')} are required.`});
            } else if (errMsg.length > 0) {
                this.toastService.toast({message:`${errMsg.join(', ')} is required.`});
            }
        }
    }


    copy(route:Route): void {
        this.copyRoute.emit(route);
    }

    cancelCopy(): void {
        this.cancelCopyRoute.emit('cancel-copy');
    }

    renderPdf():void{
        this.routeService.findRouteById(this.route.id).then((route)=>{
            this.sheetsService.buildLoadSheet(route);
        })
    }

    hasChanges() {
        this.routeService.hasChanges = true;
    }

    reload() {
        this.reloadRoute.emit(true);
    }

    printOrders() {
        const element = document.getElementById("work-order-print");
        const map = document.getElementById("route-map");
        element.classList.add("show-on-print");
        map.classList.add("no-print");
        window.print();
    }
}
