<div class="panel padding-15 employee-edit-panel">
    <form action="" name="employeeForm" #employeeTypeForm="ngForm">
        <div class="row">
            <div class="form-group col-md-6">
                <label for="typeName">Type Name</label>
                <input type="text"
                       id="typeName"
                       class="form-control"
                       placeholder="Type Name"
                       [(ngModel)]="employeeType.employeeType"
                       required
                       name="first-name">
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="checkbox-inline">
                        <input class="form-check-input" type="checkbox" name="active" id="active" [(ngModel)]="employeeType.isActive" [value]="true"> Employee is active
                    </label>
                </div>
            </div>
        </div>
        <div class="margin-top-10 flex-align-end">
            <button type="button" class="btn-warning btn" (click)="clearEmployeeType()">Clear</button>
            <button class="btn btn-primary" [disabled]="!employeeTypeForm.form.valid" (click)="save(employeeType)">Save</button>
        </div>
    </form>
</div>