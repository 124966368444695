import { Component, Input, Output, EventEmitter} from '@angular/core';

import {EmployeeType} from '../../../models/employee-type';

@Component({
    templateUrl: 'employee-type-single.html',
    styleUrls: ['employee-type-single.css'],
    selector: 'employee-type-single'
})
export class EmployeeTypeSingleComponent{
    @Input()
    employeeType: EmployeeType;
    @Output()
    resetEmployeeType = new EventEmitter<string>();
    @Output()
    saveEmployeeType = new EventEmitter<EmployeeType>();
    constructor(){}

    clearEmployeeType(){
        this.resetEmployeeType.emit('reset');
    }

    save(employeeType: EmployeeType){
        this.saveEmployeeType.emit(employeeType);
    }


}