import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Lab } from '@models/lab';
import { LabService } from '@services/lab.service';

@Injectable({ providedIn: 'root' })
export class FindLabsResolve implements Resolve<{ rows: Lab[], count: number }> {
  constructor(private labs: LabService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.labs.findLabs(route.queryParams);
  }
}
