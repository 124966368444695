import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { TankService } from '@services/tank.service';

import { Tank } from '../models/tank';
import { ProductService } from '@services/product.service';
import { Product } from '@models/product';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class TankResolve implements Resolve<Tank[]> {
  constructor(private tankService: TankService) { }

  resolve(route: ActivatedRouteSnapshot) {
    const defaultPrefs: any = {
      cities: null,
      county: null,
      dateOffset: 0,
      maxCriticalPercent: null,
      productId: null,
      selectedRegions: [],
      states: [],
      zip: null
    };
    let query: any;

    if (route.routeConfig.path.includes('edit-route')) {
      return this.tankService.getTanksForDelivery(defaultPrefs);
    } else if (localStorage.getItem('routePrefs')) {
      query = _.merge(defaultPrefs, JSON.parse(localStorage.getItem('routePrefs')));
      query.maxCriticalPercent = query.maxCriticalPercent / 100;
      return this.tankService.getTanksForDelivery(query);
    } else {
      defaultPrefs.maxCriticalPercent = .5;
      return this.tankService.getTanksForDelivery(defaultPrefs);
    }
  }
}


@Injectable()
export class ProductResolve implements Resolve<Product[]> {
  constructor(private productService: ProductService) { }

  resolve() {
    return this.productService.getProducts();
  }

}
