<div class="row grid-container min-100">
    <div class="container-fluid min-100">
        <div class="min-100" style="padding-top:60px">
            <ngx-datatable #mydatatable
                           class='material striped expandable server-scrolling-demo'
                           style="height: 100%"
                           [rows]="clientResults['rows']"
                           [rowHeight]="rowHeight"
                           [columnMode]="'force'"
                           [headerHeight]="headerHeight"
                           [footerHeight]="50"
                           [externalPaging]="true"
                           [virtualization]="false"
                           [scrollbarV]="true"
                           [count]="itemsDataSource"
                           [offset]="currentPage"
                           [limit]="itemsPerPage"
                           (page)="pageChanged($event)"
                           (sort)="onSort($event)">

                <ngx-datatable-column [width]="50"
                                      [resizeable]="false"
                                      [sortable]="false"
                                      [draggable]="false"
                                      [canAutoResize]="false">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                        <a href="javascript:void(0)"
                            [class.icon-right]="!expanded"
                            [class.icon-down]="expanded"
                            class="expander-btn"
                            title="Expand/Collapse Row"
                            (click)="toggleExpandRow(row)"></a>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [width]="50"
                                      [resizeable]="false"
                                      [sortable]="false"
                                      [draggable]="false"
                                      [canAutoResize]="false">
                    <ng-template let-row="row" let-rowIndex="rowId" ngx-datatable-cell-template>{{ rowIndex }}</ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Name">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <div style="cursor: pointer" (click)="gotoDetail(row)">{{value}}</div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Updated At">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <div style="cursor: pointer" (click)="gotoDetail(row)">{{value | date}}</div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-row-detail [rowHeight]="125" class="list-detail" #myDetailRow>
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                        <h4><em>Delivery Addresses</em></h4>
                        <div class="col-md-4 list-detail-addresses" *ngFor="let address of row.deliveryAddresses">
                            <div>{{address.street}},</div>
                            <div>{{address.city}}, {{address.state}} {{address.zip}} </div>
                      </div>
                      <div class="no-address" *ngIf="!row.deliveryAddresses"><em>No Locations to Display</em></div>
                    </ng-template>
                </ngx-datatable-row-detail>
            </ngx-datatable>
        </div>
    </div>
</div>
