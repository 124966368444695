import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPipe } from './search.pipe';
import { ClientSearchPipe } from './client-search.pipe';
import { OrderStatus } from './work-order-status.pipe';
import { FilterNestedPipe } from './filter.pipe';
import { DateSortingPipe } from './date-sort.pipe';
import { DairyFilterPipe } from './route-dairy.pipe';
import { FilterRoutesPipe } from './filter-routes.pipe';
import { ActivePipe } from './active.pipe';
import { FilterByListPipe } from './filter-by-list.pipe';
import { DateFormatterPipe } from './date-formatter.pipe';
import { EmployeeTypesPipe } from './employee-types.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ActivePipe,
    SearchPipe,
    ClientSearchPipe,
    EmployeeTypesPipe,
    OrderStatus,
    FilterNestedPipe,
    DateSortingPipe,
    DairyFilterPipe,
    FilterRoutesPipe,
    FilterByListPipe,
    DateFormatterPipe
  ],
  exports: [
    ActivePipe,
    SearchPipe,
    ClientSearchPipe,
    EmployeeTypesPipe,
    OrderStatus,
    FilterNestedPipe,
    DateSortingPipe,
    DairyFilterPipe,
    FilterRoutesPipe,
    FilterByListPipe,
    DateFormatterPipe
  ],
  providers: []
})

export class PipesModule { }
