import {
    Pipe,
    PipeTransform
  }                               from '@angular/core';
  import * as moment              from 'moment';

  @Pipe({name : 'dateFormatter'})
  export class DateFormatterPipe implements PipeTransform {
      public transform(date: string, format = 'll') {
        return moment.parseZone(date).format(format);
      }
  }