import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../authorization/auth.service';
import {UserService} from '../services-module/services/user.service';
import { EmployeeService } from '../services-module/services/employee.service';

@Component({
    selector: 'login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css']
})

export class LoginComponent {

    login = true;
    messages = {message: null, open: false};

    constructor(
        private auth: AuthService,
        private router: Router,
        private employeeService: EmployeeService,
        private userService: UserService
    ) {
        if (this.auth.loggedIn()) {
           this.sendToPage();
        }
    }

    onSubmit(creds: any) {
        this.auth.login(creds.email, creds.password).subscribe((result) => {
          result.then((res: JSON) => {
            if (res['resetPath']) {
                this.messages['message'] = 'There was an issue with your login.  Please check your email for a reset-password link. ' +
                    'If no link is present please contact your administrator immediately.';
                this.messages['open'] = true;
            } else if (res['error'] && !res['resetPath']) {
                this.messages['message'] = 'There was an issue with your email or password. Please try again. If the problem persists ' +
                    'please contact your administrator.';
                this.messages['open'] = true;
            } else {
                const employeeObject: {} = {};
                res['employee']['EmployeeEmployeeTypes'].forEach((o: any) => {
                    employeeObject[o.EmployeeType.employeeType] = o.EmployeeType.employeeType;
                });

                localStorage.setItem('employeeTypes', JSON.stringify(employeeObject));
                localStorage.setItem('id_token', res['token']);
                this.auth.loggedIn$.next(true);
                this.sendToPage();
            }
          });
        });
    }
    returned() {
        this.login = true;
    }

    forgotPass() {
        this.login = false;
    }
    cancelForgot() {
        this.login = true;
    }
    sendToPage() {
      this.employeeService.getLanding().then(landingUrl => {
        if (landingUrl) {
          this.router.navigateByUrl(landingUrl);
        } else {
          if (this.auth.isSalesrepOutside()) {
            return this.router.navigateByUrl('/clients');
          }
          this.router.navigateByUrl('/dashboard');
        }
      });
    }
}
