import * as _ from 'lodash';
import {Tank} from './tank';
import { Product } from './product';
import { Route } from './route';

export class DeliveryOrder {
    id: number = null;
    scheduledDeliveryDate: Date = null;
    delivered = false;
    deliveredDate: Date = null;
    quantityOrdered = 0;
    quantityDropped = 0;
    invoiced = false;
    note: string = null;
    isActive = true;
    tankLevelPostDrop: number = null;
    paraInches: string = null;
    enteredBy: string = null;
    clientId: number = null;
    productId: number = null;
    routeId: number = null;
    tankId: number = null;
    Tank: Tank = null;
    tank: Tank = null;
    dropOrder: number = null;
    deliveryNumber: string = null;
    createdAt: Date = null;
    addressId: number = null;
    Product: Product;
    saved = false;
    route: Route;

    constructor(data?: any) {
        _.merge(this, data);
    }
}
