import { Component, Input, Output, EventEmitter} from '@angular/core';

import {Facility} from '../../../../models/facility';

@Component({
    templateUrl: 'facility-single.component.html',
    styleUrls: ['facility-single.component.css'],
    selector: 'facility-single'
})
export class FacilitySingleComponent{
    @Input()
    facility: Facility;
    @Input()
    saveSuccess: boolean = false;
    @Input()
    saveFail: boolean = false;
    @Input()
    states: any[];
    @Output()
    resetFacility = new EventEmitter<string>();
    @Output()
    saveFacility = new EventEmitter<Facility>();
    constructor(){}

    clearFacility(){
        this.resetFacility.emit('reset');
    }

    save(facility: Facility){
        this.saveFacility.emit(facility);
    }


}