<div class="header">
    <h4><i class="fa fa-vcard"></i> Inactive Dairies</h4>
</div>
<div class="clients-container">
    <input placeholder="Search" [(ngModel)]="search" class="form-control search-input">
    <div class="client-list-panel">
        <table class="table table-hover table-responsive">
            <thead>
            <tr>
                <th class="td-20">Group Name</th>
                <th class="td-20">Dairy Name</th>
                <th class="td-30">Address</th>
                <th class="td-15">Is Active</th>
                <th class="td-15"></th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let address of addresses | searchPipe:'Client.name,alias':search">
                    <td class="td-20">{{ address?.Client.name }}</td>
                    <td class="td-20">{{ address?.alias }}</td>
                    <td class="td-30">{{ address?.street }} {{address?.city}} {{address?.state}} {{address?.zip}} </td>
                    <td class="td-15">
                        <button class="btn btn-sm"
                                [ngClass]="{'btn-success': address.isActive, 'btn-danger': !address.isActive}"
                                (click)="changeAddressStatus(address)">
                            {{ address?.isActive ? ' Yes': 'No' }}
                        </button>
                    </td>
                    <td class="td-15">
                        <button class="btn btn-sm btn-danger"
                                (click)="delete.showPromptModal(deleteAddress(address))">
                            Delete
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<app-prompt-modal-helper
  #delete header="Delete Dairy"
  title="Are you sure you want to delete this dairy? This cannot be undone."
  confirm="Delete">
</app-prompt-modal-helper>

<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>

