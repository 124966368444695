import { NgModule } from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule } from '@angular/forms';

import {S3MapService} from './s3-map.service';

import {S3RouteMapComponent} from './s3-route-map/s3-route-map.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule],
    exports: [
        S3RouteMapComponent
    ],
    providers:[
        S3MapService
    ],
    declarations:[S3RouteMapComponent]
})
export class S3MapModule{}