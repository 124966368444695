import {Component, Input, Output, ViewChild, EventEmitter} from '@angular/core';
import {Tank} from '../../models/tank';
import {DeliveryOrder} from '../../models/delivery-order';
import {ToastService} from '../../services-module/services/toast.service';
import {PromptModalComponent} from '../../shared-components/prompt-modal/prompt-modal.component';
import * as _ from 'lodash';

@Component({
    templateUrl: './drops-panel.component.html',
    styleUrls: ['./drops-panel.component.css'],
    selector: 'drops-panel'
})
export class DropsPanelComponent{
    @Input()
    drops: any[];
    @Input()
    tanks: Tank[];
    @Output() saveOverride = new EventEmitter<any>();
    @Output() hidePanel = new EventEmitter<void>();
    @Output() deleteOverride = new EventEmitter<DeliveryOrder>();
    @ViewChild('overrideModal', { static: true }) private overrideModal:PromptModalComponent;
    drop: any;
    overrideDate: Date = new Date();
    currentOverride: DeliveryOrder;
    editDate:boolean = false;
    filter:string = '';

    constructor(private toastService:ToastService){}

    hide(): void{
        this.hidePanel.emit();
    }

    setFilter(value:string) {
        this.filter = value;
    }

    filteredDrops() {
        return _.filter(this.drops, (drop) => {
            if (!this.filter) return true
            return drop.Tank.Product.abbr == this.filter
        });
    }

    setEdit(drop:DeliveryOrder) {
        drop['tempDate'] = drop.deliveredDate;
        drop['editing'] = true;
        drop['clone'] = _.cloneDeep(drop);
    }

    setOverride(order:DeliveryOrder) {
        this.editDate = true;
        this.currentOverride = order;
        this.overrideDate = this.currentOverride['tempDate'];
        this.overrideModal.showPromptModal();
    }

    setOverrideDate() {
        this.editDate = false;
        this.currentOverride['tempDate'] = this.overrideDate;
        this.overrideModal.hidePromptModal();
    }

    override(order:DeliveryOrder, index:number ) {
        let error:string[] = [];
        if (order.quantityOrdered === null) error.push('Gallons Ordered');
        if (order.quantityDropped === null) error.push('Gallons Dropped');
        if (order.tankLevelPostDrop === null) error.push('Tank Level');

        // get the list of orders
        let filterDrops = this.drops;

        // sort the data by the updatedAt field
        filterDrops.sort((o) => { return o.updatedAt });

        // get all matching tanks id for the current order being updated at
        filterDrops = filterDrops.filter((o) => {
            if(o['Tank'].id === order['Tank'].id) {
                return o;
            }
        });
        // last order will always be 1 because index 0 is the current order being updated
        const lastOrderDroped = filterDrops.length > 1 ? filterDrops[1] : null;

        if (error.length == 0) {
            this.saveOverride.emit({order, index});
        } else {
            if (error.length > 1) {
                error[error.length - 1] = 'and ' + error[error.length - 1];
                this.toastService.toast({message:`${error.join(', ')} are required.`});
            } else if (error.length > 0) {
                this.toastService.toast({message:`${error.join(', ')} is required.`});
            }
        }
    }

    cancelOverride(drop:DeliveryOrder) {
        if (!drop.id) {
            const index = this.drops.map(o => o.newId).indexOf(drop['newId']);
            this.drops.splice(index, 1);
        } else {
           Object.assign(drop, drop['clone']);
           delete drop['clone'];
           delete drop['tempDate'];
           delete drop['editing'];
        }
    }

    setTank(id:number, index:number) {
        const tank = _.find(this.tanks, [ 'id', +id ] )
        this.drops[index].Tank = tank;
        this.drops[index].tankLevelPostDrop = tank.currentGallons;
    }

    newOverride() {
        if (this.tanks.length > 0) {
            this.filter = '';
            const newOrder = new DeliveryOrder({quantityOrdered:0, quantityDropped:0, Tank: this.tanks[0], tankLevelPostDrop: this.tanks[0].currentGallons});
            newOrder.deliveredDate = new Date();
            newOrder['tempDate'] = newOrder.deliveredDate;
            newOrder['editing'] = true;
            newOrder['newId'] = new Date().valueOf() * Math.random();
            this.drops.unshift(newOrder);
        }
    }

    editMode() {
        return this.drops.map(d => d.editing).indexOf(true) !== -1;
    }
}
