import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterComponent implements OnInit {
  @Input()
  tables: any[] = [];
  @Input()
  selectedTable: any;

  private leftOperators: Array<any>;
  private validOperators: Array<any>;
  item: any = {};
  inputType: string = 'text';

  constructor() { }

  ngOnInit() {
    this.leftOperators = [
      'AND',
      'OR',
      'NOT'
    ];
    this.validOperators = [
      '=',
      '>',
      '<',
      '!=',
      '<=',
      '>=',
      'BETWEEN',
      'IS NOT',
      'IS',
      'IN',
      'LIKE',
      'STARTS WITH'
    ];
  }

  getTableIndex(tableName) {
    return this.tables?.findIndex((item) => { return item['table_name'] == tableName; });
  }

  getLeftOperator(cond) {
    const operator = Object.keys(cond)[0];
    return operator;
  };

  getRightOperator(cond) {
    const operator = cond[Object.keys(cond)[0]][1];
    return operator;
  }

  setOperator(position, tableName, cond, newOperator, index) {
    let tableIndex = this.getTableIndex(tableName);
    let condition = {};
    let leftOperator = this.getLeftOperator(cond);
    if (position == 'left') {
      condition[newOperator] = cond[leftOperator];
    } else if (position == 'right') {
      condition[leftOperator] = [];
      condition[leftOperator][0] = cond[leftOperator][0];
      condition[leftOperator][1] = newOperator;
      condition[leftOperator][2] = cond[leftOperator][2];
    }
    this.tables[tableIndex]['conditions'][index] = condition;
  }

  removeConditional(tableName, condIndex) {
    this.tables[this.getTableIndex(tableName)]['conditions'].splice(condIndex, 1);
    if (!this.tables[this.getTableIndex(tableName)]['conditions'].length) {
      delete this.tables[this.getTableIndex(tableName)]['conditions'];
    }
  }

  changeTypeaheadLoading(e) {

  }

  changeTypeaheadNoResults(e) {

  }

  typeaheadOnSelect(e) {
  }

  getInputType(i) {
    const cond = this.selectedTable.conditions[i];
    if (cond[Object.keys(cond)[0]][0]) {
      const column = this.selectedTable.columns.find(x => x.column_name == cond[Object.keys(cond)[0]][0]);
      if (column) {
        const dataType = column.data_type.toLowerCase();
        if (dataType == 'integer' || dataType == 'string' || dataType == 'boolean' || dataType == 'character varying') {
          return 'text';
        } else if (dataType == 'timestamp without time zone' || dataType.includes('timestamp')) {
          return 'date';
        }
      } else return 'text';
    } else return 'text';
  }
}
