<div class="panel">
    <table class="table table-hover">
        <thead>
            <tr>
                <td>Street</td>
                <td>City</td>
                <td>State</td>
                <td><button
                #dButton="bs-tooltip"
                (click)="active = !active;clearFacility();dButton.hide()"
                type="button"
                class="btn btn-sm btn-light pull-right"
                [tooltip]="active ? 'Show inactive facilities' : 'Show active facilities'"
                placement="left"
            >{{active ? 'Inactive' : 'Active'}}</button>Zip </td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let facility of activeFacilities()" (click)="selectFacility(facility)" [ngClass]="{'selected':isSelected(facility)}">
                <td>{{ facility.street }}</td>
                <td>{{ facility.city  }}</td>
                <td>{{ facility.state }}</td>
                <td>{{ facility.zip }}</td>
            </tr>
        </tbody>
    </table>
</div>