import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Employee } from '../../../models/employee';
import { ActivatedRoute } from "@angular/router";
import { VaporTest } from "../../../models/vapor-test";
import { VaporTestService } from "../../../services-module/services/vapor-test.service";
import { PromptModalComponent } from '../../../shared-components/prompt-modal/prompt-modal.component';
import { ToastService } from '../../../services-module/services/toast.service';
import { Address } from "../../../models/address";
import { UserService } from "../../../services-module/services/user.service";

@Component({
    selector: 'address-vapor-test',
    templateUrl: './address-vapor-test.component.html',
    styleUrls: ['./address-vapor-test.component.css']
})
export class AddressVaporTestComponent implements OnInit {

    @Input() addressId: number = null;
    @Input() address: Address;
    @ViewChild('datePicker', { static: true }) private datePicker: PromptModalComponent;
    employees: Employee[];
    vaporTest: VaporTest;
    @Input() vaporTests: VaporTest[];
    @Output() getVapTests = new EventEmitter<any>();
    showTests: boolean = false;
    testForm: boolean = false;
    deleteTest: boolean = false;
    dateType: string;
    ftp: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private vaporTestService: VaporTestService,
        private toastService: ToastService
    ) { }

    ngOnInit() {
        this.vaporTest = new VaporTest();
        this.employees = this.activatedRoute.snapshot.data['employees'];
    }

    displayTests(): void {
        if (!this.showTests) {
            this.getVaporTests();
            this.showTests = true;
        } else {
            this.showTests = false;
        }
    }

    getVaporTests() {
        this.ftp = false;
        this.getVapTests.emit(this.addressId);
    }

    openTestForm(test: VaporTest) {
        if (test) {
            this.ftp = true;
            return this.vaporTestService.getSingleTest(test.id)
                .then((t) => {
                    this.vaporTest = t;
                    this.testForm = true;
                    this.ftp = false;
                });
        } else {
            this.vaporTest = new VaporTest();
            this.testForm = true;
        }
    }

    closeTestForm() {
        this.vaporTest = new VaporTest();
        this.testForm = false;
    }

    clear() {
        this.vaporTest = new VaporTest();
    }

    setEditDate(dateType: string) {
        this.dateType = dateType;
        this.datePicker.showPromptModal();
    }

    resetDate() {
        this.dateType = '';
    }

    saveTest(vaporTest: VaporTest, form: UntypedFormGroup) {
        this.dateType = '';

        if (vaporTest.longTermTestDate == null && vaporTest.shortTermTestDate == null || !vaporTest.technicianId) {
            this.toastService.toast({ message: 'You must input at least one Long Term Test Date or Short Term Test Date', type: 'danger' });
        } else {

            this.testForm = false;
            vaporTest.shortTermTestDate ? vaporTest.shortTermTestDate = moment(vaporTest.shortTermTestDate).set('hour', 8).toDate() : vaporTest.shortTermTestDate;
            vaporTest.longTermTestDate ? vaporTest.longTermTestDate = moment(vaporTest.longTermTestDate).set('hour', 8).toDate() : vaporTest.longTermTestDate;
            this.ftp = true;

            if (vaporTest.id) {
                vaporTest.updatedById = this.userService.getEmployeeId();
                this.vaporTestService.updateVaporTest(vaporTest)
                    .then(res => {
                        this.vaporTest = new VaporTest();
                        this.getVaporTests();
                    });
            } else {

                vaporTest.createdById = this.userService.getEmployeeId();
                vaporTest['mailReq'] = this.buildVaporEmail();
                vaporTest.addressId = this.addressId;
                let employee = _.find(this.employees, { 'id': +vaporTest.technicianId });
                vaporTest['mailReq']['technician'] = `${employee.firstName} ${employee.lastName}`;

                this.vaporTestService.createVaporTest(vaporTest)
                    .then(res => {
                        this.vaporTest = new VaporTest();
                        this.getVaporTests();
                    });
            }

        }
    }



    deleteVaporTest(vaporTestId: number) {
        this.ftp = true;
        this.vaporTestService.deleteVaporTest(vaporTestId)
            .then(res => {
                this.getVaporTests();
                this.deleteTest = false;
                this.closeTestForm();
            })
    }

    showTechnician(technicianId: string) {
        let employee = _.find(this.employees, { 'id': parseInt(technicianId) });
        if (employee) {
            return employee.firstName + ' ' + employee.lastName;
        } else {
            return '--';
        }
    }

    buildVaporEmail() {
        let mailReq = {
            dairyAlias: `${this.address['alias']}`,
            dairyAddress: `${this.address['street']}, ${this.address['city']} ${this.address['state']} ${this.address['zip']}`
        };
        let fromEmployee = _.find(this.employees, { 'id': this.userService.getEmployeeId() });
        mailReq['employee'] = `${fromEmployee.firstName} ${fromEmployee.lastName}`;

        return mailReq;
    }
}
