import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PromptModalComponent } from "../prompt-modal/prompt-modal.component";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit {
  @Input() date: Date;
  @Output() dateChange = new EventEmitter<Date>();
  @ViewChild('modal', { static: true }) private modal:PromptModalComponent;
  @Input() title: string;

  constructor() {}

  ngOnInit() {}

  onChange(event: Date) {
    this.dateChange.emit(event);
    this.modal.hidePromptModal();
  }
}
