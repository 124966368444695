import * as _ from 'lodash';

export class ServiceRegion{
    constructor(data?:any){
        _.merge(this, data);
    }

    public id: number = null;
    public serviceRegionName: string = null;
    public serviceRegionAbbr: string = null;
    public state: string = null;
    public isActive: number = 1;
}