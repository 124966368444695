import {Injectable} from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';


import {ServiceTypeService} from "../services-module/services/service-types.service";
import {ServiceType} from "../models/service-type";

@Injectable()
export class ServiceTypeResolve implements Resolve<ServiceType[]>{
    constructor (private serviceTypeService: ServiceTypeService){}

    resolve(route: ActivatedRouteSnapshot){
        return this.serviceTypeService.getAll();
    }
}