import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PromptModalComponent} from '../../shared-components/prompt-modal/prompt-modal.component';

import { Router, ActivatedRoute } from '@angular/router';

import * as moment from 'moment';
import * as _ from 'lodash';

import {Route} from '../../models/route';
import {RouteService} from '../../services-module/services/route.service';
import {WorkOrder} from "../../models/work-order";
import {Employee} from "../../models/employee";
import {AuthService} from "../../authorization/auth.service";
import { ToastService } from '../../services-module/services/toast.service';
import {UserService} from "../../services-module/services/user.service";
import {WorkOrderService} from "../../services-module/services/work-order.service";
import {UtilsService} from "../../services-module/services/utils.service";

@Component({
    selector: 'work-route-single',
    templateUrl: './work-route-single.component.html',
    styleUrls:['./work-route-single.component.css']
})
export class WorkRouteSingleComponent implements OnInit{
    @Input() route: Route;
    drivers: Employee[];
    driverOptions: any = [];
    order:WorkOrder;
    _order:WorkOrder; //order to be used for removing from route
    ftp:boolean = false;
    driverView = false;

    @ViewChild('cancelDelivery') private cancelDelivery:PromptModalComponent;
    @ViewChild('removeOrderModal', { static: true }) private removeOrderModal:PromptModalComponent;

    constructor(
        private activatedRoute: ActivatedRoute,
        private auth: AuthService,
        private userService:UserService,
        private router:Router,
        private routeService: RouteService,
        private toastService: ToastService,
        private workOrderService: WorkOrderService,
        private utilsService: UtilsService
    ){}

    ngOnInit(){
        if (this.route) {
            this.driverView = true;
            this.initializeRoute(this.route);
            this.drivers = [];
        } else {
            this.initializeRoute(this.activatedRoute.snapshot.data['route']);
            this.drivers = this.activatedRoute.snapshot.data['drivers'];
        }
        this.driverOptions = [];
        this._order = new WorkOrder();
        this.drivers.forEach((driver)=>{
            let item = {
                id: driver.id,
                name: `${driver.lastName}, ${driver.firstName}`
            };
            this.driverOptions.push(item);
        });
    }

    openMap(order: WorkOrder):void{
        this.utilsService.openMap(order.Address);
    }

    cancel() {
        this.order.completionDate = null;
        this.cancelDelivery.hidePromptModal();
    }

    getDriverIds(route: Route):void{
        if (!this.driverView) {
            route.EmployeeRoutes.forEach((empRoute: any) => {
                route.driverIds.push(empRoute.employeeId);
            });
        }
    }

    updateRoute():void{
        if (!this.driverView) {
            this.routeService.updateRouteDrivers(this.route)
                .then((res)=>{
                    this.toastService.toast({message: `You have updated route drivers`, type:'success'});
                    this.route.EmployeeRoutes = res.EmployeeRoutes;
                });
        }
    }

    closeRoute(){
        this.ftp = true;
        this.route['completedAt'] = moment().toDate();
        this.routeService.updateRoute(this.route).then(()=>{
            this.ftp = false;
        });
    }

    openRoute(){
        this.route['completedAt'] = null;
        this.routeService.updateRoute(this.route)
            .then(()=>{
                this.ftp = false;
                this.toastService.toast({message: 'Route Successfully Opened!', type: 'success'});
            })
    }

    activeStatus(completed:any, scheduled:any){
        return this.utilsService.activeStatus(completed, scheduled);
    }

    setOrderToRemove(order:WorkOrder){
        this._order = order;
        this.removeOrderModal.showPromptModal();
    }

    initializeRoute(route: Route) {
        this.route = route;
        this.route.workOrders = _.orderBy(this.route.workOrders, ['dropOrder'], ['asc']);
        this.route['driverIds'] = [];
        this.getDriverIds(this.route);
    }

    removeFromRoute(order: WorkOrder){
        this.ftp = true;
        order.routeId = null;

        this.workOrderService.updateWorkOrder(order).then(()=>{
            this.routeService.findServiceRouteById(this.route.id)
                .then((res)=>{
                    this.initializeRoute(res);
                    this.ftp = false;
                });
        });
    }

    woStatus(val: any){
        return this.utilsService.woStatus(val);
    }

    print() {
        const element = document.getElementById("work-order-print");
        element.classList.add("show-on-print");
        window.print();
    }
}
