import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { WorkOrderService } from '../../../services-module/services/work-order.service';
import { WorkOrder } from '../../../models/work-order';
import {Client} from "../../../models/client";
import {Employee} from "../../../models/employee";
import {ServiceType} from "../../../models/service-type";

@Component({
    selector: 'edit-work-order',
    templateUrl: 'edit-work-order.component.html',
    styleUrls:['edit-work-order.component.css']
})

export class EditWorkOrder implements OnInit{

    @Input() ogWorkOrder: WorkOrder;
    @Input() client: Client;
    @Input() deliveryAddresses: any[];
    @Input() employees: Employee[];
    @Input() serviceTypes: ServiceType[];
    @Output() updateWorkOrder = new EventEmitter<WorkOrder>();
    @Output() cancelEdit = new EventEmitter();

    workOrder: WorkOrder = new WorkOrder();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private workOrderService: WorkOrderService){
    }

    ngOnInit(): void{
        this.workOrder = new WorkOrder(this.ogWorkOrder);
    }

    update(workOrder: WorkOrder){
        this.updateWorkOrder.emit(workOrder);
    }
    cancel(){
        this.workOrder = this.ogWorkOrder;
        this.cancelEdit.emit();
    }
}