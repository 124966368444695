import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { WorkOrder } from '@models/work-order';
import { UserService } from './user.service';
import { environment } from '@environment';
import { Employee } from '@models/employee';
import { EmployeeService } from './employee.service';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Injectable()
export class WorkOrderService {
    private apiUrl = environment.apiUrl;  // URL to web api
    employees: Employee[];

    constructor(public authHttp: HttpClient, public userService: UserService, private employeeService: EmployeeService) {
        this.employeeService.getEmployees().then(employees => {
            this.employees = employees;
        });
    }

    getWorkOrders(): Promise<WorkOrder[]> {
        return this.authHttp.get(`${this.apiUrl}work-order`)
            .toPromise()
            .then((response) => {
                return response as WorkOrder[];
            })
            .catch(this.handleError);
    }
    getAddressWorkOrders(addressId: number): Promise<WorkOrder[]> {
        return this.authHttp.get(`${this.apiUrl}work-order/getAddressWorkOrders/${addressId}`)
            .toPromise()
            .then((response) => {
                return response as WorkOrder[];
            })
            .catch(this.handleError);
    }

    getSingleWorkOrder(workOrderId: number): Promise<WorkOrder> {
        return this.authHttp.get(`${this.apiUrl}work-order/${workOrderId}`)
            .toPromise()
            .then((response) => {
                return response as WorkOrder;
            })
            .catch(this.handleError);
    }

    getUnscheduledWorkOrders(): Promise<WorkOrder[]> {
        return this.authHttp.post(`${this.apiUrl}work-order/getUnscheduledWorkOrders`, {})
            .toPromise()
            .then((response) => {
                return response as WorkOrder[];
            })
            .catch(this.handleError);
    }

    getOpenWorkOrderLocations(req: any): Promise<WorkOrder[]> {
        return this.authHttp.post(`${this.apiUrl}work-order/getOpenWorkOrderLocations`, req)
            .toPromise()
            .then((response) => {
                return response as WorkOrder[];
            })
            .catch(this.handleError);
    }

    // new route to hit
    getWorkOrdersPagination(params: any): Promise<WorkOrder[]> {

        const queryParams = {
            page: params.page ? +params.page : undefined,
            pageSize: params.pageSize ? +params.pageSize : undefined,
            startDate: params.startDate ? moment(params.startDate).toDate() : moment().subtract(1, 'months').toDate(),
            endDate: params.endDate ? moment(params.endDate).toDate() : moment().add(1, 'days').toDate(),
            selectedOrderType: params.selectedOrderType ? params.selectedOrderType : 0,
            selectedServiceType: params.selectedServiceType ? params.selectedServiceType : [],
            selectedSearchBy: params.selectedSearchBy ? params.selectedSearchBy : "",
            searchStringValue: params.searchStringValue ? params.searchStringValue : "",
            openAll: params.openAll ? params.openAll : 'less',
            sort: params.sort ? params.sort : undefined,
            employeeId: params.employeeId ? params.employeeId : this.userService.getEmployeeId()
        };

        return this.authHttp.post(`${this.apiUrl}work-order/get-work-orders`, queryParams)
            .toPromise()
            .then((response) => {
                return response as WorkOrder[];
            })
            .catch(this.handleError);
    }

    buildWorkOrderEmail(order: WorkOrder) {
        const empId = +order.employeeToId;
        const toEmployee = _.find(this.employees, {'id': empId});
        const fromEmployee = _.find(this.employees, {'id': this.userService.getEmployeeId()});
        const dairyAlias = `${order.Address['alias']}`;
        const dairyAddress = `${
            order.Address['street'] || ''
          }, ${
            order.Address['city'] || ''
          } ${
            order.Address['state'] || ''
          } ${
            order.Address['zip'] || ''
          }`;

        const mailOptions = {
            to: toEmployee ? toEmployee.email : '',
            dairyAlias: dairyAlias,
            dairyAddress: dairyAddress,
            text: order.note ? order.note : '',
            toEmployee: toEmployee ? toEmployee.firstName : '',
            fromEmployee: fromEmployee ? fromEmployee.firstName + ' ' + fromEmployee.lastName : ''
        };

        (order['emailEmployees'] || []).forEach((e: any) => {
            const employee = _.find(this.employees, {id: e});
            mailOptions.to = `${mailOptions.to}, ${employee.email}`;
        });

        order['mailOrder'] = mailOptions;
    }

    changeStatus(order: WorkOrder) {
        if (order.workOrderStatusId === 1) {
            order.completionDate = new Date();
            order.workOrderStatusId = 2;
            order['updatedBy'] = this.userService.getEmployeeId();
            order['closedBy'] = this.userService.getEmployeeId();
            this.buildWorkOrderEmail(order);

        } else {
            order.completionDate = null;
            order.workOrderStatusId = 1;
            order['updatedBy'] = this.userService.getEmployeeId();
            order['closedBy'] = null;
            this.buildWorkOrderEmail(order);

        }
        return this.saveWorkOrder(order);
    }

    saveWorkOrder(workOrder: WorkOrder): Promise<WorkOrder> {
        if (workOrder.id) {
            return this.updateWorkOrder(workOrder);
        } else {
            return this.createWorkOrder(workOrder);
        }
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    createWorkOrder(workOrder: WorkOrder): Promise<WorkOrder> {
        return this.authHttp.post(`${this.apiUrl}work-order`, workOrder)
            .toPromise()
            .then((res) => {
                return res as WorkOrder;
            })
            .catch(this.handleError);
    }
    updateWorkOrder(workOrder: WorkOrder) {
        return this.authHttp.put(`${this.apiUrl}work-order/${workOrder.id}`, workOrder)
            .toPromise()
            .then((res) => {
                return res as WorkOrder;
            })
            .catch(this.handleError);
    }

    deleteWorkOrder(workOrder: any) {
        return this.authHttp.delete(`${this.apiUrl}work-order/${workOrder.id}`, workOrder)
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

}
