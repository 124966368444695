// profile.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { UserService } from './user.service';

import * as _ from 'lodash';

import { WorkOrderDetail } from '../../models/work-order-detail';
import { environment } from '../../../environments/environment';
import { WorkOrderService } from "./work-order.service";
import { WorkOrder } from "../../models/work-order";

@Injectable()
export class WorkOrderDetailService {
    private apiUrl = `${environment.apiUrl}work-order-details`;  // URL to web api

    constructor(private workOrderService: WorkOrderService, private authHttp: HttpClient, private userService: UserService) { }

    saveNote(workOrder: WorkOrder, note: WorkOrderDetail): Promise<WorkOrderDetail> {
        if (!note.id) {
            return this.createNote(workOrder, note);
        } else {
            return this.updateNote(workOrder, note);
        }
    }

    createNote(workOrder: WorkOrder, note: WorkOrderDetail): Promise<WorkOrderDetail> {
        this.workOrderService.buildWorkOrderEmail(workOrder);
        note.mailOrder = workOrder.mailOrder;
        return this.authHttp.post(this.apiUrl, note)
            .toPromise()
            .then(res => {
                return res as WorkOrderDetail;
            });
    }

    updateNote(workOrder: WorkOrder, note: WorkOrderDetail): Promise<WorkOrderDetail> {
        this.workOrderService.buildWorkOrderEmail(workOrder);
        note.mailOrder = workOrder.mailOrder;
        return this.authHttp.put(`${this.apiUrl}/${note.id}`, note)
            .toPromise()
            .then(res => {
                return res as WorkOrderDetail;
            });
    }

    deleteNote(note: any): Promise<WorkOrderDetail | ArrayBuffer> {
        return this.authHttp.delete(`${this.apiUrl}/${note.id}`, note)
            .toPromise()
            .then(res => {
                return res;
            });
    }

}
