import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {ClientContact} from "../../../models/contact";
import {PhoneNumber} from "../../../models/phone";
import {ClientService} from '../../../services-module/services/client.service';

@Component({
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.css'],
    selector: 'contact-info'
})
export class ClientContactInfoComponent implements OnInit{
    @Input() contact: ClientContact;
    @Output() editContact = new EventEmitter();
    editNumber = false;
    selectedPhoneId: PhoneNumber;
    phone: Object = {};
    ngOnInit(){
        this.contact = new ClientContact(this.contact);
        if(this.contact.PhoneNumbers.length > 0) {
            this.selectedPhoneId = this.contact.PhoneNumbers[0].id;
        } else {
            this.selectedPhoneId = null
        }
    }
    constructor(private clientService: ClientService){}

    edit(){
        this.editContact.emit();
    }

    editPhone(id?: string):void{
        if(id){
            this.phone = new PhoneNumber(_.find(this.contact.PhoneNumbers, {id: _.parseInt(id)}));
        }else{
            this.phone = new PhoneNumber();
        }
        this.editNumber = !this.editNumber;
    }

    savePhone(phone: PhoneNumber):void{
        phone.entityId = this.contact.id;
        this.clientService.savePhoneNumber(phone)
            .then(num=>{
                if(phone.id){
                    _.merge(_.find(this.contact.PhoneNumbers, {id: phone.id}), num);
                }else{
                    this.contact.PhoneNumbers.push(num);
                }
                this.editNumber = !this.editNumber;
            });
    }

    clearPhone():void{
        this.phone = new PhoneNumber();
        this.editNumber = false;
    }



}