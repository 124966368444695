import { Component, Output, Input, EventEmitter } from '@angular/core';

import { Client } from '../../client';
import { FileUploadModel } from "@models/file-upload";
import { UserService } from '@services/user.service';
import { FileUploadService } from '@services/file-upload.service';
import { Router } from "@angular/router";
import * as _ from 'lodash';

@Component({
  templateUrl: './client-details.component.html',
  selector: 'client-details',
  styleUrls: ['../client-single.component.css']
})
export class ClientDetailsComponent {
  @Input()
  client: Client;
  @Input()
  clientsDairies: Client[];
  @Input()
  clients: Client[];
  @Input()
  currentIndex: any;
  @Output()
  toggleEditClient = new EventEmitter<string>();
  editPic: boolean = false;
  image: Object = { defaultLogo: "../../../../assets/images/clear_client_logo.png", data: null };
  searchClis: boolean = false;
  search: any;
  showClients: boolean = false;

  constructor(private userService: UserService,
    private router: Router,
    private fileUploadService: FileUploadService) { }


  handleFile(file: any) {
    this.editPic = true;
    let reader = new FileReader();
    reader.onload = (e) => {
      this.image['data'] = e.target['result'];
    };
    let coded = reader.readAsDataURL(file.target.files[0]);
  }
  uploadFile() {
    let data = {
      clientId: this.client.id,
      uploadedById: this.userService.getEmployeeId(),
      documentTypeId: 1,
      data: this.image['data'].split(',')[1],
      type: this.image['data'].split(',')[0]
    };
    this.fileUploadService.uploadFile(new FileUploadModel(data))
      .then((res) => {
        this.client.image = [];
        this.client.image.push({ filePath: res[1]['url'] });
        this.editPic = false;
      });
  }

  newDairy(event: Event) {
    this.router.navigateByUrl(`/clients/${event['id']}`);
    this.searchClis = false;
  }

  goToNewView(type: string, clientId: any, dairyId: any) {
    clientId = +clientId;

    if (type == 'dairy') {
      dairyId = +dairyId;
      this.router.navigateByUrl(`/clients/${clientId}/address/${dairyId}`);
    } else {
      this.router.navigateByUrl(`/clients/${clientId}`);
    }
    this.searchClis = false;
    this.search = null;
  }

  changeClient(str: string) {
    if (str == 'next') {
      //find the client in the clients array
      const nextCli = this.clients[this.currentIndex + 1];
      // Check if this there is a next client
      if (nextCli?.id) {
        // navigate to the next client in the list
        this.router.navigateByUrl(`/clients/${nextCli.id}`);
      }
    } else {
      //find the previous client
      const prevCli = this.clients[this.currentIndex - 1];
      //check if there is a previous client
      if (prevCli?.id) {
        //navigate to the previous client
        this.router.navigateByUrl(`/clients/${prevCli.id}`);
      }
    }
  }

  setTerm(event: Object) {
    this.search = event['value'];
  }
  closeThings(event: Event) {
    setTimeout(() => {
      this.showClients = false;
    }, 100);
  }

  allowSearch() {
    this.searchClis = !this.searchClis;
  }

  cancelProfPic() {
    this.image['data'] = null;
    this.editPic = false;
  }


  editClient(event: any): void {
    this.toggleEditClient.emit('edit');
  }
}