import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { WorkOrderService } from '@services/work-order.service';
import { WorkOrder } from '@models/work-order';
import { ClientService } from '@services/client.service';
import { EmployeeService } from '@services/employee.service';
import { Employee } from '@models/employee';
import { ServiceType } from '@models/service-type';
import { UserService } from '@services/user.service';
import { WorkOrderDetail } from '@models/work-order-detail';
import { WorkOrderDetailService } from '@services/work-order-detail.service';
import { AddressService } from '@services/address.service';
import * as _ from 'lodash';
import { AuthService } from '../../authorization/auth.service';
import { ToastService } from '@services/toast.service';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'single-work-order',
  templateUrl: 'single-work-order.component.html',
  styleUrls: ['single-work-order.component.css']
})

export class SingleWorkOrderComponent implements OnInit {

  workOrder: WorkOrder;
  workOrderDetail: WorkOrderDetail;
  deliveryAddresses: any[];
  employees: Employee[];
  serviceTypes: ServiceType[];
  editable: Boolean = false;
  addable: Boolean = false;
  askConfirm: Boolean = false;
  askConfirmNote: Boolean = false;
  orderForUpdate: WorkOrder;
  recordForDelete: WorkOrderDetail;
  ftp = false;
  isAdmin = this.auth.isAdmin();

  constructor(
    private router: Router,
    private auth: AuthService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private workOrderService: WorkOrderService,
    private clientsService: ClientService,
    private workOrderDetailService: WorkOrderDetailService,
    private userService: UserService,
    private addressService: AddressService,
    private employeeService: EmployeeService,
    private toastService: ToastService) { }

  ngOnInit() {
    this.serviceTypes = this.route.snapshot.data['serviceTypes'];
    this.employees = this.route.snapshot.data['employees'];

    this.route.params.forEach((params: Params) => {
      const id = +params['id']; // (+) converts string 'id' to a number
      this.workOrderService.getSingleWorkOrder(id)
        .then(workOrder => {
          this.workOrder = workOrder;
          this.setEmps(workOrder);
          this.addressService.getDeliveryAddresses(workOrder.clientId)
            .subscribe(addresses => {
              this.deliveryAddresses = addresses;
            });
        });
    });
    this.orderForUpdate = new WorkOrder();
    this.recordForDelete = new WorkOrderDetail();
  }

  setEmps(workOrder: WorkOrder) {
    const data = {
      'ServiceType': _.find(this.serviceTypes, { 'id': workOrder.serviceTypeId }),
      'createdBy': _.find(this.employees, { 'id': workOrder.employeeId }),
      'assignedTo': _.find(this.employees, { 'id': workOrder.employeeToId }),
      'closedByEmployee': _.find(this.employees, { 'id': workOrder['closedBy'] }) ?
        _.find(this.employees, { 'id': workOrder['closedBy'] }) : null,
      'updatedByEmployee': _.find(this.employees, { 'id': workOrder['updatedBy'] })
    };
    this.workOrder = _.merge(this.workOrder, data);
  }

  editingWorkOrder(order: WorkOrder) {
    this.ftp = true;
    this.workOrderService.buildWorkOrderEmail(order);
    order['updatedBy'] = this.userService.getEmployeeId();
    order.employeeToId = +order.employeeToId;
    this.workOrderService.updateWorkOrder(order)
      .then(workOrder => {
        this.toastService.toast({ message: 'Work order successfully updated.', type: 'success' });
        _.merge(this.workOrder, workOrder);
        this.editable = false;
        this.setEmps(workOrder);
        this.ftp = false;
      });
  }

  setEmployee(employeeId: number) {
    const employee = _.find(this.employees, { 'id': employeeId });
    return employee.firstName + ' ' + employee.lastName;
  }

  editWorkOrder(): void {
    this.editable = true;
    this.scrollToElement(250, 'editTarget');
  }
  addNote(): void {
    this.addable = true;
    this.scrollToElement(250, 'addNoteTarget');
  }

  createNote(note: WorkOrderDetail) {
    this.ftp = true;
    note.workOrderId = this.workOrder.id;
    note.clientId = this.workOrder.Address.Client.id;
    note.employeeId = this.userService.getEmployeeId();
    this.workOrderDetailService.createNote(this.workOrder, note)
      .then(res => {
        this.workOrder.WorkOrderDetails.unshift(res);
        this.addable = false;
        this.ftp = false;
      });
  }

  cancelAdding(): void {
    this.addable = false;
  }

  deleteWorkOrder(workOrder: WorkOrder) {
    this.workOrderService.deleteWorkOrder(workOrder)
      .then(() => {
        this.router.navigate(['/work-orders']);
      });
  }

  updateStatus(workOrder: WorkOrder) {
    if (workOrder.workOrderStatusId === 2) {
      this.toastService.toast({ message: 'Work order successfully closed.', type: 'success' });
      this.workOrder.workOrderStatusId = workOrder.workOrderStatusId;
      this.setEmps(workOrder);
    } else {
      this.toastService.toast({ message: 'Work order has been re-opened.', type: 'warning' });
      this.workOrder.workOrderStatusId = workOrder.workOrderStatusId;
      this.workOrder.completionDate = workOrder.completionDate;
      this.setEmps(workOrder);
    }
  }

  deleteNote(note: WorkOrderDetail) {
    this.ftp = true;
    this.workOrderDetailService.deleteNote(note)
      .then(() => {
        _.remove(this.workOrder.WorkOrderDetails, _.find(this.workOrder.WorkOrderDetails, { 'id': note.id }));
        this.ftp = false;
      });
  }

  cancelEdit(): void {
    this.editable = false;
  }
  confirmDelete(): void {
    this.askConfirm = true;
  }
  cancelConfirm(): void {
    this.askConfirm = false;
  }
  confirmNote(): void {
    this.askConfirmNote = true;
  }
  cancelConfirmNote(): void {
    this.askConfirmNote = false;
  }

  woStatus(val: any) {
    return this.utilsService.woStatus(val);
  }

  scrollToElement(duration: number, eleId: string): void {
    if (duration <= 0) return;
    const element = document.body;
    const to = document.getElementById(eleId).offsetTop;
    const difference = to - element.scrollTop;
    const perTick = difference / duration * 10;
    setTimeout(() => {
      window.scroll(0, window.scrollY + perTick);
      if (window.scrollY === to) return;
      this.scrollToElement(duration - 10, eleId);
    }, 10);
  }

  setForUpdate(order: WorkOrder) {
    this.orderForUpdate = order;
  }
  removeFromUpdate() {
    this.orderForUpdate = new WorkOrder();
  }
  setRecordForDelete(record: WorkOrderDetail) {
    this.recordForDelete = record;
  }
  removeRecordFromDelete() {
    this.recordForDelete = new WorkOrderDetail();
  }

}
