import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { ClientService } from '@services/client.service';
import { Client } from '@models/client';
import { FileUploadService } from '@services/file-upload.service';
import { UserService } from '@services/user.service';
import { ClientPagination } from '@models/client-pagination';
import { AddressService } from '@services/address.service';
import { forkJoin } from 'rxjs';
import { Address } from '@models/address';
import { User } from '@models/user';

@Injectable({ providedIn: 'root' })
export class ClientSingleResolve implements Resolve<{
  client: Client,
  reps: User[],
  deliveryAddresses: Address[],
  mailingAddresses: Address[]
}> {
  constructor(
    private clientsService: ClientService,
    private addressService: AddressService
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return forkJoin({
      client: this.clientsService.getClient(route.params.id),
      reps: this.clientsService.getClientReps(route.params.id),
      deliveryAddresses: this.addressService.getDeliveryAddresses(route.params.id),
      mailingAddresses: this.addressService.getMailingAddresses((route.params.id))
    });
  }
}

@Injectable({ providedIn: 'root' })
export class ClientsResolve implements Resolve<Client[]> {
  constructor(private clientsService: ClientService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.clientsService.getClients();
  }
}

@Injectable({ providedIn: 'root' })
export class ClientsSalesLeadsResolve implements Resolve<Client[]> {
  constructor(private clientsService: ClientService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.clientsService.getSalesLeads();
  }
}


@Injectable({ providedIn: 'root' })
export class ClientsSalesLeadsPaginationResolve implements Resolve<ClientPagination> {
  constructor(private clientsService: ClientService) { }

  resolve(route: ActivatedRouteSnapshot) {
    // passing in default values to the server for pagination
    const defaultValues = {
      page: 1,
      itemsPerPage: 75,
      search: '',
      isIndex: false,
      sort: 'asc',
      prop: 'name'
    };
    return this.clientsService.getSalesLeadsPaginationService(defaultValues);
  }
}


@Injectable({ providedIn: 'root' })
export class ClientsDairiesResolve implements Resolve<Client[]> {
  constructor(private clientsService: ClientService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.clientsService.getClientsDairies();
  }
}


@Injectable({ providedIn: 'root' })
export class ClientRepsResolve implements Resolve<any[]> {
  constructor(private clientsService: ClientService,
    private userService: UserService) { }

  resolve(route: ActivatedRouteSnapshot) {

    const clientId = +route.params['id'];
    return this.clientsService.getClientReps(clientId);
  }
}

@Injectable({ providedIn: 'root' })
export class DocumentsTypeResolve implements Resolve<any[]> {
  constructor(private fileUploadService: FileUploadService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.fileUploadService.getDocumentTypes();
  }
}


@Injectable({ providedIn: 'root' })
export class GetClientsResolve implements Resolve<Client[]> {
  constructor(private clientsService: ClientService, private userService: UserService) { }

  resolve(route: ActivatedRouteSnapshot) {
    const employeeId = this.userService.getEmployeeId();
    const typeId = this.userService.getEmployeeTypeId();

    return this.clientsService.getEmployeeClients(typeId, employeeId);
  }
}

@Injectable({ providedIn: 'root' })
export class GetClientsPaginationResolve implements Resolve<ClientPagination> {
  constructor(private clientsService: ClientService, private userService: UserService) { }

  resolve(route: ActivatedRouteSnapshot) {
    const employeeId = this.userService.getEmployeeId();
    const typeId = this.userService.getEmployeeTypeId();

    const defaultValues = {
      page: 1,
      itemsPerPage: 75,
      search: '',
      isIndex: false,
      sort: 'asc',
      prop: 'name'
    };

    return this.clientsService.getEmployeeClientsPaginationService(typeId, employeeId, defaultValues);
  }
}
