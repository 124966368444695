<form class="form">
    <div class="form-group">
        <input type="text"
               name="name"
               id="name"
               class="form-control"
               placeholder="Name"
               [(ngModel)]="_contact.name">
    </div>
    <div class="form-group">
        <input type="text"
               name="title"
               id="title"
               class="form-control"
               placeholder="Title"
               [(ngModel)]="_contact.description">
    </div>
    <div class="form-group">
        <input type="text"
               name="email"
               id="email"
               class="form-control"
               placeholder="Email"
               [(ngModel)]="_contact.email">
    </div>
    <div class="full-width flex-container">
        <div class="btn btn-primary" (click)="save(_contact)">Save</div>
        <div class="btn btn-danger" (click)="cancel()">Cancel</div>
    </div>
</form>