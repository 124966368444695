import {Injectable} from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import {WorkOrderService} from '../services-module/services/work-order.service';

import {WorkOrder} from '../models/work-order';
import * as moment from 'moment';

@Injectable()
export class UnscheduledWOResolve implements Resolve<WorkOrder[]>{
    constructor (private workOrderService: WorkOrderService){}

    resolve(route: ActivatedRouteSnapshot){
        return this.workOrderService.getUnscheduledWorkOrders();
    }
}

// new resolve to hit for pagination
@Injectable()
export class WorkOrdersResolve implements Resolve<WorkOrder[]> {
    constructor(private workOrderService: WorkOrderService) {}

    resolve(route: ActivatedRouteSnapshot) {
        return this.workOrderService.getWorkOrdersPagination(route.queryParams);
    }
}