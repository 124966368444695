import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Tank } from '@models/tank';
import { AuthService } from './../../../authorization/auth.service';

@Component({
  templateUrl: './address-tanks.component.html',
  styleUrls: ['../address.component.css', './address-tanks.component.css'],
  selector: 'address-tanks'
})
export class AddressTanksComponent {
  @Input() tanks: any[];
  @Input() open: boolean;
  @Output() showTanksPanel = new EventEmitter<void>();
  isSalesrepOutside = this.auth.isSalesrepOutside();

  public barChartOptions: any = {
    scaleShowVerticalLines: true,
    responsive: true,
    tooltips: {
      enabled: false
    },
    scales: {
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true,
          max: 100
        }
      }],
      datasets: {
        barThickness: 50
      }
    }
  };
  public barChartType = 'bar';
  public barChartLegend = false;


  constructor(
    public auth: AuthService,
  ) { }

  showAll(): void {
    this.showTanksPanel.emit();
  }

  chartClicked(e: Event) {
    console.log(e); // why is this being console.log here?
  }
}
