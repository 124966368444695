<div bsModal #reportModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myMediumModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Report Parameters</h4>
                <button type="button" class="close pull-right" (click)="hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="opened">
                <form action=""
                      *ngIf="opened"
                      (ngSubmit)="save($event, options)"
                      #reportForm="ngForm">
                    <div class="row">
                        <div class="firstContainer col-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="eventType">Select Region</label>
                                        <select class="form-control"
                                                id="region"
                                                name="region"
                                                #prop="ngModel"
                                                [(ngModel)]="options.serviceRegionID">
                                            <option selected disabled>Select a Property</option>
                                            <option *ngFor="let prop of regions" [ngValue]="prop.id">{{prop.serviceRegionName}}</option>
                                        </select>
                                        <div class="error">
                                          <ng-container *ngIf="prop.errors?.required && prop.touched">Region is required</ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="eventType"><span class="required"></span>Select State</label>
                                            <select class="form-control"
                                                    id="state"
                                                    name="state"
                                                    #state="ngModel"
                                                    [(ngModel)]="options.state">
                                                <option selected disabled>Select a State</option>
                                                <option *ngFor="let s of states" [ngValue]="s.state">{{s.state}}</option>
                                            </select>
                                            <div class="error">
                                              <ng-container *ngIf="prop.errors?.required && prop.touched">State is required</ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div class="row" *ngIf="options.reportID !== 1">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="startDate"><span class="required"></span>From</label>
                                        <input type="text"
                                            id="startDate"
                                            [(ngModel)]="options.from"
                                            [maxDate]="maxDate"
                                            name="startDate"
                                            #start="ngModel"
                                            aria-required="true"
                                            bsDatepicker
                                            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false }"
                                            class="form-control">
                                        <div class="error">
                                            <ng-container *ngIf="start.errors?.required && start.touched">
                                                From Date is required
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="endDate"><span class="required"></span>To</label>
                                        <input type="text"
                                            id="endDate"
                                            [(ngModel)]="options.to"
                                            [maxDate]="maxDate"
                                            name="endDate"
                                            #end="ngModel"
                                            aria-required="true"
                                            bsDatepicker
                                            [minDate]="options.from"
                                            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false }"
                                            class="form-control">
                                        <div class="error">
                                            <ng-container *ngIf="end.errors?.required && end.touched">
                                                End Date is required
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <div class="checkbox modalCheck">
                                            <label>
                                                <input [(ngModel)]="options.filterIDs"
                                                    name="filterIDs"
                                                    id="filterIDs"
                                                    type="checkbox"> Filter ID's?
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="checkbox modalCheck">
                                            <label>
                                                <input [(ngModel)]="options.branchFilter"
                                                    name="branchFilter"
                                                    id="branchFilter"
                                                    type="checkbox"> Filter By My Branch?
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="modal-buttons modal-footer">
                        <button class="btn btn-primary noShow"
                                [disabled]="!reportForm.form.valid"
                                type="submit">Save</button>
                        <button type="button" class="btn btn-danger noShow"
                                (click)="hide()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
