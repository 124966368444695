import { Component, OnDestroy, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { WorkOrder } from '@models/work-order';
import { WorkOrderService } from '@services/work-order.service';

import * as _ from 'lodash';
import * as moment from 'moment';
import { AuthService } from '../authorization/auth.service';
import { ToastService } from '@services/toast.service';
import { UserService } from '@services/user.service';
import { ServiceType } from '@models/service-type';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { skip } from 'rxjs/operators';

enum DisplayModeEnum {
  Grid = 0,
  List = 1,
}

@Component({
  selector: 'work-orders',
  templateUrl: './work-orders.component.html',
  styleUrls: ['./work-orders.component.css']
})

export class WorkOrdersComponent implements OnInit, OnDestroy {

  workOrders: WorkOrder[];
  viewWorkOrders = 1;
  closedWorkOrders: WorkOrder[];
  openWorkOrders: WorkOrder[];
  openOrders = true;
  orderLength: number;
  pagenum: number;
  displayMode: DisplayModeEnum;
  searching = false;
  openAll = 'less';
  serviceTypes: ServiceType[];
  displayOrderTypeSuccess = false;

  query = {
    startDate: moment().subtract(1, 'months').toDate(),
    endDate: moment().add(1, 'days').toDate(),
    selectedOrderType: 0,
    openAll: 'less',
    selectedServiceType: [],
    selectedSearchBy: '',
    searchStringValue: '',
    pageSize: 50,
    page: 1,
    sort: undefined,
    totalItems: null,
    employeeId: this.userService.getEmployeeId()
  };

  headers = [
    {
      'sortName': null,
      'className': 'td-5',
      'title': 'Detail'
    }, {
      'sortName': 'id',
      'className': 'td-10',
      'title': 'Order #'
    }, {
      'sortName': 'createdAt',
      'className': 'td-10',
      'title': 'Date Opened'
    }, {
      'sortName': 'name',
      'className': 'td-15',
      'title': 'Dairy Name'
    }, {
      'sortName': 'city',
      'className': 'td-20',
      'title': 'Location'
    }, {
      'sortName': 'workOrderStatusId',
      'className': 'td-10',
      'title': 'Active Status'
    }, {
      'sortName': 'createdBy',
      'className': 'td-15',
      'title': 'Opened By'
    }, {
      'sortName': 'employeeTo',
      'className': 'td-15',
      'title': 'Assigned To'
    }];

  sorting = {
    null: {
      asc: true,
      properties: ['null']
    },
    id: {
      asc: true,
      properties: ['id']
    },
    createdAt: {
      asc: true,
      properties: ['createdAt']
    },
    name: {
      asc: true,
      properties: ['Client', 'name']
    },
    city: {
      asc: true,
      properties: ['Address', 'city']
    },
    workOrderStatusId: {
      asc: true,
      properties: ['workOrderStatusId']
    },
    createdBy: {
      asc: true,
      properties: ['createdBy', 'lastName']
    },
    employeeTo: {
      asc: true,
      properties: ['employeeTo', 'lastName']
    }
  };

  sortColumn = 'createdAt';
  totalItems = 0;
  maxSize = 5;

  searchQuery: any;
  destroyed$ = new Subject();

  constructor(
    private router: Router,
    private auth: AuthService,
    private userService: UserService,
    private workOrderService: WorkOrderService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService) {

    const { data, queryParams } = this.activatedRoute.snapshot;

    if (this.activatedRoute.snapshot.data['workOrdersPagination']) {
      this.workOrders = data.workOrdersPagination;
      this.query.pageSize = queryParams.pageSize || 50;

      this.query.startDate = queryParams.startDate ? moment(queryParams.startDate).toDate() : this.query.startDate;
      this.query.endDate = queryParams.endDate ? moment(queryParams.endDate).toDate() : this.query.endDate;
      this.query.openAll = queryParams.openAll ? queryParams.openAll : this.query.openAll;
      this.query.selectedServiceType = queryParams.selectedServiceType ? queryParams.selectedServiceType.split(',') : [];
      this.query.selectedSearchBy = queryParams.selectedSearchBy ? queryParams.selectedSearchBy : this.query.selectedSearchBy;
      this.query.searchStringValue = queryParams.searchStringValue ? queryParams.searchStringValue : this.query.searchStringValue;

      if (this.query.selectedServiceType.length > 0) {
        this.query.selectedServiceType = this.query.selectedServiceType.map(num => +num);
      }

      this.totalItems = data.workOrdersPagination.count;
      this.serviceTypes = data.serviceTypes;
      this.query.sort = queryParams.sort;
    } else {
      this.headers.shift();
    }

    this.query.page = +queryParams.page || 1;

    if (typeof queryParams.sort === 'string') {
      this.sortColumn = queryParams.sort[0] === '-' ? queryParams.sort.slice(1) : queryParams.sort;
      const sortOptions = this.sorting[this.sortColumn];

      if (sortOptions) {
        sortOptions.asc = queryParams.sort[0] === '-' ? false : true;
      }
    }

    this.displayMode = DisplayModeEnum.Grid;
    this.subscribeToRouter();
  }

  ngOnInit() {

  }

  subscribeToRouter() {
    this.activatedRoute.queryParams
      .pipe(
        skip(1),
        takeUntil(this.destroyed$)
      ).subscribe((queryParams) => {
        this.searching = true;
        this.query.page = +queryParams.page || 1;
        this.query.pageSize = +queryParams.pageSize || 50;
        this.query.sort = queryParams.sort;
        this.query.startDate = queryParams.startDate ? moment(queryParams.startDate).toDate() : this.query.startDate;
        this.query.endDate = queryParams.endDate ? moment(queryParams.endDate).toDate() : this.query.endDate;
        localStorage.setItem('workOrderPreferences', JSON.stringify(this.query));
        this.findWorkOrderData();
      });
  }

  navigationSearchQueries(query: any) {
    this.searching = true;
    this.query = query;
    this.setFilters();
  }

  toggleDisplayDetail(query: any) {
    this.query = query;
  }

  findWorkOrderData() {
    this.workOrderService.getWorkOrdersPagination(this.query).then(workOrders => {
      this.workOrders = workOrders;
      if (this.query.selectedOrderType === 0 && this.displayOrderTypeSuccess) {
        this.toastService.toast({ message: 'All Work Orders are now being displayed', type: 'success' });
      } else if (this.query.selectedOrderType === 1 && this.displayOrderTypeSuccess) {
        this.toastService.toast({ message: 'Open Work Orders are now being displayed', type: 'success' });
      } else if (this.query.selectedOrderType === 2 && this.displayOrderTypeSuccess) {
        this.toastService.toast({ message: 'Closed Work Orders are now being displayed', type: 'success' });
      } else if (this.query.selectedOrderType === 3 && this.displayOrderTypeSuccess) {
        this.toastService.toast({ message: 'My Work Orders are now being displayed', type: 'success' });
      }

      this.searching = false;
    });
  }

  setFilters() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: Object.assign({}, this.query, {
        page: 1,
        startDate: moment(this.query.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.query.endDate).format('YYYY-MM-DD'),
        selectedServiceType: this.query.selectedServiceType.join(',')
      }),
      queryParamsHandling: 'merge'
    });
  }

  changeStatus(workOrder: WorkOrder) {
    const updatedWorkOrder = _.clone(workOrder);
    this.workOrderService.changeStatus(updatedWorkOrder).then(req => {
      this.toastService.toast({
        message: req.completionDate ? 'Successfully closed work order' : 'Work order has been re-opened.',
        type: !!req.completionDate ? 'success' : 'warning'
      });
      //    merge updatedWorkOrder with old in array
      _.merge(workOrder, req);
    });
  }

  displaySwitchOrderSuccessMessage(displaySuccess: boolean) {
    this.displayOrderTypeSuccess = displaySuccess;
  }


  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
