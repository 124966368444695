import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {S3MapService} from '../../../s3-gmap/s3-map.service';
import {ServiceRegion} from '../../../models/service-region';
import { Address } from "../../../models/address";

@Component({
    templateUrl: './address-edit.component.html',
    styleUrls: ['../../client.component.css'],
    selector: 'address-edit'
})
export class AddressEditComponent implements OnInit{
    @Input()
    address: Address;
    @Input()
    states: any[];
    @Input() serviceRegions: ServiceRegion[];
    @Input() isDelivery:boolean = false;
    @Output() saveAddress = new EventEmitter();
    @Output() cancelEdit = new EventEmitter();
    @Output() toMailing = new EventEmitter();

    constructor(
        private s3mapService: S3MapService
    ){}

    ngOnInit(){
    }

    searchCounty(term: number): void{
        if(term && term.toString().length > 4 && term.toString().length < 6 ) {
            this.s3mapService.searchCounty(term)
                .then((res)=> {
                    this.address.county = res;
                });
        }
    }

    save(address: any){
        this.saveAddress.emit(address);
    }

    saveToMailing(address: any) {
        const newAddress = Object.assign({}, address);
        delete newAddress.id;
        this.toMailing.emit(newAddress);
    }

    cancel(e: any){
        e.preventDefault();
        this.cancelEdit.emit('cancel');
    }
}
