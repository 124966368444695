<h5><i class="fa fa-map"></i> Recent Routes</h5>
<div class="shadow recent-box">
    <table id="recent-table" class="table table-striped table-hover half-table">
        <thead>
            <tr>
                <th class="td-25">Route</th>
                <th class="td-25">Map</th>
                <th class="td-25"><span class="hide-mobile">Date </span>Created</th>
                <th class="td-25">Delivery <span class="hide-mobile">Date </span></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let route of routes" [routerLink]="['/route-review',route.id]">
                <td class="td-25">{{ route.routeName }}</td>
                <td class="td-25"><i class="fa fa-map"></i></td>
                <td class="td-25">{{ route.createdAt | date: 'MM/dd/yyyy' }}</td>
                <td class="td-25"><em>{{ route.dateOfRoute | date: 'MM/dd/yyyy' }}</em></td>
            </tr>
        </tbody>
    </table>
</div>
