import { NgModule } from '@angular/core';

import {UserService} from "./services/user.service";
import {AddressService} from './services/address.service';
import {ClientService} from "./services/client.service";
import {DashboardService} from './services/dashboard.service';
import {DeliveryOrderService} from './services/delivery-order.service';
import {EmployeeService} from "./services/employee.service";
import {EmployeeTypeService} from "./services/employee-type.service";
import {FacilityService} from './services/facility.service';
import {LabService} from "./services/lab.service";
import {NoteService} from "./services/notes.service";
import {ProductService} from "./services/product.service";
import {RouteService} from "./services/route.service";
import {ServiceTypeService} from './services/service-types.service';
import {ServiceRegionService} from './services/service-region.service';
import {TankService} from "./services/tank.service";
import {TractorService} from "./services/tractor.service";
import {TrailerService} from "./services/trailer.service";
import {CargoService} from "./services/cargo.service";
import {TankTypeService} from "./services/tank-type.service";
import {WorkOrderService} from "./services/work-order.service";
import {FileUploadService} from "./services/file-upload.service";
import {WorkOrderDetailService} from "./services/work-order-detail.service";
import {VaporTestService} from "./services/vapor-test.service";
import {ToastService} from "./services/toast.service";
import {SettingsService} from "./services/settings.service";
import {ResizeService} from "./services/resize.service";
import {UtilsService} from "./services/utils.service";
import {PdfService} from "./services/pdf.service";
import { StateService } from './services/state.service';

@NgModule({
    imports: [],
    declarations: [],
    exports:[],
    providers: [
        AddressService,
        ClientService,
        DashboardService,
        DeliveryOrderService,
        EmployeeService,
        EmployeeTypeService,
        FacilityService,
        LabService,
        NoteService,
        PdfService,
        ProductService,
        RouteService,
        ServiceTypeService,
        SettingsService,
        ServiceRegionService,
        TankService,
        TankTypeService,
        TractorService,
        TrailerService,
        CargoService,
        UserService,
        FileUploadService,
        VaporTestService,
        WorkOrderService,
        WorkOrderDetailService,
        ToastService,
        UtilsService,
        ResizeService,
        StateService
    ]
})

export class ServicesModule { }


