import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class UserService {
    jwtHelper = new JwtHelperService();
    constructor() { }

    getEmployeeId(): number {
        const user = this.jwtHelper.decodeToken(localStorage.getItem('id_token'));
        return user.employeeId;
    }

    getEmployeeTypeId(): number {
        const user = this.jwtHelper.decodeToken(localStorage.getItem('id_token'));
        return user.Employee.EmployeeEmployeeTypes[0].employeeTypeId;
    }

    getEmployee(): any {
        const user = this.jwtHelper.decodeToken(localStorage.getItem('id_token'));
        return user.Employee;
    }

    role(): string {
        const user = this.jwtHelper.decodeToken(localStorage.getItem('id_token'));
        return user.role;
    }

    displayUserInfo() {
        const user = this.jwtHelper.decodeToken(localStorage.getItem('id_token'));
    }
}
