<div class="header">
    <h4><i class="fa fa-vcard"></i> Inactive Tanks</h4>
</div>
<div class="clients-container">
    <input placeholder="Search" [(ngModel)]="search" class="form-control search-input">
    <div class="client-list-panel">
    <table class="table table-hover table-responsive">
        <thead>
        <tr>
            <th class="td-30">Dairy Name</th>
            <th class="td-30">Tank Name</th>
            <th class="td-20">Is Active</th>
            <th class="td-20"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let tank of tanks | searchPipe:'Address.alias':search">
            <td class="td-30">{{ tank.Address?.alias || 'No dairy was specified'}}</td>
            <td class="td-30">{{ tank.tankName }}</td>
            <td class="td-20">
                <button class="btn btn-sm"
                        [ngClass]="{'btn-success': tank.isActive==1, 'btn-danger': tank.isActive==0}"
                        (click)="changeTankStatus(tank)">
                    {{ tank.isActive==1 ? ' Yes': 'No' }}
                </button>
            </td>
            <td class="td-20">
                <button class="btn btn-sm btn-danger"
                        (click)="delete.showPromptModal(deleteTank(tank))">
                    Delete
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>
</div>

<app-prompt-modal-helper
  #delete header="Delete Tank"
  title="Are you sure you want to delete this tank? This cannot be undone."
  confirm="Delete">
</app-prompt-modal-helper>

<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>

