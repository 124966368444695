import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Trailer } from '../../../models/trailer';
// import indexOf = require("core-js/fn/array/index-of");
import * as _ from 'lodash';

@Component({
    templateUrl: 'trailer-list.component.html',
    styleUrls: ['trailer-list.component.css'],
    selector: 'trailer-list'
})
export class TrailerListComponent {
    @Input()
    trailers: Trailer[];
    @Input() selectedTrailer: Trailer;
    @Output()
    setTrailer = new EventEmitter<Trailer>();
    @Output()
    resetTrailer = new EventEmitter<string>();
    active = true;
    constructor() { }


    selectTrailer(trailer: Trailer) {
        this.setTrailer.emit(trailer);
    }

    trailerCargo(trailer: Trailer): string {
        {
            let cargoInfo = '';
            trailer.Cargos.forEach((cargo: any) => {
                cargoInfo += cargo.maxLoad ? cargo.maxLoad.toString() : '';
                if (trailer.Cargos.indexOf(cargo) < (trailer.Cargos.length - 1)) {
                    cargoInfo += ' / ';
                }
            });
            return cargoInfo;
        }
    }

    isSelected(trailer: Trailer) {
        if (this.selectedTrailer) {
            return trailer.id === this.selectedTrailer.id;
        }
        return false;
    }

    scopedTrailers() {
        return _.filter(this.trailers, (t: Trailer) => t.isActive === this.active);
    }

    clearTrailer() {
        this.resetTrailer.emit('reset');
    }

}
