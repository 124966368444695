import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name : 'dateSorting',
})
export class DateSortingPipe implements PipeTransform {
    public transform(value:any, objKey: string) {
        if (!objKey) {
            return value;
        } else {
            return value.sort((itemA:any, itemB: any) => {
                return +new Date(itemB[objKey]) - +new Date(itemA[objKey]);
            });
        }
    }
}
