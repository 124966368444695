import { AuthService } from './../../../authorization/auth.service';
import { Component, OnInit, Input, ViewChild, EventEmitter, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TankService } from '../../../services-module/services/tank.service';
import { MultiSelectService } from '../../../multi-select/multi-select.service';
import { S3FilterService } from '../../../s3-filter/s3-filter.service';
import { RouteService } from '../../../services-module/services/route.service';
import { Route } from '../../../models/route';
import { Tank } from '../../../models/tank';
import { PromptModalComponent } from '../../../shared-components/prompt-modal/prompt-modal.component';
import * as moment from 'moment';
import { ToastService } from '../../../services-module/services/toast.service';
import { ResizeService } from '../../../services-module/services/resize.service';
import * as _ from 'lodash';

@Component({
    selector: 'client-locations',
    templateUrl: 'client-locations.component.html',
    styleUrls: ['client-locations.component.css']
})
export class ClientLocationsComponent implements OnInit, OnDestroy {
    @Input()
    clientLocations: Tank[];
    @Input()
    route: Route;
    @Input()
    workOrder: boolean;
    @ViewChild('navPrompt', { static: true }) private navPrompt: PromptModalComponent;
    @Output() moveToRoute = new EventEmitter();
    @Output() saveRoute = new EventEmitter();
    currentFilter: string = null;
    orderAsc: string = null;
    allSelected = false;
    term: Object = {};
    navId: number;
    showDetails: any = null;
    showingNotes: any;
    search: string;
    isSalesrepOutside = this.auth.isSalesrepOutside();

    constructor(
        public auth: AuthService,
        private tankService: TankService,
        private toastService: ToastService,
        private multiSelectService: MultiSelectService,
        private s3Filter: S3FilterService,
        private routeService: RouteService,
        private resizeService: ResizeService,
        private router: Router
    ) {
        this.term = { searchBy: 'Search By', property: 'Client', key: 'name' };
    }

    ngOnInit(): void {
        let container: {
            name: string;
            containerParent: string;
            selectedClass: string;
            idsToMove: any[];
            itemId: string;
            targetArray: Tank[];
        } = {
            name: 'tanks',
            containerParent: 'location-container',
            selectedClass: 'selected-location',
            idsToMove: [],
            itemId: 'client-location',
            targetArray: this.clientLocations,
        };
        this.multiSelectService.addContainer(container);
        this.resizeService.resizePanel('resizable', 'dragbar');
    }
    ngOnDestroy(): void {
        this.multiSelectService.removeContainer('tanks');
    }

    selectLocation(event: any, location: Tank) {
        this.routeService.hasChanges = true;
        this.multiSelectService.selectLocation(event, location, 'tanks');
        this.addToRoute();
    }

    addToRoute(): void {
        this.moveToRoute.emit('move')
    }

    showNotes(locId: any) {
        this.showingNotes = locId;
    }
    closeNotes() {
        this.showingNotes = null;
    }

    filter(args: any): void {
        if (args !== this.currentFilter || this.orderAsc !== 'asc') {
            this.currentFilter = args;
            this.orderAsc = 'asc';
        }
        else {
            this.orderAsc = 'desc';
        }
        this.clientLocations.sort((a, b) => {
            let nameA = typeof a[args] === 'string' ? a[args].toUpperCase() : a[args]; // ignore upper and lowercase
            let nameB = typeof b[args] === 'string' ? b[args].toUpperCase() : b[args]; // ignore upper and lowercase
            if (nameA < nameB && this.orderAsc === 'asc') {
                return -1;
            } else if (nameA < nameB && this.orderAsc === 'desc') {
                return 1;
            }
            if (nameA > nameB && this.orderAsc === 'asc') {
                return 1;
            } else if (nameA > nameB && this.orderAsc === 'desc') {
                return -1;
            }
            return 0;
        });
    }

    orderByClients(args: string): void {
        if (args !== this.currentFilter || this.orderAsc !== 'asc') {
            this.currentFilter = args;
            this.orderAsc = 'asc';
        }
        else {
            this.orderAsc = 'desc';
        }

        this.clientLocations.sort((a, b) => {
            let nameA = a['Address'][0][args].toUpperCase(); // ignore upper and lowercase
            let nameB = b['Address'][0][args].toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB && this.orderAsc === 'asc') {
                return -1;
            } else if (nameA < nameB && this.orderAsc === 'desc') {
                return 1;
            }
            if (nameA > nameB && this.orderAsc === 'asc') {
                return 1;
            } else if (nameA > nameB && this.orderAsc === 'desc') {
                return -1;
            }
            return 0;
        });
    }

    orderByLocation(): void {
        if (this.currentFilter !== 'address' || this.orderAsc !== 'asc') {
            this.currentFilter = 'address';
            this.orderAsc = 'asc';
        } else {
            this.orderAsc = 'desc';
        }

        this.clientLocations.sort((a, b) => {
            let nameAState = a['Address'][0].state.toUpperCase(); // ignore upper and lowercase
            let nameBState = b['Address'][0].state.toUpperCase(); // ignore upper and lowercase
            let nameACity = a['Address'][0].city.toUpperCase(); // ignore upper and lowercase
            let nameBCity = b['Address'][0].city.toUpperCase(); // ignore upper and lowercase
            if (nameAState < nameBState && this.orderAsc === 'asc') {
                return -1;
            } else if (nameAState < nameBState && this.orderAsc === 'desc') {
                return 1;
            }
            if (nameAState > nameBState && this.orderAsc === 'asc') {
                return 1;
            } else if (nameAState > nameBState && this.orderAsc === 'desc') {
                return -1;
            }
            if (nameAState === nameAState) {
                if (nameACity < nameBCity && this.orderAsc === 'asc') {
                    return -1;
                } else if (nameACity < nameBCity && this.orderAsc === 'desc') {
                    return 1;
                }
                if (nameACity > nameBCity && this.orderAsc === 'asc') {
                    return 1;
                } else if (nameACity > nameBCity && this.orderAsc === 'desc') {
                    return -1;
                }
                return 0;
            }
        });
    }

    notNegative(event: any) {
        return ['-', '+'].indexOf(event.key) == -1;
    }

    confirmNav(id: number) {
        let update: boolean;
        switch (true) {
            case this.route.locations.length > 0:
            case !!this.route.startLocationId:
            case !!this.route.endLocationId:
            case this.route.driverIds.length > 0:
            case !!this.route.trailerId:
                update = true;
                break;
            default:
                update = false;
                break;
        }
        if (this.routeService.hasChanges && update) {
            this.navPrompt.showPromptModal();
            this.navId = id;
        } else {
            this.router.navigate(['/clients', id]);
        }
    }

    navigate() {
        if (!this.route.routeName) this.route.routeName = 'Saved-' + moment().format('YYYYMMDD hh:mm:ss');
        this.saveRoute.emit(this.route);
        setTimeout(() => {
            this.routeService.hasChanges = false;
            this.navPrompt.hidePromptModal();
            this.router.navigate(['/clients', this.navId]);
        }, 1000);
    }

    // Projected ExpAvgDailyUse
    dailyExpAvgDailyUse(location:any) {
        if(location.expAvgDailyUse !== null && location.expAvgDailyUse !== 0) {
            return location.expAvgDailyUse;
        } else {
            return 'NEW';
        }
    }
    // Actual Avg/Daily
    dailyCalculatedDailyUse(location:any) {
        if(location.calculatedDailyUse !== null) {
            return location.calculatedDailyUse;
        } else {
            return 'NEW';
        }
    }

    doNothing() {
        //    Doing Nothing
        this.toastService.toast({ message: 'This tank is currently on a route', type: 'danger' });
    }

    toRoute(routeId: number) {
        this.router.navigate(['/route-review', routeId]);
    }
}
