import { Routes, RouterModule } from '@angular/router';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { RedirectGuard } from './authorization/auth-guard.service';

const appRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        children: [],
        canActivate: [RedirectGuard]
    },
    {
        path: '**',
        redirectTo: '/dashboard'
    },
    {
        path: '401',
        component: UnauthorizedComponent
    }
];
export const routing = RouterModule.forRoot(appRoutes, { useHash: true });
