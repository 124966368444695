import { Injectable } from '@angular/core';
import { Client } from '@models/client';
import { WorkOrder } from '@models/work-order';
import { Route } from '@models/route';
import { Lab } from '@models/lab';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Injectable()

export class DashboardService {
  private apiUrl = environment.apiUrl;  // URL to web api

  constructor(public authHttp: HttpClient) { }

  getRecentClients(params: { itemsPerPage?: number, clientPage?: number } = { itemsPerPage: 25 })
    : Promise<{ rows: Client[], count: number }> {
    const queryParams = {
      page: +params.clientPage || 1,
      itemsPerPage: +params.itemsPerPage || 50
    };

    return this.authHttp.post(`${this.apiUrl}client/get-recent-clients`, queryParams)
      .toPromise()
      .then((response) =>
        response as { rows: Client[], count: number })
      .catch(this.handleError);
  }

  getRecentRoutes(params: { itemsPerPage?: number, routePage?: number } = { itemsPerPage: 25 }): Promise<{ rows: Route[], count: number }> {
    const queryParams = {
      page: +params.routePage || 1,
      itemsPerPage: +params.itemsPerPage || 50
    };

    return this.authHttp.post(`${this.apiUrl}route/get-recent-routes`, queryParams)
      .toPromise()
      .then((response) => response as { rows: Route[], count: number })
      .catch(this.handleError);
  }

  getRecentWorkOrders(params: { itemsPerPage?: number, orderPage?: number } = { itemsPerPage: 25 })
    : Promise<{ rows: WorkOrder[], count: number }> {
    const queryParams = {
      page: +params.orderPage || 1,
      itemsPerPage: +params.itemsPerPage || 50
    };

    return this.authHttp.post(`${this.apiUrl}work-order/get-recent-work-orders`, queryParams)
      .toPromise()
      .then((response) => response as { rows: WorkOrder[], count: number })
      .catch(this.handleError);
  }

  getRecentLabs(params: any = {}): Promise<{ rows: Lab[], count: number }> {
    const queryParams = {
      page: +params.labPage || 1,
      itemsPerPage: +params.itemsPerPage || 50,
      fromDate: params.fromDate ? moment(params.fromDate).toDate() : undefined,
      toDate: params.toDate ? moment(params.toDate).toDate() : undefined
    };

    return this.authHttp.post(`${this.apiUrl}lab/get-recent-labs`, queryParams)
      .toPromise()
      .then((response) => response as Object[])
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

}
