<div>
    <div>{{ address.street }} <a href="javascript:;" (click)="edit()">Edit</a></div>
    <div>{{ address.city }}, {{ address.county }}</div>
    <div>{{ address.state}} {{address.zip}}</div>
    <div *ngIf="address.isDeliveryLocation">
        <div>Service Region: {{ address.ServiceRegion?.serviceRegionName }} - {{ address.ServiceRegion?.state }}</div>
        <div>Latitude: {{ address.latitude }}</div>
        <div>Longitude: {{ address.longitude }}</div>
    </div>
    <div *ngIf="!address.isDeliveryLocation">
        Billing Email: {{ address.billingEmail }}
    </div>
    <button class="btn btn-sm btn-success" (click)="openMap(address)">
        <i class="fa fa-map"></i>
    </button>
</div>
