<header class="no-print">
    <div class="sm-nav-btn" (click)="openMenu()">
        <span></span>
        <span></span>
        <span></span>
    </div>
    <div class="sm-nav-logo">
        <img src="/assets/images/specialtylogo.png" alt="">
    </div>
    <div class="sm-nav-menu shadow" [class.openMenu]="menuOpen" [class.closedMenu]="!menuOpen" (window:resize)="onResize()">
        <ul>
            <li *ngIf="!isSalesrepOutside" (click)="closeNav()" routerLink="/dashboard" routerLinkActive="active">
                <a><i class="fa fa-dashboard"></i> Dashboard</a>
            </li>
            <li  *ngIf="!isSalesrepOutside" (click)="closeNav()" routerLink="/labs" routerLinkActive="active">
                <a><i class="fa fa-flask"></i> Labs</a>
            </li>
            <li (click)="closeNav()"  routerLink="/clients" routerLinkActive="active">
                <a><i class="fa fa-group"></i> Clients</a>
            </li>
            <li *ngIf="!isSalesrepOutside" (click)="closeNav()"  routerLink="/routes" routerLinkActive="active">
                <a><i class="fa fa-map"></i> Routes</a>
            </li>
            <li *ngIf="isAdmin || (['Driver'] | appEmployeeTypes: employeeTypes)" (click)="closeNav()" [routerLink]="'/driver/' + driver" routerLinkActive="active">
                <a><i class="fa fa-truck"></i> Driver</a>
            </li>
            <li *ngIf="!isSalesrepOutside" (click)="closeNav()" routerLink="/work-orders" routerLinkActive="active">
                <a><i class="fa fa-file"></i> Work Orders</a>
            </li>
            <li *ngIf="!isSalesrepOutside" (click)="closeNav()" routerLink="/work-order-routes" routerLinkActive="active">
                <a><i class="fa fa-map"></i> Work Order Routes</a>
            </li>
            <li *ngIf="isAdmin || (['Driver'] | appEmployeeTypes: employeeTypes)" (click)="closeNav()" [routerLink]="'/work-order-driver/' + driver" routerLinkActive="active">
                <a><i class="fa fa-truck"></i> Work Order Driver</a>
            </li>
            <li (click)="closeNav()" routerLink="/profile" routerLinkActive="active">
                <a><i class="fa fa-user-circle"></i> Profile</a>
            </li>
            <li *ngIf="['Sales Manager'] | appEmployeeTypes: employeeTypes" (click)="closeNav()" routerLink="/admin/reporting" routerLinkActive="active">
                <a><i class="fa fa-list"></i> Reporting</a>
            </li>
            <li *ngIf="isAdmin">
                <a href="javascript:void(0);" (click)="openSubMenu()"><i class="fa fa-user"></i> Admin</a>
                <ul *ngIf="subMenu" class="sub-menu">
                    <li (click)="closeNav()" routerLink="/admin/employees" routerLinkActive="active" class="side-pointer">
                        <a href="javascript:void(0);"> <i class="fa fa-vcard"></i> Employees</a>
                    </li>
                    <li (click)="closeNav()" routerLink="/admin/vehicles" routerLinkActive="active" class="side-pointer">
                         <a href="javascript:void(0);"><i class="fa fa-truck"></i> Vehicles</a>
                    </li>
                    <li (click)="closeNav()" routerLink="/admin/products" routerLinkActive="active" class="side-pointer">
                         <a href="javascript:void(0);"><i class="fa fa-product-hunt"></i> Products</a>
                    </li>
                    <li (click)="closeNav()" routerLink="/admin/tanks" routerLinkActive="active" class="side-pointer">
                         <a href="javascript:void(0);"><i class="fa fa-tasks"></i> Tanks</a>
                    </li>
                    <li (click)="closeNav()" [routerLink]="['admin', 'inactive', { outlets: { inactive: 'inactive-clients' } }]" routerLinkActive="active" class="side-pointer">
                        <a href="javascript:void(0);"><i class="fa fa-tasks"></i> Inactive Clients</a>
                    </li>
                    <li (click)="closeNav()" routerLink="/admin/misc" routerLinkActive="active" class="side-pointer">
                        <a href="javascript:void(0);"><i class="fa fa-list"></i> Misc</a>
                    </li>
                </ul>
            </li>
            <li>
                <a (click)="logout()"><i class="fa fa-sign-out"></i> Logout</a>
            </li>
            <li class="side-pointer">
              <a (click)="showVersion()"><i class="fa fa-code"></i> v{{version}}</a>
            </li>
        </ul>
    </div>
</header>
