import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewLmntComponent } from './view-lmnt/view-lmnt.component';
import { NavbarLmntComponent } from './navbar-lmnt/navbar-lmnt.component';
import { MenuLmntComponent } from './menu-lmnt/menu-lmnt.component';
import { MenuItemLmntComponent } from './menu-item-lmnt/menu-item-lmnt.component';
import { CoreConfigService } from './core-config.service';
import { RouterModule } from '@angular/router';
import { ModalLmntComponent } from './modal-lmnt/modal-lmnt.component';
import { FormsModule } from '@angular/forms';

import { SharedComponentsModule } from '../shared-components/shared-components.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedComponentsModule
  ],
  declarations: [
    ViewLmntComponent,
    NavbarLmntComponent,
    MenuLmntComponent,
    MenuItemLmntComponent,
    ModalLmntComponent
  ],
  exports: [
    ViewLmntComponent,
    NavbarLmntComponent,
    MenuLmntComponent,
    MenuItemLmntComponent,
    ModalLmntComponent
  ],
  providers: [
    CoreConfigService
  ]
})
export class CoreLmntModule { }
