import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { TankService } from '@services/tank.service';
import { Tank } from '@models/tank';
import { TankType } from '@models/tank-type';
import { Product } from '@models/product';
import { TankDevice } from '@models/tank-device';
import { UntypedFormGroup } from '@angular/forms';
import { ToastService } from '@services/toast.service';
import { PromptModalComponent } from '@shared/prompt-modal/prompt-modal.component';
import * as _ from 'lodash';
import { Client } from '../../clients/client';
import { Address } from '@models/address';

@Component({
  templateUrl: './tanks-panel.component.html',
  styleUrls: ['./tanks-panel.component.css'],
  selector: 'tanks-panel'
})
export class TanksPanelComponent {
  @Input()
  clientsDairies: Client[];
  @Input()
  tanks: Tank[];
  @Input()
  tankTypes: TankType[];
  @Input()
  products: Product[];
  @Output()
  saveTank = new EventEmitter<any>();
  @Output()
  moveTank = new EventEmitter<any>();
  @Output()
  overrideTankLevel = new EventEmitter<any>();
  @Output()
  hidePanel = new EventEmitter<void>();
  @ViewChild('tankLinkModal', { static: true }) private tankLinkModal: PromptModalComponent;
  @ViewChild('tankDelete', { static: true }) private tankDelete: PromptModalComponent;
  @ViewChild('markInactive', { static: true }) private markInactive: PromptModalComponent;
  tank: Tank;
  deleteTank: Tank;
  tankLink = new Tank();
  tankLinkEdit: boolean = null;
  rowId: number = null;
  tankOverride: boolean;
  inactiveTank: Tank;
  ftp = false;
  tankInfo: any;
  ftw = false;
  moving = false;
  showClients = false;
  search = '';
  newClient: Client;
  newAddress: Address;

  public barChartOptions: any = {
    scaleShowVerticalLines: true,
    responsive: true,
    // tooltips:{
    //     enabled:false
    // },
    scales: {
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true,
          max: 100
        }
      }],
      datasets: {
        barThickness: 100
      }
    }
  };
  public barChartType = 'bar';
  public barChartLegend = false;
  saveError = false;
  saveSuccessful = false;
  openAll = false;

  constructor(
    private tankService: TankService,
    private toastService: ToastService
  ) {
    this.setTank();
    this.tankOverride = false;
    this.tankInfo = null;
  }

  setDeleteTank(tank?: Tank) {
    if (tank) {
      this.deleteTank = tank;
      this.tankDelete.showPromptModal();
    } else {
      this.deleteTank = new Tank();
      this.tankDelete.hidePromptModal();
    }
  }

  removeTank() {
    this.deleteTank.isActive = 0;
    this.saveTank.emit(this.deleteTank);
    this.deleteTank = new Tank();
    this.tankDelete.hidePromptModal();
  }


  hide(): void {
    this.hidePanel.emit();
  }

  save(tank: any, form: UntypedFormGroup): void {
    if (form.valid) {
      this.saveTank.emit(tank);
      this.setTank();
    } else {
      this.toastService.validate(form);
    }
  }

  toggleOverridePanel() {
    this.tankOverride = !this.tankOverride;
  }
  overrideLevel(tank: any): void {
    this.overrideTankLevel.emit(tank);
  }
  setTank(): void {
    this.tank = new Tank();
  }


  select(event: Event, tank: Tank): void {
    if (event.target['className'].includes('tankLinkToggle')) {
      return;
    }
    this.tank = new Tank();
    this.setNewTank(tank);
  }

  cancel(): void {
    this.tankInfo = null;
    this.setTank();
  }

  toggleRow(tank: Tank): void {
    if (this.rowId === tank.id) {
      this.rowId = null;
    } else {
      this.rowId = tank.id;
      if (!tank.TankDevice) {
        tank.TankDevice = new TankDevice();
        this.tankLinkEdit = true;
      } else {
        this.tankLinkEdit = false;
      }
    }
  }

  editTankLink(tank: Tank): void {
    this.tankLink = _.cloneDeep(tank);
    this.tankLinkEdit = true;
  }

  cancelTankLinkEdit(tank: Tank): void {
    Object.assign(tank, this.tankLink);
    this.tankLinkEdit = false;
  }

  saveTankDevice(tank: Tank): void {
    tank.TankDevice.SerialNumber = tank.TankDevice.SerialNumber.replace(/\s/g, '');
    tank.TankDevice.CellularID = tank.TankDevice.CellularID.replace(/\s/g, '');
    tank.TankDevice.isActive = tank.TankDevice.SerialNumber ? true : false;

    this.ftp = true;
    this.tankService.saveTankDevice(tank)
      .then((res) => {
        _.merge(tank, res);
        this.ftp = false;
      });
    this.tankLinkEdit = false;
  }

  setTanklink(tank: Tank) {
    this.tankLink = tank;
  }

  setTankType(e: any) {
    this.tank.TankType = _.filter(this.tankTypes, ['id', +e.target.value])[0];
    this.currentLimit(this.tank);
  }

  clearTanklink() {
    this.tankLink.TankDevice.isActive = false;
    this.tankLink.TankDevice['CellularID'] = '';
    this.tankLink.TankDevice.SerialNumber = '';
    this.tankLink.TankDevice.TankDeviceName = '';
    this.saveTankDevice(this.tankLink);
    this.tankLinkModal.hidePromptModal();
  }

  currentLimit(tank: Tank) {
    switch (true) {
      case !tank || !tank.TankType:
        break;
      case tank.currentGallons > tank.TankType.capacityVolume:
        tank.currentGallons = tank.TankType.capacityVolume;
        break;
      case tank.currentGallons < 0:
        tank.currentGallons = Math.abs(tank.currentGallons);
        if (tank.currentGallons > tank.TankType.capacityVolume) {
          tank.currentGallons = tank.TankType.capacityVolume;
        }
        break;
      default:
        break;
    }
  }

  promptInactiveModal() {
    this.markInactive.showPromptModal();
  }

  promptDismiss() {
    this.markInactive.hidePromptModal();
  }

  setInactiveTank(tank: Tank) {
    this.inactiveTank = tank;
  }

  markTankInactive() {
    this.inactiveTank.isActive = 0;
    this.promptDismiss();
    return this.tankService.updateTank(this.inactiveTank)
      .then((res) => {
        this.toastService.toast({
          message: `You have marked ${this.inactiveTank.tankName ? this.inactiveTank.tankName : 'this tank'
            } as inactive`, type: 'success'
        });
      });
  }

  /* Tank Graph Display */
  getTankPercent(tank: Tank): number {
    const percent = this.tankService.calculatePercent(tank);
    if (percent <= 0) {
      return 0;
    } else if (percent >= 100) {
      return 100;
    } else return percent;
  }

  getTankStatus(tank: Tank): string {
    return this.tankService.calculateTankStatus(tank);
  }
  tankLevel(level: number, total: number) {
    if (level > total) {
      return total;
    } else if (level < 0) {
      return 0;
    } else return level;
  }
  tankData(tank: Tank) {
    const tankLevel = (this.getTankPercent(tank) * 100);
    const status = this.getTankStatus(tank);
    return [{ data: [tankLevel], label: status }];
  }
  chartLabel(tank: Tank) {
    const tankLevel = (this.getTankPercent(tank) * 100).toFixed(0);
    const status = this.getTankStatus(tank);
    return [`${status} - ${tankLevel}%`];

  }
  tankColors(tank: Tank) {
    const status = this.getTankStatus(tank).toLowerCase();
    const colors: any = [];
    switch (status) {
      case 'critical':
        colors.push({ backgroundColor: '#d52100' });
        break;
      case 'low':
        colors.push({ backgroundColor: '#e1c403' });
        break;
      case 'good':
        colors.push({ backgroundColor: '#177E00' });
        break;
      default:
        colors.push({ backgroundColor: '#ccc', hoverBackgroundColor: '#333' });
    }

    return colors;
  }
  setNewTank(tank: Tank) {

    this.tankInfo = null;
    this.tank = tank;

    setTimeout(() => {
      this.tankInfo = {
        data: this.tankData(tank),
        colors: this.tankColors(tank),
        labels: this.chartLabel(tank)
      };
    }, 250);
  }

  closeDropdown(event: Event) {
    if (event.target['id'] !== 'myInput') {
      setTimeout(() => {
        this.showClients = false;
      }, 100);
    }
  }

  selectAddress(client: Client, address: Address) {
    this.newClient = client;
    this.newAddress = address;
    this.showClients = false;
  }

  callMoveTank(tank: Tank) {
    this.moveTank.emit({ id: tank.id, addressId: this.newAddress.id, clientId: this.newClient.id });
    this.setTank();
    this.clearMove();
  }

  clearMove() {
    this.moving = false;
    this.newClient = null;
    this.newAddress = null;
    this.search = null;
  }
}
