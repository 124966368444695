<div *ngIf="client">
    <div class="client-detail-img shadow" *ngIf="!editPic">
        <label for="image">
            <input type="file" name="image" id="image" style="display:none;" (change)="handleFile($event)"/>
            <img [attr.src]="client.image?.length ? client.image[0]?.filePath: image.defaultLogo"/>
        </label>
    </div>

    <div class="client-detail-img shadow" *ngIf="editPic">
        <label for="image">
            <input type="file" name="image" id="image" style="display:none;" (change)="handleFile($event)"/>
            <img [attr.src]="image.data"/>
        </label>
    </div>

    <div class="client-detail-info border-bottom">
        <div class="padding-bottom-10 inline-block">
            <a href="javascript:void(0)"
               class="arrow-client"
               (click)="changeClient('previous')"> < </a>
            <div class="client-name">
                <a href="javascript:void(0)" (click)="allowSearch()">
                    {{client.name}}
                </a>
            </div>
            <a href="javascript:void(0)" class="arrow-client" (click)="changeClient('next')"> > </a>

            <div class="client-tags">
                <span class="btn btn-sm" [ngClass]="{'btn-success':client.isActive, 'btn-danger':!client.isActive }">{{client.isActive ? 'Active': 'Not Active'}}</span>
                <span class="btn btn-sm" [ngClass]="{'btn-primary':client.isClient, 'btn-warning':!client.isClient }">{{client.isClient ? 'Client': 'Sales Lead'}}</span>
            </div>
        </div>
        <div class="pull-right">
            <button class="btn btn-primary btn-sm margin-bottom-5" (click)="editClient()" href="javascript:;">Edit Details</button>
        </div>
    </div>
    <div class="save-pic" *ngIf="editPic">
        <span><a (click)="uploadFile()">Save </a></span>
        <span style="color: #0275d8;"> | </span>
        <span><a (click)="cancelProfPic()"> Cancel</a></span>
    </div>
</div>



<!-- Loading Spinner -->
<div class="loading-overlay" *ngIf="searchClis">
    <div class="overlay-search">
        <a href="javascript:void(0)" (click)="allowSearch()">cancel</a>
    </div>
    <h3>Search for Client: </h3>
    <div id="myClientList">
        <input type="text" placeholder="Search.." id="myInput" (keyup)="setTerm($event.target)" class="input" #daInput (focus)="showClients = true" (blur)="closeThings($event)">
        <div class="client-dropdown" *ngIf="showClients" id="clientDropdown">
            <ul>
                <li *ngFor="let cd of clientsDairies | clientSearchPipe:search">
                    <span (click)="goToNewView('client', cd.id, 0); $event.stopPropagation()">{{cd.name}}</span>
                    <div *ngFor="let d of cd.Addresses" class="dairy-info">
                        <div (click)="goToNewView('dairy', cd.id, d.id)">{{d.alias}}</div>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</div>
