// profile.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { UserService } from './user.service';

import * as _ from 'lodash';

import { Note } from '../../models/note';
import { environment } from '../../../environments/environment';

@Injectable()
export class NoteService {
    private apiUrl = `${environment.apiUrl}`;  // URL to web api

    constructor(private authHttp: HttpClient, private userService: UserService) { }

    saveNote(note: Note): Promise<Note> {
        if (!note.id) {
            return this.createNote(note);
        } else {
            return this.updateNote(note);
        }
    }

    createNote(note: Note): Promise<Note> {
        note.employeeId = this.userService.getEmployeeId();
        return this.authHttp.post(`${this.apiUrl}note`, note)
            .toPromise()
            .then(res => {
                return this.addEmployeeInfo(res as Note);
            });
    }

    updateNote(note: Note): Promise<Note> {
        return this.authHttp.put(`${this.apiUrl}note/${note.id}`, note)
            .toPromise()
            .then(res => {
                return res as Note;
            });
    }

    deleteNote(note: any): Promise<ArrayBuffer> {
        return this.authHttp.delete(`${this.apiUrl}note/${note.id}`, note)
            .toPromise()
            .then(res => {
                return res;
            });
    }

    buildNote(note: Note, notableId: number, notableType: string): Note {
        return _.merge(note, { notableId: notableId, notable: notableType });
    }

    addEmployeeInfo(note: Note): Note {
        note['employee'] = this.userService.getEmployee();
        return note;
    }

    getAllNoteTypes(): Promise<any[]> {
        return this.authHttp.get(`${this.apiUrl}notes-type`)
            .toPromise()
            .then((res) => {
                return res as any[];
            });
    }
}
