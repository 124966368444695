import * as _ from 'lodash';
export class RouteParams{
    constructor(data?: any){
        _.merge(this, data);
    }

    public routeId: number = null;
    public dateOfRoute: any = null;
    public states: any = [];
    public city:any = null;
    public productId: number = null;
    public maxCriticalPercent: string = null;
    public county: any = null;
    public zip: string = null;
    public selectedRegions: any = [];
}