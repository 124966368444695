import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Employee } from '@models/employee';
import { Route } from '@models/route';
import { RouteService } from '@services/route.service';

import * as moment from 'moment';
import * as _ from 'lodash';
import { UserService } from '@services/user.service';
import { Product } from '@models/product';
import { skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'drivers',
  templateUrl: 'drivers.component.html',
  styleUrls: ['./drivers.component.css']
})

export class DriversComponent implements OnInit, OnDestroy {
  workOrder: false;
  employee: Employee;
  routes: Route[];
  routeId: number;
  page = 1;
  itemsPerPage = 50;
  totalRoutes = 0;
  employeeRoute: Route;
  viewRoute: Boolean = false;
  driverOption: number;
  ftp = false;
  productDetails: Product[] = [];
  destroyed$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private routeService: RouteService) {
  }

  ngOnInit(): void {
    const { data, queryParams } = this.activatedRoute.snapshot;
    this.employee = data.employee;
    this.routes = data.activeRoutes.rows || [];
    this.totalRoutes = data.activeRoutes.count || 0;
    this.workOrder = data.workOrder;
    this.driverOption = queryParams.option ? queryParams.option : 1;
    this.page = queryParams.page ? queryParams.page : 1;
    this.itemsPerPage = queryParams.itemsPerPage ? queryParams.itemsPerPage : 50;
    this.routeId = queryParams.route ? queryParams.route : null;

    if (this.routeId) {
      if (this.employeeRoute) {
        this.viewRoute = true;
      } else {
        this.getNewRoute(this.routeId);
      }
    }

    this.subscribeToParams();
  }

  subscribeToParams() {
    this.route.queryParams.pipe(
      skip(1),
      takeUntil(this.destroyed$)
    ).subscribe(params => {
      this.routeId = params.route ? params.route : null;
      if (this.routeId) {
        if (this.employeeRoute) {
          this.viewRoute = true;
        } else {
          this.getNewRoute(this.routeId);
        }
      } else {
        this.viewRoute = false;
      }
      if (!this.routeId) {
        switch (+params.option) {
          case 1:
            this.getActiveRoutes();
            break;
          case 2:
            this.getCompletedRoutes();
            break;
          case 3:
            this.getFutureRoutes();
            break;
          default:
            break;
        }
      }
    });
  }

  updateTotalGallons(): void {
    if (!this.workOrder) {
      this.productDetails = this.routeService.calculateGallons(this.employeeRoute);
    }
  }

  setOrder() {
    if (this.employeeRoute) {
      this.employeeRoute.workOrders = _.orderBy(this.employeeRoute.workOrders, ['dropOrder', 'id'], ['asc', 'asc']);
      this.employeeRoute.deliveryOrders = _.orderBy(this.employeeRoute.deliveryOrders, ['dropOrder', 'id'], ['asc', 'asc']);
      this.updateTotalGallons();
    }
  }

  getCompletedRoutes() {
    const employeeId = this.userService.getEmployeeId();
    this.ftp = true;
    return this.routeService.getCompletedEmployeeRoutes({
      page: +this.page || 1,
      itemsPerPage: this.itemsPerPage
    }, employeeId, this.workOrder).then(res => {
      this.viewRoute = false;
      this.routes = [];
      this.routes = res.rows;
      this.totalRoutes = res.count;
      this.ftp = false;
    });
  }

  getFutureRoutes() {
    const setDate = moment();
    const employeeId = this.userService.getEmployeeId();
    this.ftp = true;

    this.routeService.getFutureEmployeeRoutes({
      page: +this.page || 1,
      itemsPerPage: this.itemsPerPage,
      fromDate: setDate
    }, employeeId, this.workOrder).then(res => {
      this.routes = [];
      this.routes = res.rows;
      this.totalRoutes = res.count;
      this.viewRoute = false;
      this.ftp = false;
    });
  }

  getActiveRoutes() {
    const currentDate = moment();
    const outputDateFormat = 'MM-DD-YYYY';
    const setDate = this.workOrder ? moment(currentDate, outputDateFormat).add(1,'days').format(outputDateFormat) : moment().format('MM/DD/YYYY');
    const employeeId = this.userService.getEmployeeId();
    this.ftp = true;
    this.routeService.getActiveEmployeeRoutes({
      fromDate: setDate,
      page: +this.page || 1,
      itemsPerPage: this.itemsPerPage
    }, employeeId, this.workOrder).then(res => {
      this.routes = [];
      this.routes = res.rows;
      this.totalRoutes = res.count;
      this.viewRoute = false;
      this.ftp = false;
    });
  }

  pageChanged({ page, option, route }: { page?: number, option?: number, route?: number }) {
    if (option) this.driverOption = option;
    if (!page) this.page = 1;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: page || 1,
        itemsPerPage: this.itemsPerPage,
        option: option || this.driverOption,
        route: route
      }
    });
  }

  getNewRoute(routeId: number) {
    const employeeId = this.userService.getEmployeeId();
    this.ftp = true;
    this.routeService.getRouteById({ routeId: routeId }, employeeId, this.workOrder).then((route: Route) => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          option: this.driverOption,
          route: routeId
        },
        queryParamsHandling: 'merge'
      });
      if (this.workOrder) {
        this.employeeRoute = route['rows'][0];
      } else {
        this.employeeRoute = route;
      }

      this.setOrder();
      this.viewRoute = true;
      this.ftp = false;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
