<prompt-modal
        #modal
        size="small">

  <div class="modal-header">
    <h4 class="modal-title">Update Work Order {{workOrder.id}} </h4>
  </div>

  <div class="modal-item">
    <div class="col-12">
      <p>Are you sure you would like to {{update ? 'Update' : workOrder.workOrderStatusId == 1 ? 'Close':'Open'}} work order {{workOrder.id}}?</p>
      <strong>Email Employees</strong>
      <br>
      <ng-select [multiple]="true"
                 id="employees"
                 name="Employees"
                 class="email-select"
                 [(ngModel)]="workOrder.emailEmployees"
                 placeholder="Select Employees">
          <ng-option *ngFor="let employee of employeeOptions" [value]="employee.id">{{employee.name}}</ng-option>
      </ng-select>
    </div>
  </div>

  <div class="modal-buttons">
    <button (click)="save();" type="submit" class="btn btn-sm btn-primary">Update</button>
    <button type="button" class="btn btn-sm btn-danger" (click)="modal.hidePromptModal();">Cancel</button>
  </div>

</prompt-modal>
