<div class="panel employee-list-panel">
    <table class="table table-hover">
        <thead>
            <tr>
                <th class="td-20">Name</th>
                <th class="td-20">Email</th>
                <th class="td-10">State</th>
                <th class="td-20">Emp. Types</th>
                <th class="td-10">Access</th>
                <th class="td-10">Reset</th>
                <th class="td-10">
                    <button
                        (click)="active = !active;clearEmployee()"
                        type="button"
                        class="btn btn-sm btn-light"
                        [tooltip]="active ? 'Show inactive' : 'Show active'"
                        placement="left"
                    >{{active ? 'Inactive' : 'Active'}}</button>
                </th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let employee of activeUsers()" (click)="selectEmployee(employee)" [ngClass]="{'selected':isSelected(employee)}">
                <td  class="td-20">{{ employee.lastName }}, {{ employee.firstName }}</td>
                <td  class="td-20">{{ employee.email  }}</td>
                <td  class="td-10">{{ employee.state }}</td>
                <td  class="td-20">{{ listEmployeeTypes(employee) }}</td>
                <td  class="td-10" [ngClass]="{'roleAdmin':employee.User?.role == 'Admin', 'roleLimited': employee.User?.role=='Limited'}">
                    {{ employee.User?.role ? employee.User?.role: '--' }}
                </td>
                <td class="td-10">
                    <a href="javascript:void(0);" (click)="resetPass(employee)" class="margin-left-15">
                        <i class="fa fa-envelope" ></i>
                    </a>
                </td>
                <td  class="td-10" [ngClass]="{'active': employee.isActive, 'inactive': !employee.isActive}">
                    {{ employee.isActive ? 'Active' : 'Inactive' }}
                </td>

            </tr>
        </tbody>
    </table>
</div>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
