import { Component, Input, Output, EventEmitter} from '@angular/core';

import {Trailer} from '../../../models/trailer';
import {Cargo} from '../../../models/cargo';

@Component({
    templateUrl: 'trailer-single.component.html',
    styleUrls: ['trailer-single.component.css'],
    selector: 'trailer-single'
})
export class TrailerSingleComponent{
    @Input()
    trailer: Trailer;
    @Output()
    resetTrailer = new EventEmitter<string>();
    @Output()
    saveTrailer = new EventEmitter<Trailer>();
    constructor(){}

    clearTrailer(){
        this.resetTrailer.emit('reset');
    }

    save(trailer: Trailer){
        this.saveTrailer.emit(trailer);
    }

    addTank() {
        this.trailer.Cargos.push(new Cargo());
    }
}