<div class="inactive-wrap">
  <div class="subheader">
    <div
      class="link"
      [routerLink]="[{ outlets: { inactive: 'inactive-clients' } }]"
      routerLinkActive="admin-active"
    >
      <i class="fa fa-tasks"></i><br class="mobile-br" />
      Inactive<span class="tablet"> Clients</span>
    </div>
    <div
      class="link"
      [routerLink]="[{ outlets: { inactive: 'inactive-tanks' } }]"
      routerLinkActive="admin-active"
    >
      <i class="fa fa-outdent fa-rotate-90" aria-hidden="true"></i
      ><br class="mobile-br" />
      Inactive<span class="tablet"> Tanks</span>
    </div>
    <div
      class="link"
      [routerLink]="[{ outlets: { inactive: 'inactive-dairies' } }]"
      routerLinkActive="admin-active"
    >
      <i class="fa fa-outdent" aria-hidden="true"></i><br class="mobile-br" />
      Inactive<span class="tablet"> Dairies</span>
    </div>
  </div>
  <div class="min-100">
    <div [@routerAnimation]="getView()" class="position-relative">
      <router-outlet name="inactive"></router-outlet>
    </div>
  </div>
</div>
