import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";

import * as moment from "moment";

import { RouteService } from "../services-module/services/route.service";

import { Route } from "../models/route";
import { UserService } from "../services-module/services/user.service";
import { RouteParams } from "../models/route-params";
import { ServiceRegion } from "../models/service-region";
import { ServiceRegionService } from "../services-module/services/service-region.service";
import * as _ from "lodash";

@Injectable({ providedIn: "root" })
export class ServiceRouteResolve implements Resolve<Route> {
  constructor(private routeService: RouteService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.routeService.findServiceRouteById(route.params["id"]);
  }
}

@Injectable({ providedIn: "root" })
export class RouteResolve implements Resolve<Route> {
  constructor(private routeService: RouteService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.routeService.findRouteById(route.params["id"]);
  }
}

@Injectable({ providedIn: "root" })
export class RouteListResolve implements Resolve<any> {
  constructor(private routeService: RouteService) {}

  resolve(route: ActivatedRouteSnapshot) {
    let defaultQuery: any = {};

    if (route.data.workOrder) {
      defaultQuery = {
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().add(30, "days").format("YYYY-MM-DD"),
        itemsPerPage: 75,
        page: 1,
        sort: "desc",
      };
    } else {
      defaultQuery.itemsPerPage = 75;
      defaultQuery.page = 1;
      defaultQuery.sort = "desc";

      defaultQuery.selectedDrivers =
        localStorage.getItem("userSelectedDrivers") !== null
          ? localStorage.getItem("userSelectedDrivers").split(",")
          : undefined;
      defaultQuery.selectedStates =
        localStorage.getItem("userSelectedStates") !== null
          ? localStorage.getItem("userSelectedStates").split(",")
          : undefined;

      defaultQuery.fromDate =
        localStorage.getItem("fromDate") !== null
          ? localStorage.getItem("fromDate")
          : moment().subtract(4, "days").format("YYYY-MM-DD");
      defaultQuery.toDate =
        localStorage.getItem("toDate") !== null
          ? localStorage.getItem("toDate")
          : moment().add(7, "days").format("YYYY-MM-DD");
    }
    const {
      fromDate,
      toDate,
      selectedStates,
      column,
      sort,
      page,
      itemsPerPage,
      search,
      selectedDrivers,
    } = route.params;

    const routeQuery = _.omitBy(
      {
        fromDate,
        toDate,
        selectedStates,
        column,
        sort,
        page,
        itemsPerPage,
        search,
        selectedDrivers,
      },
      (value) => [null, ""].includes(value)
    );

    Object.keys(routeQuery).forEach((param) => {
      if (routeQuery[param] === undefined) delete routeQuery[param];
    });

    const params = Object.assign({}, defaultQuery, routeQuery);

    if (route.data.workOrder) {
      return this.routeService.getCurrentWorkRoutes(params);
    } else {
      return this.routeService.getCurrentRoutes(params);
    }
  }
}

@Injectable({ providedIn: "root" })
export class CurrentWorkRouteResolve implements Resolve<any> {
  constructor(private routeService: RouteService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const startDate = moment().subtract(1, "days").format("MM/DD/YYYY");
    const toDate = moment().add(30, "days").format("MM/DD/YYYY");
    return this.routeService.getCurrentWorkRoutes({
      fromDate: startDate,
      toDate: toDate,
    });
  }
}

@Injectable({ providedIn: "root" })
export class EmployeeActiveRoutesResolve implements Resolve<any> {
  constructor(
    private routeService: RouteService,
    private userService: UserService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const employeeId = this.userService.getEmployeeId();
    const setDate = moment().format("MM/DD/YYYY");

    route.queryParams = {
      fromDate: setDate,
      page: route.queryParams.page ? +route.queryParams.page : 1,
      itemsPerPage: route.queryParams.itemsPerPage
        ? +route.queryParams.itemsPerPage
        : 50,
      option: route.queryParams.option ? +route.queryParams.option : 1,
      isIndex: false,
      route: route.queryParams.route ? route.queryParams.route : null,
    };

    if (route.queryParams.option === 1) {
      return this.routeService.getActiveEmployeeRoutes(
        route.queryParams,
        employeeId,
        false
      );
    } else if (route.queryParams.option === 2) {
      return this.routeService.getCompletedEmployeeRoutes(
        route.queryParams,
        employeeId,
        false
      );
    } else if (route.queryParams.option === 3) {
      return this.routeService.getFutureEmployeeRoutes(
        route.queryParams,
        employeeId,
        false
      );
    }
  }
}

@Injectable({ providedIn: "root" })
export class EmployeeActiveWorkRoutesResolve implements Resolve<any> {
  constructor(
    private routeService: RouteService,
    private userService: UserService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const employeeId = this.userService.getEmployeeId();
    const currentDate = moment();
    const outputDateFormat = "MM-DD-YYYY";
    const setDate = moment(currentDate, outputDateFormat)
      .add(1, "days")
      .format(outputDateFormat);

    route.queryParams = {
      fromDate: setDate,
      page: route.queryParams.page ? +route.queryParams.page : 1,
      itemsPerPage: route.queryParams.itemsPerPage
        ? +route.queryParams.itemsPerPage
        : 50,
      option: route.queryParams.option ? +route.queryParams.option : 1,
      isIndex: false,
      route: route.queryParams.route ? route.queryParams.route : null,
    };

    if (route.queryParams.option === 1) {
      return this.routeService.getActiveEmployeeRoutes(
        route.queryParams,
        employeeId,
        true
      );
    } else if (route.queryParams.option === 2) {
      return this.routeService.getCompletedEmployeeRoutes(
        route.queryParams,
        employeeId,
        true
      );
    } else if (route.queryParams.option === 3) {
      return this.routeService.getFutureEmployeeRoutes(
        route.queryParams,
        employeeId,
        true
      );
    }
  }
}

@Injectable({ providedIn: "root" })
export class RouteRouteResolve implements Resolve<RouteParams> {
  constructor(private routeService: RouteService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const routeId = +route.params["id"];
    return this.routeService.getRouteRouteParams(routeId);
  }
}

@Injectable({ providedIn: "root" })
export class ServiceRegionResolve implements Resolve<ServiceRegion[]> {
  constructor(private serviceRegionService: ServiceRegionService) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.serviceRegionService.getAllServiceRegions().then((regions) => {
      return regions.filter((region) => region.isActive);
    });
  }
}
