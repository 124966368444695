import { Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter } from '@angular/core';

import * as _ from 'lodash';

import { AddressService } from '@services/address.service';
import { NoteService } from '@services/notes.service';
import { ProductService } from '@services/product.service';
import { ServiceTypeService } from '@services/service-types.service';
import { TankService } from '@services/tank.service';
import { TankTypeService } from '@services/tank-type.service';
import { UserService } from '@services/user.service';
import { WorkOrderService } from '@services/work-order.service';

import { DeliveryOrderService } from '@services/delivery-order.service';

import { Address } from '@models/address';
import { Note } from '@models/note';
import { Product } from '@models/product';
import { ServiceType } from '@models/service-type';
import { Tank } from '@models/tank';
import { TankType } from '@models/tank-type';
import { WorkOrder } from '@models/work-order';
import { DeliveryOrder } from '@models/delivery-order';
import * as moment from 'moment';
import { Employee } from '@models/employee';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@services/toast.service';
import { WorkOrderDetail } from '@models/work-order-detail';
import { WorkOrderDetailService } from '@services/work-order-detail.service';
import { VaporTestService } from '@services/vapor-test.service';
import { VaporTest } from '@models/vapor-test';
import { Client } from '../client';
import { EmployeeService } from '@services/employee.service';

@Component({
  templateUrl: './address.component.html',
  styleUrls: ['../client.component.css', './address.component.css'],
  selector: 'address'
})
export class AddressComponent implements OnChanges, OnInit {
  ftp: Boolean = false;
  @Input()
  clientsDairies: Client[];
  @Input()
  currentAddress: Address;
  @Output() setContacts = new EventEmitter<Address>();
  products: Product[];
  address: Address;
  notes: Note[];
  tankTypes: TankType[];
  serviceTypes: ServiceType[];
  showNotesPanel = false;
  showTanksPanel = false;
  showDropsPanel = false;
  showWorkOrdersPanel = false;
  employees: Employee[];
  employeeOptions: any[];
  workOrders: WorkOrder[];
  workOrder: WorkOrder;
  vaporTests: VaporTest[];
  selectedDeliveryAddress: any;
  // dropCharts:any[];
  // dropLabels:any[];

  constructor(private addressService: AddressService,
    private employeeService: EmployeeService,
    private noteService: NoteService,
    private activeRoute: ActivatedRoute,
    private tankService: TankService,
    private vaporTestService: VaporTestService,
    private productService: ProductService,
    private tankTypeService: TankTypeService,
    private serviceTypeService: ServiceTypeService,
    private workOrderService: WorkOrderService,
    private workOrderDetailService: WorkOrderDetailService,
    private userService: UserService,
    private toastService: ToastService,
    private deliveryOrderService: DeliveryOrderService) {
  }

  ngOnInit() {
    this.address = new Address();
    this.notes = [];
    this.getTankTypes();
    this.getProductTypes();
    this.getServiceTypes();
    this.employees = this.activeRoute.snapshot.data['employees'];
    this.workOrder = new WorkOrder();
    this.workOrder['emailEmployees'] = [];
    this.employeeOptions = this.employeeService.getEmployeeOptions(this.employees);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const newValue = changes['currentAddress'].currentValue;
    const addressId = newValue ? newValue.id : null;
    if (addressId) {
      this.setAddress(addressId);
      this.getAddressWorkOrders(addressId);
      this.getAddressNotes(addressId);
      this.getVapTests(addressId);
    }
  }

  getAddressNotes(addressId: number) {
    this.addressService.getAddressNotes(addressId)
      .then(res => {
        this.notes = res;
        this.ftp = false;
      });
  }
  getAddressWorkOrders(addressId: number) {
    this.workOrders = [];
    this.workOrderService.getAddressWorkOrders(addressId)
      .then((res) => {
        this.workOrders = res;
      });
  }
  getVapTests(addressId: number) {
    this.vaporTests = [];
    this.vaporTestService.getAddressVaporTests(addressId)
      .then(res => {
        this.vaporTests = res;
      });
  }

  setAddress(id: number): void {
    if (!id) {
      this.address = new Address();
    } else {
      this.getAddressInfo(id)
        .then((res) => {
          res.deliveryOrders = this.address.deliveryOrders;
          this.address = new Address(res);
          this.setContacts.emit(res);
          this.addressService.getDeliveryOrdersForAddress(id).then((resp => {
            this.address.deliveryOrders = resp;
          }));
        });
    }
  }

  getAddressInfo(id: number) {
    return this.addressService.getAddress(id);
  }

  togglePanel(panel: string): void {
    let open: boolean;

    switch (panel) {
      case 'notes':
        this.showNotesPanel = !this.showNotesPanel;
        open = this.showNotesPanel;
        break;
      case 'tanks':
        this.showTanksPanel = !this.showTanksPanel;
        open = this.showTanksPanel;
        break;
      case 'drops':
        this.showDropsPanel = !this.showDropsPanel;
        open = this.showDropsPanel;
        if (!this.showDropsPanel) {
          this.address.deliveryOrders = this.address.deliveryOrders.filter(order => order.deliveredDate);
        }
        break;
      case 'work-orders':
        this.showWorkOrdersPanel = !this.showWorkOrdersPanel;
        open = this.showWorkOrdersPanel;
        break;
      default:
        return null;
    }

    if (open) this.scrollToElement(250, panel);
  }

  saveAddress(address: Address): void {
    this.addressService.saveAddress(address).then((res) => {
      _.merge(this.address, res);
    });
  }

  saveAddressNote(event: Note): void {

    this.ftp = true;
    this.noteService.saveNote(this.noteService.buildNote(event, this.address.id, 'address'))
      .then((res) => {
        this.notes = [];
        this.getAddressNotes(this.currentAddress.id);
      });
  }

  moveTank(event: Tank): void {
    this.ftp = true;
    this.tankService.saveTank(event)
      .then(() => {
        this.address.Tanks = _.filter(this.address.Tanks, (tank) => tank.id !== event.id);
        this.address.Tanks = _.cloneDeep(this.address.Tanks);
        this.ftp = false;
      });
  }

  saveTank(event: Tank): void {
    this.ftp = true;
    this.tankService.saveTank(this.tankService.buildTank(event, this.address.id, this.address.clientId))
      .then((res) => {
        if (event.id) {
          if (event.isActive) {
            event = this.setTankType(this.setTankProduct(event));
            this.findAndReplace(this.address.Tanks, res);
          } else {
            this.address.Tanks = _.filter(this.address.Tanks, (tank) => tank.id !== event.id);
          }
        } else {
          this.address.Tanks.push(this.setTankType(this.setTankProduct(res)));
        }
        this.address.Tanks = _.cloneDeep(this.address.Tanks);
        this.ftp = false;
      });
  }

  overrideTankLevel(tank: Tank): void {
    const user = this.userService.getEmployee();
    const order = new DeliveryOrder({
      scheduledDeliveryDate: null,
      delivered: false,
      quantityOrdered: 0,
      quantityDropped: 0,
      tankLevelPostDrop: tank.currentGallons,
      enteredBy: user.firstName + ' ' + user.lastName,
      clientId: tank.clientId,
      productId: tank.productId,
      tankId: tank.id,
    });
    this.deliveryOrderService.createDeliveryOrder(order).then((res) => {
      if (res) {
        res.deliveredDate = res.createdAt;
        this.saveTank(tank);
        res['Tank'] = tank;
        this.address.deliveryOrders.unshift(res);
      }
    });
  }

  saveOverride(orderInfo: any) {
    const user = this.userService.getEmployee();
    const tank = orderInfo.order.Tank;
    tank.currentGallons = orderInfo.order.tankLevelPostDrop;

    const newOrder = {
      clientId: tank.clientId,
      productId: tank.productId,
      tankId: tank.id,
      deliveredDate: moment(orderInfo.order['tempDate']).set({ hour: 8 }).toDate()
    };

    const order = Object.assign({}, orderInfo.order, newOrder);
    order.enteredBy = order.enteredBy || user.firstName + ' ' + user.lastName;
    delete orderInfo.order.tempDate;
    delete orderInfo.order.clone;

    this.deliveryOrderService.saveDeliveryOrder(order).then((res) => {
      if (res) {
        // this.saveTank(tank);
        res['Tank'] = tank;
        if (order.id) {
          orderInfo.index = this.address.deliveryOrders.map(o => o.id).indexOf(order.id);
        } else {
          const newId = order.newId;
          delete order.newId;
          orderInfo.index = this.address.deliveryOrders.map(o => o.newId).indexOf(newId);
        }
        this.address.deliveryOrders[orderInfo.index] = res;
      }
      this.setAddress(this.address.id);
    });
  }

  deleteOverride(order: Tank) {
    this.deliveryOrderService.deleteDeliveryOrders(order.id)
      .then((res) => {
        const index = this.address.deliveryOrders.map(o => o.id).indexOf(order.id);
        this.address.deliveryOrders.splice(index, 1);
        this.setAddress(this.address.id);
      });
  }

  saveWorkOrder(order: WorkOrder) {
    this.ftp = true;
    if (order.id) {

      order['updatedBy'] = this.userService.getEmployeeId();

      this.buildWorkOrderEmail(order);

      this.workOrderService.updateWorkOrder(order)
        .then((res) => {
          this.getAddressWorkOrders(this.address.id);
          this.ftp = false;
          this.toastService.toast({ message: `You have successfuly updated work order number ${res.id}. `, type: 'success' });
          this.getWorkOrderById(res.id);
        });
    } else {
      order.clientId = this.address.clientId;
      order['addressId'] = this.address.id;
      order.employeeId = this.userService.getEmployeeId();
      order['workOrderStatusId'] = 1;
      order['isActive'] = true;
      this.buildWorkOrderEmail(order);
      this.workOrderService.createWorkOrder(order)
        .then((res) => {
          this.getAddressWorkOrders(this.address.id);
          this.ftp = false;
          this.toastService.toast({ message: `You have successfully created work order number ${res.id}. `, type: 'success' });
          this.workOrder = new WorkOrder();
        });
    }
  }

  deleteWorkOrder(workOrder: WorkOrder) {
    this.workOrderService.deleteWorkOrder(workOrder)
      .then((res) => {
        this.getAddressWorkOrders(this.address.id);
        this.workOrder = new WorkOrder();
        this.toastService.toast({ message: `You have successfuly removed work order number #${workOrder.id}. `, type: 'success' });
      });
  }

  buildWorkOrderEmail(order: WorkOrder) {
    if (!order.id) {
      order = this.workOrder;
    }
    order.Address = this.address;
    this.workOrderService.buildWorkOrderEmail(order);
  }

  getWorkOrderById(workOrderId: number) {
    this.workOrderService.getSingleWorkOrder(workOrderId)
      .then((res) => {
        this.ftp = false;
        this.workOrder = res;
      });
  }

  createNote(details: { workOrder: WorkOrder, note: WorkOrderDetail }) {
    const note = details.note;
    if (!note.id) {
      note.clientId = this.address.clientId;
      note.employeeId = this.userService.getEmployeeId();
    }
    this.ftp = true;
    this.workOrderDetailService.saveNote(details.workOrder, note)
      .then(res => {
        this.toastService.toast({ message: 'You have Successfully updated the work order.', type: 'success' });
        this.getAddressWorkOrders(this.address.id);
        this.getWorkOrderById(note.workOrderId);
      });
  }

  setWorkOrderInfo(workOrder: WorkOrder): WorkOrder {
    workOrder.ServiceType = _.find(this.serviceTypes, { PK_ServiceType: workOrder.ServiceType_PK });
    return workOrder;
  }

  findAndReplace(arr: any[], item: any) {
    _.merge(_.find(arr, { id: item.id }), item);
  }

  getTankTypes(): void {
    this.tankTypeService.getAllTankTypes()
      .then(res => this.tankTypes = res);
  }

  getProductTypes(): void {
    this.productService.getProducts()
      .then(res => this.products = res);
  }

  getServiceTypes(): void {
    this.serviceTypeService.getAll().then(res => this.serviceTypes = res);
  }

  setTankType(tank: Tank): Tank {
    if (typeof tank.tankTypeId === 'string') {
      tank.tankTypeId = _.parseInt(tank.tankTypeId);
    }
    tank.TankType = _.find(this.tankTypes, { id: tank.tankTypeId });
    return tank;
  }

  setTankProduct(tank: Tank): Tank {
    if (typeof tank.productId === 'string') {
      tank.productId = _.parseInt(tank.productId);
    }
    tank.Product = _.find(this.products, { id: tank.productId });
    return tank;
  }

  scrollToElement(duration: number, eleId: string): void {
    if (duration <= 0) return;
    const element = document.body;
    const to = document.getElementById(eleId).offsetTop;
    const difference = to - element.scrollTop;
    const perTick = difference / duration * 10;
    setTimeout(() => {
      element.scrollTop = element.scrollTop + perTick;
      if (element.scrollTop === to) return;
      this.scrollToElement(duration - 10, eleId);
    }, 10);
  }

}
