import { Injectable } from '@angular/core';
import { DeliveryOrder } from '../../models/delivery-order';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DeliveryOrderService {
    private apiUrl = environment.apiUrl;  // URL to web api

    constructor(public authHttp: HttpClient) {
    }

    getDeliveryOrder(id: number): Promise<DeliveryOrder> {
        return this.authHttp.get(`${this.apiUrl}delivery-order/${id}`)
            .toPromise()
            .then((response) => {
                return response as DeliveryOrder;
            })
            .catch(this.handleError);
    }

    // use angular2-jwt authHttp when accessing secure routes
    getDeliveryOrders(): Promise<DeliveryOrder[]> {
        return this.authHttp.get(`${this.apiUrl}delivery-order`)
            .toPromise()
            .then((response) => response as DeliveryOrder[])
            .catch(this.handleError);
    }

    getSingleDeliveryOrder(deliveryOrderId: number): Promise<DeliveryOrder> {
        return this.authHttp.get(`${this.apiUrl}delivery-order/${deliveryOrderId}`)
            .toPromise()
            .then((response) => response as DeliveryOrder)
            .catch(this.handleError);
    }

    saveDeliveryOrder(deliveryOrder: DeliveryOrder): Promise<DeliveryOrder> {
        if (deliveryOrder.id) {
            return this.updateDeliveryOrder(deliveryOrder);
        } else {
            return this.createDeliveryOrder(deliveryOrder);
        }
    }

    createDeliveryOrder(deliveryOrder: DeliveryOrder): Promise<DeliveryOrder> {
        return this.authHttp.post(`${this.apiUrl}delivery-order`, deliveryOrder)
            .toPromise()
            .then((res) => {
                return res as DeliveryOrder;
            });
    }
    updateDeliveryOrder(deliveryOrder: DeliveryOrder) {
        return this.authHttp.put(`${this.apiUrl}delivery-order/${deliveryOrder.id}`, deliveryOrder)
            .toPromise()
            .then((res) => {
                return res as DeliveryOrder;
            });
    }

    deleteDeliveryOrders(deliveryOrderId: number) {
        return this.authHttp.delete(`${this.apiUrl}delivery-order/${deliveryOrderId}`)
            .toPromise()
            .then(res => {
                return res as DeliveryOrder;
            });
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
