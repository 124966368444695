<div *ngIf="contact || editNumber === false" class="edit-number">
    <div><strong>Name:</strong> <span>{{ contact.name }}</span> <a href="javascript:;" (click)="edit()"> Edit</a></div>
    <div><strong>Title:</strong> <span>{{ contact.description }}</span></div>
    <div><strong>Email:</strong> <span>{{ contact.email}}</span> <span *ngIf="!contact.email">--</span></div>
    <a href="javascript:;" (click)="editPhone()">Add Number</a>
    <div class="form-group">
        <label for="contactNumbers">Numbers</label>
        <select name="contactNumbers" id="contactNumbers" class="form-control" [(ngModel)]="selectedPhoneId">
            <option [value]="phone.id" *ngFor="let phone of contact.PhoneNumbers">
                {{ phone.type}}: {{phone.number}}
            </option>
        </select>
        <a href="javascript:;" (click)="editPhone(selectedPhoneId)">Edit</a>
    </div>
</div>
<div *ngIf="editNumber" class="edit-number row">
    <div class="form-group col-12">
        <label for="phoneNumber">Number</label>
        <input type="text" id="phoneNumber" name="phoneNumber" [(ngModel)]="phone.number" class="form-control">
    </div>
    <div class="form-group col-12 col-lg-6" >
        <label for="phoneType">Type</label>
        <select name="phoneType" id="phoneType" [(ngModel)]="phone.type" class="form-control">
            <option value="">--</option>
            <option value="Phone">Phone</option>
            <option value="Fax">Fax</option>
            <option value="Cell">Cell</option>
        </select>
    </div>

    <div class="form-group col-12 col-lg-6" >
        <label for="ext">Ext</label>
        <input type="text" id="ext" name="ext" [(ngModel)]="phone.ext" class="form-control">
    </div>

    <div class="form-group col-12">
        <button class="btn btn-primary" (click)="savePhone(phone)">Save</button>
        <button class="btn btn-danger" (click)="clearPhone()">Cancel</button>
    </div>
</div>
<div *ngIf="!contact">
    <div>nothing to display</div>
</div>
