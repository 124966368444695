import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { clientRouting } from './client.routing';

import {InfoPanelsModule} from '../info-panels/info-panels.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {ClientComponent} from './client.component';
import { ClientListComponent } from './client-list/client-list.component';
import {ClientGridComponent} from './client-grid/client-grid.component';
import {ClientSingleComponent} from './client-single/client-single.component';
import {ClientDetailsComponent} from './client-single/client-details/client-details.component';
import {ClientEditComponent} from './client-single/client-edit/client-edit.component';
import {AddressInfoComponent} from './client-single/address-info/address-info.component';
import {AddressEditComponent} from './client-single/address-edit/address-edit.component';
import {ClientAddComponent} from './client-add/client-add.component';
import {AddressComponent} from './address/address.component';
import {AddressDetailsComponent} from './address/address-details/address-details.component';
import {AddressNotesComponent} from './address/address-notes/address-notes.component';
import {AddressTanksComponent} from './address/address-tanks/address-tanks.component';
import {AddressWorkOrdersComponent} from './address/address-work-orders/address-work-orders.component';
import {AddressInventoryComponent} from './address/address-inventory/address-inventory.component';
import {AddressLabworkComponent} from './address/address-labwork/address-labwork.component';
import {ClientsNavComponent} from './clients-nav/clients-nav.component';
import {ClientContactInfoComponent} from './client-single/client-contact/contact-info.component';
import {ClientContactEditComponent} from './client-single/client-contact/edit-contact.component';
import {AddressDocumentsComponent} from './address/address-documents/address-documents.component';
import {PipesModule} from '../pipes/pipes.module';
import {PreviewImageComponent} from './address/address-documents/preview-image.component';
import {AddressVaporTestComponent} from './address/address-vapor-test/address-vapor-test.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ChartsModule } from 'ng2-charts';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TankPercentPipe } from './address/address-tanks/tank-percent.pipe';
import { TankDataPipe } from './address/address-tanks/tank-data.pipe';
import { TankColorPipe } from './address/address-tanks/tank-color.pipe';
import { TankChartLabelPipe } from './address/address-tanks/tank-chart-label.pipe';
import { TankStatusPipe } from './address/address-tanks/tank-status.pipe';
import { TankLevelPipe } from './address/address-tanks/tank-level.pipe';

@NgModule({
    imports: [
        CommonModule,
        clientRouting,
        NgxDatatableModule,
        FormsModule,
        InfoPanelsModule,
        PipesModule,
        SharedComponentsModule,
        NgSelectModule,
        BsDropdownModule.forRoot(),
        DatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        PaginationModule.forRoot(),
        ChartsModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        ClientListComponent,
        ClientComponent,
        ClientGridComponent,
        ClientAddComponent,
        ClientEditComponent,
        AddressComponent,
        AddressInfoComponent,
        AddressEditComponent,
        AddressLabworkComponent,
        AddressDocumentsComponent,
        AddressVaporTestComponent,
        ClientSingleComponent,
        ClientDetailsComponent,
        AddressDetailsComponent,
        AddressNotesComponent,
        AddressTanksComponent,
        AddressWorkOrdersComponent,
        AddressInventoryComponent,
        ClientsNavComponent,
        ClientContactInfoComponent,
        ClientContactEditComponent,
        PreviewImageComponent,
        TankPercentPipe,
        TankChartLabelPipe,
        TankColorPipe,
        TankDataPipe,
        TankLevelPipe,
        TankStatusPipe
    ],
    providers: []
})

export class ClientModule { }
