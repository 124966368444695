<div class="panel-body scroll-overflow-y min-height-13">
  <ng-container *ngIf="getTableIndex(selectedTable?.table_name) !== -1">
    <div class="row margin-bottom-5" *ngFor="let cond of tables[getTableIndex(selectedTable?.table_name)].conditions; let i = index">
      <div class="col-lg-6 no-padding-right">
        <div class="input-group">
          <div class="input-group-btn">
            <div class="btn-group" dropdown>
              <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle btn-cond">
                {{getLeftOperator(cond)}} <span class="caret"></span>
              </button>
              <div *dropdownMenu class="dropdown-menu height-75" role="menu">
                <ul class="cond-list" *ngFor="let op of leftOperators">
                  <li role="menuitem" (click)="setOperator('left', selectedTable?.table_name, cond, op, i)"><a class="dropdown-item">{{op}}</a></li>
                </ul>
              </div>
            </div>
          </div>
          <input autofocus name="leftVal" [(ngModel)]="cond[getLeftOperator(cond)][0]"
                 [typeahead]="selectedTable.columns" typeaheadOptionField="column_name"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 typeaheadOptionsLimit="50"
                 placeholder="Type a table property"
                 (focus)="noUsers = false"
                 class="form-control cond-select-input">
        </div>
      </div>
      <div class="col-lg-6 no-padding-left">
        <div class="input-group">
          <div class="input-group-btn">
            <div class="btn-group" dropdown>
              <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle btn-cond">
                {{getRightOperator(cond)}} <span class="caret"></span>
              </button>
              <div *dropdownMenu class="dropdown-menu height-75" role="menu">
                <ul class="cond-list" *ngFor="let opt of validOperators">
                  <li role="menuitem" (click)="setOperator('right', selectedTable?.table_name, cond, opt, i)"><a class="dropdown-item">{{opt}}</a></li>
                </ul>
              </div>
            </div>
          </div>
          <input [type]="getInputType(i)" placeholder="Type a value (or values for 'IN')" [(ngModel)]="cond[getLeftOperator(cond)][2]" class="form-control" aria-label="Text input with dropdown button">
          <span class="input-group-btn">
            <button class="btn btn-light" [disabled]="false" type="button" (click)="removeConditional(selectedTable?.table_name, i)">X</button>
          </span>
        </div>
        <div class="input-group" *ngIf="getRightOperator(cond).toLowerCase() == 'between'">
          <div class="input-group-btn">
            <div class="btn-group" dropdown>
              <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle btn-cond">
                AND
              </button>
            </div>
          </div>
          <input [type]="getInputType(i)" placeholder="Type or select a value" [(ngModel)]="cond[getLeftOperator(cond)][3]" class="form-control" aria-label="Text input with dropdown button">
          <!--<span class="input-group-btn">
            <button class="btn btn-light" [disabled]="false" type="button" (click)="removeConditional(selectedTable.table_name, i)">X</button>
          </span>-->
        </div>
      </div>
    </div>
  </ng-container>
</div>
