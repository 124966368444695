import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {Employee} from '../../../models/employee';
import {EmployeeType} from '../../../models/employee-type';
import { ToastService } from '../../../services-module/services/toast.service';
import { ActivatedRoute } from "@angular/router";
import { ServiceRegion } from "../../../models/service-region";

@Component({
    templateUrl: 'employee-single.component.html',
    styleUrls: ['employee-single.component.css'],
    selector: 'employee-single'
})
export class EmployeeSingleComponent implements OnChanges{
    @Input() employee: Employee;
    @Input() employeeTypes: EmployeeType[];
    @Output() resetEmployee = new EventEmitter<string>();
    @Output() saveEmployee = new EventEmitter<Employee>();

    types: any;
    empTypes: any[];
    regions: ServiceRegion[];

    constructor(private toastService: ToastService, private activatedRoute: ActivatedRoute){
        this.regions = this.activatedRoute.snapshot.data['serviceRegions'].map(region => {
            return {
                id: region.id,
                name: region.serviceRegionName
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void{
        if(changes.employeeTypes && this.employeeTypes){
            this.empTypes = this.employeeTypes.map((type)=>{
                return {
                    id: type.id,
                    name: `${type.employeeType}`
                };
            });
        }

    }

    clearEmployee(form:UntypedFormGroup){
        form.reset();
        this.resetEmployee.emit('reset');
    }

    save(employee: Employee, form:UntypedFormGroup){
        if(form.valid) {
            this.saveEmployee.emit(employee);
        } else {
            this.toastService.validate(form);
        }
    }

}
