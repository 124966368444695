import { AfterViewInit, Directive, ElementRef } from "@angular/core";

@Directive({
  selector: '[appZoom]'
})
export class ZoomDirective implements AfterViewInit {

  constructor(private el: ElementRef) {
    console.log(el);
    el.nativeElement.classList.add('zoom');
    Object.assign(el.nativeElement?.firstChild?.style || {}, {
      opacity: '0',
      transform: 'scale(0)',
      transition: 'all .4s ease-in-out'
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.el.nativeElement.classList.add('open');
    });
  }
}
