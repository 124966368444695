<div *ngFor="let product of productDetails" class="pull-right text-left no-mobile-pull margin-top-10 padding-0-20">
    <h3 class="product-title">{{product.abbr}} Levels
        <button class="btn btn-sm btn-warning" (click)="editLevel(product)" *ngIf="editProduct != product">Edit</button>
        <button class="btn btn-sm btn-success"
                (click)="updateLoadedProduct(editLoaded)"
                *ngIf="editProduct === product" >Save</button>
        <button class="btn btn-sm btn-danger" *ngIf="editProduct === product" (click)="editProduct = null;">Cancel</button>
    </h3>
    <div class="product-levels-edit form-inline row">
        <div class="col-12">
            <div class="level-inline"><strong>Ordered: </strong> <span>{{product.ordered}} gals</span></div>
            <div  class="level-inline"><strong>Loaded: </strong>
                <span *ngIf="editProduct !== product">{{product.level}} gals</span>
                <input type="text"
                    *ngIf="editProduct === product"
                    class="form-control mobile-width level-input edit-product"
                    [(ngModel)]="editLoaded">
            </div>
            <div class="level-inline"><strong>Delivered: </strong> <span>{{product.delivered}} gals</span></div>
            <div class="level-inline"><strong>Remaining: </strong> <span>{{product.remaining}} gals</span> </div>
        </div>
    </div>
</div>
