<div id="tanks" class="panel margin-top-20">
    <div class="panel-header">
        <div class="flex-container flex-space-between">
            <h4><i class="fa fa-database"></i> Tanks</h4>
            <button type="button" class="btn btn-sm btn-danger" (click)="hide()">X</button>
        </div>
    </div>
    <div class="container-fluid panel-content overflow">
        <div class="row margin-top-10">
            <div class="col-12">
                <form #tankForm="ngForm" (submit)="save(tank, tankForm.form)" class="form tank-form form-inline" novalidate>
                    <div *ngIf="!tankOverride" class="form-row">
                        <div class="form-group col-sm-3 mb-3">
                            <label class="text-left" for="tankType">*Tank Type</label>
                            <select name="Tank Type"
                                    id="tankType"
                                    class="form-control"
                                    required
                                    (change)="setTankType($event)"
                                    [(ngModel)]="tank.tankTypeId">
                                <option value="" selected>Tank Type</option>
                                <option [value]="type.id" *ngFor="let type of tankTypes">
                                    {{ type.tankTypeName }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-sm-3 mb-3">
                            <label class="text-left" for="product">*Product Type</label>
                            <select name="Product"
                                    id="product"
                                    class="form-control"
                                    required
                                    [(ngModel)]="tank.productId">
                                <option value="" selected>Product Type</option>
                                <option [value]="type.id" *ngFor="let type of products">
                                    {{ type.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-sm-3 mb-3">
                            <label class="text-left" for="tankName">*Tank Name</label>
                            <input type="text"
                                   class="form-control"
                                   name="Tank Name"
                                   id="tankName"
                                   placeholder="Tank Name"
                                   required
                                   [(ngModel)]="tank.tankName">
                        </div>
                        <div class="form-group col-sm-3 mb-3">
                            <label class="text-left" for="averageUsage">Projected Avg/Daily Usage</label>
                            <input type="number"
                                   min="0"
                                   class="form-control"
                                   id="averageUsage"
                                   name="Average Usage"
                                   placeholder="Avg Usage"
                                   [(ngModel)]="tank.expAvgDailyUse">
                        </div>
                        <div class="form-group col-sm-3 mb-3">
                            <label class="text-left" for="alertThreshhold">Alert %</label>
                            <input type="number"
                                   min="0"
                                   max="100"
                                   class="form-control"
                                   id="alertThreshhold"
                                   name="Alert Threshhold"
                                   placeholder="Alert %"
                                   [(ngModel)]="tank.serviceThreshholdAlert">
                        </div>
                        <div class="form-group col-sm-3 mb-3">
                            <label for="criticalThreshhold">Critical %</label>
                            <input type="number"
                                   min="0"
                                   max="100"
                                   class="form-control"
                                   id="criticalThreshhold"
                                   name="Critical Threshhold"
                                   placeholder="Critical %"
                                   [(ngModel)]="tank.serviceThreshholdCritical">
                        </div>
                        <div class="form-group col-sm-3 mb-3">
                            <label for="currentGal">Current Gallons</label>
                            <input type="number"
                                   min="0"
                                   [max]="tank?.TankType?.capacityVolume"
                                   class="form-control current-gallons"
                                   id="currentGal"
                                   name="Current Gallon"
                                   placeholder="Current Gallons"
                                   (blur)="currentLimit(tank)"
                                   [(ngModel)]="tank.currentGallons">
                        </div>
                        <div class="form-group col-sm-12 mb-3">
                            <div class="input-required-msg">Inputs with "*" are required</div>
                        </div>

                    </div>
                    <div *ngIf="tankOverride">
                    <span class="margin-top-10 pull-left">
                        <label for="currentGal">Desired Inventory Level</label>
                        <input type="number"
                               [title]="!tank.id ? 'Select record to set inventory level.' : 'Inventory Override'"
                               min="0"
                               [max]="tank?.TankType?.capacityVolume"
                               class="form-control"
                               id="currentGalOverride"
                               name="Current Gallon"
                               placeholder="Current Gallons"
                               [(ngModel)]="tank.currentGallons"
                               [disabled]="!tank.id">
                    </span>
                        <span class="pull-right margin-top-10">
                        <button type="button"
                                class="btn btn-primary"
                                (click)="overrideLevel(tank); tankOverride=false;"
                                [disabled]="!tank.id">Override</button>
                        <button type="button" class="btn btn-danger" (click)="tankOverride=false;">Cancel</button>
                    </span>
                    </div>

                    <div *ngIf="moving" class="move-tank">
                        <h3>Select the target address for where this tank will be moved</h3>
                        <div id="myClientList">
                            <input autocomplete="off" type="text" placeholder="Search.." id="myInput" [(ngModel)]="search" name="search" class="input" (focus)="showClients = true" wo-click-outside (woClickOutside)="closeDropdown($event)">
                            <div class="client-dropdown" *ngIf="showClients" id="clientDropdown">
                                <ul>
                                    <li *ngFor="let client of clientsDairies | clientSearchPipe:search">
                                        <span *ngIf="client.Addresses.length">{{client.name}}</span>
                                        <div *ngFor="let address of client.Addresses" class="dairy-info">
                                            <div (click)="selectAddress(client, address)">{{address.alias}}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <span *ngIf="newClient">Selected Client: {{newClient.name}}: {{newAddress.alias}}</span>
                    </div>

                    <div *ngIf="!tankOverride" class="margin-top-20">
                        <p class="bg-success" *ngIf="saveSuccessful">Tank information saved successfully</p>
                        <p class="bg-danger" *ngIf="saveError">There was an error when saving tank information</p>
                        <button type="submit" class="btn btn-primary" *ngIf="!moving">{{tank.id ? 'Save' : 'Add Tank'}}</button>

                        <button type="button" class="btn btn-primary" *ngIf="tank.id && !moving" (click)="moving = true">Move Tank</button>
                        <button type="button" class="btn btn-primary" [disabled]="!newClient" *ngIf="tank.id && moving" (click)="callMoveTank(tank)">Confirm Move</button>
                        <button type="button" class="btn btn-danger" (click)="moving = false"  *ngIf="moving" (click)="clearMove()">Cancel Move</button>

                        <button type="button" class="btn btn-danger" (click)="cancel()"  *ngIf="!moving">Clear</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="orders-table">
                    <table class="table table-striped">
                        <thead class="wo-table-head">
                        <tr>
                            <th class="td-5"><span class="sm-view-no">Detail</span></th>
                            <th class="td-15"><span class="sm-view-no">Tank Name</span></th>
                            <th class="td-10"><span class="sm-view-no">Tank Type</span></th>
                            <th class="td-10">Product</th>
                            <th class="td-10"><span class="sm-view-no">Curr Gal</span></th>
                            <th class="td-10">Ttl Cap</th>
                            <th class="td-10"><span class="sm-view-no">Projected Avg/Daily</span></th>
                            <th class="td-10"><span class="sm-view-no">Actual Avg/Daily</span></th>
                            <th class="td-10">Percent</th>
                            <th class="td-10">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tank of tanks"
                                [ngClass]="{'lightGreen':tank.id === rowId }" (click)="select($event, tank)">
                                <span *ngIf="tank.isActive == 1">
                                    <div class="work-order-info">
                                        <td class="td-5">
                                            <div [ngClass]="{'rotate': tank.id === rowId || openAll}"
                                                (click)="toggleRow(tank)"
                                                class="rowCarat tankLinkToggle"> <i class="fa fa-caret-right tankLinkToggle"></i> </div>
                                        </td>
                                        <td class="td-15 point"><span>
                                            <i class="fa fa-circle tank-link" *ngIf="tank.TankDevice?.SerialNumber"></i>
                                            {{ tank.tankName ? tank.tankName : 'No Tank Name'}}</span> </td>
                                        <td class="td-10 point"><span>{{ tank.TankType?.tankTypeName }}</span></td>
                                        <td class="td-10"><span></span>{{ tank.Product?.name }}</td>
                                        <td class="td-10 point"><span>{{ tank.currentGallons }}</span></td>
                                        <td class="td-10 point">{{ tank.TankType?.capacityVolume}}</td>
                                        <td class="td-10 point">{{ tank.expAvgDailyUse }}</td>
                                        <td class="td-10 point">{{ tank.calculatedDailyUse }}</td>
                                        <td class="td-10 point">{{ getTankPercent(tank) | percent:'1.0'}}</td>
                                        <td class="td-10 point">{{ getTankStatus(tank) }}</td>
                                        <!--{{tank.isActive}}-->
                                    </div>
                                    <div class="work-order-detail" *ngIf="tank.id == rowId || openAll">
                                        <div *ngIf="tank.TankDevice.id && tankLinkEdit === false">
                                            <h4 class="work-order-detail-heading">Tank Link Info <div class="sep"></div></h4>
                                            <td class="td-15"><div>Tank Link Name: {{ tank.TankDevice.TankDeviceName }}</div></td>
                                            <td class="td-15"><div>Serial #: {{ tank.TankDevice.SerialNumber }}</div></td>
                                            <td class="td-15">
                                                <div>Cell Id: {{tank.TankDevice.CellularID}}</div>
                                            </td>
                                            <td class="td-15">
                                                Is Active: {{ tank.TankDevice.isActive ? "Yes" : "No" }}
                                            </td>
                                            <td class="td-15">
                                                <button class="btn btn-warning"
                                                        (click)="editTankLink(tank);$event.preventDefault();$event.stopPropagation()">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                                <button type="button" class="btn btn-danger"
                                                        (click)="setDeleteTank(tank);$event.preventDefault();$event.stopPropagation()">Delete</button>
                                            </td>
                                        </div>
                                        <div *ngIf="!tank.TankDevice.id || tankLinkEdit === true">
                                            <h4 class="work-order-detail-heading">Tank Link Info <div class="sep"></div></h4>
                                            <form #tankDeviceForm="ngForm" class="form-inline tank-link-form">
                                                <td class="td-20">
                                                    <div class="form-group">
                                                        <label for="TankDeviceName">
                                                            *Name
                                                        </label>
                                                        <input type="text"
                                                            class="form-control"
                                                            id="TankDeviceName"
                                                            name="Tank Link Name"
                                                            (click)="$event.preventDefault();$event.stopPropagation()"
                                                            [(ngModel)]="tank.TankDevice.TankDeviceName">
                                                    </div>
                                                </td>
                                                <td class="td-20">
                                                    <div class="form-group">
                                                        <label for="serialNumber">
                                                            *Serial #
                                                        </label>
                                                        <input type="text"
                                                            class="form-control"
                                                            id="serialNumber"
                                                            name="Serial Number"
                                                            (click)="$event.preventDefault();$event.stopPropagation()"
                                                            [(ngModel)]="tank.TankDevice.SerialNumber">
                                                    </div>
                                                </td>
                                                <td class="td-20">
                                                    <div class="form-group">
                                                        <label for="cellularId">
                                                            *Cellular Id
                                                        </label>
                                                        <input type="text"
                                                            class="form-control"
                                                            id="cellularId"
                                                            name="Cellular Id"
                                                            (click)="$event.preventDefault();$event.stopPropagation()"
                                                            [(ngModel)]="tank.TankDevice.CellularID">
                                                    </div>
                                                </td>
                                                <td class="td-15">
                                                    <div class="form-group">
                                                        <div *ngIf="tank.TankDevice?.isActive">
                                                            <label>Tank Link Active</label>
                                                            <button class="btn btn-danger btn-sm"
                                                                    (click)="setTanklink(tank);tankLinkModal.showPromptModal();$event.preventDefault();$event.stopPropagation()">Clear Tank Link</button>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="td-25 table-buttons">
                                                    <div class="form-group">
                                                        <button class="btn btn-success"
                                                                [disabled]="!tankDeviceForm.form.valid"
                                                                (click)="saveTankDevice(tank); $event.preventDefault();$event.stopPropagation()">Save</button>
                                                        <button *ngIf="tank.TankDevice.id" type="button" class="btn btn-danger"
                                                                (click)="cancelTankLinkEdit(tank); $event.preventDefault();$event.stopPropagation()">Cancel</button>
                                                        <button type="button" class="btn btn-danger"
                                                                (click)="setDeleteTank(tank);$event.preventDefault();$event.stopPropagation()">Delete</button>
                                                        <button type="button" class="btn btn-warning" (click)="setInactiveTank(tank);markInactive.showPromptModal();$event.preventDefault();$event.stopPropagation();">Mark <span *ngIf="tank.isActive">In</span>active</button>
                                                    </div>
                                                </td>
                                            </form>
                                        </div>
                                    </div>
                                </span>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

 <!--Mark Tank Inactive Confirm Modal-->
<prompt-modal #markInactive size="small">
    <div class="modal-header">
        <h4 class="modal-title">Mark Tank as Inactive</h4>
    </div>

    <div class="modal-item">
        <div class="col-12">
            Are you sure you want to mark {{inactiveTank?.tankName}} as inactive?"
        </div>
    </div>

    <div class="modal-buttons">
        <button type="submit" (click)="markTankInactive()" class="btn btn-danger">Mark Inactive</button>
        <button type="button" class="btn btn-light" (click)="markInactive.hidePromptModal()">Cancel</button>
    </div>

</prompt-modal>

<!--Tank Link Confirm Modal-->

<prompt-modal #tankLinkModal size="small">
    <div class="modal-header">
        <h4 class="modal-title">Clear Tank Link Information</h4>
    </div>

    <div class="modal-item">
        <div class="col-12">
            Are you sure you want to clear tank link information{{tank.tankName ? ' for "' + tankLink.tankName + '."' : '.'}}"
        </div>
    </div>

    <div class="modal-buttons">
        <button type="submit" (click)="clearTanklink()" class="btn btn-danger">Clear Tank Link</button>
        <button type="button" class="btn btn-light" (click)="tankLinkModal.hidePromptModal()">Cancel</button>
    </div>
</prompt-modal>

<!--Tank Delete Confirm Modal-->

<prompt-modal #tankDelete size="small">
    <div class="modal-header">
        <h4 class="modal-title">Remove Tank</h4>
    </div>

    <div class="modal-item">
        <div class="col-12">
            <p>Are you sure you would like to delete "{{deleteTank?.tankName}}"? This cannot be undone.</p>
        </div>
    </div>

    <div class="modal-buttons">
        <button (click)="removeTank()" type="submit" class="btn btn-sm btn-danger">Delete</button>
        <button type="button" class="btn btn-sm btn-warning" (click)="setDeleteTank();">Cancel</button>
    </div>
</prompt-modal>


<!-- Loading Spinner -->
<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
