<div class="panel padding-15 employee-edit-panel">
    <form action="" name="employeeForm" #addEmployeeForm="ngForm">
        <div class="row">
            <div class="form-group col-md-4">
                <label for="firstName">*First Name</label>
                <input type="text"
                       id="firstName"
                       required
                       #firstName="ngModel"
                       class="form-control"
                       [(ngModel)]="employee.firstName"
                       name="First Name">
            </div>
            <div class="form-group col-md-4">
                <label for="lastName">*Last Name</label>
                <input type="text"
                       required
                       #lastName="ngModel"
                       class="form-control"
                       [(ngModel)]="employee.lastName"
                       name="Last Name"
                       id="lastName">
            </div>
            <div class="form-group col-md-4">
                <label for="state">State</label>
                <select name="state"
                        id="state"
                        class="form-control"
                        [(ngModel)]="employee.state">
                    <option value="" selected>State</option>
                    <option [value]="state" *ngFor="let state of ['CA','ID', 'WI']">{{ state }}</option>
                </select>
            </div>
        </div>
        <div class="margin-top-10 row">
            <div class="form-group col-md-4">
                <label for="email">*Email</label>
                <input type="text"
                       id="email"
                       name="Email"
                       class="form-control"
                       #employeeEmail="ngModel"
                       required
                       [(ngModel)]="employee.email">
            </div>
            <div class="form-group col-md-4 flex">
                <div class="btn-margin-right btn-margin-bottom">
                    <label for="empType">Employee Type</label>
                  <div>
                        <ng-select [items]="empTypes"
                                   [multiple]="true"
                                   id="empType"
                                   [searchable]="false"
                                   bindLabel="name"
                                   bindValue="id"
                                   name="employee type"
                                   [(ngModel)]="employee.employeeTypeIds">

                        </ng-select>
                  </div>
                </div>
                <div *ngIf="employee.employeeTypeIds.indexOf(4) !== -1" class="btn-margin-right">
                    <div class="btn-margin-right btn-margin-bottom">
                        <label for="empType">*Sales Rep Type</label>
                        <div>
                            <select name="Sales Rep Type"
                                    required
                                    class="form-control"
                                    [(ngModel)]="employee.salesRepType">
                                <option value="0">Outside</option>
                                <option value="1">Inside</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div *ngIf="employee.employeeTypeIds.indexOf(4) !== -1 && employee.salesRepType == 1" class="btn-margin-right">
                    <div class="btn-margin-right btn-margin-bottom">
                    <label for="empType">*Sales Rep Regions</label>
                        <div>
                            <ng-select [items]="regions"
                                       [multiple]="true"
                                       id="empType"
                                       [searchable]="false"
                                       bindLabel="name"
                                       bindValue="id"
                                       name="Sales Rep Regions"
                                       [(ngModel)]="employee.salesRepRegions">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-4">
                <label>Access Level</label>
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="admin" [(ngModel)]="employee.role"  [value]="'Admin'">
                        <span [ngClass]="{'selected-admin': employee.role==='Admin'}">Admin</span>
                    </label>
                </div>
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="limited" [(ngModel)]="employee.role"  [value]="'Limited'" >
                        <span [ngClass]="{'selected-limited': employee.role==='Limited'}">Limited</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="margin-top-10 row">
            <div class="form-group col-md-4">
                <label>Work Order Emails</label>
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" name="work-order-email-list" [(ngModel)]="employee.workOrderEmails">
                        <span [ngClass]="{'selected-admin': employee.workOrderEmails}">Yes</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="margin-top-10 flex-align-end">
            <button *ngIf="employee.id && employee.isActive" (click)="employeeDelete.showPromptModal()" type="button" class="btn-danger btn">Inactive</button>
            <button *ngIf="employee.id && !employee.isActive" (click)="employeeDelete.showPromptModal()" type="button" class="btn-success btn">Restore</button>
            <button type="button" class="btn-warning btn" (click)="clearEmployee(addEmployeeForm.form)" >Clear</button>
            <button class="btn btn-primary" (click)="save(employee, addEmployeeForm.form)">Save</button>
        </div>
    </form>
    <small style="color:red">*Required</small>
</div>

<prompt-modal
    #employeeDelete
    size="small"
>
        <div class="modal-header">
            <h4 class="modal-title">Set Employee {{employee.isActive ? 'Inactive' : 'Active'}}</h4>
        </div>

        <div class="modal-item">
            <div class="col-12">
                <p *ngIf="employee?.isActive">Are you sure you want to set "{{employee.firstName}} {{employee.lastName}}" to inactive?</p>
                <p *ngIf="!employee?.isActive">Would you like to restore "{{employee.firstName}} {{employee.lastName}}"?</p>
            </div>
        </div>

        <div class="modal-buttons">
            <button *ngIf="employee.isActive" (click)="employee.isActive = false;save(employee, addEmployeeForm.form);employeeDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-danger">Inactive</button>
            <button *ngIf="!employee.isActive" (click)="employee.isActive = true;save(employee, addEmployeeForm.form);employeeDelete.hidePromptModal()" type="submit" class="btn btn-sm btn-success">Restore</button>
            <button type="button" class="btn btn-sm btn-light" (click)="employeeDelete.hidePromptModal()">Cancel</button>
        </div>

</prompt-modal>
