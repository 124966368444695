import * as _ from 'lodash';

export class Product {
    public abbr: string = null;
    public name: string = null;
    public dot: string = null;
    public description: string = null;
    public id: number = null;
    public isActive:boolean = true;
    public level: number = 0;
    public ordered: number = 0;
    public delivered: number = 0;
    public remaining: number = 0;
    
    constructor(data?: any){
        _.merge(this, data);
    }
}
