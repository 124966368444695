import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { WorkOrder } from "../../models/work-order";
import { WorkOrderService } from "../../services-module/services/work-order.service";
import { PromptModalComponent } from "../prompt-modal/prompt-modal.component";
import { ActivatedRoute } from "@angular/router";
import { EmployeeService } from "../../services-module/services/employee.service";

@Component({
  selector: 'app-update-work-order',
  templateUrl: './update-work-order.component.html',
  styleUrls: ['./update-work-order.component.css']
})
export class UpdateWorkOrderComponent implements OnInit {
  workOrder: WorkOrder = new WorkOrder();
  @Output() onUpdate = new EventEmitter<WorkOrder>();
  @ViewChild('modal', { static: true }) private modal:PromptModalComponent;
  employeeOptions;
  @Input() update = false;

  constructor(private workOrderService: WorkOrderService, private route: ActivatedRoute, private employeeService: EmployeeService
  ) {
    this.employeeService.getEmployees().then(employees => {
      this.employeeOptions = this.employeeService.getEmployeeOptions(employees);
    });
  }

  ngOnInit() {}

  save() {
    if (this.update) {
      this.onUpdate.emit(this.workOrder);
    } else {
      this.workOrderService.changeStatus(this.workOrder).then((workOrder) => {
        this.onUpdate.emit(workOrder);
      });
    }
    this.modal.hidePromptModal();
  }

  showPromptModal(workOrder: WorkOrder) {
    this.workOrder = workOrder;
    this.modal.showPromptModal();
  }

}
