import { Component, Input, Output, EventEmitter} from '@angular/core';

import {Tank} from '../../../models/tank';

@Component({
    templateUrl: 'tank-single.component.html',
    styleUrls: ['tank-single.component.css'],
    selector: 'tank-single'
})
export class TankSingleComponent{
    @Input() tank: Tank;
    @Input() active: number;
    @Output() resetTank = new EventEmitter<string>();
    @Output() saveTank = new EventEmitter<Tank>();

    constructor(){}

    clearTank(){
        this.resetTank.emit('reset');
    }

    save(tank: Tank){
        this.saveTank.emit(tank);
    }


}