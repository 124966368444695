import {Component, OnInit} from '@angular/core';

import {AddressService} from '../../../services-module/services/address.service';
import {Address} from '../../../models/address';

@Component({
    templateUrl: './inactive-dairy.component.html',
    styleUrls:['../inactive-client-panel/inactive-client.component.css'],
})
export class InactiveDairyPanelComponent implements OnInit{
    addresses: Address[];
    ftp:boolean = false;
    search = '';

    constructor(
        private addressService: AddressService
    ){}

    ngOnInit(): void{
        this.getInactiveAddresses();
    }

    getInactiveAddresses(): void{
    this.addressService.getInactiveAddresses()
        .then((response)=>{
            this.addresses = response;
        });
    }

    deleteAddress(address: Address) {
        return () => {
            this.ftp = true;
            this.addressService.deleteAddress(address)
                .then((res)=>{
                    this.ftp = false;
                    this.addresses.splice(this.addresses.findIndex(item => {
                        return item.id === address.id;
                    }), 1);
                });
        }
    }

    changeAddressStatus(address: Address): void{
       address.isActive = !address.isActive;
        this.ftp = true;
        this.addressService.updateAddress(address)
            .then((res)=>{
                this.ftp = false;
            });
    }

}
