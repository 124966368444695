<div class="panel data-panel">
    <div class="panel-header">
        <div class="flex-container flex-space-between">
            <h5><i class="fa fa-truck"></i> Drops</h5>
        </div>
    </div>
    <div class="panel-content">

        <div *ngFor="let drop of drops | dateSorting: 'deliveredDate'">
            <div *ngIf="drop.deliveredDate" class="drop-container">
                <div class="flex-container flex-space-between">
                    <div>
                        <span class="drop-number">{{drop.id}}</span>
                    </div>
                    <div>
                        Gal: {{drop.quantityDropped}}
                    </div>
                    <div>
                        Post: {{drop.tankLevelPostDrop}}
                    </div>
                </div>
                <div class="flex-container flex-space-between">
                    <div>{{ drop.enteredBy }}</div>
                    <div>{{ drop.deliveredDate | date: 'MM/dd/yyyy'}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="drops.length === 0">
            No Drops
        </div>
    </div>
    <div class="panel-footer">
        <button class="btn btn-drops" (click)="showAll()">{{ open ? 'Hide' : 'View' }} Drop History</button>
    </div>
</div>