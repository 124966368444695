import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { workOrdersRouting } from './work-orders.routing';
import { WorkOrdersComponent } from './work-orders.component';
import { WorkOrderTableComponent } from './work-orders-table/work-orders-table.component';
import { WorkOrderNavComponent } from './work-order-nav/work-order-nav.component';
import { FormsModule } from '@angular/forms';
import { S3MapModule } from '../s3-gmap/s3-map.module';
import { SingleWorkOrderComponent } from './single-work-order/single-work-order.component';
import { EditWorkOrder } from './single-work-order/edit-work-order/edit-work-order.component';
import { AddWorkOrderComponent } from './add-work-order/add-work-order.component';
import { AddWorkOrderNote } from './single-work-order/add-note/add-note.component';
import { WorkOrdersResolve } from '@resolvers/unscheduled-work-orders.resolver';
import { PipesModule } from '@pipes/pipes.module';
import { WoSearchPipe } from '@pipes/wo-search.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { SharedComponentsModule } from '@shared/shared-components.module';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
    imports: [
        CommonModule,
        workOrdersRouting,
        FormsModule,
        S3MapModule,
        NgSelectModule,
        PipesModule,
        DatepickerModule.forRoot(),
        PaginationModule.forRoot(),
        SharedComponentsModule

    ],
    declarations: [
        WorkOrdersComponent,
        WorkOrderTableComponent,
        WorkOrderNavComponent,
        SingleWorkOrderComponent,
        AddWorkOrderComponent,
        AddWorkOrderNote,
        EditWorkOrder,
        WoSearchPipe
    ],
    providers: [WorkOrdersResolve]
})

export class WorkOrdersModule { }
