import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {TankTypeService} from '../../services-module/services/tank-type.service';
import {TankType} from '../../models/tank-type';

@Component({
    templateUrl: './tank.component.html',
    styleUrls:['tank.component.css']
})
export class TankComponent implements OnInit{
    tanks: TankType[];
    selectedTank: TankType;
    active = {active: 1};
    ftp:boolean = false;

    constructor(private tankService: TankTypeService){}

    ngOnInit(): void{
        this.selectedTank = new TankType();
        this.getTanks();
    }

    getTanks(): void{
        this.tankService.getAllTankTypes()
            .then((res)=>{
                this.tanks = res;
            });
    }

    setTank(tank: TankType){
        this.selectedTank = _.clone(tank);
    }

    resetTank(event: string) {
        this.selectedTank = new TankType();
    }

    saveTank(tank: TankType){
        this.ftp = true;
        this.tankService.saveTankType(tank)
            .then((res)=>{
                let origTank = _.find(this.tanks, {id: tank.id});
                if(origTank){
                    _.merge(origTank, res);
                }else{
                    this.tanks.push(res);
                }
                this.ftp = false;
                this.selectedTank = new TankType();
            })
    }

}

