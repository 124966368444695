import {Component, Input, Output, EventEmitter} from "@angular/core";
import {Client} from "../../../models/client";
@Component({
    selector:'dairy-as-group',
    templateUrl:'dairy-as-group.component.html',
    styleUrls:['../client-group-management.component.css','./dairy-as-group.component.css']
})

export class DairyAsGroupComponent{
    @Input()clientGroup:Client;
    @Input()newGroup:any;
    @Input()dairies:any[];
    @Input()defaultImage:string;
    @Output()setGroup = new EventEmitter<any>();
    @Output()showModal = new EventEmitter<any>();
    @Output()setAsNewGroup = new EventEmitter<any>();
    @Output()addressToNewGroup = new EventEmitter<any>();
    @Output()confirmNewGroup = new EventEmitter<any>();
    @Output()cancelNewGroup = new EventEmitter<any>();
    @Output()removeFromGroup = new EventEmitter<any>();

    constructor(){}

    groupSet(group:any){
        this.setGroup.emit(group);
    }

    setBG(path:string){
        return "url(" + path + ")";
    }

    save(dairy:any){
        this.showModal.emit();
    }

    setAsGroup(dairy:any){
        this.setAsNewGroup.emit(dairy);
    }

    addressToGroup(dairy:any){
        this.addressToNewGroup.emit(dairy);
    }

    create(){
        this.confirmNewGroup.emit();
    }
    cancel(){
        this.cancelNewGroup.emit();
    }
    remove(dairy:any){
        this.removeFromGroup.emit(dairy);
    }


}