import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BsModalRef
} from 'ngx-bootstrap/modal';
import { HttpService } from '../../../shared-services/http-service/http-service.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'textarea-modal',
  templateUrl: './textarea-modal.component.html',
  exportAs: 'textareaModal'
})
export class TextareaModalComponent implements OnInit {
  @Output() noteSaved = new EventEmitter();
  public note: any;
  public saveDisabled = false;
  public saveResult;
  title: string;
  modalRef: BsModalRef;
  @ViewChild('textModal', { static: true }) public textModal: TemplateRef<any>;

  constructor(
    private http: HttpService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.note = {};
  }

  public show(note?): void {
    if (note && note.id == null) note.value = '';
    this.note = note ? note : {};
    this.title = this.note.key.charAt(0).toUpperCase() + this.note.key.slice(1);
    this.modalRef = this.modalService.show(
      this.textModal,
      Object.assign({}, { ignoreBackdropClick: true, keyboard: false, class: 'modal-lg' })
    );
  }

  public hide(): void {
    this.note = {};
    this.modalRef.hide();
  }

  private clearData(): void {
    this.note = {};
    this.saveResult = null;
    this.saveDisabled = false;
  }

  public save(event, note: any) {
    event.preventDefault();
    const form = event.target;
    this.saveDisabled = true;
    if (!note.id) {
      this.closeSuccessfully(form, note)
    } else {
      this.saveNote(note)
        .then((res) => {
          this.displayResults(this.handleResponse(res));
          if (res.saved) this.closeSuccessfully(form, note);
        });
    }
  }

  private saveNote(note: any) {
    if (note.id) {
      return this.updateNote(note)
        .then((res) => {
          return {
            saved: true,
            note: res
          };
        });
    } else {
      return null;
      //return this.createNote(note)
      //.then((res) => {
      //  return {
      //    saved: true,
      //    note: res
      //  };
      //});
    }
  }

  private updateNote(note: any): Promise<any> {
    const payload = {}
    payload[note.key] = note.value;
    return this.http.put(`${note.entity}/id/${note.id}`, payload)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  private createNote(note: any) {
    note.client_id = +this.activatedRoute.snapshot.params.id;
    return this.http.post(note.entity, note)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  private handleResponse(res: any): Object {
    if (res.saved) {
      return {
        saveSuccess: true,
        noteId: res.note.id
      };
    } else if (!res.saved) {
      return {
        saveSuccess: false,
        error: res.errorMessage
      };
    } else {
      return {
        saveSuccess: false,
        error: 'No response from server, please contact a system admin'
      };
    }
  }

  private displayResults(res: any): Object {
    this.saveResult = res;
    if (!res.noteId) return null;
    return res;
  }

  private closeSuccessfully(form: any, note: any) {
    setTimeout(() => {
      this.noteSaved.emit(note);
      if (this.saveResult) this.saveResult.saveSuccess = null;
      this.clearData();
      form.reset();
      this.hide();
    }, 500);
  }
}
