import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PromptModalComponent } from '../../shared-components/prompt-modal/prompt-modal.component';

import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';

import * as moment from 'moment';
import * as _ from 'lodash';

import { DeliveryOrderService } from '../../services-module/services/delivery-order.service';
import { RouteSheetsService } from '../route-sheets.service';

import { Route } from '../../models/route';
import { RouteService } from '../../services-module/services/route.service';
import { DeliveryOrder } from '../../models/delivery-order';
import { Employee } from '../../models/employee';
import { AuthService } from '../../authorization/auth.service';
import { ToastService } from '../../services-module/services/toast.service';
import { TankService } from '../../services-module/services/tank.service';
import { UtilsService } from '../../services-module/services/utils.service';
import { Tank } from '../../models/tank';
import { UserService } from '../../services-module/services/user.service';
import { Address } from '../../models/address';
import { Note } from '@models/note';

@Component({
  selector: 'app-route-single',
  templateUrl: './route-single.component.html',
  styleUrls: ['./route-single.component.css']
})
export class RouteSingleComponent implements OnInit {
  tanks: Tank[];
  updateTank: Tank;
  @Input() route: Route;
  drivers: Employee[];
  addOrder: DeliveryOrder;
  driverOptions: any = [];
  order: DeliveryOrder;
  tankAddress: Address;
  productDetails = [];
  _order: DeliveryOrder; // order to be used for removing from route
  ftp = false;
  workOrder = false;
  isAdmin = this.auth.isAdmin();
  isOffice = this.isAdmin || this.auth.employeeType(['Office']);
  driverView = false;

  @ViewChild('updateInventory', { static: true }) private updateInventory: PromptModalComponent;
  @ViewChild('deliveryNotesModal', { static: true }) private deliveryNotesModal: PromptModalComponent;
  @ViewChild('cancelDelivery', { static: true }) private cancelDelivery: PromptModalComponent;
  @ViewChild('removeOrderModal', { static: true }) private removeOrderModal: PromptModalComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private userService: UserService,
    public tankService: TankService,
    private deliveryOrderService: DeliveryOrderService,
    private routeService: RouteService,
    private sheetsService: RouteSheetsService,
    private utilsService: UtilsService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    const route = this.activatedRoute.snapshot.data['route'];
    if (route) {
      this.route = route;
      this.route['driverIds'] = [];
      this.driverOptions = [];
      this.drivers = this.activatedRoute.snapshot.data['drivers'];
      this.workOrder = this.activatedRoute.snapshot.data['workOrder'];
      this.drivers.forEach((driver) => {
        const item = {
          id: driver.id,
          name: `${driver.lastName}, ${driver.firstName}`
        };
        this.driverOptions.push(item);
      });
      this.getDriverIds(this.route);
    } else {
      this.driverView = true;
      this.workOrder = this.route.routeType === 'SERVICE';
    }
    this.filterNotes();
    this.tankAddress = new Address({ Notes: [] });
    this.route.deliveryOrders = _.orderBy(this.route.deliveryOrders, ['dropOrder', 'id'], ['asc', 'asc']);
    this._order = new DeliveryOrder();
    this.tanks = [];
    this.updateTank = new Tank();
    this.addOrder = new DeliveryOrder();
    this.updateTotalGallons();
  }

  updateTotalGallons(): void {
    this.productDetails = this.routeService.calculateGallons(this.route);
  }

  filterNotes() {
    this.route.deliveryOrders.forEach((d) => {
      if (d.Tank && d.Tank.Address) {
        d.Tank.Address.Notes = _.filter(d.Tank.Address.Notes, (note: Note) => +note.notesTypeId === 2 );
      }
    });
  }

  save(order: DeliveryOrder, form: UntypedFormGroup) {
    for (let i = 0; i < this.productDetails.length; i++) {
      if (this.productDetails[i].level === 0) {
        this.toastService.toast({ message: 'Loaded levels must be set before delivery' });
        return;
      }
    }
    if (form.valid) {
      if (order.delivered === true) {
        this.order = order;
        this.cancelDelivery.showPromptModal();
      } else {
        const user = this.userService.getEmployee();
        order.delivered = true;
        order.deliveredDate = moment().toDate();
        order.enteredBy = `${user.firstName} ${user.lastName}`;
        this.updateOrder(order);
      }
    } else {
      this.toastService.validate(form);
    }
  }

  cancel() {
    this.order.delivered = false;
    this.order.deliveredDate = null;
    this.order.quantityDropped = 0;
    this.order.tankLevelPostDrop = null;
    this.order.enteredBy = null;
    this.updateOrder(this.order);
    this.cancelDelivery.hidePromptModal();
  }

  getDriverIds(route: Route): void {
    route.EmployeeRoutes.forEach((empRoute: any) => {
      route.driverIds.push(empRoute.employeeId);
    });
  }

  updateClosedOrder(order: DeliveryOrder) {
    this.deliveryOrderService.saveDeliveryOrder(order)
      .then(() => {
        this.updateTotalGallons();
        this.toastService.toast({ message: `You have updated delivery order: ${order.deliveryNumber}`, type: 'success' });
      });
  }

  updateOrder(order: DeliveryOrder) {
    this.deliveryOrderService.saveDeliveryOrder(order)
      .then(() => {
        this.updateTotalGallons();
        if (order.tankLevelPostDrop) {
          order.Tank.currentGallons = order.tankLevelPostDrop;
          this.toastService.toast({ message: `You have updated delivery order: ${order.deliveryNumber}`, type: 'success' });
        } else if (!order.delivered) {
          this.toastService.toast({
            message: `You have set order number ${order.deliveryNumber} as undelivered. `,
            type: 'warning'
          });
        } else {
          order.Tank.currentGallons = order.tankLevelPostDrop;
          this.toastService.toast({ message: `You have updated delivery order: ${order.deliveryNumber}`, type: 'success' });
        }
      });
  }


  openMap(order: DeliveryOrder): void {
    this.utilsService.openMap(order.Tank.Address);
  }

  updateRoute(): void {
    this.routeService.updateRouteDrivers(this.route)
      .then((res) => {
        this.toastService.toast({ message: `You have updated route drivers`, type: 'success' });
        this.route.EmployeeRoutes = res.EmployeeRoutes;
        // _.merge(this.route, res);
      });
  }

  viewLoadSheets(): void {
    this.routeService.findRouteById(this.route.id).then((route) => {
      this.sheetsService.buildLoadSheet(route);
    });
  }

  getAddressTanks(addressId: number) {
    this.tanks = [];
    this.updateTank = new Tank();
    this.addOrder = new DeliveryOrder();
    this.tankService.getAddressTanks(addressId)
      .then((res) => {
        this.tanks = res;
        this.updateInventory.showPromptModal();
      });
  }
  closeModal() {
    this.updateInventory.hidePromptModal();
    this.tanks = [];
    this.updateTank = new Tank();
  }
  addToUpdate(tank: Tank) {
    this.addOrder = new DeliveryOrder({ quantityOrdered: 0, quantityDropped: 0 });
    this.updateTank = tank;
  }

  canUpdate(order: DeliveryOrder, form: UntypedFormGroup) {
    if (form.valid) {
      this.updateTankDrop(order);
    } else {
      this.toastService.validate(form);
    }
  }


  updateTankDrop(order: DeliveryOrder) {
    const user = this.userService.getEmployee();
    const userName = `${user.firstName} ${user.lastName}`;
    const address = _.find(this.route.deliveryOrders, ((d) => {
      if (d.Tank.addressId === this.tanks[0].addressId) {
        return d;
      }
    }));
    const addOrder = {
      Tank: this.updateTank,
      clientId: this.updateTank.clientId,
      tankId: this.updateTank.id,
      productId: this.updateTank.productId,
      deliveredDate: moment().toDate(),
      enteredBy: userName,
      routeId: this.route.id,
      deliveryNumber: address.deliveryNumber,
      delivered: true,
      dropOrder: address.dropOrder
    };
    _.merge(order, addOrder);
    this.deliveryOrderService.createDeliveryOrder(order)
      .then(() => {
        this.updateTank.currentGallons = order.tankLevelPostDrop;
        this.saveTank(this.updateTank);
      });
  }

  saveTank(tank: Tank) {
    this.tankService.updateTank(tank)
      .then(() => {
        this.getRoute();
        // this.getAddressTanks(tank.addressId);
      });
  }


  cancelUpdate() {
    this.addOrder = new DeliveryOrder();
  }


  getTankPercent(tank: Tank): number {
    return this.tankService.calculatePercent(tank);
  }

  getTankStatus(tank: Tank): string {
    return this.tankService.calculateTankStatus(tank);
  }

  checkForRoute(tank: Tank) {
    return _.find(this.route.deliveryOrders, { tankId: tank.id });
  }

  currentLimit(order: DeliveryOrder) {
    switch (true) {
      case !this.updateTank || !this.updateTank.TankType:
        break;
      case order.tankLevelPostDrop > this.updateTank.TankType.capacityVolume:
        order.tankLevelPostDrop = this.updateTank.TankType.capacityVolume;
        break;
      case order.tankLevelPostDrop < 0:
        order.tankLevelPostDrop = Math.abs(order.tankLevelPostDrop);
        if (order.tankLevelPostDrop > this.updateTank.TankType.capacityVolume) {
          order.tankLevelPostDrop = this.updateTank.TankType.capacityVolume;
        }
        break;
      default:
        break;
    }
  }

  orderLimit(order: DeliveryOrder) {
    switch (true) {
      case !order.Tank || !order.Tank.TankType:
        break;
      case order.tankLevelPostDrop > order.Tank.TankType.capacityVolume:
        order.tankLevelPostDrop = order.Tank.TankType.capacityVolume;
        break;
      case order.tankLevelPostDrop < 0:
        order.tankLevelPostDrop = Math.abs(order.tankLevelPostDrop);
        if (order.tankLevelPostDrop > order.Tank.TankType.capacityVolume) {
          order.tankLevelPostDrop = order.Tank.TankType.capacityVolume;
        }
        break;
      default:
        break;
    }
  }

  closeRoute() {
    this.ftp = true;
    this.route['completedAt'] = moment().toDate();
    this.routeService.updateRoute(this.route)
      .then(() => {
        this.getRoute();
      });
  }

  openRoute() {
    this.route['completedAt'] = null;
    this.routeService.updateRoute(this.route)
      .then(() => {
        this.getRoute();
        this.toastService.toast({ message: 'Route Successfully Opened!', type: 'success' });
      });
  }

  activeStatus(completed: any, scheduled: any) {
    return this.utilsService.activeStatus(completed, scheduled);
  }

  getRoute() {
    // reload the route with the newly added delivery orders
    this.routeService.findRouteById(this.route.id)
      .then((res) => {
        this.route = res;
        this.route.deliveryOrders = _.orderBy(this.route.deliveryOrders, ['dropOrder', 'id'], ['asc', 'asc']);
        this.route['driverIds'] = [];
        this.getDriverIds(this.route);
        this.updateTotalGallons();
        this.ftp = false;
      });
  }

  showDeliveryNotes(add: Address) {
    this.tankAddress = add;
    this.deliveryNotesModal.showPromptModal();
  }

  closeDeliveryNotes() {
    this.deliveryNotesModal.hidePromptModal();
    this.tankAddress = new Address();
  }

  setOrderToRemove(order: DeliveryOrder) {
    this._order = order;
    this.removeOrderModal.showPromptModal();
  }

  removeFromRoute() {
    this.ftp = true;
    this.deliveryOrderService.deleteDeliveryOrders(this._order.id)
      .then(() => {
        this.getRoute();
      });
  }

}
