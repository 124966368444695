import { Component, OnInit, Output, Input, OnChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 's3h-pagination',
  templateUrl: './s3h-pagination.component.html',
  styleUrls: ['./s3h-pagination.component.scss']
})
export class S3hPaginationComponent implements OnInit, OnChanges {
  @Input()
  items: any[];
  @Input()
  displayNumber: number;
  @Output()
  filteredItems = new EventEmitter();
  totalItems: number;
  currentIndex: number = 0;

  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    this.resetIndexes();
    if (this.items.length > 0) {
      this.filteredItems.emit(this.items.slice(this.currentIndex, this.currentIndex + this.displayNumber));
      this.totalItems = this.items.length;
    } else {
      this.filteredItems.emit([]);
      this.totalItems = 0;
    }
  }

  resetIndexes() {
    this.currentIndex = 0;
  }

  pageUp() {
    if (this.currentIndex < this.items.length - this.displayNumber) {
      this.currentIndex += this.displayNumber;
      this.setFilteredItems(this.currentIndex, this.currentIndex + this.displayNumber);
    }
  }

  pageDown() {
    if (this.currentIndex >= this.displayNumber) {
      const end = this.currentIndex;
      const start = this.currentIndex - this.displayNumber > 0 ? this.currentIndex - this.displayNumber : 0;
      this.currentIndex -= this.displayNumber;
      this.setFilteredItems(start, end);
    }
  }

  setFilteredItems(start: any, end: any) {
    if (start < 0) start = 0;
    this.filteredItems.emit(this.items.slice(start, end));
  }
}
