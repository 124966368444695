import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Trailer } from '../../models/trailer';
import { environment } from '../../../environments/environment';

@Injectable()

export class TrailerService {
    private apiUrl = environment.apiUrl;  // URL to web api
    constructor(public authHttp: HttpClient) { }

    getTrailers(): Promise<Trailer[]> {
        return this.authHttp.get(`${this.apiUrl}trailer`)
            .toPromise()
            .then((response) => {
                return response as Trailer[];
            })
            .catch(this.handleError);
    }

    saveTrailer(trailer: Trailer): Promise<Trailer> {
        if (trailer.id) {
            return this.updateTrailer(trailer);
        } else {
            return this.createTrailer(trailer);
        }
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    createTrailer(trailer: Trailer): Promise<Trailer> {
        return this.authHttp.post(`${this.apiUrl}trailer`, trailer)
            .toPromise()
            .then((res) => {
                return res as Trailer;
            });
    }
    updateTrailer(trailer: Trailer) {
        return this.authHttp.put(`${this.apiUrl}Trailer/${trailer.id}`, trailer)
            .toPromise()
            .then((res) => {
                return res as Trailer;
            });
    }

}
