<div id="drops" class="panel margin-top-20">
    <div class="panel-header">
        <div class="flex-container flex-space-between">
            <h4><i class="fa fa-truck"></i> Drops</h4>
            <div>
                <select [ngModel]="filter" (change)="setFilter($event.target.value)" [disabled]="editMode()">
                    <option value=""> -- All Products -- </option>
                    <option *ngFor="let tank of tanks" [value]="tank.Product?.abbr">{{tank.Product?.abbr}}</option>
                </select>
            </div>
            <div>
                <button
                    class="btn btn-sm btn-primary"
                    (click)="newOverride()"
                    [disabled]="tanks.length == 0"
                    >Update</button>
                <button class="btn btn-sm btn-danger" (click)="hide()">X</button>
            </div>
        </div>
    </div>
        <div class="panel-content">
            <table class="table-striped table">
                <thead>
                    <tr>
                        <td>
                            Date
                        </td>
                        <td>
                            Tank
                        </td>
                        <td>
                            Product
                        </td>
                        <td>
                            Gal Ordered
                        </td>
                        <td>
                            Gal Dropped
                        </td>
                        <td>
                            Tank Level
                        </td>
                        <td>
                            Para Inches
                        </td>
                        <td>
                            Entered By
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let drop of filteredDrops() | dateSorting: 'deliveredDate'; let i = index"
                        [ngClass]="{'inventory-override': !drop.scheduledDate, 'new-override': drop.editing }">
                        <td *ngIf="!drop.editing">
                            <i (click)="setEdit(drop)" class="fa fa-pencil-square-o edit-drop" aria-hidden="true"></i>
                            {{ drop.deliveredDate | date: 'MM/dd/yyyy' }}
                        </td>
                        <td *ngIf="drop.editing">
                            <span (click)="setOverride(drop)" class="drop-date"><i class="fa fa-calendar" aria-hidden="true"></i> {{drop.tempDate | date:'MM/dd/yyyy'}}</span>
                        </td>
                        <td *ngIf="!drop.editing || drop.id" [ngClass]="{'hcho-green': drop.Tank?.productId == 1, 'lqa-blue':drop.Tank?.productId == 2}">{{ drop.Tank?.tankName }}</td>
                        <td *ngIf="drop.editing && !drop.id">
                            <select #tankSelect (change)="setTank(tankSelect.value, i)">
                                <option *ngFor="let tank of tanks" [value]="tank.id">{{tank.tankName}}</option>
                            </select>
                        </td>
                        <td [ngClass]="{'hcho-green': drop.Tank?.productId == 1, 'lqa-blue':drop.Tank?.productId == 2}">{{ drop.Tank?.Product?.abbr }}</td>
                        <td *ngIf="!drop.editing">{{ drop.quantityOrdered }}</td>
                        <td *ngIf="drop.editing">
                            <input [(ngModel)]="drop.quantityOrdered" type="number" />
                        </td>
                        <td *ngIf="!drop.editing">{{ drop.quantityDropped }}</td>
                        <td *ngIf="drop.editing">
                            <input [(ngModel)]="drop.quantityDropped" type="number" />
                        </td>
                        <td *ngIf="!drop.editing">{{ drop.tankLevelPostDrop }}</td>
                        <td *ngIf="drop.editing">
                            <input [(ngModel)]="drop.tankLevelPostDrop" type="number" />
                        </td>
                        <td *ngIf="!drop.editing">{{ drop.paraInches }}</td>
                        <td *ngIf="drop.editing">
                            <input [(ngModel)]="drop.paraInches" type="text" />
                        </td>
                        <td *ngIf="!drop.editing">{{ drop.enteredBy }}</td>
                        <td *ngIf="drop.editing">
                            <button
                                (click)="override(drop, i)"
                                class="btn btn-sm btn-primary"
                            >Save</button>
                            <button
                                (click)="currentOverride = drop;overrideDelete.showPromptModal()"
                                *ngIf="drop.id"
                                class="btn btn-sm btn-danger"
                            >Delete</button>
                            <button (click)="cancelOverride(drop)" class="btn btn-sm btn-danger">Cancel</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        <div *ngIf="drops.length === 0">
            <h5>No drops at location</h5>
        </div>
    </div>
</div>

<prompt-modal
    #overrideModal
    size="small"
    (onClosed)="setOverrideDate()"
>
        <div class="modal-header">
            <h4 class="modal-title">Override Date</h4>
        </div>

        <div class="modal-item">

            <datepicker
                *ngIf="editDate"
                [(ngModel)]="overrideDate"
                class="date-modal"
                name="Override Date"
                datepickerMode="day"
                [showWeeks]="false"
                (selectionDone)="overrideModal.hidePromptModal()"
            ></datepicker>
        </div>

        <div class="modal-buttons">
            <button type="button" class="btn btn-primary" (click)="overrideModal.hidePromptModal()">OK</button>
        </div>

</prompt-modal>

<prompt-modal
    #overrideDelete
>
        <div class="modal-header">
            <h4 class="modal-title">Delete Update</h4>
        </div>

        <div class="modal-item">
        <p>{{currentOverride?.Tank.tankName}} | {{currentOverride?.Tank.Product.name}} | {{currentOverride?.enteredBy}}<br>
        <label>Ordered:</label>&nbsp;{{currentOverride?.quantityOrdered}}&emsp;<label>Dropped:</label>&nbsp;{{currentOverride?.quantityDropped}}&emsp;<label>Tank Level:</label>&nbsp;{{currentOverride?.tankLevelPostDrop}}</p>
            <p>Are you sure you want to delete this update?</p>
        </div>

        <div class="modal-buttons">
            <button type="button" class="btn btn-danger" (click)="deleteOverride.emit(currentOverride);overrideDelete.hidePromptModal()">Delete</button>
            <button type="button" class="btn btn-light" (click)="overrideDelete.hidePromptModal()">Cancel</button>
        </div>

</prompt-modal>
