import { Component, Input, Output, EventEmitter} from '@angular/core';

import {Employee} from '../../../models/employee';
import * as _ from 'lodash';
import {AuthService} from "../../../authorization/auth.service";
import {ToastService} from "../../../services-module/services/toast.service";
import { EmployeeTypeService } from "../../../services-module/services/employee-type.service";

@Component({
    templateUrl: './employee-list.component.html',
    styleUrls:['employee-list.component.css'],
    selector: 'employee-list'
})
export class EmployeeListComponent{
    @Input() employees: Employee[];
    @Input() employeeId:number;
    @Output() setEmployee = new EventEmitter<Employee>();
    @Output() resetEmployee = new EventEmitter<string>();
    active:boolean = true;
    ftp:boolean = false;

    constructor(private auth:AuthService, private toast:ToastService, public employeeTypeService: EmployeeTypeService){}

    selectEmployee(employee: Employee): void{
        this.setEmployee.emit(employee)
    }

    listEmployeeTypes(employee: Employee): string{
        let types: string = '';
        employee.EmployeeEmployeeTypes.forEach((type)=>{
            if (type.employeeTypeId === this.employeeTypeService.TYPES.SALES_REP) {
                types += `${employee.salesRepType === this.employeeTypeService.SALES_TYPES.INSIDE ? 'Inside ' : 'Outside '} ${type['EmployeeType'].employeeType} - `;
            } else {
                types += ` ${type['EmployeeType'].employeeType} - `;
            }
        });
        types = types.slice(0, -2)
        return types;
    }

    isSelected(employee:Employee) {
        if (this.employeeId) {
            return employee.id == this.employeeId;
        }
        return false;
    }

    clearEmployee(){
        this.resetEmployee.emit('reset');
    }

    activeUsers() {
        return _.filter(this.employees, (e:Employee) => e.isActive == this.active );
    }

    resetPass(employee:Employee){

        // sends email to employee alerting them of password reset
        if(confirm(`Are you sure you would like to send ${employee.firstName} ${employee.lastName} an email to reset their password?`)) {

            let creds = {email: employee.email, cc: `Lisa@specialtysalesllc.com, Vanessa@specialtysalesllc.com`};
            this.ftp = true;
            return this.auth.requestNewPassword(creds)
                .then((res)=> {
                    if (res.error) {
                        this.toast.toast({
                            message: 'There was an error attempting to send the password reset',
                            type: 'danger'
                        });
                        this.ftp = false;

                    } else {
                        this.toast.toast({message: 'You have successfully sent a password reset!', type: 'success'});
                        this.ftp = false;
                    }
                })
        }

    }

}
