<div class="container-fluid">
    <div class="row">
        <ul class="route-list-options">
            <li class="no-border-left" (click)="editDate()">
                <a href="javascript:void(0)">{{renderDateOfRoute(route.dateOfRoute)}}</a>
                <a href="javascript:void(0)">
                    <i class="fa fa-calendar"></i>
                </a>
            </li>
            <!--========== Product Dropdown ==========-->
            <li id="dropdownCmp" *ngIf="!workOrder" click-outside (clickOutside)="onClickOutside($event)" (click)="filterOption === 'product' ? closeOut():filterBy('product')">
                <div>
                    <span>{{routeParams.productId ? productName(routeParams.productId):'Product'}}
                        <i class="fa" [ngClass]="{'fa-caret-down':filterOption !=='product', 'fa-caret-up': filterOption =='product'}"></i>
                    </span>
                    <div class="filter-options" *ngIf="filterOption == 'product'">
                        <div class="form-check" *ngFor="let product of products">
                            <span (click)="product.id == routeParams.productId ? removeFromRouteParams(product.id, 'product') : addToRouteParams(product.id, 'product')" class="hover-param">
                                <i class="fa" [ngClass]="{'fa-check-square-o': product.id == routeParams.productId, 'fa-square-o':product.id !== routeParams.productId}"></i>
                                <span>{{product.abbr}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <!--========== Max % Input ==========-->
            <li *ngIf="!workOrder">
                <input type="number"
                       id="percent"
                       name="percent"
                       placeholder="Max %"
                       [(ngModel)]="routeParams.maxCriticalPercent">
            </li>
            <!--========== State Dropdown ==========-->
            <li id="dropdownCmp" click-outside (clickOutside)="onClickOutside($event)" (click)="filterBy('state')">
                <div>
                    <span *ngIf="routeParams.states.length == 0">State
                        <i class="fa" [ngClass]="{'fa-caret-down':filterOption !=='state', 'fa-caret-up': filterOption =='state'}"></i>
                    </span>
                    <span *ngIf="routeParams.states.length > 0">
                         <span *ngFor="let s of routeParams.states">{{s}}<span *ngIf="routeParams.states.length > 1">,</span> </span>
                        <i class="fa" [ngClass]="{'fa-caret-down':filterOption !=='state', 'fa-caret-up': filterOption =='state'}"></i>
                    </span>
                    <div class="filter-options" *ngIf="filterOption == 'state'">
                        <div class="form-check" *ngFor="let state of states">
                            <span (click)="inUse(state.state) ? removeFromRouteParams(state.state, 'state') : addToRouteParams(state.state, 'state')" class="hover-param">
                                <i class="fa" [ngClass]="{'fa-check-square-o': inUse(state.state), 'fa-square-o':!inUse(state.state)}"></i>
                                <span class="">
                                    {{state.state}}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <!--========== Region Dropdown ==========-->
            <li id="dropdownCmp" click-outside (clickOutside)="onClickOutside($event)" (click)="filterBy('region')">
                <div>
                    <span *ngIf="routeParams.selectedRegions.length == 0">Region
                        <i class="fa" [ngClass]="{'fa-caret-down':filterOption !=='region', 'fa-caret-up': filterOption =='region'}"></i>
                    </span>
                    <span *ngIf="routeParams.selectedRegions.length > 0">
                         <span *ngFor="let s of routeParams.selectedRegions">{{regionName(s)}}<span *ngIf="routeParams.selectedRegions.length > 1">,</span> </span>
                        <i class="fa" [ngClass]="{'fa-caret-down':filterOption !=='region', 'fa-caret-up': filterOption =='region'}"></i>
                    </span>
                    <div class="filter-options" *ngIf="filterOption == 'region'">
                        <div class="form-check" *ngFor="let s of serviceRegions">
                            <span (click)="regionUse(s.id) ? removeFromRouteParams(s.id, 'region') : addToRouteParams(s.id, 'region')" class="hover-param">
                                <i class="fa" [ngClass]="{'fa-check-square-o': regionUse(s.id), 'fa-square-o':!regionUse(s.id)}"></i>
                                <span class="">
                                    {{s.name}}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <!--========== Zip Code Input ==========-->
            <li>
                <input type="text"
                       name="queryzip"
                       id="zipCode"
                       placeholder="Zip Code"
                       [(ngModel)]="routeParams.zip">
            </li>
            <li (click)="search()">
                <a href="javascript:void(0)">Search</a>
            </li>
            <li class="border-right-1" (click)="clearParams()">
                <a href="javascript:void(0)">Clear</a>
            </li>

            <li id="dropdownCmp" class="pull-right" *ngIf="!workOrder" click-outside (clickOutside)="onClickOutside($event)" (click)="filterOption === 'more' ? closeOut():filterBy('more')">
                <div>
                    <span>More
                        <i class="fa" [ngClass]="{'fa-caret-down':filterOption !=='more', 'fa-caret-up': filterOption =='more'}"></i>
                    </span>
                    <div class="filter-options more-box" *ngIf="filterOption == 'more'">
                        <div *ngIf="isAdmin && location.path() !== '/new-route'" (click)="newR()" class="hover-param">New Route</div>
                        <div (click)="cancel()" class="hover-param">Cancel</div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</div>

<prompt-modal
    #deliveryPrompt
    size="small"
    (onClosed)="setDate()"
>
        <div class="modal-header">
            <h4 class="modal-title" *ngIf="workOrder">Route Date</h4>
            <h4 class="modal-title" *ngIf="!workOrder">Delivery Date</h4>
        </div>

        <div class="modal-item">
            <div class="col-12">

                <datepicker
                    *ngIf="modalDate"
                    [(ngModel)]="modalDate"
                    class="date-modal"
                    name="Delivery Date"
                    datepickerMode="day"
                    [showWeeks]="false"
                    (selectionDone)="deliveryPrompt.hidePromptModal();"
                >
                </datepicker>
            </div>
        </div>

        <div class="modal-buttons">
            <button type="submit" class="btn btn-primary" (click)="deliveryPrompt.hidePromptModal()">Set Date</button>
            <button type="button" class="btn btn-light" (click)="deliveryPrompt.hidePromptModal()">Cancel</button>
        </div>

</prompt-modal>
