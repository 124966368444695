import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Tractor } from '../../models/tractor';
import { environment } from '../../../environments/environment';

@Injectable()

export class TractorService {
    private apiUrl = environment.apiUrl; // URL to web api
    constructor(public authHttp: HttpClient) { }

    getTractors(): Promise<Tractor[]> {
        return this.authHttp.get(`${this.apiUrl}tractor`)
            .toPromise()
            .then((response) => {
                return response as Tractor[];
            })
            .catch(this.handleError);
    }

    saveTractor(tractor: Tractor): Promise<Tractor> {
        if (tractor.id) {
            return this.updateTractor(tractor);
        } else {
            return this.createTractor(tractor);
        }
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    createTractor(tractor: Tractor): Promise<Tractor> {
        return this.authHttp.post(`${this.apiUrl}tractor`, tractor)
            .toPromise()
            .then((res) => {
                return res as Tractor;
            });
    }
    updateTractor(tractor: Tractor) {
        return this.authHttp.put(`${this.apiUrl}tractor/${tractor.id}`, tractor)
            .toPromise()
            .then((res) => {
                return res as Tractor;
            });
    }

}
