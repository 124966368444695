import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { dashboardRouting } from './dashboard.routing';
import { DashboardComponent } from './dashboard.component';
import { RecentClientsComponent} from './recent-clients/recent-clients.component';
import {RecentRoutesComponent} from './recent-routes/recent-routes.component';
import {RecentWorkOrdersComponent} from './recent-work-orders/recent-work-orders.component';
import {RecentLabsComponent } from './recent-labs/recent-labs.component';
import {RecentClientsResolve, RecentRoutesResolve, RecentWorkOrdersResolve, RecentLabsResolve} from './dashboard.resolver';
import {PipesModule} from '../pipes/pipes.module';
import { CurrentEmployeeResolve } from '../resolvers/employees.resolver';
import { SortablejsModule } from 'ngx-sortablejs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        dashboardRouting,
        PipesModule,
        SortablejsModule,
        PaginationModule,
        FormsModule
    ],
    declarations: [
       DashboardComponent, RecentClientsComponent, RecentRoutesComponent, RecentWorkOrdersComponent, RecentLabsComponent
    ],
    providers: [RecentClientsResolve, RecentRoutesResolve, RecentWorkOrdersResolve, RecentLabsResolve, CurrentEmployeeResolve]
})

export class DashboardModule { }
