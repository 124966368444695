import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  HostListener,
  OnDestroy
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  get as _get,
  startCase as _startCase
} from 'lodash';
import * as moment from 'moment';
import { HttpService } from '../../../shared-services/http-service/http-service.service';
import { EventService } from '../../../shared-services/event-service/event.service';
import { ToastService } from '@services/toast.service';
import { AuthService } from "../../../authorization/auth.service";

@Component({
  selector: 'reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportingComponent implements OnInit {
  @ViewChild('rm', { static: true }) public reportModal;
  headerConfig = {
    title: 'Reporting',
    searchableView: false,
    creatable: false,
    header: 'Reporting'
  };
  tbodyHeight: string;
  navHeight: string;
  accHeight: string;
  rows = [];
  selectedTable: any = null;
  config: any = {
    title: '',
    description: ''
  };
  allSelected = false;
  flexConfig: any;
  loading = false;
  myBranchQuery: any;
  dateRangeFrom = moment.parseZone().subtract(3, 'month').format('YYYY-MM-DDTHH:mm');
  dateRangeTo = moment.parseZone().format('YYYY-MM-DDTHH:mm');

  public tables: any[] = [];
  public selectedCustomQuery: any = {};
  public savedQueries: Array<Object> = [];
  public access = 1;
  public calcableProps: Array<string> = [];

  private noShow: Array<any> = [];
  private show: Array<any> = [];

  constructor(
    private toastService: ToastService,
    private http: HttpService,
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private auth: AuthService
  ) {
    const user = this.activatedRoute.snapshot.data['user'] || {};
    if (user.role === 'Admin') {
      this.access = 5;
    } else if (this.auth.employeeType(['Sales Manager'])) {
      this.access = 2;
    }
  }

  async ngOnInit() {
    this.getHeight();
    this.noShow = [
      'App',
      'AppOption',
      'Changelog',
      'DocumentBlob',
      'Document',
      'PLV8Module',
      'RoleType',
      'SavedReport',
      'Type',
      'TypeCode',
      'TypeMeta',
      'UserRole'
    ];

    this.flexConfig = { sort: false, routing: false, table: 'schema' };
    this.loading = true;
    this.tables = this.hideNoShow(this.activatedRoute.snapshot.data['tables']);
    this.orderColumns();
    this.getTables();

    // this.myBranchQuery = {
    //   'branch_id': this.currentUser.employee.branch.id
    // };

    this.savedQueries = this.activatedRoute.snapshot.data['savedReports'] ? this.activatedRoute.snapshot.data['savedReports'] : [];
  }

  hideNoShow(tables: any) {
    this.show = [];
    return tables && tables.length > 0 ? tables.filter((table) => {
      if (this.noShow.indexOf(table.table_name) === -1 && !table.table_name.includes('_')) {
        this.show.push(table.table_name);
        return true;
      }
      return false;
    }) : [];
  }

  updateReport(evt) {
    this.eventService.broadcast('loading', true);
    // set branchID
    // evt.branchID = evt.branchFilter ? this.currentUser.employee.branch.id : null;
    this.http.post(evt.endpoint, { options: evt })
      .toPromise()
      .then((resp) => {
        if (resp.statusCode !== 500) {
          this.save(resp)
            .then(() => this.getTables());
        } else {
          this.toastService.toast({ message: resp.errorMessage.message, type: 'danger' });
          this.eventService.broadcast('loading', false);
        }
      });
  }

  @HostListener('window:resize') getHeight() {
    const height = window.innerHeight > 655 ? window.innerHeight : 655;
    this.tbodyHeight = `${height - 597}px`;
    this.navHeight = `${height - 115}px`;
    this.accHeight = `${height / 2 - 90}px`;
  }

  orderColumns() {
    this.tables.forEach((table) => {
      table.columns = table.columns.sort((a, b) => (a.column_name > b.column_name) ? 1 : ((b.column_name > a.column_name) ? -1 : 0));
    });
  }

  getTables() {
    this.http.post('schema/get', {
      'get': ['*'],
      'where': {
        'active': true,
        'condition': [{ 'and': ['schema_name', 'in', this.show] }]
      },
      'group': ['schema_name'],
      'sort': {
        'schema_name': 'ASC'
      }
    })
      .toPromise()
      .then((res) => {
        this.mergeTables(res.data);
      });
  }

  private async mergeTables(schemaData) {
    await this.tables.forEach((table) => {
      schemaData.forEach((data) => {
        if (table.table_name === data.schema_name) {
          table.id = data.id;
          table.schema_nice_name = data.schema_nice_name;
          table.description = data.description;

          table.columns.forEach((column) => {
            const schema = _get(data.schema, column.column_name, null);
            if (schema) {
              if (schema.hasOwnProperty('default')) column.column_default = schema['default'];
              if (schema.hasOwnProperty('maxlength')) column.column_maxlength = schema['maxlength'];
              if (schema.hasOwnProperty('desc')) column.desc = schema['desc'];
              if (schema.hasOwnProperty('nice_name')) column.nice_name = schema['nice_name'];
              if (schema.hasOwnProperty('key')) {
                column.column_key = schema['key'];
                if (schema['key'] === 'foreign' && schema.hasOwnProperty('references')) {
                  column.key_reference = schema['references'];
                }
              }
            }
          });
        } else {
          // if table not exists in schema table, remove it
          // this.tables.splice(i, 1);
        }
      });
    });
    this.loading = false;
  }

  async selectDeselectAll(event) {
    event.preventDefault();
    if (!this.allSelected) {
      await this.selectedTable.columns.forEach((col) => {
        this.onColumnSelected(col, true);
      });
    } else {
      await this.selectedTable.columns.forEach((col) => {
        this.onColumnSelected(col, false);
      });
    }
    this.allSelected = !this.allSelected;
  }

  onColumnSelected(column: any, selectAll) {
    this.tables = this.tables.map((tab: any) => {
      tab.selected = tab.table_name === column.table_name;
      tab.columns.map((col: any) => {
        if (selectAll !== null) {
          if (selectAll) {
            if (col.selected != null && !col.selected) {
              col.selected = !col.selected;
            } else {
              col.selected = true;
            }
          } else if (!selectAll) {
            if (col.selected != null && col.selected) {
              col.selected = !col.selected;
            } else {
              col.selected = false;
            }
          }
        } else {
          if (col.selected != null) {
            if (col.column_name === column.column_name) {
              col.selected = !col.selected;
            }
          } else {
            col.selected = col.column_name === column.column_name;
          }
        }
        return col;
      });
      return tab;
    });
  }

  startCase(str: string) {
    return _startCase(str);
  }

  onCloseClicked(table: any) {
    const output = {};
    this.tables.forEach((item: any) => {
      if (item.table_name === table.table_name) {
        output['table'] = item.table_name.split(' ').join('');
        output['columns'] = item.columns.filter((col: any) => {
          return col.selected === true;
        }).map((r) => {
          return r.column_name;
        });
        output['conditions'] = item.conditions != null ? item.conditions : null;
        for (const cond in output['conditions']) { // for in
          if (output['conditions'].hasOwnProperty(cond)) {
            const operator = Object.keys(output['conditions'][cond])[0];
            const condition = output['conditions'][cond][operator][1].toLowerCase();
            const value = output['conditions'][cond][operator][2];
            if (condition === 'in' && !Array.isArray(value)) {
              output['conditions'][cond][operator][2] = value.split(',').join(', ').split(',');
            }
            if (condition === 'between') {
              output['conditions'][cond][operator][2] = `${value} AND ${output['conditions'][cond][operator][3]}`;
              output['conditions'][cond][operator].pop();
            }
            if (condition === 'starts with') {
              output['conditions'][cond][operator][1] = 'STARTSWITH';
            }
          }
        }
        item.selected = null;
      }
    });
    if (output['columns'].length > 0) {
      this.eventService.broadcast('loading', true);
      this.fetchReport(output['table'], output['columns'], output['conditions']);
    }

    return output;
  }

  onItemClicked(table: any) {
    if (this.access > 1) {
      this.selectedCustomQuery = {}; // single table mode
      this.tables = this.tables.map((item: any) => {
        item.selected = item.table_name === table.table_name;
        if (item.selected) {
          if (this.selectedTable) this.selectedTable.columns = this.clearColumnSelection(this.selectedTable.columns);
          item.columns = this.clearColumnSelection(table.columns);
          this.selectedTable = item;
          this.setConfig();
        }
        return item;
      });
    } else {
      this.toastService.toast({ message: 'Reporting is not available for User!', type: 'danger' });
    }
  }

  async onCustomClicked(event) {
    if (this.access > 1 || event.access_level === 'user') {
      this.selectedTable = null; // clear the selection
      this.selectedCustomQuery['report_name'] = event.report_name;
      // event.query = this.formatEventQuery(event.query, event.type);
      // event.query.options.report_name = event.report_name;
      await this.fetchCustomReport(event.table_name, event.query);
    } else if (event.access_level !== 'user' && this.access === 1) {
      this.toastService.toast({ message: 'Reporting is not available for User!', type: 'danger' });
    }
  }

  // formatEventQuery(query, type) {
  //   if (type && type.length && type[0] !== null) {
  //     if (type[0].type_name.toLowerCase() === 'my branch') {
  //       if (query.hasOwnProperty('where')) {
  //         _merge(query['where'], this.myBranchQuery);
  //       } else {
  //         query['where'] = this.myBranchQuery;
  //       }
  //     } else if (type[0].type_name.toLowerCase() === 'member account history') {
  //       query.options['from'] = this.dateRangeFrom;
  //       query.options['to'] = this.dateRangeTo;
  //       query.options['branchID'] = this.currentUser.employee.branch.id;
  //     }
  //   }

  //   return query;
  // }

  clearColumnSelection(columns) {
    columns.forEach((col) => {
      if (col.selected) delete col.selected;
    });
    return columns;
  }

  setConfig() {
    if (this.selectedTable) {
      this.config = {
        title: this.selectedTable.schema_nice_name,
        description: this.selectedTable.description
      };
    } else {
      this.config = {
        title: '',
        description: ''
      };
    }
  }

  getAllTables() {
    this.http.post('session/list-all-table', {})
      .subscribe((resp) => {
        this.tables = resp && resp.data && resp.data.length > 0 ? resp.data : [];
      });
  }

  fetchReport(table: string, props: any, conditions: any) {

    const params = props != null && props.length > 0 ? props : ['*'];

    const query = {
      get: params
    };
    if (conditions && conditions.length > 0) {
      if (query['where'] == null) query['where'] = {};
      query['where']['condition'] = conditions;
    }

    if (table === "ClientRep") {
      query['join'] = [
        {
          "table": "Client",
          "joinType": "left outer join",
          "get": ['name'],
          "on": [{ "Client": "id", "ClientRep": "clientId" }],
          "as": "Dairy"
        }, {
          "table": "Employee",
          "joinType": "left outer join",
          "get": ["concat(firstName, ' ', lastName) as Sales Rep"],
          "on": [{ "Employee": "id", "ClientRep": "repId" }],
          "as": "SalesRep"
        }];
    } else if (table === "Client") {
      query['join'] = [
        {
          "table": "ClientRep",
          "joinType": "inner join",
          "get": ["repId"],
          "include": false,
          "on": [{ "ClientRep": "clientId", "Client": "id" }],
          "as": "repId"
        }, {
          "table": "Employee",
          "joinType": "inner join",
          "get": ["concat(firstName, ' ', lastName) as Sales Rep"],
          "on": [{ "Employee": "id", "ClientRep": "repId" }],
          "as": "SalesRep"
        }];
    } else if (table === "DeliveryOrder") {
      query['sort'] = { 'id': 'DESC' };
      query['limit'] = 63580;
      query['join'] = [
        {
          "table": "Client",
          "joinType": "left join",
          "get": ["name"],
          "on": [{ "Client": "id", "DeliveryOrder": "clientId" }],
          "as": "clientName"
        }, {
          "table": "Tank",
          "joinType": "left join",
          "get": ["tankName"],
          "on": [{ "Tank": "id", "DeliveryOrder": "tankId" }],
          "as": "tankName"
        }, {
          "table": "Product",
          "joinType": "left join",
          "get": ["name"],
          "on": [{ "Product": "id", "DeliveryOrder": "productId" }],
          "as": "productName"
        }, {
          "table": "Address",
          "joinType": "left join",
          "on": [{ "Address": "id", "Tank": "addressId" }]
        }, {
          "table": "ServiceRegion",
          "joinType": "left join",
          "get": ["serviceRegionName"],
          "on": [{ "ServiceRegion": "id", "Address": "serviceRegionId" }],
          "as": "territory"
        }
      ];
      query['group'] = query['get'];
    } else if (table === "PhoneNumber") {
      query['join'] = [
        {
          "table": "ClientContact",
          "joinType": "left outer join",
          "get": ['name', 'email'],
          "on": [{ "ClientContact": "id", "PhoneNumber": "entityId" }],
          "as": "Client"
        }, {
          "table": "Client",
          "joinType": "left join",
          "get": ["name"],
          "on": [{ "Client": "id", "ClientContact": "clientId" }],
          "as": "Dairy Name"
        }];
    }

    this.http.post(`${table}/csv-export`, query)
      .subscribe((resp) => {
        if (resp.statusCode !== 500) {
          this.save(resp)
            .then(() => this.getTables());
        } else {
          this.toastService.toast({ message: resp.errorMessage.message, type: 'danger' });
          this.eventService.broadcast('loading', false);
        }
      });
  }

  fetchCustomReport(table: any, query: any) {
    let options = null;
    if (query.options) {
      options = Object.assign({}, query.options);
    }
    const endpoint = query.endpoint != null ? `${table}/${query.endpoint}` : `${table}/csv-export`;

    if (options != null) {
      options.endpoint = endpoint;
      this.reportModal.show(options);
    } else {
      this.eventService.broadcast('loading', true);
      this.http.post(endpoint, query)
        .subscribe((resp) => {
          if (resp.statusCode !== 500) {
            this.save(resp)
              .then(() => this.getTables());
          } else {
            this.toastService.toast({ message: resp.errorMessage.message, type: 'danger' });
            this.eventService.broadcast('loading', false);
          }
        });
    }

  }

  save(resp) {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.http.saveAs(resp.filename, resp.data);
        if (this.selectedTable) {
          this.selectedTable.columns = this.clearColumnSelection(this.selectedTable.columns);
          this.allSelected = false;
          this.clearConditions();
        } else {
          // customQuery save
        }

        this.eventService.broadcast('loading', false);
        resolve(true);
      }, 1000);
    });
  }

  clearConditions() {
    delete this.selectedTable.conditions;
  }

  getTableIndex(tableName) {
    return this.tables.findIndex((item) => item['table_name'] === tableName);
  }

  addConditional(tableName) {
    const cond = { 'AND': ['', 'IS'] };
    const tableIndex = this.getTableIndex(tableName);
    if (this.tables[tableIndex]['conditions'] == null) this.tables[tableIndex]['conditions'] = [];
    this.tables[tableIndex]['conditions'].push(cond);
  }

  updateSuccess(event) {
    if (event.data) {
      this.getTables();
    }
  }

}
