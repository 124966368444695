import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';

import {ClientContact} from "../../../models/contact";
import {PhoneNumber} from "../../../models/phone";

@Component({
    templateUrl: './edit-contact.component.html',
    styleUrls: ['./contact-info.component.css'],
    selector: 'contact-edit'
})
export class ClientContactEditComponent implements OnInit{
    @Input() contact: ClientContact;
    @Output() cancelEdit = new EventEmitter();
    @Output() saveContact = new EventEmitter<ClientContact>();
    _contact:ClientContact;
    numbers: PhoneNumber[];

    ngOnInit(){
        this._contact = new ClientContact(this.contact);
    }
    constructor(){}

    save(cont: ClientContact){
        this.saveContact.emit(cont);
    }

    cancel(){
        this.cancelEdit.emit();
    }
}