import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Toast } from '../../models/toast';
import { UntypedFormGroup } from '@angular/forms';

@Injectable()
export class ToastService {
    private _toasts = new BehaviorSubject<Toast[]>([]);
    toasts$ = this._toasts.asObservable();

    toast(toast: Toast) {
        const toasts = this._toasts.getValue();
        toasts.push(new Toast(toast));
        this._toasts.next(toasts);
    }

    validate(form: UntypedFormGroup) {
        const errMsg: string[] = [];
        Object.keys(form.controls).map(key => {
            const errors = form.controls[key]['errors'];
            if (errors) {
                // using form input name in error message
                errMsg.push(key);
            }
        });
        if (errMsg.length > 1) {
            errMsg[errMsg.length - 1] = 'and ' + errMsg[errMsg.length - 1];
            this.toast({ message: `${errMsg.join(', ')} are required.` });
        } else if (errMsg.length > 0) {
            this.toast({ message: `${errMsg.join(', ')} is required.` });
        }
    }
}
