<!--Large modal-->
<ng-template #textModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <a href="#" (focus)="last.focus()" class="tab-loop">to last</a>
    <button #first type="button" class="close pull-right" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" (ngSubmit)="save($event, note)">
      <div class="form-group">
        <label for="note">Content</label>
        <app-textarea name="note"
                      [focus]="true"
                      [(ngModel)]="note.value">
        </app-textarea>
      </div>
      <p class="bg-success bg"
          *ngIf="saveResult?.saveSuccess">Note Saved Successfully</p>
      <p class="bg-danger bg"
          *ngIf="saveResult?.saveSuccess === false">{{ saveResult?.error }}</p>
      <div class="flex-container flex-end">
        <button #last class="btn btn-primary margin-left"
                type="submit">&nbsp;<i class="fa fa-save"></i>&nbsp;</button>
      </div>
      <a href="#" (focus)="first.focus()" class="tab-loop">to first</a>
    </form>
  </div>
</ng-template>