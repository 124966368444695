import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import * as _ from "lodash";

import { Route } from "@models/route";
import { Tank } from "@models/tank";
import { Product } from "@models/product";
import { Facility } from "@models/facility";
import { DeliveryOrder } from "@models/delivery-order";
import { RouteParams } from "@models/route-params";
import { environment } from "@environment";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class RouteService {
  constructor(private authHttp: HttpClient) {}

  private apiUrl = environment.apiUrl; // URL to web api
  public hasChanges = false;

  getAllDeliveryRoutes(): void {}
  getAllWorkOrderRoutes(): void {}

  findServiceRouteById(id: number): Promise<Route> {
    return this.authHttp
      .get(`${this.apiUrl}route/service/${id}`)
      .toPromise()
      .then((res) => {
        return res as Route;
      })
      .catch(this.handleError);
  }

  findRouteById(id: number): Promise<Route> {
    return this.authHttp
      .get(`${this.apiUrl}route/${id}`)
      .toPromise()
      .then((res) => {
        return res as Route;
      })
      .catch(this.handleError);
  }

  saveDeliveryRoute(route: Route): Promise<Route> {
    if (route.id) {
      return this.updateDeliveryRoute(route).then((res) => {
        return this.findRouteById(res.id);
      });
    } else {
      return this.createDeliveryRoute(route).then((res) => {
        return this.findRouteById(res.id);
      });
    }
  }

  updateRoute(route: Route): Promise<Route> {
    return this.authHttp
      .put(`${this.apiUrl}route/${route.id}`, route)
      .toPromise()
      .then((res) => {
        return res as Route;
      })
      .catch(this.handleError);
  }

  saveServiceRoute(route: Route): Promise<Route> {
    if (route.id) {
      return this.updateServiceRoute(route).then((res) => {
        return this.findServiceRouteById(res.id);
      });
    } else {
      return this.createServiceRoute(route).then((res) => {
        return this.findServiceRouteById(res.id);
      });
    }
  }

  // Methods for creating a route and its delivery orders

  /*
   * Create a delivery route
   *
   * */
  createDeliveryRoute(route: Route): Promise<Route> {
    route.routeType = "DROP";
    this.makeDeliveryOrders(route);
    return this.authHttp
      .post(`${this.apiUrl}route/createDeliveryRoute`, route)
      .toPromise()
      .then((res) => {
        return res as Route;
      })
      .catch(this.handleError);
  }

  /*
   * Method to make the delivery order
   *
   * */
  makeDeliveryOrders(route: Route): void {
    const locations = route.locations;
    locations.forEach((location) => {
      const hasOrder = _.find(route.deliveryOrders, { tankId: location.id });
      if (!hasOrder) {
        route.deliveryOrders.push(this.buildDeliveryOrder(location, route));
      }
    });
  }

  /*
   * Method to build the delivery order and then
   * */
  buildDeliveryOrder(location: Tank, route: Route): DeliveryOrder {
    // let dropOrder = location['dropOrder'] ? location['dropOrder'] : _.indexOf(route.locations, location) + 1;
    const data = {
      quantityOrdered: location.gallonsToFull,
      clientId: location.clientId,
      productId: location.productId,
      tankId: location.id,
      scheduledDate: route.dateOfRoute,
      dropOrder: location["dropOrder"],
      deliveryNumber: location["deliveryNumber"],
      addressId: location.addressId,
    };

    return new DeliveryOrder(data);
  }

  // Methods to update a delivery route

  /*
   * Method to update a delivery route
   * */
  updateDeliveryRoute(route: Route): Promise<Route> {
    // Loops through deliveryOrders
    route.deliveryOrders.forEach((order, index) => {
      // Checks to see if delivery order is also a route location
      const location = _.find(route.locations, { id: order.tankId });
      // TODO: Cleans out duplicates that do not match a location.
      // This check should not be necessary once all routes have been corrected.
      // If it is then it updates the do by itself
      if (location) {
        const gal = location.gallonsToFull;
        order.quantityOrdered =
          order.quantityOrdered === gal ? order.quantityOrdered : gal;
        order.deliveryNumber = location.deliveryNumber;
      } else {
        // if a delivery order has been removed then it will splice it off the orders here
        route.deliveryOrders.splice(index, 1);
      }
    });
    // checks here for new location that will need to be saved as a delivery order
    if (
      route.locations.length !== route.deliveryOrders.length &&
      route.locations.length > route.deliveryOrders.length
    ) {
      const newLocations = _.filter(route.locations, (location) => {
        const found = _.find(route.deliveryOrders, { tankId: location.id });
        return typeof found !== "object";
      });
      newLocations.forEach((location) => {
        route.deliveryOrders.push(this.buildDeliveryOrder(location, route));
      });
    }
    route.deliveryOrders.forEach((o) => {
      if (o.id) {
        const loc = _.find(route.locations, {
          addressId: o["Tank"]["addressId"],
        });
        o.dropOrder = loc.dropOrder;
      }
    });

    return this.authHttp
      .put(`${this.apiUrl}route/updateDeliveryRoute/${route.id}`, route)
      .toPromise()
      .then((res) => {
        return res as Route;
      })
      .catch(this.handleError);
  }

  updateRouteDrivers(route: Route) {
    return this.authHttp
      .put(`${this.apiUrl}route/updateRouteDrivers/${route.id}`, route)
      .toPromise()
      .then((res) => {
        return res as Route;
      })
      .catch(this.handleError);
  }

  // Not in use at the moment
  createServiceRoute(route: Route): Promise<Route> {
    route.routeType = "SERVICE";
    return this.authHttp
      .post(`${this.apiUrl}route/createServiceRoute`, route)
      .toPromise()
      .then((res) => {
        return res as Route;
      })
      .catch(this.handleError);
  }

  // Not in use at the moment
  updateServiceRoute(route: Route): Promise<Route> {
    return this.authHttp
      .put(`${this.apiUrl}route/updateServiceRoute/${route.id}`, route)
      .toPromise()
      .then((res) => {
        return res as Route;
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  calculateRouteWeight(tanks: Tank[]): Product[] {
    const totalGallons = {};
    if (tanks.length === 0) return [];
    tanks.forEach((tank) => {
      totalGallons[tank.productId] = totalGallons[tank.productId] || {
        id: tank.productId,
        ordered: 0,
        name: tank.Product.name,
        abbr: tank.Product.abbr,
      };
      totalGallons[tank.productId].ordered += tank.gallonsToFull;
    });
    return Object.values(totalGallons);
  }

  calculateGallons(route: Route): Product[] {
    const totalGallons: { number?: Product } = {};
    if (route.deliveryOrders.length === 0) return [];
    route.deliveryOrders.forEach((order) => {
      const product = order.Product;
      totalGallons[order.productId] = totalGallons[order.productId] || {
        id: order.productId,
        ordered: 0,
        delivered: 0,
        name: product.name,
        abbr: product.abbr,
        level: 0,
        remaining: 0,
      };
      totalGallons[order.productId].ordered += order.quantityOrdered;
      totalGallons[order.productId].delivered += order.quantityDropped;
    });

    const productDetails = Object.values(totalGallons);

    productDetails.forEach((product) => {
      const routeProduct = (route.products || []).find((rtProduct) => {
        return product.id === rtProduct.productId;
      });
      if (routeProduct) {
        product.level = routeProduct.loaded;
        product.remaining = routeProduct.loaded - product.delivered;
      }
    });

    return productDetails;
  }

  calculateDeliveryOrdersGallons(
    deliveryOrders: DeliveryOrder[],
    productType: number
  ): number {
    if (deliveryOrders.length === 0) return 0;
    let gallons = 0;
    deliveryOrders.forEach((order) => {
      if (order.productId === productType) {
        gallons += order.quantityOrdered;
      }
    });
    return gallons;
  }

  getCurrentRoutes(dates: any): Observable<Route[]> {
    return this.authHttp
      .post(`${this.apiUrl}route/findRoutesByDate`, dates)
      .pipe(map((response: any) => response as Route[]));
  }

  getCurrentWorkRoutes(dates: any): Observable<Route[]> {
    return this.authHttp
      .post(`${this.apiUrl}route/findWorkRoutesByDate`, dates)
      .pipe(map((response: any) => response as Route[]));
  }

  getRouteAddresses(route: Route): any[] {
    const addresses: any[] = [];
    route["uniqueAddresses"].forEach((location: any) => {
      const address = location["Address"][0];
      let addressString = `${address.street},${address.city},${address.state},${address.zip}`;
      addressString = addressString.replace(/\s/g, "+");
      addresses.push({ location: addressString, stopover: true });
    });
    return addresses;
  }

  setRoute(route: Route, locations: Tank[], workOrder: boolean): void {
    let orders: any = route.deliveryOrders;
    if (workOrder) {
      orders = route.workOrders;
    }
    if (!route.locations) {
      route.locations = [];
    }
    orders.sort((a: any, b: any) => {
      return a.dropOrder - b.dropOrder;
    });
    orders.forEach((order: any) => {
      let location: any = null;
      if (workOrder) {
        location = _.find(locations, { id: order.id });
      } else {
        location = _.find(locations, { id: order.tankId });
      }
      if (location) {
        location["dropOrder"] = order["dropOrder"];
        location["gallonsToFull"] = order.quantityOrdered;
        const hasLocation = _.find(route.locations, { id: location.id });
        if (!hasLocation) {
          route.locations.push(location);
        }
      }
    });
    route.locations.forEach((location) => {
      const foundLocation = _.find(locations, { id: location.id });
      if (foundLocation) {
        _.remove(locations, { id: location.id });
      }
    });
  }

  getRouteStartAndEnd(route: Route, facilities: Facility[]): any {
    const startId = route["startLocationId"];
    const endId = route["endLocationId"];
    const origin = _.find(facilities, { id: _.parseInt(`${startId}`) });
    const destination = _.find(facilities, { id: _.parseInt(`${endId}`) });
    return {
      origin: `${origin.street}+${origin.city}+${origin.state}+${origin.zip}`,
      destination: `${destination.street}+${destination.city}+${destination.state}+${destination.zip}`,
    };
  }

  getCompletedEmployeeRoutes(
    params: any,
    employeeId: number,
    workOrder: boolean
  ): Promise<{ rows: Route[]; count: number }> {
    if (workOrder) {
      return this.getEmployeeCompletedWorkRoutes(params, employeeId);
    }
    return this.authHttp
      .get(`${this.apiUrl}employee-route/employee-routes/${employeeId}`, params)
      .toPromise()
      .then((response: any) => {
        return response as { rows: Route[]; count: number };
      })
      .catch(this.handleError);
  }

  getFutureEmployeeRoutes(
    params: any,
    employeeId: number,
    workOrder: boolean
  ): Promise<{ rows: Route[]; count: number }> {
    if (workOrder) {
      return this.getFutureEmployeeWorkRoutes(params, employeeId);
    }
    return this.authHttp
      .post(
        `${this.apiUrl}employee-route/employee-future-routes/${employeeId}`,
        params
      )
      .toPromise()
      .then((response: any) => {
        return response as { rows: Route[]; count: number };
      })
      .catch(this.handleError);
  }

  getActiveEmployeeRoutes(
    queryParams: any,
    employeeId: number,
    workOrder: boolean
  ): Promise<{ rows: Route[]; count: number }> {
    if (workOrder) {
      return this.getActiveEmployeeWorkRoutes(queryParams, employeeId);
    }

    return this.authHttp
      .post(
        `${this.apiUrl}employee-route/employee-active-routes/${employeeId}`,
        queryParams
      )
      .toPromise()
      .then((response: any) => {
        return response as { rows: Route[]; count: number };
      })
      .catch(this.handleError);
  }

  getRouteById(
    routeId: any,
    employeeId: number,
    workOrder: boolean
  ): Promise<{ rows: Route[]; count: number } | Route> {
    if (workOrder) {
      return this.getWorkRouteById(routeId, employeeId);
    }
    return this.authHttp
      .post(
        `${this.apiUrl}employee-route/employee-route/${employeeId}`,
        routeId
      )
      .pipe(
        map(
          (response: { rows: Route[]; count: number }) =>
            _.get(response, "rows[0]") || new Route()
        )
      )
      .toPromise()
      .catch(this.handleError);
  }

  getEmployeeCompletedWorkRoutes(
    params: any,
    employeeId: number
  ): Promise<{ rows: Route[]; count: number }> {
    return this.authHttp
      .post(
        `${this.apiUrl}employee-route/employee-work-routes/${employeeId}`,
        params
      )
      .toPromise()
      .then((response: any) => {
        return response as Route[];
      })
      .catch(this.handleError);
  }

  getFutureEmployeeWorkRoutes(
    params: any,
    employeeId: number
  ): Promise<{ rows: Route[]; count: number }> {
    return this.authHttp
      .post(
        `${this.apiUrl}employee-route/employee-future-work-routes/${employeeId}`,
        params
      )
      .toPromise()
      .then((response: any) => {
        return response as Route[];
      })
      .catch(this.handleError);
  }

  getActiveEmployeeWorkRoutes(
    params: any,
    employeeId: number
  ): Promise<{ rows: Route[]; count: number }> {
    return this.authHttp
      .post(
        `${this.apiUrl}employee-route/employee-active-work-routes/${employeeId}`,
        params
      )
      .toPromise()
      .then((response: any) => {
        return response as Route[];
      })
      .catch(this.handleError);
  }

  getWorkRouteById(
    routeId: any,
    employeeId: number
  ): Promise<{ rows: Route[]; count: number }> {
    return this.authHttp
      .post(
        `${this.apiUrl}employee-route/employee-work-route/${employeeId}`,
        routeId
      )
      .toPromise()
      .then((response: any) => {
        return response as { rows: Route[]; count: number };
      });
  }

  delete(id: number): Promise<Route[]> {
    return this.authHttp
      .delete(`${this.apiUrl}route/${id}`)
      .toPromise()
      .then((response: any) => {
        return response as Route[];
      })
      .catch(this.handleError);
  }

  /*
   * Get RouteRouteParams
   * Method for retrieving the route parameters for a specific route
   * */
  getRouteRouteParams(routeId: number): Promise<RouteParams> {
    return this.authHttp
      .post(`${this.apiUrl}route-params/${routeId}`, {})
      .toPromise()
      .then((res) => {
        return res as RouteParams;
      })
      .catch(this.handleError);
  }

  /*
   * Method for saving route parameters
   * */
  saveParams(routeParams: RouteParams): Promise<RouteParams> {
    return this.authHttp
      .post(`${this.apiUrl}route-params`, routeParams)
      .toPromise()
      .then((res) => {
        return res as RouteParams;
      })
      .catch(this.handleError);
  }
  /*
   * Method for updating route parameters
   * */
  updateParams(routeParams: RouteParams): Promise<RouteParams> {
    return this.authHttp
      .put(`${this.apiUrl}route-params/${routeParams["id"]}`, routeParams)
      .toPromise()
      .then((res) => {
        return res as RouteParams;
      })
      .catch(this.handleError);
  }

  updateProduct(routeProduct: any) {
    return this.authHttp
      .post(`${this.apiUrl}route-products`, routeProduct)
      .toPromise()
      .then((res) => {
        return res as RouteParams;
      })
      .catch(this.handleError);
  }
}
