import * as _ from 'lodash';

export class Facility{
    constructor(data?: any){
        _.merge(this, data);
    }

    public id: number = null;
    public street: string = null;
    public city: string = null;
    public state: string = null;
    public zip: string = null;
    public lat: string = null;
    public lon: string = null;
    public isActive: boolean = true;
}