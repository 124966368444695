import { ChangeDetectorRef, Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { MultiSelectService } from '../../../multi-select/multi-select.service';
import { TankService } from '@services/tank.service';
import { ResizeService } from '@services/resize.service';
import { Tank } from '@models/tank';
import { Route } from '@models/route';

import { Trailer } from '@models/trailer';
import { Tractor } from '@models/tractor';
import { Facility } from '@models/facility';
import { Employee } from '@models/employee';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Product } from '@models/product';

@Component({
  selector: 'route-overview',
  templateUrl: 'route-overview.component.html',
  styleUrls: ['route-overview.component.css'],
})
export class RouteOverviewComponent implements OnDestroy, OnInit {
  @Input()
  route: Route;
  @Input()
  tractors: Tractor[];
  @Input()
  trailers: Trailer[];
  @Input()
  drivers: Employee[];
  @Input()
  totalGallons: Product[];
  @Input()
  facilities: Facility[];
  @Input()
  saveSuccessful: boolean;
  @Input()
  saveError: boolean;
  @Input()
  copying: boolean;
  @Input()
  saving: boolean;
  @Input()
  deleteSuccessful: boolean;
  @Output()
  saveRoute = new EventEmitter();
  @Output()
  copyRoute = new EventEmitter();
  @Output()
  cancelCopyRoute = new EventEmitter();
  @Output()
  removeFromRoute = new EventEmitter();
  @Output()
  updateQuantities = new EventEmitter();
  @Output()
  reloadRoute = new EventEmitter();
  showingNotes: any;
  allSelected = false;
  @Input() setManual: boolean;
  @Input() workOrder: boolean;
  @Output() setManualOrder = new EventEmitter();
  @Output() mustSetOrder = new EventEmitter();
  @Output() moveLocationDown = new EventEmitter();
  @Output() moveLocationUp = new EventEmitter();
  dragging = false;
  sortableOptions: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private multiSelectService: MultiSelectService,
    private resizeService: ResizeService,
    private tankService: TankService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const container: {
      name: string;
      containerParent: string;
      selectedClass: string;
      idsToMove: any[];
      itemId: string;
      targetArray: Tank[];
    } = {
      name: 'route',
      containerParent: 'route-container',
      selectedClass: 'selected-location',
      idsToMove: [],
      itemId: 'route-location',
      targetArray: this.route.locations
    };
    this.multiSelectService.addContainer(container);
    this.sortableOptions = {
      onUpdate: (event: any) => {
        this.mustSetManual();
      },
      onStart: (event: any) => {
        this.dragging = true;
        this.changeDetectorRef.detectChanges();
      },
      onEnd: (event: any) => {
        this.dragging = false;
        this.changeDetectorRef.detectChanges();
      }
    };
    this.resizeService.resizePanel('route-container', 'route_dragbar');
  }

  ngOnDestroy(): void {
    this.multiSelectService.removeContainer('route');
  }

  showNotes(locId: any) {
    this.showingNotes = locId;
  }
  closeNotes() {
    this.showingNotes = null;
  }

  selectLocation(event: any, location: Tank): void {
    if (event.target.id === 'move-up' || event.target.id === 'move-down') return;
    if (event.target.id === 'gallonsToFull') return;
    this.multiSelectService
      .selectLocation(event,
        location,
        'route');
    this.removeFromRoute.emit('move');
  }

  mustSetManual() {
    this.mustSetOrder.emit();
  }
  moveUp(tank: Tank) {
    this.moveLocationUp.emit(tank);
  }

  moveDown(tank: Tank) {
    this.moveLocationDown.emit(tank);
  }

  save(route: Route): void {
    this.saveRoute.emit(route);
  }

  copy(route: Route): void {
    this.copyRoute.emit(route);
  }
  setManOrder(route: Route): void {
    this.setManualOrder.emit(route);
  }

  cancelCopy(): void {
    this.cancelCopyRoute.emit('cancel-copy');
  }

  updateQuantity() {
    this.updateQuantities.emit();
  }

  notNegative(event: any) {
    return ['-', '+'].indexOf(event.key) === -1;
  }

  // Projected ExpAvgDailyUse
  dailyExpAvgDailyUse(location: any) {
    if (location.expAvgDailyUse !== null && location.expAvgDailyUse !== 0) {
      return location.expAvgDailyUse;
    } else {
      return 'NEW';
    }
  }

  // Actual Avg/Daily
  dailyCalculatedDailyUse(location: any) {
    if (location.calculatedDailyUse !== null) {
      return location.calculatedDailyUse;
    } else {
      return 'NEW';
    }
  }

}
