import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { HttpService } from '../../shared-services/http-service/http-service.service';
import { EventService } from '../../shared-services/event-service/event.service';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { TextareaModalComponent } from './textarea-modal/textarea-modal.component';
import { get as _get } from 'lodash';
import { ToastService } from "../../services-module/services/toast.service";
import { FlexModel } from '@models/flex-model';

@Component({
  selector: 'flex-input',
  templateUrl: './flex-input.component.html',
  styleUrls: ['./flex-input.component.scss']
})
export class FlexInputComponent implements OnInit, OnChanges {
  @Input() flexModel = new FlexModel();
  @Input() config;
  @Output() onUpdateSuccess: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateInput: EventEmitter<any> = new EventEmitter();
  @Output() expandInput: EventEmitter<any> = new EventEmitter();
  @Output() textSaved: EventEmitter<any> = new EventEmitter();
  @Output() onValidate: EventEmitter<any> = new EventEmitter();
  @ViewChild('flexInput') private input: ElementRef;
  @ViewChild('modal', { static: true }) private modal: TextareaModalComponent;

  private originalModel: any;
  editMode: boolean;
  allowEdit: boolean;
  private selectedValue: any;
  private mode: any;
  private inputType: any;

  constructor(private http: HttpService,
    private toastService: ToastService,
    private eventService: EventService) {
  }

  ngOnInit() {
    this.editMode = false;
    this.allowEdit = true;
    this.mode = (!this.flexModel.id || typeof this.flexModel.id === 'object' ?
      this.flexModel.belongsToID != null ? true : false :
      this.flexModel.id ? true : false) ? 'update' : 'create';
    if (this.mode === 'create' && (this.flexModel.inputType === 'date' || this.flexModel.pipe === 'date')) {
      this.flexModel.value = this.flexModel.value ? moment.parseZone(this.flexModel.value).format('YYYY-MM-DD') : undefined;
    }
    this.selectedValue = this.setDefaultValue(this.flexModel);
  }

  ngOnChanges(changes) {
    if (changes && changes['flexModel']) {
      this.originalModel = Object.assign({}, this.flexModel);
    }
  }

  setDefaultValue(flexModel) {
    const values = flexModel.value;
    // show/hide input on init
    this.setEditModeOnInit(flexModel);
    if (values && Array.isArray(values)) { // if multi select
      this.inputType = 'select';
      const defaultVal = values.filter((item, i, a) => {
        return item.id === flexModel.belongsToID;
      });
      if (defaultVal.length > 0) {
        return defaultVal.pop();
      }
      return values[0];
    } else if (flexModel.inputType === 'color') {
      this.inputType = 'color';
    } else if (flexModel.inputType === 'date') {
      this.inputType = 'date';
    } else if (flexModel.key !== 'version') {
      this.inputType = 'text';
    } else if (flexModel.key === 'version') {
      this.inputType = 'textgroup';
    }
    return values;
  }

  private setEditModeOnInit(flexModel) {
    // show/hide input on init
    const values = flexModel.value,
      key = flexModel.key,
      dataType = typeof values;
    if (flexModel.belongsToID == null &&
      typeof flexModel.disableOnCreate === 'undefined') {
      switch (true) {
        case dataType === 'object' && values == null && (flexModel.inputType === 'textarea' && flexModel.maxLength === Infinity):
          this.editMode = false;
          this.flexModel.value = 'New description...';
          break;
        case dataType === 'object':
        case (dataType === 'string'):
        case (dataType === 'number' && key !== 'id'):
          this.editMode = true;
          break;
        case (dataType === 'number' && key === 'id'):
        case dataType === 'boolean':
          this.editMode = false;
          break;
        default:
          this.editMode = true;
          break;
      }
      this.expandInput.emit(this.editMode);
    }
    return this;
  }

  private onFocus(e) {
  }

  onFocusOut(flexModel) {
    if (flexModel.inputType === 'color') return;
    if (this.mode === 'update' && this.editMode) {
      if (flexModel.pipe === 'date') {
        flexModel.value = flexModel.value + ' 00:00:00+00:00';
      }
      this.editMode = false;
      this.expandInput.emit(false);
    } else if (flexModel.format) {
      flexModel.value = flexModel.format(flexModel.value);
    }
    this.onUpdateInput.emit(flexModel);
    this.updateOrCreate(flexModel, { toast: true });
  }

  closeColor(flexModel) {
    if (this.mode === 'update' && this.editMode) {
      this.editMode = false;
      this.expandInput.emit(false);
    }
    this.onUpdateInput.emit(flexModel);
    this.updateOrCreate(flexModel, { toast: true });
  }

  private onCalendarBlur(event, flexModel) {
  }

  private async updateOrCreate(flexModel, options?) {
    const key = flexModel['key'],
      value = flexModel['value'],
      hasChanges = value !== this.originalModel.value;
    if (hasChanges) {
      if (key === 'id') {
        this.flexModel = Object.assign({}, this.originalModel);
        this.toastService.toast({message: 'Changing an ID property is not allowed!', type: 'danger'});
      } else {
        const payload = {};
        let recordID, table;
        // CHECK TO SEE IF TABLE IS TYPE
        if (this.config && this.config.table.toLowerCase().trim() === 'type'
          && this.config.type != null) {
          payload['type_code_id'] = await this.getTypeId(this.config.type);
        }
        // END CHECK TO SEE IF TABLE IS TYPE
        payload[key] = value;
        table = this.config.table;
        if (this.mode === 'update') {
          recordID = (!this.flexModel.id || typeof this.flexModel.id === 'object') ? flexModel.belongsToID : flexModel.id;
          const isCrossTableUpdate = flexModel.belongsToID && flexModel.belongsToTable &&
            flexModel.belongsToTable.toLowerCase() !== this.config.table.toLowerCase();
          if (isCrossTableUpdate) {
            recordID = flexModel.belongsToID;
            table = flexModel.belongsToTable;
            const msg = 'Warning, you are requesting a cross table update!';
            this.toastService.toast({message: msg, type: 'warning'});

          } else {
          }
        } else if (this.mode === 'create') {
          recordID = null;
        }
        this.save(table, payload, recordID, false, options);
      }
    } else if (this.mode === 'update') {
      this.editMode = false;
      this.expandInput.emit(false);
    }
  }

  // for database using Type and TypeCode
  private getTypeId(codeName: String) {
    return this.http.post('typeCode/get', {
      'get': ['id'],
      'where': {
        'code_name': codeName
      }
    })
      .toPromise()
      .then((resp) => resp.data[0].id )
      .catch((err) => { });
  }

  private save(table, payload, recordID, foreignKey, options) {
    const option = options && options.toast ? {
      toast: {
        message: `Success! ${recordID ? ' ID: ' + recordID + ' was successfully updated!' : ' A record was inserted into ' + table}`
      }
    } : null;
    if (recordID) {
      if (table.toLowerCase() === 'schema') {
        return this.saveSchema(table, payload, recordID, foreignKey, option);
      } else {
        return this.saveRecord(table, payload, recordID, foreignKey, option);
      }
    } else {
      if (this.inputType === 'select') {
        this.flexModel.selectedValue = this.selectedValue && this.selectedValue != null ? this.selectedValue : null;
      } else {
        delete this.flexModel.selectedValue;
      }
      if (this.mode === 'update') {
        this.editMode = false;
        this.expandInput.emit(false);
      }
      this.notifyParent({ message: 'No Record ID', data: this.flexModel, index: this.flexModel.index });
    }
  }

  private saveSchema(table, payload, recordID, foreignKey, option) {
    this.http.post(`${table}/get`, {
      'get': [
        'id',
        'schema'
      ],
      'where': {
        'id': recordID,
        'active': true
      }
    })
      .toPromise()
      .then((res) => {
        if (this.flexModel['key'].toLowerCase() === 'desc' || this.flexModel['key'].toLowerCase() === 'nice_name') {
          const schemas = res.data[0].schema;
          if (schemas.hasOwnProperty(`${this.flexModel['displayProp']}`)) {
            if (this.flexModel['key'].toLowerCase() === 'desc') {
              schemas[`${this.flexModel['displayProp']}`].desc = payload[Object.keys(payload)[0]];
            } else if (this.flexModel['key'].toLowerCase() === 'nice_name') {
              schemas[`${this.flexModel['displayProp']}`].nice_name = payload[Object.keys(payload)[0]];
            }
          }
          this.http.put(`${table}/id/${recordID}`, res.data[0], option)
            .toPromise()
            .then((resp) => {
              this.editMode = false;
              this.expandInput.emit(false);
              this.notifyParent({ message: 'Save Success', data: this.flexModel });
            })
            .catch((err) => {
              this.notifyParent({ message: 'Save Error', err });
            });
        } else {
          this.saveRecord(table, payload, recordID, foreignKey, option);
        }
      });
  }

  private saveRecord(table, payload, recordID, foreignKey, option) {
    return this.http.put(`${table}/id/${recordID}`, payload, option)
      .toPromise()
      .then((resp) => {
        if (foreignKey) {
        } else {
          recordID ? this.flexModel.value = resp.data[this.flexModel.key] :
            this.flexModel.value = resp[this.flexModel.key];
        }
        this.originalModel = Object.assign({}, this.flexModel);
        if (this.editMode) {
          this.editMode = false;
          this.expandInput.emit(false);
        }
        this.notifyParent({ message: 'Save Success', data: this.flexModel, index: this.flexModel.index });
      })
      .catch((err) => {
        this.notifyParent({ message: 'Save Error', err, index: this.flexModel.index });
      });
  }

  validate() {
    if (!this.flexModel.belongsToID) {
      this.onValidate.emit(this.flexModel)
    }
  }

  private notifyParent(resp) {
    this.onUpdateSuccess.emit(resp);
  }

  private onItemSelected(flexModel, selectedValue) {
    this.selectedValue = selectedValue;

    if (flexModel.belongsToTable === 'activity' && flexModel.displayProp === 'type_name') {
      this.http.post('activityDetail/get', {
        'get': ['*'],
        'where': {
          'client_activity_id': this.selectedValue.id.belongsToID
        }
      })
        .pipe(take(1))
        .subscribe((res: any) => {
          if (res.data.length > 1) {
            this.deleteId(this.flexModel.id.belongsToID);
          }
        });
    }

    this.saveSelection(flexModel, selectedValue);
    // this.editMode = false;
  }

  private deleteId(id: number) {
    this.http.post(`activityDetail/remove`, {
      where: {
        client_activity_id: id
      }
    });
  }

  private saveSelection(flexModel, selectedValue) {
    const foreignKey = flexModel.foreignKey ? flexModel.foreignKey : false;
    if (foreignKey) { // update foreign table reference
      // doing update on multi select
      let recordID = typeof flexModel.id === 'object' ? flexModel.id.belongsToID : flexModel.id;
      const payload = {};
      let table;
      const isCrossTableUpdate = flexModel.id.belongsToID &&
        flexModel.belongsToTable.toLowerCase() !== this.config.table.toLowerCase() &&
        flexModel.primaryKey == null;

      const n2MUpdate = this.flexModel.id.belongsToID &&
        flexModel.belongsToTable.toLowerCase() !== this.config.table.toLowerCase() &&
        flexModel.primaryKey != null;

      payload[foreignKey] = selectedValue.id;
      table = flexModel.belongsToTable;

      if (isCrossTableUpdate) {
        recordID = flexModel.belongsToID;
      } else if (n2MUpdate) {
        recordID = flexModel.primaryKey;
      }

      this.save(table, payload, recordID, foreignKey, { toast: true });
    } else {
    }
  }

  onSelectBlur(flexModel) {
    if (flexModel && typeof flexModel.id === 'object') {
      if (flexModel.id.belongsToID != null) {
        this.editMode = false;
      } else {
        this.editMode = true;
      }
    } else if (flexModel && flexModel.id != null) {
      this.editMode = false;
    } else {
      this.editMode = true;
    }
    this.expandInput.emit(this.editMode);
    return this.editMode;
  }

  enableEdit(flexModel) {
    const value = flexModel.value,
      dataType = typeof value;
    if (flexModel.id != null && flexModel.id.value != null) {
      if (typeof flexModel.belongsToID === 'undefined' || flexModel.disable) {
        this.toastService.toast({message: 'Changes to this property are not allowed!', type: 'warning'});
        this.allowEdit = false;
        return;
      }
    } else if (flexModel.disableOnCreate) {
      this.toastService.toast({message: 'Changes to this property are not allowed!', type: 'warning'});
      this.allowEdit = false;
      return;
    }
    if (flexModel.inputType === 'color') {
      this.inputType = 'color';
    } else if (flexModel.inputType === 'textarea') {
      this.modal.show({ entity: flexModel.belongsToTable, id: flexModel.belongsToID, key: flexModel.key, value: flexModel.value });
      return;
    } else if (this.inputType === 'date' || flexModel.pipe === 'date') {
      this.inputType = 'date';
      this.flexModel.value = moment.parseZone(this.flexModel.value).format('YYYY-MM-DD');
    } else if (value && Array.isArray(value)) {
      this.inputType = 'select';
    } else {
      switch (dataType) {
        case 'string':
          if (flexModel.pipe) {
            this.inputType = flexModel.pipe;
          } else if (flexModel.inputType != null) {
            if (flexModel.inputType === 'textarea' && flexModel.maxLength === Infinity) {
              // this.inputType = 'modal';
            } else {
              this.inputType = flexModel.inputType;
            }
          } else if (flexModel.inputType !== null) {
            if (flexModel.key === 'version') {
              this.inputType = 'textgroup';
              setTimeout(() => {
                this.input.nativeElement.type = 'textgroup';
              }, 0);
            }
          } else {
            this.inputType = 'text';
            setTimeout(() => {
              this.input.nativeElement.type = 'text';
            }, 0);
          }
          break;
        case 'boolean':
          this.inputType = 'toggle';
          return this.toggle(flexModel);
        case 'number':
          this.inputType = 'number';
          setTimeout(() => {
            this.input.nativeElement.type = 'number';
          }, 0);
          break;
        default:
          this.inputType = null;
          break;
      }
    }
    setTimeout(() => {
      if (_get(this.input, 'nativeElement.focus')) this.input.nativeElement.focus();
    }, 0);
    this.editMode = true;
    this.expandInput.emit(true);
    // this.inputType == 'text' ? this.flexModel.value == '-' ? this.flexModel.value = '' : null : null;
  }

  private toggle(flexModel) {
    flexModel.value = !flexModel.value;
    this.updateOrCreate(flexModel, { toast: true });
    return !flexModel;
  }

  updateTextarea(event) {
    this.flexModel.value = event.value;
    this.textSaved.emit();
    this.notifyParent({ message: 'No Record ID', data: this.flexModel, index: this.flexModel.index });
  }

  // This save the changes resulting from the use of the version masking component//
  public updateInput(event) {
    this.flexModel.value = event;
  }

  public displayDate(sentText: any) {
    const oldDate = new Date(sentText).getTime();
    const date = new Date(oldDate + 8 * 3600 * 1000);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}/${day}`;
  }
}
