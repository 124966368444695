<div id="preview-image" class="large-img">
    <div class="mask" (click)="closePreview()"></div>
    <div class="container preview-image-container">
        <div class="row p-0 m-0">
            <div class="col-offset-12 p-0 m-0">
                <a class="preview-close" (click)="closePreview()"><i class="fa fa-times" aria-hidden="true"></i></a>
            </div>
            <div class="col-md-8 m-0 p-0">
                <div class="full-height">
                    <div class="view-img-box">
                        <img *ngIf="isImage(largeImage.filePath)" [attr.src]="largeImage.filePath" alt="" id="img-to-rotate">
                        <div *ngIf="!isImage(largeImage.filePath)" class="file-download">
                            <a [href]="pdfUrl(largeImage.filePath)">
                                <h4>Click To Download File</h4>
                                <i *ngIf="!isImage(largeImage.filePath) && largeImage.filePath.split('.')[largeImage.filePath.split('.').length - 1] == 'pdf'" class="fa fa-file-pdf-o preview" aria-hidden="true"></i>
                                <i *ngIf="!isImage(largeImage.filePath) && largeImage.filePath.split('.')[largeImage.filePath.split('.').length - 1] == 'doc'" class="fa fa-file-word-o preview" aria-hidden="true"></i>
                                <i *ngIf="!isImage(largeImage.filePath) && largeImage.filePath.split('.')[largeImage.filePath.split('.').length - 1] == 'xls'" class="fa fa-file-excel-o preview" aria-hidden="true"></i>
                                <i *ngIf="!isImage(largeImage.filePath) && ['pdf','doc','xls'].indexOf(largeImage.filePath.split('.')[largeImage.filePath.split('.').length - 1]) == -1" class="fa fa-file-text-o preview" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 m-0 p-0">
                <div class="col-12 m-0 p-0">
                    <div class="preview-details">
                        <h3><i class="fa fa-picture-o"></i> Image Details</h3>
                        <div><strong>Uploaded By:</strong>  <span>{{setEmp(largeImage.uploadedById)}}</span></div>
                        <div><strong>Date Added:</strong>  <span>{{largeImage.createdAt | date}}</span></div>
                        <div><strong>File Type:</strong>  <span>{{setType(largeImage.documentTypeId)}}</span></div>
                        <div><strong>Title:</strong>  <span>{{largeImage.title}}</span></div>
                        <div><strong>Description:</strong><br/>  <span>{{largeImage.description}}</span></div>
                    </div>
                </div>
                <div class="col-12 m-0 p-0">
                    <div class="preview-images">
                        <div class="row">
                            <div *ngFor="let img of images" class="col-4">
                                <div class="preview-title"><span *ngIf="img.title">{{img.title}}</span> <span *ngIf="!img.title">No Title</span></div>
                                <div class="preview-images-box" (click)="setAsImage(img.id)">
                                    <a *ngIf="isImage(img.filePath)"><img [attr.src]="img.filePath"></a>
                                    <i *ngIf="!isImage(img.filePath) && img.filePath.split('.')[img.filePath.split('.').length - 1] == 'pdf'" class="fa fa-file-pdf-o preview" aria-hidden="true"></i>
                                    <i *ngIf="!isImage(img.filePath) && img.filePath.split('.')[img.filePath.split('.').length - 1] == 'doc'" class="fa fa-file-word-o preview" aria-hidden="true"></i>
                                    <i *ngIf="!isImage(img.filePath) && img.filePath.split('.')[img.filePath.split('.').length - 1] == 'xls'" class="fa fa-file-excel-o preview" aria-hidden="true"></i>
                                    <i *ngIf="!isImage(img.filePath) && ['pdf','doc','xls'].indexOf(img.filePath.split('.')[img.filePath.split('.').length - 1]) == -1" class="fa fa-file-text-o preview" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>

                        <div class="rotate-btns center-block">
                            <button class="btn btn-success" (click)="rotateImage()"><i class="fa fa-repeat" aria-hidden="true"></i> 90&deg;</button>
                            <button class="btn btn-success" (click)="zoomIn()"><i class="fa fa-search-plus" aria-hidden="true"></i>Zoom</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>