import * as _ from 'lodash';
export class VaporTest{
    constructor(
        data?: any
    ){
        _.merge(this, data);
    }

    public id: number = null;
    public technicianId: number = null;
    public longTermTestDate: Date = null;
    public longTermBadge: string = null;
    public longTermResult: string = null;
    public shortTermTestDate: Date = null;
    public shortTermBadge: string = null;
    public shortTermResult: string = null;
    public addressId: number = null;
    public updatedById:number = null;
    public createdById:number = null;
}