<div class="header">
    <h4><i class="fa fa-product-hunt"></i> Product</h4>
</div>
<div class="row">
    <div class="col-md-6">
        <product-list [products]="products"
                      [selectedProductId]="selectedProduct?.id"
                      (setProduct)="setProduct($event)"
                      (resetProduct)="resetProduct($event)"></product-list>
    </div>
    <div class="col-md-6">
        <product-single [product]="selectedProduct"
                        (resetProduct)="resetProduct($event)"
                        (saveProduct)="saveProduct($event)"></product-single>
    </div>
</div>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>

