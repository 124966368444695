import * as _ from 'lodash';

import {ServiceType} from './service-type';

export class WorkOrder{

    constructor(data?: any){
        _.merge(this, data);
    }

    public id: number = null;
    public routeId: number = null;
    // todo: change to clientId when db is finished
    public Customer_PK: number = null;
    public Employee_PK: number = null;
    public ServiceType_PK: number = null;
    public PK_WorkOrder: number = null;
    public serviceDate: Date = null;
    public note: string = null;
    public Notes: any[] = [];
    public Address: any = null;
    public clientId: number = null;
    public IsActive: number = null;
    public WorkOrderDetails: any[] = [];
    public workOrderStatusId: number = null;
    public completionDate: Date = null;
    public tankId: number = null;
    public employeeToId: number = null;
    public employeeId: number = null;
    public serviceTypeId: number = null;
    public ServiceType: ServiceType = null;
    public dropOrder: number = null;
    public emailEmployees?: any[] = [];
    public updatedByEmployee: any;
    public closedByEmployee: any;
    public createdBy: any;
    public employeeTo: any;
    public createdAt: any;
    public addressId: any;
    public mailOrder: any;
}
