import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { environment } from '../../../environments/environment';
import { ToastService } from './toast.service';

@Injectable()
export class PdfService {
  private apiUrl = `${environment.apiUrl}`;  // URL to web api

  constructor(private authHttp: HttpClient, private userService: UserService, private toastService: ToastService) { }

  createPdf(body: {}, filename: string) {
    return this.authHttp.post(`${this.apiUrl}pdf/fillPDF`, body, { responseType: 'text' })
      .toPromise()
      .then(res => {
        const getArrayBuffer = function getPdfArrayBuffer() {
          const data = res;
          let len = data.length;
          const  ab = new ArrayBuffer(len),
            u8 = new Uint8Array(ab);

          while (len--) {
            u8[len] = data.charCodeAt(len);
          }

          return ab;
        };

        const getBlob = function getPdfBlob() {
          return new Blob([getArrayBuffer()], {
            type: 'application/pdf'
          });
        };

        if ((window.navigator as Navigator & { msSaveOrOpenBlob }).msSaveOrOpenBlob) { // IE10+
          (window.navigator as Navigator & { msSaveOrOpenBlob }).msSaveOrOpenBlob(getBlob(), filename);
        } else { // Others
          const a = document.createElement('a'),
            url = URL.createObjectURL(getBlob());
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 0);
        }

      }).catch(e => {
        console.error(e);
        this.toastService.toast({ message: 'Error occurred while filling pdf', type: 'danger' });
      });
  }

}
