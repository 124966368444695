import { ErrorHandler, Injectable, NgModule, Provider } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { routing } from './app.routing';
import { FormsModule } from '@angular/forms';

import { AdminModule } from './admin/admin.module';
import { ClientModule } from './clients/client.module';
import { ClientRoutesModule } from './client-routes/client-routes.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ServicesModule } from './services-module/services.module';
import { WorkOrdersModule } from './work-orders/work-orders.module';
import { S3MapModule } from './s3-gmap/s3-map.module';
import { S3JSPdfModule } from './s3-jspdf/s3-jspdf.module';
import { S3FilterModule } from './s3-filter/s3-filter.module';
import * as Sentry from '@sentry/browser';

import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { S3StatesModule } from './s3-states/s3-states.module';

import { ProductResolve } from './resolvers/tank.resolver';
import { UnscheduledWOResolve } from './resolvers/unscheduled-work-orders.resolver';
import { ServiceTypeResolve } from './resolvers/service-type.resolver';
import {
  EmployeesResolve,
  CurrentEmployeeResolve,
  SalesRepsResolve,
  LabTechResolve
} from './resolvers/employees.resolver';
import { LoginModule } from './login/login.module';
import { PipesModule } from './pipes/pipes.module';
import { MobileNavbarComponent } from './mobile-navbar/navbar.component';
import { ProfileModule } from './profile/profile.module';
import { DriversModule } from './drivers/drivers.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { ToastComponent } from './toast/toast.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedServicesModule } from './shared-services/shared-services.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { SortablejsModule } from 'ngx-sortablejs';
import { StatesResolve } from './resolvers/states.resolver';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ConfirmComponent } from '@shared/confirm/confirm.component';
import { AuthInterceptor } from './interceptors/auth.interceptors';
import { get as _get } from 'lodash';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ZoomDirective } from '@directives/zoom.directive';

export function jwtTokenGetter() {
  return localStorage.getItem('id_token');
}

if (environment.production) {
  Sentry.init({
    dsn: environment.sentryDsn
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private jwtHelper: JwtHelperService) { }
  handleError(error: Error & { originalError?: any; error?: any }) {
    const user = this.jwtHelper.decodeToken(localStorage.getItem('id_token'));

    Sentry.configureScope(scope => {
      scope.setTag('user_id', _get(user, 'id', 0).toString());
      scope.setTag('role', _get(user, 'role', 0).toString());
      scope.setTag('employee_id', _get(user, 'Employee.id', 0).toString());
      scope.setTag('environment', environment.environmentName);
    });

    let capturedError: Error & { originalError?: any; error?: any };

    switch (error) {
      case error.originalError?.stack && error.originalError?.message:
        capturedError = error.originalError;
        break;
      case error.error?.stack && error.error?.message:
        capturedError = error.error;
        break;
      default:
        capturedError = error;
        break;
    }
    const eventId = Sentry.captureException(capturedError);

    if (environment.sentryDialog) Sentry.showReportDialog({ eventId });
  }
}

const providers: Provider[] = [
  StatesResolve,
  EmployeesResolve,
  CurrentEmployeeResolve,
  ProductResolve,
  SalesRepsResolve,
  LabTechResolve,
  UnscheduledWOResolve,
  ServiceTypeResolve,
  Title,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  }
];

if (environment.production) {
  providers.push({ provide: ErrorHandler, useClass: SentryErrorHandler });
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    routing,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: ['localhost:3000',
          'bis2.specialtysalesllc.com:3000',
          'specialtysales.shift3sandbox.com:3000']
      }
    }),
    LoginModule,
    ClientModule,
    DashboardModule,
    DriversModule,
    AdminModule,
    ServicesModule,
    ClientRoutesModule,
    WorkOrdersModule,
    ClientRoutesModule,
    S3MapModule,
    S3StatesModule,
    S3JSPdfModule,
    S3FilterModule,
    ProfileModule,
    SharedComponentsModule,
    SharedServicesModule,
    PipesModule,
    SortablejsModule.forRoot({ animation: 150 }),
    DatepickerModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  declarations: [
    AppComponent,
    ConfirmComponent,
    SidebarComponent,
    MobileNavbarComponent,
    UnauthorizedComponent,
    ToastComponent,
    ZoomDirective
  ],
  exports: [],
  providers,
  bootstrap: [AppComponent]
})

export class AppModule { }
