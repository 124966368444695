<div class="document-uploads panel padding-15 margin-top-20">
    <div class="row document-heading">
        <div class="col-md-12">
            <h4> <i class="fa fa-file-image-o"></i> PDFs</h4>
            <div class="document-actions">
                <div class="btn btn-success address-btn" (click)="toggleDocs()">{{ showDocs ? 'Hide' : 'Show'}} PDFs</div>
            </div>
            <div *ngIf="showDocs" class="show-documents margin-top-20 padding-top-20">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th class="td-20">Type</th>
                        <th class="td-20">Description</th>
                        <th class="td-10">Download</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="td-20">Filled</td>
                        <td class="td-20">Equipment & Installation Agreement</td>
                        <td class="td-10"><button class="btn-info" (click)="download(1, true)"><i class="fa fa-floppy-o"></i></button></td>
                    </tr>
                    <tr>
                        <td class="td-20">Empty</td>
                        <td class="td-20">Equipment & Installation Agreement</td>
                        <td class="td-10"><button class="btn-info" (click)="download(1, false)"><i class="fa fa-floppy-o"></i></button></td>
                    </tr>
                    <tr>
                        <td class="td-20">Filled</td>
                        <td class="td-20">Credit Application</td>
                        <td class="td-10"><button class="btn-info" (click)="download(3, true)"><i class="fa fa-floppy-o"></i></button></td>
                    </tr>
                    <tr>
                        <td class="td-20">Empty</td>
                        <td class="td-20">Credit Application</td>
                        <td class="td-10"><button class="btn-info" (click)="download(3, false)"><i class="fa fa-floppy-o"></i></button></td>
                    </tr>
                    <tr>
                        <td class="td-20">Filled</td>
                        <td class="td-20">Exception Certificate</td>
                        <td class="td-10"><button class="btn-info" (click)="download(4, true)"><i class="fa fa-floppy-o"></i></button></td>
                    </tr>
                    <tr>
                        <td class="td-20">Empty</td>
                        <td class="td-20">Exception Certificate</td>
                        <td class="td-10"><button class="btn-info" (click)="download(4, false)"><i class="fa fa-floppy-o"></i></button></td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>
