import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {S3StatesService} from '../../../s3-states/s3-states.service';
import {ServiceRegionService} from '../../../services-module/services/service-region.service';
import {ServiceRegion} from '../../../models/service-region';
import {ToastService} from "../../../services-module/services/toast.service";

@Component({
    templateUrl: './service-region.component.html',
    styleUrls: ['service-region.component.css'],
    selector: 'service-region'
})
export class ServiceRegionComponent implements OnInit{
    constructor(
        private serviceRegionService: ServiceRegionService,
        private toastService:ToastService,
        private s3StatesService: S3StatesService
    ){}
    states: any[] = this.s3StatesService.states;
    serviceRegions: ServiceRegion[] = [];
    serviceRegion: ServiceRegion;
    saveSuccess: boolean = null;
    saveFail: boolean = null;
    ftp:boolean = false;

    ngOnInit(){
        this.serviceRegionService.getAllServiceRegions()
            .then(res => this.serviceRegions = res);
        this.serviceRegion = new ServiceRegion();
    }

    setServiceRegion(serviceRegion: ServiceRegion): void{
        this.serviceRegion = new ServiceRegion(serviceRegion);
    }

    clearServiceRegion():void{
        this.serviceRegion = new ServiceRegion();
    }

    saveServiceRegion(serviceRegion: ServiceRegion): void{
        this.ftp = true;
        this.serviceRegionService.saveServiceRegion(serviceRegion)
            .then((res)=>{
                if(serviceRegion.id){
                    _.merge(_.find(this.serviceRegions, {id: serviceRegion.id}), res)
                }else{
                    this.serviceRegions.push(res);
                }
                this.toastService.toast({message:'Your changes have been saved!', type:'success'});
                this.ftp = false;
            });
    }
}