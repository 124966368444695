
import { Injectable } from '@angular/core';

import { Client } from '@models/client';
import { ClientContact } from '@models/contact';
import { PhoneNumber } from '@models/phone';
import { from, Observable } from 'rxjs';

import { UserService } from './user.service';
import * as _ from 'lodash';
import { environment } from '@environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ClientPagination } from 'src/app/models/client-pagination';
import { User } from '@models/user';

@Injectable()
export class ClientService {
  private _clients: Client[];
  private cacheDate: Object;
  private apiUrl = environment.apiUrl;  // URL to web api

  constructor(public authHttp: HttpClient, private userService: UserService) { }

  getClient(id: number): Observable<Client> {
    return this.authHttp.get(`${this.apiUrl}client/${id}`)
      .pipe(map(res => res as Client));
  }

  getInactiveClients(): Promise<Client[]> {
    return this.authHttp.post(`${this.apiUrl}client/inactive`, {})
      .toPromise()
      .then((res) => {
        return res as Client[];
      });
  }

  getClientsDairies(): Promise<Client[]> {
    return this.authHttp.post(`${this.apiUrl}client/get-clients-dairies`, {})
      .toPromise()
      .then((res) => {
        return res as Client[];
      });
  }

  // use angular2-jwt authHttp when accessing secure routes
  getClients(): Promise<Client[]> {
    const employeeId = this.userService.getEmployeeId();
    const typeId = this.userService.getEmployeeTypeId();

    return this.getEmployeeClients(typeId, employeeId).then(clients => {
      return _.filter(clients, (c: Client) => c.isClient);
    });
  }
  // this is what use for getting list of clients ===========================================================
  getEmployeeClients(typeId: number, employeeId: number): Promise<Client[]> {
    return this.authHttp.post(`${this.apiUrl}client/get-cache-date`, {})
      .toPromise()
      .then((response) => {
        if (response !== this.cacheDate || !this._clients) {
          this.cacheDate = response;
          return this.authHttp.post(`${this.apiUrl}client/get-clients/${typeId}`, { employeeId: employeeId })
            .toPromise()
            .then((res) => {
              this._clients = res as Client[];
              return this._clients;
            })
            .catch(this.handleError);
        } else {
          return from(this._clients).toPromise().then((res) => {
            return this._clients;
          });
        }
      })
      .catch(this.handleError);
  }

  // new api for getting employee clients version 2!
  getEmployeeClientsPaginationService(typeId: number, employeeId: number, query: any): Promise<ClientPagination> {
    return this.authHttp.post(`${this.apiUrl}client/get-clients-pagination/${typeId}`, { employeeId: employeeId, query })
      .toPromise()
      .then((res) => res as ClientPagination).catch(this.handleError);
  }

  getSingleClient(clientId: number): Promise<Client> {
    return this.authHttp.get(`${this.apiUrl}client/${clientId}`)
      .toPromise()
      .then((response) => response as Client)
      .catch(this.handleError);
  }

  saveClient(client: Client): Promise<Client> {
    if (client.id) {
      return this.updateClient(client);
    } else {
      return this.createClient(client);
    }
  }

  createClient(client: Client): Promise<Client> {
    return this.authHttp.post(`${this.apiUrl}client`, client)
      .toPromise()
      .then((res) => {
        return res as Client;
      });
  }
  updateClient(client: Client) {
    return this.authHttp.put(`${this.apiUrl}client/${client.id}`, client)
      .toPromise()
      .then((res) => {
        return res as Client;
      });
  }
  deleteClient(client: Client) {
    return this.authHttp.delete(`${this.apiUrl}client/${client.id}`)
      .toPromise()
      .then((res) => {
        return res as Client;
      });
  }

  /*Contact Methods*/
  saveContact(contact: ClientContact): Promise<ClientContact> {
    if (contact.id) {
      return this.updateContact(contact);
    } else {
      return this.createContact(contact);
    }
  }

  updateContact(contact: ClientContact): Promise<ClientContact> {
    return this.authHttp.put(`${this.apiUrl}client-contact/${contact.id}`, contact)
      .toPromise()
      .then((res) => {
        return res as ClientContact;
      });
  }

  createContact(contact: ClientContact): Promise<ClientContact> {
    return this.authHttp.post(`${this.apiUrl}client-contact`, contact)
      .toPromise()
      .then((res) => {
        return res as ClientContact;
      });
  }

  savePhoneNumber(phone: PhoneNumber): Promise<PhoneNumber> {
    if (phone.id) {
      return this.updatePhoneNumber(phone);
    } else {
      return this.createPhoneNumber(phone);
    }
  }
  updatePhoneNumber(phone: PhoneNumber): Promise<PhoneNumber> {
    return this.authHttp.put(`${this.apiUrl}phone-number/${phone.id}`, phone)
      .toPromise()
      .then((res) => {
        return res as PhoneNumber;
      });
  }
  createPhoneNumber(phone: PhoneNumber): Promise<PhoneNumber> {
    return this.authHttp.post(`${this.apiUrl}phone-number`, phone)
      .toPromise()
      .then((res) => {
        return res as PhoneNumber;
      });
  }

  /* Searching Clients*/
  search(term: string): Observable<Client[]> {
    return this.authHttp
      .get(`${this.apiUrl}?name=${term}`).pipe(
        map((r) => r['data'] as Client[]));
  }
  // sales leads are also used on clients page
  getSalesLeads(): Promise<Client[]> {
    return this.authHttp.get(`${this.apiUrl}client/sales-leads`)
      .toPromise()
      .then((res) => {
        return res as Client[];
      });
  }

  // sales leads for pagination version 2!
  getSalesLeadsPaginationService(query: any): Promise<ClientPagination> {
    const params = new HttpParams()
      .set('page', query.page)
      .set('itemsPerPage', query.itemsPerPage)
      .set('search', query.search)
      .set('isIndex', query.isIndex)
      .set('sort', query.sort)
      .set('prop', query.prop);

    return this.authHttp.get(`${this.apiUrl}client/sales-leads-pagination`, { params: params })
      .toPromise()
      .then((res) => {
        return res as ClientPagination;
      });
  }

  saveGroupToDairy(data: any): Promise<Object> {
    return this.authHttp.post(`${this.apiUrl}client/address-to-client`, data)
      .toPromise()
      .then((res) => {
        return res as Object;
      });
  }

  createNewGroup(data: any): Promise<Object> {
    return this.authHttp.post(`${this.apiUrl}client/address-to-new-client`, data)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  getClientReps(clientId: number): Observable<User[]> {
    return this.authHttp.post(`${this.apiUrl}client-rep/get-client-reps/${clientId}`, {})
      .pipe(map(res => res as User[]));
  }

  addClientRep(clientRep: any): Promise<any> {
    return this.authHttp.post(`${this.apiUrl}client-rep`, clientRep)
      .toPromise()
      .then((response) => {
        return response as any[];
      })
      .catch(this.handleError);
  }

  removeClientRep(clientRep: any): Promise<any> {
    return this.authHttp.delete(`${this.apiUrl}client-rep/${clientRep.id}`)
      .toPromise()
      .then((response) => {
        return response as any;
      })
      .catch(this.handleError);

  }

  /* Error Handler */
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
