<div class="header">
    <h4><i class="fa fa-vcard"></i> Inactive Clients</h4>
</div>
<div class="clients-container">
    <input placeholder="Search" [(ngModel)]="search" class="form-control search-input">
<div class="client-list-panel">
    <table class="table table-hover table-responsive">
        <thead>
        <tr>
            <th class="td-30">Name</th>
            <th class="td-30">Is Customer</th>
            <th class="td-20">Is Active</th>
            <th class="td-20"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let client of clients | searchPipe:'name':search">
            <td class="td-30">{{ client.name }}</td>
            <td class="td-30">
                <button
                    class="btn btn-sm"
                    [ngClass]="{'btn-success': client.isClient, 'btn-danger': !client.isClient}"
                    (click)="changeIsClientStatus(client)"
                >{{ client.isClient ? 'Yes' : 'No'  }}</button>
            </td>
            <td class="td-20">
                <button class="btn btn-sm"
                        [ngClass]="{'btn-success': client.isActive, 'btn-danger': !client.isActive}"
                        (click)="changeClientStatus(client)">
                    {{ client.isActive ? ' Yes': 'No' }}
                </button>
            </td>
            <td class="td-20">
                <button class="btn btn-sm btn-danger"
                        (click)="delete.showPromptModal(deleteClient(client))">
                    Delete
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>
</div>


<app-prompt-modal-helper
  #delete header="Delete Client"
  title="Are you sure you want to delete this client? This cannot be undone."
  confirm="Delete">
</app-prompt-modal-helper>

<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>

