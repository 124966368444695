<div class="panel">
    <table class="table table-hover">
        <thead>
        <tr>
            <td>Name</td>
            <td>Abbr</td>
            <td>Model Number</td>
            <td>Capacity</td>
            <td>Description</td>
            <td>Brand</td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let tank of activeTanks()" (click)="selectTank(tank)" [ngClass]="{'selected':isSelected(tank)}">
            <td>{{ tank.tankTypeName }}</td>
            <td>{{ tank.tankTypeAbbr  }}</td>
            <td>{{ tank.modelNumber }}</td>
            <td>{{ tank.capacityVolume }}</td>
            <td>{{ tank.tankTypeDescription }}</td>
            <td>{{ tank.brandName }}</td>
        </tr>
        </tbody>
    </table>
</div>