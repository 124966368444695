import { Directive, OnInit, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';

import { fromEvent, Subscription } from 'rxjs';
import { delay, tap } from "rxjs/operators";

@Directive({
    selector: '[wo-click-outside]'
})

export class WoClickOutside implements OnInit, OnDestroy {
    private listening: boolean;
    private globalClick: Subscription;

    @Output('woClickOutside') woClickOutside: EventEmitter<Object>;

    constructor(private _elRef: ElementRef) {
        this.listening = false;
        this.woClickOutside = new EventEmitter();
    }

    ngOnInit() {
        this.globalClick = fromEvent(document, 'click').pipe(
            delay(1),
            tap(() => {
                this.listening = true;
            })).subscribe((event: MouseEvent) => {
                this.onGlobalClick(event);
            });
    }

    ngOnDestroy() {
        this.globalClick.unsubscribe();
    }

    onGlobalClick(event: MouseEvent) {
        if (event instanceof MouseEvent && this.listening === true) {
            if (this.isDescendant(this._elRef.nativeElement, event.target) === true) {
                this.woClickOutside.emit({
                    target: (event.target || null),
                    value: false,
                    parent: this._elRef.nativeElement
                });
            } else {
                this.woClickOutside.emit({
                    target: (event.target || null),
                    value: true,
                    parent: this._elRef.nativeElement
                });
            }
        }
    }

    isDescendant(parent: any, child: any) {
        let node = child;
        while (node !== null) {
            if (node.id === 'woDropdownCmp') {
                return true;
            } else {
                node = node.parentNode;
            }
        }
        return false;
    }
}
