
import { Pipe, PipeTransform } from '@angular/core';
import { Tank } from '@models/tank';
import { TankService } from '@services/tank.service';

@Pipe({ name: 'appTankLevel' })
export class TankLevelPipe implements PipeTransform {
  constructor(private tankService: TankService) { }

  transform(level: number, total: number): any {
    if (level > total) {
      return total;
    } else if (level < 0) {
      return 0;
    } else return level;
  }
}
