import { Component, OnInit, Input } from '@angular/core';
import {Route} from '../../models/route';

@Component({
    selector: 'work-order-print',
    templateUrl: 'work-order-print.component.html',
    styleUrls: ['work-order-print.component.css'],
})
export class WorkOrderPrintComponent implements OnInit{
    @Input()
    route: Route;
    @Input()
    orders: any;

    constructor(){}

    ngOnInit(): void{}

}