import {Component, Output, Input, EventEmitter, OnInit} from '@angular/core';

import {Client} from '../../../models/client';
import {Employee} from '../../../models/employee';
import {EmployeeService} from '../../../services-module/services/employee.service';
import * as _ from 'lodash';

@Component({
    templateUrl: './client-edit.component.html',
    selector: 'client-edit',
    styleUrls: ['../client-single.component.css']
})
export class ClientEditComponent implements OnInit{
    @Input()
    client: Client;
    clone: Client;
    @Output()
    toggleEditMode = new EventEmitter<string>();
    @Output()
    saveClient = new EventEmitter<Client>();
    salesReps: Employee[];
    constructor(private employeeService: EmployeeService){}

    ngOnInit(){
        this.employeeService.getEmployeesByType('Sales Rep')
            .then((res)=>{
            this.salesReps = res;
        });
        this.clone = _.cloneDeep(this.client);
    }

    cancelEdit(): void{
        this.toggleEditMode.emit('Cancel Edit');
        Object.assign(this.client, { isActive:this.clone.isActive, isClient:this.clone.isClient, name:this.clone.name });
    }

    save(client: Client): void{
        this.saveClient.emit(client);
    }


}