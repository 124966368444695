<div id="ClientGroupManagementComponent">
    <div class="header">
        <h4><i class="fa fa-exchange"></i> Dairy Group Management</h4>
    </div>
    <!--********* Dairy Options *********-->
    <div class="row">
        <div class="col-12">
            <div class="dairy-group-options">
                <ul>
                    <li>
                        <span class="option-span">Select an Option:</span>
                    </li>
                    <li>
                        <span class="group-option" [ngClass]="{'option':option == 1}" (click)="setAsOption(1)">
                            <a href="javascript:void(0);">Group to Dairy</a>
                        </span>
                    </li>
                    <li>
                        <span class="group-option" [ngClass]="{'option':option == 2}" (click)="setAsOption(2)">
                            <a href="javascript:void(0);">Dairy as New Group</a>
                        </span>
                    </li>
                    <li>
                        <span class="group-option" [ngClass]="{'option':option == 3}" (click)="setAsOption(3)">
                            <a href="javascript:void(0);">Dairy to Group</a>
                        </span>
                    </li>
                    <li><a href="javascript:void(0);"><i class="fa fa-help"></i></a></li>
                </ul>
            </div>
        </div>
    </div>

    <!--********* Dairy Management Panels **********-->
    <group-to-dairy [clientGroup]="clientGroup"
                    *ngIf="option == 1"
                    [dairies]="dairies"
                    [defaultImage]="defaultImage"
                    (setGroup)="setGroup($event)"
                    (setToGroup)="setToGroup($event)"
                    (pushDairiesToGroup)="pushDairiesToGroup($event)"
                    (removeDairies)="removeDairies()"
                    (cancelActions)="cancelActions()"
                    (showModal)="showModal()"
                    [groupToGroup]="groupToGroup"></group-to-dairy>

    <dairy-as-group [clientGroup]="clientGroup"
                    *ngIf="option == 2"
                    [dairies]="dairies"
                    [defaultImage]="defaultImage"
                    (setGroup)="setGroup($event)"
                    (setAsNewGroup)="setAsNewGroup($event)"
                    (addressToNewGroup)="addressToNewGroup($event)"
                    (confirmNewGroup)="confirmNewGroup($event)"
                    (cancelNewGroup)="cancelNewGroup($event)"
                    (removeFromGroup)="removeFromGroup($event)"
                    [newGroup]="newGroup"></dairy-as-group>

    <dairy-to-group [clientGroup]="clientGroup"
                    *ngIf="option == 3"
                    [dairies]="dairies"
                    [defaultImage]="defaultImage"
                    (setGroup)="setGroup($event)"
                    (setToGroup)="setToGroup($event)"
                    (addressToExistingGroup)="addressToExistingGroup($event)"
                    (removeFromExistingGroup)="removeFromExistingGroup($event)"
                    (showDairyToGroupModal)="showDairyToGroupModal($event)"
                    (cancelDairyToGroup)="cancelDairyToGroup($event)"
                    [groupToGroup]="groupToGroup"></dairy-to-group>

</div>

<!--********** Confirm Modal **********-->

<prompt-modal
        #confirmSave
        size="small">
    <div class="modal-header">
        <h4 class="modal-title">Submit Changes </h4>
    </div>

    <div class="modal-item">
        <div class="col-12">
            <p>Are you sure you would like to submit your changes?</p>
        </div>
    </div>

    <div class="modal-buttons">
        <button (click)="saveToDairy();confirmSave.hidePromptModal()" type="submit" class="btn btn-sm btn-primary">Update</button>
        <button type="button" class="btn btn-sm btn-danger" (click)="confirmSave.hidePromptModal();">Cancel</button>
    </div>

</prompt-modal>

<!--********** Confirm Delete Modal **********-->


<prompt-modal #confirmGroupModal size="small">
    <div class="modal-header">
        <h4 class="modal-title">Submit Changes </h4>
    </div>

    <div class="modal-item">
        <div class="col-12">
            <p>Are you sure you would like to submit your changes?</p>
        </div>
    </div>

    <div class="modal-buttons">
        <button (click)="createNewGroup();confirmGroupModal.hidePromptModal()" type="submit" class="btn btn-sm btn-primary">Update</button>
        <button type="button" class="btn btn-sm btn-danger" (click)="confirmGroupModal.hidePromptModal();">Cancel</button>
    </div>
</prompt-modal>



<prompt-modal #dairyToGroupModal size="small">
    <div class="modal-header">
        <h4 class="modal-title">Submit Changes </h4>
    </div>

    <div class="modal-item">
        <div class="col-12">
            <p>Are you sure you would like to submit your changes?</p>
        </div>
    </div>

    <div class="modal-buttons">
        <button (click)="saveToGroup();dairyToGroupModal.hidePromptModal()" type="submit" class="btn btn-sm btn-primary">Update</button>
        <button type="button" class="btn btn-sm btn-danger" (click)="dairyToGroupModal.hidePromptModal();">Cancel</button>
    </div>

</prompt-modal>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
