import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';

import {Note} from '../../../models/note';

@Component({
    templateUrl: './address-notes.component.html',
    styleUrls: ['../address.component.css', './address-notes.component.css'],
    selector: 'address-notes'
})
export class AddressNotesComponent implements OnInit{
    @Input()
    notes: Note[];
    @Input()
    open: boolean;
    @Input()
    deliveryInstructions: string;
    @Output()
    showNotesPanel = new EventEmitter<void>();
    constructor(){}

    ngOnInit():void{
        this.notes = [];
    }

    showAll(): void{
        this.showNotesPanel.emit();
    }

}