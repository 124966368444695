<div id="addNoteComponent" class="shadow">
    <form class="form" #addNote="ngForm" name="workOrder">
        <div class="row">
            <div class="form-group col-12">
                <h4 for="note">*Add a Service Record</h4>
                <textarea #serviceRecord="ngModel" required maxLength="8192" name="note" id="note" class="form-control" rows="3" [(ngModel)]="workOrderDetail.note"></textarea>
                <div *ngIf="serviceRecord.errors && (serviceRecord.dirty || serviceRecord.touched)"
                     class="alert alert-danger">
                    <div [hidden]="!serviceRecord.errors.required">
                        Record is required
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12 shift-right">
                <button class="btn btn-success" (click)="addServiceRecord.showPromptModal(workOrder)" [disabled]="!addNote.form.valid">Add</button>
                <div class="btn btn-danger" (click)="cancel()">Cancel</div>
            </div>
        </div>
    </form>
    <small style="color:red">* Required</small>
</div>

<app-update-work-order #addServiceRecord (onUpdate)="newNote(workOrderDetail)" [update]="true"></app-update-work-order>
