import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import {Employee} from "../../../models/employee";
import {WorkOrderDetail} from "../../../models/work-order-detail";
import { WorkOrder } from "../../../models/work-order";

@Component({
    selector: 'add-note',
    templateUrl: 'add-note.component.html',
    styleUrls:['add-note.component.css']
})

export class AddWorkOrderNote implements OnInit{

    @Input() employees: Employee[];
    @Input() workOrder: WorkOrder;
    @Output() createNote = new EventEmitter<WorkOrderDetail>();
    @Output() cancelAdding = new EventEmitter();
    workOrderDetail: WorkOrderDetail;

    constructor(
        private router: Router){}
    ngOnInit(){
            this.workOrderDetail = new WorkOrderDetail();
        }

    newNote(workOrderDetail: WorkOrderDetail){
        this.createNote.emit(workOrderDetail);
    }

    cancel(){
        this.cancelAdding.emit();
    }
}
