import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Product } from '../../models/product';
import { environment } from '../../../environments/environment';

@Injectable()

export class ProductService {
    private apiUrl = environment.apiUrl;  // URL to web api
    constructor(public authHttp: HttpClient) { }

    getProducts(): Promise<Product[]> {
        return this.authHttp.get(`${this.apiUrl}product`)
            .toPromise()
            .then((response) => {
                return response as Product[];
            })
            .catch(this.handleError);
    }

    saveProduct(product: Product): Promise<Product> {
        if (product.id) {
            return this.updateProduct(product);
        } else {
            return this.createProduct(product);
        }
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    createProduct(product: Product): Promise<Product> {
        return this.authHttp.post(`${this.apiUrl}product`, product)
            .toPromise()
            .then((res) => {
                return res as Product;
            });
    }
    updateProduct(product: Product) {
        return this.authHttp.put(`${this.apiUrl}product/${product.id}`, product)
            .toPromise()
            .then((res) => {
                return res as Product;
            });
    }

}
