import {Component, Output, EventEmitter, Input} from '@angular/core';

@Component({
    selector: 'login-form',
    templateUrl: 'login-form.component.html',
    styleUrls: ['../login.component.css']
})

export class LoginFormComponent{
    @Output() onSubmit = new EventEmitter <any>();
    @Output() forgotPass = new EventEmitter();
    @Input() messages: {message:null, open:false};
    cred: {email: string, password: string};
    constructor() {
        this.cred = {
            email:null,
            password:null
        }
    }

    closeMessages():void{
        this.messages['open'] = false;
    }

    submit() {
        this.onSubmit.emit(this.cred);
    }
    forgot(){
        this.forgotPass.emit();
    }
}
