import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { S3hPaginationComponent } from './s3h-pagination.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [S3hPaginationComponent],
  exports: [S3hPaginationComponent]
})
export class S3hPaginationModule { }
