import {Component, Input, Output, EventEmitter, ViewChild} from '@angular/core';

import {PromptModalComponent} from '../../shared-components/prompt-modal/prompt-modal.component';

import * as _ from 'lodash';

import {ServiceType} from '../../models/service-type';
import {Tank} from '../../models/tank';
import {WorkOrder} from '../../models/work-order';
import {Router} from '@angular/router';
import {Employee} from '../../models/employee';
import { NgForm } from '@angular/forms';
import {ToastService} from '../../services-module/services/toast.service';
import {WorkOrderService} from '../../services-module/services/work-order.service';
import {WorkOrderDetail} from '../../models/work-order-detail';
import {UserService} from '../../services-module/services/user.service';
import {WorkOrderDetailService} from '../../services-module/services/work-order-detail.service';
import {UtilsService} from '../../services-module/services/utils.service';

@Component({
    templateUrl: './work-orders-panel.component.html',
    styleUrls: ['./work-orders-panel.component.css'],
    selector: 'work-orders-panel'
})
export class WorkOrdersPanelComponent {
    @Input() workOrders: WorkOrder[];
    @Input() tanks: Tank[];
    @Input() serviceTypes: ServiceType[];
    @Input() employees: Employee[];
    @Input() employeeOptions: any[];
    @Output() saveWorkOrder = new EventEmitter<any>();
    @Output() deleteWorkOrder = new EventEmitter<any>();
    @Output() hidePanel = new EventEmitter<void>();
    @Output() createNote = new EventEmitter<{workOrder: WorkOrder, note: WorkOrderDetail}>();
    @ViewChild('changeOrderStatus', { static: true }) private changeOrderStatus: PromptModalComponent;
    @ViewChild('orderPanelForm') public orderPanelForm: NgForm;
    @ViewChild('addWorkOrderNote') private addWorkOrderNote: NgForm;
    @Input() workOrder: WorkOrder;
    workOrderDetail: WorkOrderDetail;
    sortOptions: Object;
    openWorkOrder: Boolean = false;

    constructor(private router: Router,
                private userService: UserService,
                private utilsService: UtilsService,
                private workOrderDetailService: WorkOrderDetailService,
                private workOrderService: WorkOrderService,
                private toastService: ToastService) {
        // this.setWorkOrder();
        this.workOrderDetail = new WorkOrderDetail();
        this.sortOptions = {
            byId: 'asc',
            byDairy: 'desc',
            byCity: 'desc',
            byStatus: 'asc',
            byEmployeeTo: 'desc',
            byEmployee: 'desc',
            byOpenDate: 'asc',
            byClosedDate: 'desc',
            byType: 'desc'
        };
    }


    hide(): void {
        this.hidePanel.emit();
    }

    save(workOrder: WorkOrder, form: NgForm) {
        if (form.form.valid) {
            this.saveWorkOrder.emit(workOrder);
            form.reset();
        } else {
            this.toastService.validate(form.form);
        }
    }
    delete(workOrder: WorkOrder) {
        this.deleteWorkOrder.emit(workOrder);
    }

    editNote(note: any) {
        this.workOrderDetail = note;
    }

    addNote(note: any) {
        if (!note.id) {
            note.workOrderId = this.workOrder.id;
        }
        this.createNote.emit({workOrder: this.workOrder, note: note});
        this.addWorkOrderNote.reset();
    }

    clearForm() {
        this.workOrder = new WorkOrder();
    }

    setWorkOrder(order: WorkOrder) {
        this.workOrder = order;
        this.openWorkOrder = true;
    }

    changeStatus(order: WorkOrder) {
        this.workOrder = new WorkOrder();
    }

    selectWorkOrder(orderId: number): void {
        this.router.navigate(['/work-orders', orderId]);
    }

    setEmployee(employeeId: number) {
        const employee = _.find(this.employees, {id: employeeId});
        return `${employee.firstName} ${employee.lastName}`;
    }

    sortById(): void {
        if (this.sortOptions['byId'] === 'asc') {

            this.workOrders.sort((a: WorkOrder, b: WorkOrder) => {
                if (a.id > b.id) {
                    return 1;
                } else {
                    return -1;
                }
            });

            this.sortOptions['byId'] = 'desc';
        } else {

            this.workOrders.sort((a: WorkOrder, b: WorkOrder) => {
                if (a.id < b.id) {
                    return 1;
                } else {
                    return -1;
                }
            });
            this.sortOptions['byId'] = 'asc';
        }
    }

    sortByEmployee(): void {
            if (this.sortOptions['byEmployee'] === 'desc') {
                this.workOrders = _.orderBy(this.workOrders, ['createdBy.lastName'], ['asc']);
                this.sortOptions['byEmployee'] = 'asc';
            } else {
                this.workOrders = _.orderBy(this.workOrders, ['createdBy.lastName'], ['desc']);
                this.sortOptions['byEmployee'] = 'desc';
            }
        }
    sortByType(): void {
        if (this.sortOptions['byType'] === 'desc') {
            this.workOrders = _.orderBy(this.workOrders, ['ServiceType.serviceTypeName'], ['asc']);
            this.sortOptions['byType'] = 'asc';
        } else {
            this.workOrders = _.orderBy(this.workOrders, ['ServiceType.serviceTypeName'], ['desc']);
            this.sortOptions['byType'] = 'desc';
        }
    }
    sortByEmployeeTo(): void {
        if (this.sortOptions['byEmployeeTo'] === 'desc') {
            this.workOrders = _.orderBy(this.workOrders, ['employeeTo.lastName'], ['asc']);
            this.sortOptions['byEmployeeTo'] = 'asc';
        } else {
            this.workOrders = _.orderBy(this.workOrders, ['employeeTo.lastName'], ['desc']);
            this.sortOptions['byEmployeeTo'] = 'desc';
        }
    }
    sortByStatus(): void {
            if (this.sortOptions['byStatus'] === 'desc') {
                this.workOrders = _.orderBy(this.workOrders, ['workOrderStatusId'], ['asc']);
                this.sortOptions['byStatus'] = 'asc';
            } else {
                this.workOrders = _.orderBy(this.workOrders, ['workOrderStatusId'], ['desc']);
                this.sortOptions['byStatus'] = 'desc';
            }
        }
    sortByOpenDate(): void {
            if (this.sortOptions['byOpenDate'] === 'desc') {
                this.workOrders = _.orderBy(this.workOrders, ['createdAt'], ['asc']);
                this.sortOptions['byOpenDate'] = 'asc';
            } else {
                this.workOrders = _.orderBy(this.workOrders, ['createdAt'], ['desc']);
                this.sortOptions['byOpenDate'] = 'desc';
            }
        }
    sortByClosedDate(): void {
            if (this.sortOptions['byClosedDate'] === 'desc') {
                this.workOrders = _.orderBy(this.workOrders, ['completionDate'], ['asc']);
                this.sortOptions['byClosedDate'] = 'asc';
            } else {
                this.workOrders = _.orderBy(this.workOrders, ['completionDate'], ['desc']);
                this.sortOptions['byClosedDate'] = 'desc';
            }
        }


    woStatus(val: any) {
        return this.utilsService.woStatus(val);
    }

}
