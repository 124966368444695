<div class="input-group">
	<input type="text"
       class="form-control"
       placeholder="MM/DD/YYYY"
       [ngModel]="date | date"
       (click)="modal.showPromptModal()">
	<div class="input-group-append">
		<button type="button" (click)="modal.showPromptModal()" class="btn btn-default btn-calendar"><i class="fa fa-calendar" aria-hidden="true"></i></button>
	</div>

</div>


<prompt-modal #modal size="small">
    <div class="modal-header">
      <h4 class="modal-title">{{title}}</h4>
    </div>
  	<div class="modal-item row">
    	<div class="col-12">
      		<datepicker [(ngModel)]="date"
              			class="date-modal"
              			name="Date"
						datepickerMode="day"
              			[showWeeks]="false"
              			(selectionDone)="onChange($event)"></datepicker>

		</div>
  	</div>
	<div class="modal-buttons">
		<button type="button" class="btn btn-primary" (click)="modal.hidePromptModal()">OK</button>
	</div>
</prompt-modal>