<prompt-modal
        #modal
        size="small">

  <div class="modal-header">
    <h4 class="modal-title">{{header}}</h4>
  </div>

  <div class="modal-item">
    <div class="col-12">
      <p>{{title}}</p>
    </div>
  </div>

  <div class="modal-buttons">
    <button (click)="save();" type="submit" class="btn btn-sm btn-primary">{{confirm}}</button>
    <button type="button" class="btn btn-sm btn-danger" (click)="modal.hidePromptModal();">{{cancel}}</button>
  </div>

</prompt-modal>
