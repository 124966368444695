import * as _ from 'lodash';
export class PhoneNumber{
    constructor(data?: any){
        _.merge(this, data);
    }

    public id: number = null;
    public number: string = null;
    public type: string = null;
    public entityId: number = null;
    public entity: string = null;
}