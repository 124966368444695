<div id="login">
    <div class="login-form shadow">
        <div class="login-logo">
            <img src="../../../../assets/images/sprite_46.gif" alt="">
        </div>
        <div class="view-name padding-bottom-20" *ngIf="!saved">
            <h6>Please enter a new password below</h6>
        </div>
        <form *ngIf="!saved" #resetForm="ngForm">
            <div class="form-group">
                <label for="email">Email</label>
                <input id="email"
                       name="email"
                       required
                       [(ngModel)]="creds.email"
                       type="email"
                       #newEmail="ngModel"
                       class="form-control">
                <div *ngIf="newEmail.errors && (newEmail.dirty || newEmail.touched)"
                     class="alert alert-danger">
                    <div [hidden]="!newEmail.errors.required">
                        Email is required
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="newPassword">New Password</label>
                <input id="newPassword"
                       name="newPassword"
                       required
                       [(ngModel)]="creds.newPassword"
                       type="password"
                       #newPassword="ngModel"
                       class="form-control">
                <div *ngIf="newPassword.errors && (newPassword.dirty || newPassword.touched)"
                     class="alert alert-danger">
                    <div [hidden]="!newPassword.errors.required">
                        Please Enter a password
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="pass">Retype Password</label>
                <input id="pass"
                       name="pass"
                       type="password"
                       [(ngModel)]="pass"
                       required
                       #confirmPassword="ngModel"
                       validateEqual="newPassword"
                       class="form-control">
                <div *ngIf="confirmPassword.errors && (confirmPassword.dirty || confirmPassword.touched)"
                     class="alert alert-danger">
                    <div [hidden]="!confirmPassword.errors.required">
                        Please Enter a password
                    </div>
                    <div [hidden]="confirmPassword.valid">
                        Password mismatch
                    </div>
                </div>
            </div>
            <div class="post-form">
                <button (click)="save(); resetForm.reset()" [disabled]="!resetForm.form.valid" class="btn btn-primary">
                    Save
                </button>
                <div (click)="cancel()" class="btn btn-danger ">
                    Cancel
                </div>
            </div>
        </form>
        <!--Alerts regarding password reset-->
        <div class="successful-password" *ngIf="alert.open && !alert.errorMessage">
            <div class="padding-top-20">
                <h4>{{alert.successHeading}}</h4>
                <div>{{alert.successText}}</div>
            </div>
            <div class="padding-top-20">
                <div class="btn btn-primary" (click)="return()">return</div>
            </div>
        </div>
        <div class="alert alert-danger" *ngIf="alert.open && alert.errorMessage">
            <span>{{alert.errorMessage}}</span>
            <div class="padding-top-20 shift-right">
                <div class="btn btn-primary" (click)="tryAgain()">Cancel</div>
            </div>
        </div>

    </div>
</div>
