import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AccordionModule } from 'ngx-bootstrap/accordion';

//import { ReportingRouting } from './reporting.routing';

import { CoreLmntModule } from '../../core-lmnt/core-lmnt.module';
import { S3hPaginationModule } from '../../s3h-pagination/s3h-pagination.module';
import { SharedComponentsModule } from '../../shared-components/shared-components.module';
import { SharedServicesModule } from '../../shared-services/shared-services.module';

import { TablesResolver } from './resolvers/tables.resolver';
import { SavedReportsResolver } from './resolvers/saved-reports.resolver';

import { ReportingComponent } from './reporting/reporting.component';
import { DataDictionaryComponent } from './data-dictionary/data-dictionary.component';
import { ReportFilterComponent } from './report-filter/report-filter.component';
import { ReportModalComponent } from './report-modal/report-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NormAuthResolver } from './resolvers/norm-auth.resolver';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    //ReportingRouting,
    CoreLmntModule,
    S3hPaginationModule,
    BsDropdownModule,
    TypeaheadModule.forRoot(),
    SharedComponentsModule,
    SharedServicesModule
  ],
  declarations: [
    ReportingComponent,
    DataDictionaryComponent,
    ReportFilterComponent,
    ReportModalComponent
  ],
  providers: [
    TablesResolver,
    SavedReportsResolver,
    NormAuthResolver
  ]
})
export class ReportingModule { }
