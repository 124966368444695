import {Injectable}                 from '@angular/core';
import { Resolve,
         ActivatedRouteSnapshot }   from '@angular/router';


import { StateService }              from "../services-module/services/state.service";
import { State }                     from "../models/state";

@Injectable()
export class StatesResolve implements Resolve<State> {
    constructor(private statesService: StateService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.statesService.getStates();
    }
}