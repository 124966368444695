<h5><i class="fa fa-map"></i> Recent Labs</h5>
<div class="full-width shadow recent-box">
    <table class="table table-striped table-hover" id="recent-table">
        <thead>
            <tr class="center-text">
                <th class="td-10">Dairy</th>
                <th class="td-10">Lab Number</th>
                <th class="td-10">Date</th>
                <th class="td-10">Time</th>
                <th class="td-10">Bath Location</th>
                <th class="td-10">Bath Cycle</th>
                <th class="td-10">Corrosive</th>
                <th class="td-10">Hazardous</th>
                <th class="td-10">ESH</th>
                <th class="td-10">Notes</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let lab of labs" [routerLink]="['/clients',lab.Address.clientId]" class="center-text">
                <td *ngIf="lab.Address.alias != null">{{lab?.Address.alias}}</td>
                <td *ngIf="lab.Address.alias == null">No Dairy is associated to this lab.</td>
                <td *ngIf="lab.id != null" >{{ lab?.id }}</td>
                <td *ngIf="lab.id == null" >No ID was recorded for this lab.</td>
                <td *ngIf="lab.date != null" >{{ lab?.date | date : 'shortDate' }}</td>
                <td *ngIf="lab.date == null" >No date was recorded for this lab.</td>
                <td *ngIf="lab.date != null" >{{ lab?.date | date : 'shortTime' }}</td>
                <td *ngIf="lab.date == null" >No time was recorded for this lab.</td>
                <td *ngIf="lab.tankLocation != null" >{{ lab?.tankLocation }}</td>
                <td *ngIf="lab.tankLocation == null" >No location was recorded for this lab.</td>
                <td *ngIf="lab.bathCycle != null" >{{ lab?.bathCycle }}</td>
                <td *ngIf="lab.bathCycle == null" >No Bath Cycle was recorded for this lab.</td>
                <td *ngIf="lab.isCorrosive != null" ><i *ngIf="lab?.isCorrosive" class="fa fa-check is-checked" aria-hidden="true"></i></td>
                <td *ngIf="lab.isCorrosive == null" >No data about corrosiveness was recorded for this lab.</td>
                <td *ngIf="lab.isHazardous != null" ><i *ngIf="lab?.isHazardous" class="fa fa-check is-checked" aria-hidden="true"></i></td>
                <td *ngIf="lab.isHazardous == null" >No data about hazardousness was recorded for this lab.</td>
                <td *ngIf="lab.isESH != null" ><i *ngIf="lab?.isESH" class="fa fa-check is-checked" aria-hidden="true"></i></td>
                <td *ngIf="lab.isESH == null" >No ESH data was recorded for this lab.</td>
                <td *ngIf="lab.note != null" >{{lab?.note}}</td>
                <td *ngIf="lab.note == null" >No note was recorded for this lab.</td>
            </tr>
        </tbody>
    </table>
</div>
