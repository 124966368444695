import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { EmployeeType } from '../../models/employee-type';
import { environment } from '../../../environments/environment';

@Injectable()
export class EmployeeTypeService {
    private apiUrl = environment.apiUrl;
    TYPES = {
        DRIVER: 1,
        OFFICE: 2,
        LAB_TECH: 3,
        SALES_REP: 4,
        FIELD_SERVICE: 5,
        SALES_MANAGER: 6,
    }
    SALES_TYPES = {
        OUTSIDE: 0,
        INSIDE: 1
    }

    constructor(
        private authHttp: HttpClient
    ) { }

    getEmployeeTypes(): Promise<EmployeeType[]> {
        return this.authHttp.get(`${this.apiUrl}employee-type`)
            .toPromise()
            .then((res) => {
                return res as EmployeeType[];
            });
    }

    saveEmployeeType(employeeType: EmployeeType): Promise<EmployeeType> {
        if (employeeType.id) {
            return this.updateEmployeeType(employeeType);
        } else {
            return this.createEmployeeType(employeeType);
        }
    }

    createEmployeeType(employeeType: EmployeeType): Promise<EmployeeType> {
        return this.authHttp.post(`${this.apiUrl}employee-type`, employeeType)
            .toPromise()
            .then((res) => {
                return res as EmployeeType;
            });
    }

    updateEmployeeType(employeeType: EmployeeType): Promise<EmployeeType> {
        return this.authHttp.put(`${this.apiUrl}employee-type/${employeeType.id}`, employeeType)
            .toPromise()
            .then((res) => {
                return res as EmployeeType;
            });
    }
}
