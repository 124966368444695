// core modules
import { NgModule } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

// bootstrap module

import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';

// components
import { PromptModalComponent } from './prompt-modal/prompt-modal.component';
import { DropdownComponent } from './dropdown/dropdown.component';

import { SettingsComponent } from './settings/settings.component';

import { FlexInputModule } from './flex-input/flex-input.module';
import { UpdateWorkOrderComponent } from './update-work-order/update-work-order.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { LoadedLevelsComponent } from '../client-routes/loaded-levels/loaded-levels.component';
import { RouteSingleComponent } from '../client-routes/route-single/route-single.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { WoClickOutside } from '../directives/wo-click-outside.directive';
import { PromptModalHelperComponent } from './prompt-modal-helper/prompt-modal-helper.component';

@NgModule({
    imports: [
        DatepickerModule.forRoot(),
        // BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        ModalModule,
        FlexInputModule,
        RouterModule,
        NgSelectModule
    ],
    declarations: [
        WoClickOutside,
        RouteSingleComponent,
        LoadedLevelsComponent,
        PromptModalComponent,
        DropdownComponent,
        UpdateWorkOrderComponent,
        PromptModalHelperComponent,
        SettingsComponent,
        DatePickerComponent
    ],
    exports: [
        WoClickOutside,
        RouteSingleComponent,
        LoadedLevelsComponent,
        PromptModalComponent,
        DropdownComponent,
        SettingsComponent,
        UpdateWorkOrderComponent,
        PromptModalHelperComponent,
        DatePickerComponent,
        FlexInputModule
    ]
})
export class SharedComponentsModule { }
