import { Component } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { routeAnimation } from 'src/app/animations';

@Component({
  templateUrl: './inactive.component.html',
  styleUrls: ['inactive.component.css'],
  animations: [routeAnimation]
})
export class InactiveComponent {
  constructor(private contexts: ChildrenOutletContexts) { }

  getView() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.view;
  }
}
