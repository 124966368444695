import * as _ from 'lodash';

export class Tractor{
    constructor(
        data?: any
    ){
        _.merge(this, data);
    }
    public id: number = null;
    public Cargos: any[] = null;
    public name: string = null;
    public isActive: boolean = true;
    public number: string;
}
