import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileUploadModel } from '@models/file-upload';
import { environment } from '@environment';


@Injectable()
export class FileUploadService {
    private apiUrl = `${environment.apiUrl}client-document`; // URL to web api

    constructor(public authHttp: HttpClient) { }

    uploadFile(file: FileUploadModel): Promise<Object[]> {
        return this.authHttp.post(`${this.apiUrl}/upload`, file)
            .toPromise()
            .then((res) => {
                return res as Object[];
            })
            .catch(this.handleError);
    }

    getDocuments(addressId: number): Promise<Object[]> {
        return this.authHttp.get(`${this.apiUrl}/address/${addressId}/-thumb/all`)
            .toPromise()
            .then((res) => {
                return res as Object[];
            })
            .catch(this.handleError);
    }

    getDocument(addressId: number, imageId: number): Promise<Object> {
        return this.authHttp.get(`${this.apiUrl}/address/${addressId}/image/${imageId}/-medium`)
            .toPromise()
            .then((res) => {
                return res as Object;
            })
            .catch(this.handleError);
    }

    updateDocument(document: any): Promise<Object> {
        const imageId = document.id;
        return this.authHttp.put(`${this.apiUrl}/${imageId}`, document)
            .toPromise()
            .then((res) => {
                return res as Object;
            }).catch();
    }

    deleteDocument(imageId: number): Promise<Object> {
        return this.authHttp.delete(`${this.apiUrl}/${imageId}`)
            .toPromise()
            .then((res) => {
                return res as Object;
            })
            .catch(this.handleError);
    }

    getDocumentTypes(): Promise<Object[]> {
        return this.authHttp.get(`${environment.apiUrl}document-type`)
            .toPromise()
            .then((res) => {
                return res as Object[];
            })
            .catch(this.handleError);
    }


    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
