<div id="login">
    <div class="login-form shadow">
        <div class="login-logo">
            <img src="../../../assets/images/sprite_46.gif" alt="">
        </div>
        <login-form *ngIf="login"
                (forgotPass)="forgotPass()"
                [messages]="messages"
                (onSubmit)="onSubmit($event)"></login-form>
        <forgot-password *ngIf="!login"
                (cancelForgot)="cancelForgot()"
                (returned)="returned($event)"></forgot-password>
    </div>
</div>