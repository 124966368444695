import { Component,
         Input,
         EventEmitter,
         Output,
         OnInit }                   from '@angular/core';
import { Client }                   from '../../models/client';

import { Subject,
         Observable,
         Subscription }             from 'rxjs';
import { debounceTime,
         distinctUntilChanged }     from 'rxjs/operators';

import { ClientService }            from "../../services-module/services/client.service";
import { UserService }              from "../../services-module/services/user.service";

@Component({
    templateUrl: 'clients-nav.component.html',
    styleUrls: ['../client.component.css'],
    selector: 'clients-nav'
})

export class ClientsNavComponent implements OnInit{
    @Output() changeDisplayMode = new EventEmitter <number>();
    @Output() saveClient = new EventEmitter<Client>();
    @Output() searchTerm:EventEmitter<string> = new EventEmitter<string>();
    @Output() toggle = new EventEmitter<boolean>();
    @Input()  displayMode:number;

    public searchClientModelChanged: Subject<string> = new Subject<string>();
    private searchClientModelChangeSubscription: Subscription;


    add:boolean = false;
    client:Client = new Client();
    clients:Observable<Client[]>;
    tablet:boolean;
    mobile:boolean;

    searchString:string = '';

    @Input() salesLeads:boolean;

    constructor(private clientService: ClientService, private userService:UserService){}

    ngOnInit():void {
        this.setMobile();

        this.searchClientModelChangeSubscription = this.searchClientModelChanged.pipe(
            debounceTime(2000),
            distinctUntilChanged()).subscribe(searchResult => {
                this.searchTerm.emit(searchResult);
            });
    }

    ngOnDestroy() {
        this.searchClientModelChangeSubscription.unsubscribe();
    }

    addClients(){
        this.add = !this.add;
    }

    addNewClient(client:Client){
        this.saveClient.emit(client);
    }

    cancelSave():void{
        this.client = new Client();
        this.add = !this.add;
    }

    changeDisplay(mode: number){
        this.changeDisplayMode.emit(mode);
    }

    passString(event:Object){
        this.searchTerm.emit(event['value']);
    }

    toggleType(){
        this.salesLeads = !this.salesLeads;
        this.toggle.emit(this.salesLeads);
    }

    setMobile() {
        this.tablet = (window.innerWidth < 1400) ? true : false;
        this.mobile = (window.innerWidth < 1102) ? true : false;
    }
}
