<div class="row">
    <div class="col-12 col-md-4">
        <div class="select-dairy-card shadow">
            <div class="card-container">
                <div class="card-header">
                    <strong>Select Client Group </strong>
                    <ng-select [clearable]="false"
                                [items]="dairies"
                                bindLabel="text"
                                bindValue="id"
                                (change)="groupSet($event)"
                                id="client"
                                name="client"
                                class="client-select-dropdown"
                                style="display: inline-block; width: 201px;"
                                placeholder="Select Client Group">
                    </ng-select>
                </div>
                <div class="card-body">
                    <div class="client-group" *ngIf="clientGroup">
                            <div class="client" [ngClass]="{'select-to-group':groupToGroup}" (click)="groupToGroup ? pushToGroup(clientGroup): null">
                                <div class="client-image shadow" [ngClass]="{'limbo-border':clientGroup.limbo}">
                                    <div class="background-img" [style.background]="clientGroup.image.length > 0 ? setBG(clientGroup.image[0].filePath) : defaultImage">
                                        <!--<a *ngIf="groupToGroup" class="select-to-group" href="javascript:void(0);" (click)="pushToGroup(clientGroup)"></a>-->
                                    </div>
                                </div>
                                <div class="client-info">
                                    <h3>{{clientGroup.name}}</h3>
                                    <p><span>Dairy Group Since: </span>{{clientGroup.createdAt | date}}</p>
                                </div>
                            </div>

                        <div class="client-dairies" >
                            <div class="dairy" *ngFor="let dairy of clientGroup.Addresses; let i = index;">
                                <span class="dairy-no" [ngClass]="{'limbo-bg':clientGroup.limbo}">{{i+1}}</span>
                                <div class="dairy-info">
                                    <div>{{dairy.alias}}</div>
                                    <span>{{dairy.street}}</span><br/>
                                    <span>{{dairy.city}}, {{dairy.state}} {{dairy.zip}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-8">
        <div class="new-group-card shadow">
            <div class="card-container">
                <div class="card-header">
                    <strong>Select Group To Merge With </strong>
                    <ng-select [clearable]="false"
                               [editableSearchTerm]="true"
                               bindLabel="text"
                               bindValue="id"
                               [items]="dairies"
                               (change)="toGroupSet($event)"
                               id="client"
                               name="client"
                               class="client-select-dropdown"
                               style="display: inline-block; width: 201px;"
                               [readonly]="!clientGroup"
                               placeholder="Select Group To Merge With">
                    </ng-select>
                </div>

                <div class="card-body">
                    <div class="client-group" *ngIf="groupToGroup">
                        <div class="client">
                            <div class="client-image shadow">
                                <div class="background-img" [style.background]="groupToGroup.image.length > 0 ? setBG(groupToGroup.image[0].filePath) : defaultImage"></div>
                            </div>
                            <div class="client-info">
                                <h3>{{groupToGroup.name}}</h3>
                                <p><span>Dairy Group Since: </span>{{groupToGroup.createdAt | date}}</p>
                            </div>
                        </div>
                        <div class="client-dairies" >
                            <div class="dairy" *ngFor="let dairy of groupToGroup.Addresses; let i = index;">
                                <span class="dairy-no" [ngClass]="{'limbo-bg':dairy.newDairy}">{{i+1}}</span>
                                <div class="dairy-info">
                                    <div>{{dairy.alias}}</div>
                                    <span>{{dairy.street}}</span><br/>
                                    <span>{{dairy.city}}, {{dairy.state}} {{dairy.zip}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer" *ngIf="groupToGroup">
                <button class="btn btn-warning" (click)="remove()">Clear</button>
                <button class="btn btn-danger" (click)="cancel()">Cancel</button>
                <button class="btn btn-success" (click)="save()" [disabled]="!groupToGroup ">Save</button>
            </div>
        </div>
    </div>
</div>
