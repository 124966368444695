import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { EmployeeService } from '@services/employee.service';

import { Employee } from '@models/employee';

@Injectable({ providedIn: 'root' })
export class DriversResolve implements Resolve<Employee[]> {
  constructor(private employeeService: EmployeeService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.employeeService.getEmployeesByType('Driver');
  }
}
