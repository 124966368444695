import * as _ from 'lodash';

export class TankDevice{
    constructor(data?: any){
        _.merge(this, data);
    }

    public id: number = null;
    public TankDeviceName: string = null;
    public SerialNumber: string = null;
    public CellularID: string = null;
    public isActive: boolean = null;
}
