<div id="single-route" class="no-print">
    <div *ngIf="!driverView" class=" header">
        <h2><i class="fa fa-map"></i> Update Route</h2>
    </div>

    <div class="padding-15 shadow">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-4 single-route-details">
                    <div>
                        <strong>Name:</strong> <span>{{ route.routeName }}</span>
                        <small style="font-size: 12px;"  *ngIf="!driverView"><a href="javascript:void(0);" [routerLink]="['/edit-work-route', route.id]">Edit Route</a></small>
                    </div>
                    <div>
                        <strong>Scheduled:</strong>  <span>{{route.dateOfRoute | date}}</span>
                    </div>
                    <div>
                        <strong>Type:</strong>  <span>{{ route.routeType }}</span>
                    </div>
                    <div>
                        <strong>Status:</strong> <span class="active-status" [ngClass]="activeStatus(route.completedAt, route.dateOfRoute)">{{activeStatus(route.completedAt, route.dateOfRoute)}}</span>

                    </div>
                </div>

                <div class="col-12 col-md-4 no-print">
                    <div class="mobile-right">
                        <button class="btn btn-sm btn-warning" (click)="print()">Print</button>
                    </div>
                    <div class="single-route-form mobile-right" *ngIf="!driverView">
                        <ng-select [multiple]="true"
                                   id="drivers"
                                   name="Drivers"
                                   [(ngModel)]="route.driverIds"
                                   placeholder="Select Employees">
                            <ng-option *ngFor="let driver of driverOptions" [value]="driver.id">{{driver.name}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="padding-top-10 mobile-right" *ngIf="!driverView">
                        <button class="btn btn-primary" (click)="updateRoute()">
                            Update Driver
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="margin-top-20">
        <div class="padding-10 shadow route-content">
            <div class="row mobile-hidden strong-font">
                <div class="col-lg-3">Dairy Details</div>
                <div class="col-lg-1">Order #</div>
                <div class="col-lg-1">Service Type</div>
                <div class="col-lg-4">Details</div>
                <div class="col-lg-1">Status</div>
                <div class="col-lg-2">Map</div>
            </div>
            <form #routeForm="ngForm" *ngFor="let order of route.workOrders" class="row margin-top-25 margin-bottom-5 route-form">
                <div class="col-lg-3 flex-column flex-space-between">
                    <span class="mobile-display">Dairy Details:</span>
                    <div class="driver-route-container">
                        <address>
                            <a *ngIf="order.Tank" href="javascript:void(0);" [routerLink]="['/clients', order.Address.Client.id]">{{ order?.Address?.Client?.name }}</a><br/>
                            {{order?.Address?.alias}}<br/>
                            {{ order?.Address?.street }}, <br/>
                            {{ order?.Address?.city}} {{ order?.Address?.state }} {{ order?.Address?.zip }}
                        </address>
                        <div class="button-row" *ngIf="!driverView">
                            <button class="btn btn-sm btn-danger" (click)="setOrderToRemove(order)">Remove Order</button>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-1">
                    <span class="mobile-display">Order #</span>
                    <span><a [routerLink]="'/work-orders/' + order.id">{{order.id}}</a></span>
                </div>
                <div class="col-6 col-lg-1">
                    <span class="mobile-display">Service Type</span>
                    <span>{{order.ServiceType.serviceTypeName}}</span>
                </div>
                <div class="col-6 col-lg-4">
                    <span class="mobile-display">Details</span>
                    <span>{{order.note}}</span>
                </div>
                <div class="col-6 col-lg-1">
                    <span class="mobile-display">Status</span>
                    <span class="shadow point" [ngClass]="woStatus(order.workOrderStatusId)" (click)="updateWorkOrder.showPromptModal(order);">{{order.workOrderStatusId | orderStatus}}</span>
                </div>
                <div class="col-6 col-lg-2">
                    <span class="mobile-display">Map</span>
                    <button class="btn btn-sm btn-success" (click)="openMap(order)">
                        <i class="fa fa-map"></i>
                    </button>
                </div>
            </form>

            <div class="container-fluid">
                <div class="row margin-top-10 driver-route route-separator">
                    <div class="col-sm-12">
                        <div class="actions pull-right">
                            <button class="btn" [ngClass]="{'btn-success':!route.completedAt, 'btn-danger':route.completedAt}" (click)="completedRouteModal.showPromptModal()"><span>{{route.completedAt ? 'Closed':'Close'}}</span> Route</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<work-order-print [route]="route" [orders]="route.workOrders"></work-order-print>

<app-update-work-order #updateWorkOrder></app-update-work-order>

<prompt-modal
        #completedRouteModal
        size="medium">

    <div class="modal-header">
        <h4 class="modal-title">Update Your Route</h4>
    </div>

    <div class="modal-item">
        <p>Are you sure you want to {{route.completedAt ? 'Open':'Close'}} this route?</p>
    </div>

    <div class="modal-buttons">
        <button (click)="route.completedAt ? openRoute(): closeRoute(); completedRouteModal.hidePromptModal();" type="submit" class="btn btn-sm btn-success">{{route.completedAt ? 'Open':'Close'}}</button>
        <button type="button" class="btn btn-sm btn-light" (click)="completedRouteModal.hidePromptModal()">Cancel</button>
    </div>

</prompt-modal>


<prompt-modal
        #removeOrderModal
        size="medium">

    <div class="modal-header">
        <h4 class="modal-title">Remove Order from Route</h4>
    </div>

    <div class="modal-item">
        <p>Are you sure you want to remove this work order to {{_order?.Address?.alias}}?</p>
    </div>

    <div class="modal-buttons">
        <button (click)="removeFromRoute(_order); removeOrderModal.hidePromptModal();" type="submit" class="btn btn-sm btn-danger">Remove</button>
        <button type="button" class="btn btn-sm btn-light" (click)="removeOrderModal.hidePromptModal()">Cancel</button>
    </div>

</prompt-modal>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
