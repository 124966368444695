import * as _ from 'lodash';

export class Cargo {
    constructor(data?: any){
        _.merge(this, data);
    }
    id:number = null;
    maxLoad:number = null;
    description:string = null;
    isActive:boolean = true;
}