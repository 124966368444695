
import { Pipe, PipeTransform } from '@angular/core';
import { Tank } from '@models/tank';
import { TankService } from '@services/tank.service';

@Pipe({ name: 'appTankPercent' })
export class TankPercentPipe implements PipeTransform {
  constructor(private tankService: TankService) { }

  transform(tank: Tank): any {
    const percent = this.tankService.calculatePercent(tank);
    if (percent <= 0) {
      return 0;
    } else if (percent >= 100) {
      return 100;
    } else return percent;
  }
}
