import {Component, OnInit} from '@angular/core';

import {TankService} from '../../../services-module/services/tank.service';
import {Tank} from '../../../models/tank';

@Component({
    templateUrl: './inactive-tank.component.html',
    styleUrls:['../inactive-client-panel/inactive-client.component.css'],
})
export class InactiveTankPanelComponent implements OnInit{
    tanks: Tank[];
    ftp:boolean = false;
    search = '';

    constructor(
        private tankService: TankService
    ){}

    ngOnInit(): void{
        this.getInactiveTanks();
    }

    getInactiveTanks(): void{
        this.tankService.getInactiveTanks()
            .then((response)=>{
                this.tanks = response;
            });
    }

    changeTankStatus(tank: Tank): void{
        tank.isActive == 1 ? tank.isActive = 0 : tank.isActive = 1;
        this.ftp = true;
        this.tankService.updateTank(tank)
            .then((res)=>{
                this.ftp = false;
            });
    }

    deleteTank(tank: Tank) {
        return () => {
            this.ftp = true;
            this.tankService.deleteTank(tank)
                .then((res)=>{
                    this.ftp = false;
                    this.tanks.splice(this.tanks.findIndex(item => {
                        return item.id === tank.id;
                    }), 1);
                });
        }
    }
}
