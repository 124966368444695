import { Component, OnDestroy } from '@angular/core';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ChildrenOutletContexts
} from '@angular/router';
import { environment } from '@environment';
import { UpdateService } from '@services/update.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routeAnimation } from './animations';
import { AuthService } from './authorization/auth.service';
import './rxjs-operator';
import { EventService } from './shared-services/event-service/event.service';

@Component({
  selector: 'app-root',
  template: `<div class="min-100">
      <div *ngIf="environmentName" class="environment-name">{{environmentName}}</div>
      <sidebar *ngIf="auth.loggedIn()" (smallSidebar)="smallSidebar($event)"></sidebar>
                <mobile-navbar *ngIf="auth.loggedIn()"></mobile-navbar>
                <main [class.smallSidebar]="smallSide" class="min-100">
                    <div class="loading-overlay" *ngIf="loading">
                        <img src="../../assets/images/specialtylogo.png"/>
                        <i class="fa fa-spinner fa-spin fa-2x"></i>

                        <h4>Loading ...</h4>
                    </div>
                  <div [@routerAnimation]="getView()" class="position-relative min-100">
                    <router-outlet></router-outlet>
                  </div>
                </main>
                <span class="prefs-icon no-print" (click)="showPreferences()"><i class="fa fa-gear"></i></span>
                <settings *ngIf="showPrefs"
                (close)="showPreferences()"></settings>
                <toast></toast>
               </div>`,
  animations: [routeAnimation]
})
export class AppComponent implements OnDestroy {
  public loading = true;
  public smallSide = true;
  showPrefs = false;
  environmentName = environment.environmentName;
  destroyed$ = new Subject();

  constructor(
    public router: Router,
    public auth: AuthService,
    private eventService: EventService,
    private updates: UpdateService,
    private contexts: ChildrenOutletContexts
  ) {
    router.events
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: RouterEvent) => {
        this.navigationInterceptor(event);
      });
    this.listen();
  }

  listen() {
    this.eventService.on('loading', action => this.loading = action);
  }
  smallSidebar(val: boolean) {
    return this.smallSide = val;
  }

  showPreferences() {
    this.showPrefs = !this.showPrefs;
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {

    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  getView() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.view;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
