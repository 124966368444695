import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
// import { DashboardService } from '../dashboard.service';
import { Lab } from '../../models/lab';

@Component({
    selector: 'recent-labs',
    templateUrl: './recent-labs.component.html',
    styleUrls: ['../dashboard.component.css']
})
export class RecentLabsComponent {
     @Input()
     labs: Lab[];
     constructor(
        private router: Router
        ){}
}
