import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { Client } from '../../clients/client';

@Component({
    selector: 'recent-clients',
    templateUrl: './recent-clients.component.html',
    styleUrls: ['../dashboard.component.css']
})
export class RecentClientsComponent {

    @Input() clients:Client[];
    constructor(
        private router: Router){}
}
