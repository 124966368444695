import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkOrderService } from '@services/work-order.service';
import { WorkOrder } from '@models/work-order';
import { Client } from '@models/client';
import { Employee } from '@models/employee';
import { ServiceType } from '@models/service-type';
import { ClientService } from '@services/client.service';
import { AddressService } from '@services/address.service';
import { UserService } from '@services/user.service';
import { ToastService } from '@services/toast.service';
import * as _ from 'lodash';
import { EmployeeService } from '@services/employee.service';

@Component({
  selector: 'add-work-order',
  templateUrl: 'add-work-order.component.html',
  styleUrls: ['add-work-order.component.css']
})

export class AddWorkOrderComponent implements OnInit {

  order: WorkOrder;
  clients: Client[];
  clientsSelect: Object[];
  deliveryAddresses: any[];
  employees: Employee[];
  serviceTypes: ServiceType[];
  dropDown = false;
  employeeOptions: any[];
  ftp = false;


  constructor(
    private router: Router,
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private workOrderService: WorkOrderService,
    private addressService: AddressService,
    private userService: UserService,
    private clientService: ClientService,
    private toastService: ToastService) { }

  ngOnInit() {
    this.serviceTypes = this.route.snapshot.data['serviceTypes'];
    this.clients = this.route.snapshot.data['clients'];
    this.clientsSelect = [];
    this.employeeOptions = [];

    this.employees = this.route.snapshot.data['employees'];
    this.order = new WorkOrder();
    this.order['emailEmployees'] = [];
    this.clients.forEach((client) => {
      this.clientsSelect.push({
        id: client.id,
        text: client.name
      });
    });
    this.employeeOptions = this.employeeService.getEmployeeOptions(this.employees);
  }

  buildWorkOrderEmail() {
    const client = _.find(this.clients, { id: this.order.clientId });
    const dairy = _.find(client['Addresses'], { id: +this.order['addressId'] });
    this.order.Address = dairy;
    this.workOrderService.buildWorkOrderEmail(this.order);
  }

  saveWorkOrder(order: WorkOrder) {
    this.ftp = true;
    order.employeeId = this.userService.getEmployeeId();
    order.workOrderStatusId = 1;
    this.buildWorkOrderEmail();
    this.workOrderService.saveWorkOrder(order)
      .then(workOrder => {
        if (workOrder['errors']) {
          this.toastService.toast({ message: `Work order creation failed with "${workOrder['errors'][0].message}".` });
        } else {
          this.toastService.toast({ message: `Successfully created work order.`, type: 'success' });
          this.ftp = false;
          this.router.navigate(['/work-orders']);
        }
      });
  }

  findAddresses(client: Object) {
    this.order.clientId = client['id'];
    this.addressService.getDeliveryAddresses(client['id'])
      .subscribe(addresses => {
        this.deliveryAddresses = addresses;
        this.order['addressId'] = addresses.length === 1 ? addresses[0].id : null;
      });
  }

  cancelAdd() {
    this.order = new WorkOrder();
    this.router.navigate(['/work-orders']);
  }

}
