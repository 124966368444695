import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { ServiceRegion} from '../../models/service-region';
import { environment } from '../../../environments/environment';

@Injectable()
export class ServiceRegionService{
    private apiUrl = `${environment.apiUrl}`;  // URL to web api
    constructor(private authHttp: HttpClient){}

    getAllServiceRegions(): Promise<ServiceRegion[]>{
        return this.authHttp.get(`${this.apiUrl}service-region`)
            .toPromise()
            .then((res)=>{
                return res as ServiceRegion[];
            })
    }

    saveServiceRegion(serviceRegion: ServiceRegion): Promise<ServiceRegion>{
        if(serviceRegion.id){
            return this.updateServiceRegion(serviceRegion);
        }else{
            return this.createServiceRegion(serviceRegion);
        }
    }

    createServiceRegion(serviceRegion: ServiceRegion): Promise<ServiceRegion>{
        return this.authHttp.post(`${this.apiUrl}service-region`, serviceRegion)
            .toPromise()
            .then((res)=>{
                return res as ServiceRegion;
            })
    }
    updateServiceRegion(serviceRegion: ServiceRegion){
        return this.authHttp.put(`${this.apiUrl}service-region/${serviceRegion.id}`, serviceRegion)
            .toPromise()
            .then((res)=>{
                return res as ServiceRegion;
            })
    }
}
