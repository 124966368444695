<div>
    <div class="header">
        <h4><i class="fa fa-map"></i>Service Regions</h4>
    </div>
    <div class="row">
        <div class="col-md-6">
            <service-region-list [serviceRegions]="serviceRegions"
                                 [selectedId]="serviceRegion.id"
                                 (resetServiceRegion)="clearServiceRegion()"
                                 (setServiceRegion)="setServiceRegion($event)"></service-region-list>
        </div>
        <div class="col-md-6">
            <service-region-single [serviceRegion]="serviceRegion"
                                [saveSuccess]="saveSuccess"
                                [saveFail]="saveFail"
                                [states]="states"
                                (resetServiceRegion)="clearServiceRegion()"
                                (saveServiceRegion)="saveServiceRegion($event)"></service-region-single>
        </div>
    </div>
</div>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>

