import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '../authorization/auth-guard.service';
import {RecentRoutesResolve, RecentClientsResolve, RecentWorkOrdersResolve, RecentLabsResolve} from './dashboard.resolver';
import {ServiceTypeResolve} from '@resolvers/service-type.resolver';
import { CurrentEmployeeResolve } from '@resolvers/employees.resolver';

const dashboardRoutes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
          view: 'dashboard'
        },
        resolve: {
            employee: CurrentEmployeeResolve,
            clients: RecentClientsResolve,
            workOrders: RecentWorkOrdersResolve,
            routes: RecentRoutesResolve,
            labs: RecentLabsResolve,
            statusTypes: ServiceTypeResolve
        }
    }
];

export const dashboardRouting = RouterModule.forChild(dashboardRoutes);
