<div *ngIf="selectedDeliveryAddress !== null">
    <div >
        <address-details [address]="address" (saveAddress)="saveAddress($event)"></address-details>
    </div>
    <div>
        <div class="row margin-top-20">
            <div class="col-sm-6 col-md-6 col-lg-12 col-xl-3">
               <address-notes [notes]="notes"
                              [deliveryInstructions]="address.deliveryInstructions"
                              (showNotesPanel)="togglePanel('notes')"
                              [open]="showNotesPanel"></address-notes>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <address-tanks [tanks]="address.Tanks"
                               (showTanksPanel)="togglePanel('tanks')"
                               [open]="showTanksPanel"
                               ></address-tanks>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <address-inventory [drops]="address.deliveryOrders"
                                   (showDropsPanel)="togglePanel('drops')"
                                   [open]="showDropsPanel"
                                   ></address-inventory>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <address-work-orders [workOrders]="workOrders"
                                     (showWorkOrdersPanel)="togglePanel('work-orders')"
                                     [open]="showWorkOrdersPanel"
                                     ></address-work-orders>
            </div>
        </div>
    </div>
    <div>
        <address-labwork [address]="address"
                         [addressId]="address.id"
                         [labWorks]="address.Labs"></address-labwork>
    </div>

    <div id="vapor-testing">
       <address-vapor-test
               [address]="address"
               [vaporTests]="vaporTests"
               (getVapTests)="getVapTests($event)"
               [addressId]="address.id" ></address-vapor-test>
    </div>

    <div id="document-component">
        <address-documents [address]="address"></address-documents>
    </div>

    <div id="notes">
        <notes-panel [notes]="notes"
                     id="notesPanel notes"
                     *ngIf="showNotesPanel"
                     (hidePanel)="togglePanel('notes')"
                     (saveNote)="saveAddressNote($event)"></notes-panel>
    </div>

    <div id="tanks">
        <tanks-panel [tanks]="address.Tanks"
                     id="tanks"
                     [clientsDairies]="clientsDairies"
                     [tankTypes]="tankTypes"
                     [products]="products"
                     *ngIf="showTanksPanel"
                     (hidePanel)="togglePanel('tanks')"
                     (saveTank)="saveTank($event)"
                     (moveTank)="moveTank($event)"
                     (overrideTankLevel)="overrideTankLevel($event)"></tanks-panel>
    </div>

    <div id="drops">
        <drops-panel [tanks]="address.Tanks"
                     [drops]="address.deliveryOrders"
                     id="drops"
                     *ngIf="showDropsPanel"
                     (hidePanel)="togglePanel('drops')"
                     (saveOverride)="saveOverride($event)"
                     (deleteOverride)="deleteOverride($event)"
                     ></drops-panel>
    </div>

    <div id="work-orders">
        <work-orders-panel [workOrders]="workOrders"
                           [workOrder]="workOrder"
                           [serviceTypes]="serviceTypes"
                           [tanks]="address.Tanks"
                           [employees]="employees"
                           [employeeOptions]="employeeOptions"
                           *ngIf="showWorkOrdersPanel"
                           (hidePanel)="togglePanel('work-orders')"
                           (createNote)="createNote($event)"
                           (deleteWorkOrder)="deleteWorkOrder($event)"
                           (saveWorkOrder)="saveWorkOrder($event)"></work-orders-panel>
    </div>

</div>


<div class="loading-overlay" *ngIf="ftp">
    <img src="../../../../assets/images/specialtylogo.png"/>
    <i class="fa fa-spinner fa-spin fa-2x"></i>

    <h4>Loading ...</h4>
</div>
