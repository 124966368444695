import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import * as moment from "moment";

import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  skip,
  takeUntil,
} from "rxjs/operators";

import { RouteService } from "@services/route.service";
import { Route } from "@models/route";
import { Employee } from "@models/employee";

import { AuthService } from "../../authorization/auth.service";
import { PromptModalComponent } from "@shared/prompt-modal/prompt-modal.component";
import { ToastService } from "@services/toast.service";

import { UtilsService } from "@services/utils.service";
import { State } from "src/app/models/state";

// column properties for table
interface IColumn {
  width: string;
  sort: string;
  prop: string;
  displayText: string;
  ShowOrHide?: boolean;
}

@Component({
  selector: "route-list",
  templateUrl: "route-list.component.html",
  styleUrls: ["route-list.component.css"],
})
export class RouteListComponent implements OnInit, OnDestroy {
  @ViewChild("datePicker", { static: true })
  private datePicker: PromptModalComponent;
  @ViewChild("routeDeleteModal", { static: true })
  private routeDeleteModal: PromptModalComponent;

  // list of data returning from the api
  currentRoutes: Route[] = [];

  drivers: Employee[] = [];
  driverOptions: any = [];
  selectedDrivers: number[] = [];
  states: State[] = [];
  statesSelect: any = [];
  selectedState: any = [];
  userSelectedStates: string = ""; // the selected states the use have chosen
  userSelectedDrivers: string = "";
  tablet: boolean;
  mobile: boolean;
  dateType = "";
  searching = false;
  searchRoutes = "";
  routeToDelete: Route;
  workOrder = false;
  isAdmin = this.auth.isAdmin();
  localFromKey = "fromDate";
  localToKey = "toDate";
  isOffice = this.isAdmin || this.auth.employeeType(["Office"]);
  submitting = false;
  sortColumn = "dateOfRoute"; // default value of sortColumn
  currentPage = 1; // this is the current page of the data
  itemsPerPage = 75; // items the user wants to show per page
  itemsDataSource = 0; // the data amount that is total in the database
  // setting the default date of the date the user visits the routes or work order routes page
  query = this.getDefaultDate();
  maxSize = 5;
  rotate = true;
  selectedColumn: string = null; // selected column
  selectedSort = "desc"; // select sort and default to desc

  public searchRoutesModelChanged: Subject<string> = new Subject<string>();

  // setting the table headers
  headers: Array<IColumn> = [
    {
      width: "td-10",
      sort: "desc",
      prop: "dateOfRoute",
      displayText: "Scheduled Date",
    },
    {
      width: "td-20",
      sort: "asc",
      prop: "routeName",
      displayText: "Route Name",
    },
    {
      width: "td-10",
      sort: "asc",
      prop: "getNumberOfDrops",
      displayText: "# Of Drops",
    },
    {
      width: "td-10",
      sort: "asc",
      prop: "workOrders",
      displayText: "# Of Work Orders",
    },
    {
      width: "td-15",
      sort: "asc",
      prop: "EmployeeRoutes",
      displayText: "Drivers",
    },
    {
      width: "td-10",
      sort: "asc",
      prop: "trailerId",
      displayText: "Trailer",
    },
    {
      width: "td-10",
      sort: "asc",
      prop: "status",
      displayText: "Status",
    },
    {
      width: "td-10",
      sort: "asc",
      prop: "billingComplete",
      displayText: "Billing Complete",
    },
  ];
  destroyed$ = new Subject();

  constructor(
    private router: Router,
    private auth: AuthService,
    private routeService: RouteService,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    const { data, queryParams } = this.activatedRoute.snapshot;
    this.drivers = data.drivers;
    this.workOrder = data.workOrder;
    this.states = data.states;

    this.userSelectedDrivers =
      localStorage.getItem("userSelectedDrivers") || "";

    this.selectedDrivers = (
      queryParams.selectedDrivers ||
      this.userSelectedDrivers ||
      ""
    )
      .split(",")
      .filter((driver) => driver)
      .map((driver) => +driver);

    localStorage.removeItem("userSelectedDrivers");
    if (this.selectedDrivers.length > 0) {
      localStorage.setItem(
        "userSelectedDrivers",
        this.selectedDrivers.join(",")
      );
    }

    this.userSelectedStates = localStorage.getItem("userSelectedStates");

    this.selectedState = (
      queryParams.selectedStates ||
      this.userSelectedStates ||
      ""
    )
      .split(",")
      .filter((state: string) => state);

    localStorage.removeItem("userSelectedStates");
    if (this.selectedState.length > 0) {
      localStorage.setItem("userSelectedStates", this.selectedState.join(","));
    }

    this.driverOptions = this.drivers.map((driver) => {
      return {
        id: driver.id,
        name: `${driver.lastName}, ${driver.firstName}`,
      };
    });

    this.setMobile();

    // if statement is meant to know what page is being viewed because this component is being used in two different pages.
    if (this.workOrder) {
      this.query = this.getDefaultDate();
      this.localFromKey = "workOrder" + this.localFromKey;
      this.localToKey = "workOrder" + this.localToKey;
    } else {
      this.query.fromDate =
        localStorage.getItem(this.localFromKey) !== null
          ? localStorage.getItem(this.localFromKey)
          : moment().subtract(4, "days").format("YYYY-MM-DD");
      this.query.toDate =
        localStorage.getItem(this.localToKey) !== null
          ? localStorage.getItem(this.localToKey)
          : moment().add(7, "days").format("YYYY-MM-DD");
      this.getListOfStates();
    }

    this.searchRoutesModelChanged
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
      )
      .subscribe((searchResult) => {
        this.userSelectedStates = "";
        localStorage.removeItem("userSelectedStates");
        this.selectedState = [];
        this.searchRoutes = searchResult;
        this.updateQuery(true);
      });

    // have to set the correct boolean based on workOrder and isOffice values
    this.headers = this.headers.map((header) => {
      const sortHeader = header.prop.toString();
      if (sortHeader === "trailerId" || sortHeader === "getNumberOfDrops") {
        header.ShowOrHide = !this.workOrder;
      } else if (sortHeader === "workOrders") {
        header.ShowOrHide = this.workOrder;
      } else if (sortHeader === "billingComplete") {
        header.ShowOrHide = this.isOffice;
      } else {
        header.ShowOrHide = true;
      }
      return header;
    });

    this.subscribeToParams();
  }

  subscribeToParams() {
    const { data } = this.activatedRoute.snapshot;
    this.itemsDataSource = data.routeList.count;
    this.currentRoutes = data.routeList.rows;

    this.activatedRoute.queryParams
      .pipe(skip(1), takeUntil(this.destroyed$))
      .subscribe((queryParams) => {
        const newQuery = { ...queryParams };

        newQuery.selectedStates = (
          queryParams.selectedStates ||
          this.userSelectedStates ||
          ""
        )
          .split(",")
          .filter((state) => state);

        newQuery.selectedDrivers = (
          queryParams.selectedDrivers || this.userSelectedDrivers
        )
          .split(",")
          .filter((driver) => driver)
          .map((driver) => +driver);

        this.selectedDrivers = newQuery.selectedDrivers;

        const params = Object.assign(
          {},
          _.omitBy(
            {
              fromDate: this.query.fromDate,
              toDate: this.query.toDate,
              selectedStates: this.userSelectedStates,
              column: this.selectedColumn,
              sort: this.selectedSort,
              page: this.currentPage,
              itemsPerPage: this.itemsPerPage,
              search: this.searchRoutes,
              selectedDrivers: this.userSelectedDrivers,
            },
            (value) => [null, ""].includes(value)
          ),
          newQuery
        );
        this.search(params);
      });
  }

  // belongs to work order routes page
  getDefaultDate() {
    const queryParams = {
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().add(30, "days").format("YYYY-MM-DD"),
      itemsPerPage: this.itemsPerPage,
      page: this.currentPage,
      sort: this.selectedSort,
    };

    return queryParams;
  }

  // getting the list of states
  getListOfStates() {
    this.states.map((state) => {
      if (["CA", "TX", "ID", "WI", "WA", "NY"].includes(state.StatesAbbr)) {
        this.statesSelect.push({ id: state.id, text: state.StatesAbbr });
      }
    });
  }

  // Select Location button function
  selectedStates(selectedStates: any) {
    // setting list of states for database to research through
    const searchStates = [];
    selectedStates.map((state) => {
      searchStates.push(state);
    });
    this.userSelectedStates = "";
    localStorage.removeItem("userSelectedStates");
    if (selectedStates.length > 0) {
      this.userSelectedStates = searchStates.join(",");
      localStorage.setItem("userSelectedStates", this.userSelectedStates);
    }
    this.updateQuery(true);
  }

  updateQuery(resetPage: boolean) {
    // if not work orders routes page
    if (!this.workOrder) {
      // store user input
      localStorage.setItem(this.localFromKey, this.query.fromDate);
      localStorage.setItem(this.localToKey, this.query.toDate);
    }
    // reset pagination page based on the user selection
    if (resetPage) {
      this.currentPage = 1;
    }

    const queryParams = _.omitBy(
      {
        fromDate: this.query.fromDate,
        toDate: this.query.toDate,
        selectedStates: this.userSelectedStates,
        column: this.selectedColumn,
        sort: this.selectedSort,
        page: this.currentPage,
        itemsPerPage: this.itemsPerPage,
        search: this.searchRoutes,
        selectedDrivers: this.userSelectedDrivers,
      },
      (value) => [null, ""].includes(value)
    );

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    });
  }

  // update the route
  updateRoute(route: Route): void {
    if (this.workOrder) {
      this.router.navigate(["/work-route-review", route.id]);
    } else {
      this.router.navigate(["/route-review", route.id]);
    }
  }
  // edit the user route
  editRoute(route: Route): void {
    if (this.workOrder) {
      this.router.navigate(["/edit-work-route", route.id]);
    } else {
      this.router.navigate(["/edit-route", route.id]);
    }
  }

  setDeleteRoute(route: Route) {
    this.routeToDelete = route;
    this.routeDeleteModal.showPromptModal();
  }

  deleteRoute() {
    this.routeService.delete(this.routeToDelete.id).then((res) => {
      this.currentRoutes = _.filter(this.currentRoutes, (route: Route) => {
        return route.id !== this.routeToDelete.id;
      });
      this.toastService.toast({
        message: "Route successfully deleted.",
        type: "success",
      });
    });
    this.routeDeleteModal.hidePromptModal();
  }

  search(query: any): void {
    this.searching = true;

    if (this.workOrder) {
      this.routeService.getCurrentWorkRoutes(query).subscribe((res) => {
        this.itemsDataSource = res["count"];
        this.currentRoutes = res["rows"];
        this.searching = false;
      });
    } else {
      // this is the main function for all
      this.routeService.getCurrentRoutes(query).subscribe((res) => {
        this.itemsDataSource = res["count"];
        this.currentRoutes = res["rows"];
        this.searching = false;
      });
    }
  }

  setMobile() {
    this.tablet = window.innerWidth < 1450 ? true : false;
    this.mobile = window.innerWidth < 1102 ? true : false;
  }

  setEditDate(dateType: string) {
    this.dateType = dateType;
    this.datePicker.showPromptModal();
  }

  resetDate() {
    this.dateType = "";
  }
  // this is for the date default button when the user clicks on
  setDefaultDate() {
    this.query = this.workOrder
      ? this.getDefaultDate()
      : this.setRoutesDefaultDate();
    this.searchRoutes = "";

    this.search(this.query);
  }

  setRoutesDefaultDate() {
    localStorage.setItem(
      this.localFromKey,
      moment().subtract(4, "days").format("YYYY-MM-DD")
    );
    localStorage.setItem(
      this.localToKey,
      moment().add(7, "days").format("YYYY-MM-DD")
    );
    return {
      fromDate: moment().subtract(4, "days").format("YYYY-MM-DD"),
      toDate: moment().add(7, "days").format("YYYY-MM-DD"),
      itemsPerPage: this.itemsPerPage,
      page: this.currentPage,
      sort: this.selectedSort,
    };
  }

  routeDrivers(route: Route) {
    if (route.EmployeeRoutes) {
      return route.EmployeeRoutes.map(
        (er: any) =>
          ` ${er.Employee ? er.Employee.firstName : ""} ${
            er.Employee ? er.Employee.lastName : ""
          }`
      );
    }
    return "--";
  }

  activeStatus(completed: any, scheduled: any) {
    return this.utilsService.activeStatus(completed, scheduled);
  }

  toggleBillingComplete($event: Event, route: any) {
    this.submitting = true;
    this.routeService
      .updateRoute(route)
      .then(() => {
        this.submitting = false;
      })
      .catch(() => {
        this.submitting = false;
      });
  }

  // sort data in table some values are filter in the front end. Meaning that not all values are displayed
  // in the object that is being sent from the server.
  sort(column: IColumn): Array<any> {
    // If we want to reset all other columns back to asc, else omit and just toggle it.sort
    this.headers = this.headers.map((it: IColumn) => {
      it.sort = it !== column ? "asc" : it.sort === "desc" ? "asc" : "desc";
      return it;
    });

    this.sortColumn = column.prop;

    this.selectedColumn = column.prop;
    this.selectedSort = column.sort;

    this.updateQuery(false);

    return;
  }

  // code for pagination
  pageChanged(event) {
    this.currentPage = event.page;
    this.updateQuery(false);
  }

  switchDates(dates) {
    this.searchRoutes = "";

    this.query = {
      fromDate: moment(new Date(dates.fromDate)).format("YYYY-MM-DD"),
      toDate: moment(new Date(dates.toDate)).format("YYYY-MM-DD"),
      itemsPerPage: this.itemsPerPage,
      page: this.currentPage,
      sort: this.selectedSort,
    };

    this.updateQuery(true);
  }

  selectedDriver(selectedDrivers: any) {
    const searchDrivers = [];
    selectedDrivers.map((state) => {
      searchDrivers.push(state);
    });
    localStorage.removeItem("userSelectedDrivers");
    this.userSelectedDrivers = "";
    if (searchDrivers.length > 0) {
      this.userSelectedDrivers = searchDrivers.join(",");
      localStorage.setItem("userSelectedDrivers", this.userSelectedDrivers);
    }

    this.updateQuery(true);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
