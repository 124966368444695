import { Injectable } from '@angular/core';
import { Address } from '@models/address';
import { Note } from '@models/note';

import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()

export class AddressService {
  private apiUrl = environment.apiUrl;

  constructor(public authHttp: HttpClient) { }

  getAddress(id: number): Promise<Address> {

    return this.authHttp.get(`${this.apiUrl}address/${id}`)
      .toPromise()
      .then((response) => {
        return response as Address;
      })
      .catch(this.handleError);
  }

  getAddressses(): Promise<Address[]> {
    return this.authHttp.get(`${this.apiUrl}address/`)
      .toPromise()
      .then((response) => response as Address[])
      .catch(this.handleError);
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  saveAddress(address: Address): Promise<Address> {
    if (address.id) {
      return this.updateAddress(address);
    } else {
      return this.createAddress(address);
    }
  }

  createAddress(address: Address): Promise<Address> {
    return this.authHttp.post(`${this.apiUrl}address/`, address)
      .toPromise()
      .then((res) => {
        return res as Address;
      }).catch();
  }

  updateAddress(address: Address): Promise<Address> {
    return this.authHttp.put(`${this.apiUrl}address/${address.id}`, address)
      .toPromise()
      .then((res) => {
        return res as Address;
      }).catch();
  }

  deleteAddress(address: Address): Promise<Address> {
    return this.authHttp.delete(`${this.apiUrl}address/${address.id}`)
      .toPromise()
      .then((res) => {
        return res as Address;
      }).catch();
  }

  getUniqueCounties(): Promise<any[]> {
    return this.authHttp.get(`${this.apiUrl}address/counties`)
      .toPromise()
      .then((res) => {
        return res as any[];
      });
  }

  getUniqueCities(): Promise<any[]> {
    return this.authHttp.get(`${this.apiUrl}address/cities`)
      .toPromise()
      .then((res) => {
        return res as any[];
      });
  }

  getUniqueStates(): Promise<any[]> {
    return this.authHttp.get(`${this.apiUrl}address/states`)
      .toPromise()
      .then((res) => {
        return res as any[];
      });
  }

  getUniqueCountiesByState(state: string): Promise<any[]> {
    return this.authHttp.get(`${this.apiUrl}address/${state}/counties`)
      .toPromise()
      .then((res) => {
        return res as any[];
      });
  }

  getDeliveryOrdersForAddress(id: number): Promise<any[]> {
    return this.authHttp.post(`${this.apiUrl}delivery-order/delivery-orders`, { addressId: id })
      .toPromise()
      .then((res: any) => {
        return res as any[];
      });
  }

  getDeliveryAddresses(clientId: number): Observable<Address[]> {
    return this.authHttp.post(`${this.apiUrl}address/getDeliveryAddresses`, { clientId: clientId })
      .pipe(map((res: any) => res as Address[]));
  }

  getMailingAddresses(clientId: number): Observable<Address[]> {
    return this.authHttp.post(`${this.apiUrl}address/getMailingAddresses`, { clientId: clientId })
      .pipe(map((res: any) => res as Address[]));
  }

  getAddressNotes(addressId: number): Promise<Note[]> {
    return this.authHttp.get(`${this.apiUrl}note/notes/${addressId}`)
      .toPromise()
      .then((res: any) => {
        return res as Note[];
      });
  }

  getInactiveAddresses(): Promise<Address[]> {
    return this.authHttp.post(`${this.apiUrl}address/inactive`, {})
      .toPromise()
      .then((res) => {
        return res as Address[];
      });
  }
}
