import {Injectable} from '@angular/core';
import * as jsPDF from 'jspdf'

@Injectable()
export class S3JSPdfService{
    constructor(){}

    createNewPdf() {
        return new jsPDF('p', 'mm', 'letter');
    }
}
