import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {ClientService} from '../../../services-module/services/client.service';
import {Client} from '../../../models/client';

@Component({
    templateUrl: './inactive-client.component.html',
    styleUrls:['inactive-client.component.css'],
})
export class InactiveClientPanelComponent implements OnInit{
    clients: Client[];
    ftp:boolean = false;
    search: '';
    constructor(
        private clientService: ClientService
    ){}

    ngOnInit(): void{
        this.getInactiveClients();
    }

    getInactiveClients(): void{
        this.clientService.getInactiveClients()
            .then((response)=>{
                this.clients = response;
            });
    }

    changeClientStatus(client: Client): void{
       client.isActive = !client.isActive;
        this.ftp = true;
        this.clientService.updateClient(client)
            .then((res)=>{
                this.ftp = false;

            });
    }

    deleteClient(client: Client) {
        return () => {
            this.ftp = true;
            this.clientService.deleteClient(client)
                .then((res)=>{
                    this.ftp = false;
                    this.clients.splice(this.clients.findIndex(item => {
                        return item.id === client.id;
                    }), 1);
                });
        }
    }

    changeIsClientStatus(client: Client): void {
        client.isClient = !client.isClient;
        this.ftp = true;
        this.clientService.updateClient(client)
            .then((res)=>{
                this.ftp = false;
            });
    }
}
