<div *ngIf="!editMode" (dblclick)="enableEdit(flexModel)"
  placement="top" container="body">
  <div *ngIf="inputType !== 'select' && flexModel.inputType !== 'textarea' && inputType !== 'color'">
    <span>{{ flexModel.value == '' || flexModel.value == null ? flexModel.value != 0 && flexModel.value == false ? "No" : '-' : flexModel.value === true ? "Yes" : flexModel.pipe == "currency" ? (flexModel.value | currency:'USD':'symbol':'1.2-2') : flexModel.pipe == "date" ? ( flexModel.value | date:'MM/DD/YYYY' ) : flexModel.value}} </span>
  </div>
  <div *ngIf="flexModel.inputType === 'textarea'">
    <span class="wrap">{{ flexModel.value == '' || flexModel.value == null ? '-' : flexModel.value.length > 240 ? flexModel.value.substring(0, 240) + '...' : flexModel.value }}</span>
  </div>
  <div *ngIf="inputType === 'select'">
    <span>{{ selectedValue && selectedValue[flexModel.displayProp] ? selectedValue[flexModel.displayProp] : '' }}</span>
  </div>
  <div *ngIf="inputType === 'color'">
    <span class="flex-color" [style.background]="flexModel.value"></span><span> {{ flexModel.value || '-' }}</span>
  </div>  
</div>
<div *ngIf="editMode">
  <div [ngSwitch]="inputType">
    <div *ngSwitchCase="'select'">
      <div class="form-group">
        <select class="form-control" #flexInput (blur)="onSelectBlur(flexModel);" [(ngModel)]="selectedValue" (change)="onItemSelected(flexModel, selectedValue)">
            <option *ngFor="let item of flexModel.value"
                    [ngValue]="item"
                    [selected]="item.id == selectedValue.id">{{item[flexModel.displayProp]}}</option>
        </select>
      </div>
    </div>
    <div *ngSwitchCase="'text'">
      <div class="form-group">
        <input #flexInput class="form-control" (keyup)="validate()" (focus)="onFocus($event)" (focusout)="onFocusOut(flexModel)" type="text"
          name="c.data" [(ngModel)]="flexModel.value">
      </div>
    </div>
    <div *ngSwitchCase ="'textgroup'">
      <div class="form-group"></div>
    </div>
    <div *ngSwitchCase="'date'">
      <div class="form-group">
        <input #flexInput (focus)="onFocus($event)" (focusout)="onFocusOut(flexModel)" (blur)="onCalendarBlur(flexModel)" type="date"
          [(ngModel)]="flexModel.value" max="2100-12-31" name="c.data" required aria-required="true" [attr.min]="flexModel.min"
          class="form-control">
      </div>
    </div>
    <div *ngSwitchCase="'color'">
        <div class="input-group color" [style.borderColor]="flexModel.value && flexModel.value !== '#ffffff' ? flexModel.value : '#000'">
          <input #flexInput class="form-control" (focus)="onFocus($event)" (focusout)="onFocusOut(flexModel)" type="text" [cpPosition]="'left'"
                 name="c.data" [(colorPicker)]="flexModel.value" (colorPickerChange)="validate()" (colorPickerClose)="closeColor(flexModel)" [(ngModel)]="flexModel.value" readonly>
          <div class="input-group-addon" [style.background]="flexModel.value">
            <!-- <i class="fa fa-search" aria-hidden="true"></i> -->
          </div>      
        </div>
      </div>  
    <div *ngSwitchDefault>
      <div class="form-group">
        <input #flexInput class="form-control" (focus)="onFocus($event)" (focusout)="onFocusOut(flexModel)" type="text"
          name="c.data" [(ngModel)]="flexModel.value">
      </div>
    </div>
  </div>
</div>
<textarea-modal #modal="textareaModal" (noteSaved)="updateTextarea($event)"></textarea-modal>
