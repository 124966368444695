import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { S3MapModule } from '../s3-gmap/s3-map.module';

import { RouteSheetsService } from './route-sheets.service';

import { ClientRoutesRouting } from './client-routes.routing';

import { ClientLocationsComponent } from './components/client-locations/client-locations.component';
import { RouteDetailsComponent } from './components/route-details/route-details.component';
import { RouteListComponent } from './route-list/route-list.component';
import { RouteOptionsComponent } from './components/route-options/route-options.component';
import { RouteOverviewComponent } from './components/route-overview/route-overview.component';
import { WorkRouteSingleComponent } from './work-route-single/work-route-single.component';
import { RouteViewComponent } from './route-view/route-view.component';
import { WorkOrderPrintComponent } from './work-order-print/work-order-print.component';
import { PipesModule } from '../pipes/pipes.module';
import { SharedComponentsModule } from '@shared/shared-components.module';

import { ClickOutside } from '../directives/click-outside.directive';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SortablejsModule } from 'ngx-sortablejs';

@NgModule({
  imports: [
    CommonModule,
    ClientRoutesRouting,
    FormsModule,
    S3MapModule,
    PipesModule,
    DatepickerModule.forRoot(),
    SharedComponentsModule,
    SortablejsModule,
    NgSelectModule,
    PaginationModule.forRoot()
  ],
  exports: [WorkRouteSingleComponent],
  declarations: [
    ClientLocationsComponent,
    RouteDetailsComponent,
    RouteListComponent,
    ClickOutside,
    RouteOptionsComponent,
    RouteOverviewComponent,
    WorkRouteSingleComponent,
    RouteViewComponent,
    WorkOrderPrintComponent,
  ],
  providers: [RouteSheetsService, PipesModule]

})

export class ClientRoutesModule { }
