import { Routes, RouterModule } from '@angular/router';
import { WorkOrdersComponent } from './work-orders.component';
import { AuthGuard } from '../authorization/auth-guard.service';
import { EmployeesResolve } from '../resolvers/employees.resolver';
import { SingleWorkOrderComponent } from './single-work-order/single-work-order.component';
import { ServiceTypeResolve } from '@resolvers/service-type.resolver';
import { AddWorkOrderComponent } from './add-work-order/add-work-order.component';
import { ClientsResolve } from '@resolvers/client.resolver';
import { WorkOrdersResolve } from '@resolvers/unscheduled-work-orders.resolver';


const workOrdersRoutes: Routes = [
  {
    path: 'work-orders',
    component: WorkOrdersComponent,
    canActivate: [AuthGuard],
    data: {
      view: 'work-orders'
    },
    resolve: {
      workOrdersPagination: WorkOrdersResolve,
      serviceTypes: ServiceTypeResolve
    }
  },
  {
    path: 'add-work-order',
    component: AddWorkOrderComponent,
    canActivate: [AuthGuard],
    data: {
      view: 'add-work-order'
    },
    resolve: {
      serviceTypes: ServiceTypeResolve,
      clients: ClientsResolve,
      employees: EmployeesResolve
    }
  },
  {
    path: 'work-orders/:id',
    component: SingleWorkOrderComponent,
    canActivate: [AuthGuard],
    data: {
      view: 'work-order'
    },
    resolve: {
      serviceTypes: ServiceTypeResolve,
      employees: EmployeesResolve
    }
  }
];

export const workOrdersRouting = RouterModule.forChild(workOrdersRoutes);
