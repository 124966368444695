import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { HttpService } from '../../../shared-services/http-service/http-service.service';

@Injectable()
export class SavedReportsResolver implements Resolve<any[]> {
    constructor (private http: HttpService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.http.post('savedReport/get', {
            "get": ["*"],
            "join": [{
                "table": "Type",
                "joinType": "left outer join",
                "get": ["id", "type_name"],
                "where": {
                    "active": true
                },
                "on": [{
                    "Type": "id",
                    "SavedReport": "report_type"
                }],
                "as": "type"
            }],
            "where": {
                "active": true
            },
            "sort": {
                "report_name": "ASC"
            }
        })
            .toPromise()
            .then((res) => {
                return res && res.data && res.data.length > 0 ? res.data : [];
            });
    }
}