<div class="view-name padding-bottom-20" *ngIf="!submitted">
    <h6>Enter your email to begin resetting password</h6>
</div>
<form *ngIf="!submitted">
    <div class="form-group">
        <label for="email">Email</label>
        <input id="email"
               name="email"
               [(ngModel)]="creds.email"
               type="text"
               required
               #newEmail="ngModel"
               placeholder="you@email.com"
               class="form-control">
        <div *ngIf="newEmail.errors && (newEmail.dirty || newEmail.touched)"
             class="alert alert-danger">
            <div [hidden]="!newEmail.errors.required">
                Email is required
            </div>
        </div>
    </div>
    <div class="post-form">
        <div (click)="newPassword(creds)" class="btn btn-primary">
            Submit
        </div>
        <div (click)="cancel()" class="btn btn-danger">
            Cancel
        </div>
    </div>
</form>

<div class="successful-password" *ngIf="submitted">
    <div class="padding-top-20">
        <h4>{{creds.contentHeading}}</h4>
        <div>{{creds.content}}</div>
    </div>
    <div class="padding-top-20" *ngIf="creds.success">
        <div class="btn btn-primary" (click)="return()">return</div>
    </div>
    <div class="padding-top-20" *ngIf="!creds.success">
        <div class="btn btn-warning" (click)="tryAgain()">Try Again</div>
    </div>
</div>