import {Component, Input, Output, EventEmitter} from "@angular/core";
import {Client} from "../../../models/client";
@Component({
    selector:'group-to-dairy',
    templateUrl:'group-to-dairy.component.html',
    styleUrls:['../client-group-management.component.css', './group-to-dairy.component.css']
})

export class GroupToDairyComponent{
    @Input() clientGroup:Client;
    @Input() groupToGroup:Client;
    @Input() dairies:any[];
    @Input() defaultImage:string;
    @Output() setGroup = new EventEmitter<any>();
    @Output() setToGroup = new EventEmitter<any>();
    @Output() pushDairiesToGroup = new EventEmitter<any>();
    @Output() removeDairies = new EventEmitter<any>();
    @Output() cancelActions = new EventEmitter<any>();
    @Output() showModal = new EventEmitter<any>();

    constructor(){}

    groupSet(group:any) {
        this.setGroup.emit(group);
    }

    toGroupSet(group:any) {
        this.setToGroup.emit(group);
    }

    setBG(path:string){
        return "url(" + path + ")";
    }

    pushToGroup(dairy:any){
        this.pushDairiesToGroup.emit(dairy);
    }

    remove(){
        this.removeDairies.emit();
    }

    cancel(dairy:any){
        this.cancelActions.emit();
    }
    save(dairy:any){
        this.showModal.emit();
    }

}