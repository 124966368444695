import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LabType } from '@models/labType';
import { Lab } from '@models/lab';
import { environment } from '@environment';
import * as moment from 'moment';

@Injectable()
export class LabService {
  private apiUrl = environment.apiUrl;

  constructor(
    private authHttp: HttpClient
  ) { }

  getLabTypes(): Promise<LabType[]> {
    return this.authHttp.get(`${this.apiUrl}lab-type`)
      .toPromise()
      .then((res) => {
        return res as LabType[];
      });
  }

  getLab(labId: number): Promise<Lab> {
    return this.authHttp.get(`${this.apiUrl}lab/${labId}`)
      .toPromise()
      .then((res) => {
        return res as Lab;
      });
  }

  findLabs(params: any): Promise<{ rows: Lab[], count: number }> {
    const queryParams = {
      page: params.page ? +params.page : undefined,
      pageSize: params.pageSize ? +params.pageSize : undefined,
      fromDate: params.fromDate ? moment(params.fromDate).toDate() : moment().subtract(30, 'days').toDate(),
      toDate: params.toDate ? moment(params.toDate).toDate() : undefined,
      labTech: params.labTech ? +params.labTech : undefined,
      region: params.region ? +params.region : undefined,
      sort: params.sort ? params.sort : undefined
    };

    return this.authHttp.post(`${this.apiUrl}lab/find-labs`, queryParams)
      .toPromise()
      .then((response) => response as { rows: Lab[], count: number });
  }

  saveLabWork(lab: Lab): Promise<Lab> {
    if (lab.id) {
      return this.updateLabWork(lab);
    } else {
      return this.createLabWork(lab);
    }
  }

  createLabWork(lab: Lab): Promise<Lab> {
    return this.authHttp.post(`${this.apiUrl}lab`, lab)
      .toPromise()
      .then((res) => {
        return res as Lab;
      });
  }

  updateLabWork(lab: Lab): Promise<Lab> {
    return this.authHttp.put(`${this.apiUrl}lab/${lab.id}`, lab)
      .toPromise()
      .then((res) => {
        return res as Lab;
      });
  }

  deleteLabWork(lab: Lab): any {
    return this.authHttp.delete(`${this.apiUrl}lab/${lab.id}`)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

}
