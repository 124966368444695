import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import * as _ from 'lodash';

import { Employee } from '../../models/employee';
import { environment } from '../../../environments/environment';
import { UserService } from "./user.service";

@Injectable()

export class EmployeeService {

    private apiUrl = environment.apiUrl;  // URL to web api
    constructor(public authHttp: HttpClient, private userService: UserService) { }
    employee: Employee;

    getLanding(): Promise<string> {
      if (this.employee) {
        return new Promise((resolve) => {
          resolve(this.employee.landingUrl);
        })
      } else {
        let employeeId = this.userService.getEmployeeId();
        return this.getEmployee(employeeId).then(res => {
          this.employee = res;
        }).then(() => {
          return this.employee.landingUrl;
        });
      }
    }

    setLanding(landingUrl: string) {
      this.employee.landingUrl = landingUrl;
      return this.authHttp.put(`${this.apiUrl}employee/${this.employee.id}`, {id: this.employee.id, landingUrl: landingUrl})
        .toPromise()
        .then((res) => {
          return res as Employee;
        });
    }

    setDashboardOrder(employee: Employee) {
        return this.authHttp.put(`${this.apiUrl}employee/${employee.id}`, {id: employee.id, dashboardOrder: employee.dashboardOrder})
            .toPromise()
            .then((res) => {
                return res as Employee;
            });
    }

    getEmployees(): Promise<Employee[]> {
        return this.authHttp.get(`${this.apiUrl}employee`)
            .toPromise()
            .then((response) => {
                return response as Employee[];
            })
            .catch(this.handleError);
    }

    getEmployeeOptions(employees: Employee[]) {
        let employeeOptions = [];
        employees.forEach((emp)=>{
            if (emp.isActive) {
                let item = {
                    id: emp.id,
                    name: `${emp.lastName}, ${emp.firstName}`
                };
                employeeOptions.push(item);
            }
        });
        return employeeOptions;
    }

    getEmployee(employeeId: number): Promise<Employee> {
        return this.authHttp.get(`${this.apiUrl}employee/${employeeId}`)
            .toPromise()
            .then((response) => {
                return response as Employee;
            })
            .catch(this.handleError);
    }

    saveEmployee(employee: Employee): Promise<Employee> {
        if (employee.id) {
            return this.updateEmployee(employee);
        } else {
            return this.createEmployee(employee);
        }
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    createEmployee(employee: Employee): Promise<Employee> {
        return this.authHttp.post(`${this.apiUrl}employee`, employee)
            .toPromise()
            .then((res) => {
                return res as Employee;
            });
    }
    updateEmployee(employee: Employee): Promise<Employee> {
        this.compareEmployeeTypes(employee);
        if (employee.User && employee['role'] !== employee.User.role) {
            employee.User.role = employee['role'];
            this.authHttp.put(`${this.apiUrl}user/${employee.User.id}`, employee.User).toPromise();
        }
        return this.authHttp.put(`${this.apiUrl}employee/${employee.id}`, employee)
            .toPromise()
            .then((res) => {
                return res as Employee;
            }).then((updatedEmp) => {
                return this.getEmployee(updatedEmp.id);
            });
    }

    getEmployeesByType(type: string): Promise<Employee[]> {
        return this.authHttp.post(`${this.apiUrl}employee/getEmployeesByType`, { type: type })
            .toPromise()
            .then((res) => {
                return res as Employee[];
            });
    }

    compareEmployeeTypes(employee: Employee) {
        employee.employeeTypeIds.forEach((id) => {
            const exists = _.find(employee.EmployeeEmployeeTypes, { employeeTypeId: id });
            if (!exists) {
                this.createEmployeeEmployeeType({ employeeTypeId: id, employeeId: employee.id });
            }
        });
        employee.EmployeeEmployeeTypes.forEach((empEmpType) => {
            const exists = employee.employeeTypeIds.filter((id) => {
                return id === empEmpType.employeeTypeId;
            });
            if (!exists.length) {
                this.removeEmployeeEmployeeType(empEmpType);
            }
        });
    }

    createEmployeeEmployeeType(empEmpType: any) {
        return this.authHttp.post(`${this.apiUrl}employee-employee-type`, empEmpType)
            .toPromise()
            .then((res) => {
                console.log(res); // why is this being console.log here?
            });
    }

    removeEmployeeEmployeeType(empEmpType: any) {
        return this.authHttp.delete(`${this.apiUrl}employee-employee-type/${empEmpType.id}`)
            .toPromise()
            .then((res) => {
                console.log(res); // why is this being console.log here?
            });
    }

}
