import { Component, Input, Output, EventEmitter} from '@angular/core';

import {Product} from '../../../models/product';

@Component({
    templateUrl: 'product-single.component.html',
    styleUrls: ['product-single.component.css'],
    selector: 'product-single'
})
export class ProductSingleComponent{
    @Input()
    product: Product;
    @Output()
    resetProduct = new EventEmitter<string>();
    @Output()
    saveProduct = new EventEmitter<Product>();
    constructor(){}

    clearProduct(){
        this.resetProduct.emit('reset');
    }

    save(product: Product){
        this.saveProduct.emit(product);
    }


}