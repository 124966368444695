import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models/address';

@Pipe({
    name : 'clientSearchPipe',
    pure: false
})
export class ClientSearchPipe implements PipeTransform {
    public transform(clients:any, term: string) {
        let regExp = new RegExp('\\b' + term, 'i');

        return clients.filter((client:any) => {
            let result = false;
            if (term) {
                if (client.hasOwnProperty('name')) {
                    if (regExp.test(client.name)) {
                        return true;
                    }

                    //Checks for a client to have an address
                    if(client.Addresses){
                        client.Addresses.forEach((address:Address) => {
                            if (regExp.test(address.alias)) {
                                result = true;
                            }
                        });
                    }
                }
            } else {
                return true;
            }
            return result;
        });
    }
}
