export class FlexModel {
  id: number & { belongsToID: number };
  belongsToID: number;
  index: number;
  inputType: string;
  pipe: string;
  key: string;
  value: string;
  selectedValue: string;

  constructor(flexModel: Partial<FlexModel> = {}) {
    this.id = flexModel.id;
    this.belongsToID = flexModel.belongsToID;
    this.index = flexModel.index;
    this.inputType = flexModel.inputType;
    this.pipe = flexModel.pipe;
    this.key = flexModel.key;
    this.value = flexModel.value;
    this.selectedValue = flexModel.selectedValue;
  }
}