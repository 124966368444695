import { Component, Input, OnInit } from '@angular/core';

import { PdfService } from "../../services-module/services/pdf.service";
import { Address } from "../../models/address";
import { Client } from "../../clients/client";
import { ClientContact } from "../../models/contact";

const FILENAMES = {
    1: 'Installation_Agreement.pdf',
    3: 'Credit_Application.pdf',
    4: 'Exception_Certificate.pdf',
};

@Component({
    templateUrl: './pdfs-panel.component.html',
    styleUrls: ['./pdfs-panel.component.css'],
    selector: 'pdfs-panel'
})
export class PdfsPanelComponent implements OnInit{
    @Input() deliveryAddress: Address;
    @Input() address: Address;
    @Input() client: Client;
    @Input() contact: ClientContact;
    showDocs = false;

    constructor(private pdfService: PdfService){
    }

    ngOnInit(){
    }

    toggleDocs(){
        this.showDocs = !this.showDocs;
    }

    download(id: number, fill: boolean) {
        let fields = {id: id} as any;
        if (fill) {
            if (id === 1) {
                fields = {
                    id: id,
                    "Dairy": this.client.name,
                };
            } else if (id === 3) {
                fields = {
                    id: id,
                    'Company name': this.client.name,
                };

                if (this.address) {
                    fields['Mailing address'] = this.address.street;
                    fields['City'] = this.address.city;
                    fields['ZIP Code'] = this.address.zip;
                    fields['State'] = this.address.state;
                }

                if (this.deliveryAddress) {
                    fields['Physical Dairy Address'] = this.deliveryAddress.street;
                    fields['City_2'] = this.deliveryAddress.city;
                    fields['ZIP Code_2'] = this.deliveryAddress.state;
                    fields['State_2'] = this.deliveryAddress.state;
                }

                if (this.contact) {
                    fields['Email Invoices  Statements'] = this.contact.email;
                    fields['Office Contact'] = this.contact.name;

                    this.contact.PhoneNumbers.forEach(number => {
                        if (number.type === 'Fax') {
                            fields['Fax'] = number.number;
                        }
                        else if (number.type === 'Phone') {
                            fields['Phone'] = number.number;
                        }
                        else if (number.type === 'Cell' && !fields['Phone']) {
                            fields['Cell'] = number.number;
                        }
                    });
                }
            } else if (id === 4) {
                fields = {
                    id: id,
                    "Account Name": this.client.name
                };
                if (this.address) {
                    fields["Address"] = `${this.address.street} ${this.address.city}, ${this.address.state} ${this.address.zip}`;
                }
            }
        }

        this.pdfService.createPdf(fields, FILENAMES[id]);
    }
}
