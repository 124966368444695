<div class="margin-top-20 map-container">
    <div class="row">
        <div class="col-sm-12">
            <h3 class="map-title">Map</h3>
            <div class="pull-right no-print">
                <button class="btn route-btn btn-success"
                        (click)="getRoute()"
                        [disabled]="route.locations.length === 0 || route.startLocationId === null || route.endLocationId === null">Route</button>
                <button class="btn route-btn btn-primary"
                        (click)="getOptimizedRoute()"
                        [disabled]="route.locations.length === 0 || route.startLocationId === null || route.endLocationId === null">Optimize Route</button>
                <button class="btn-warning route-btn btn"
                        (click)="reverseRoute()"
                        [disabled]="route.locations.length === 0 || route.startLocationId === null || route.endLocationId === null">Reverse Route</button>
                <button class="btn btn-light route-btn"
                        (click)="print()"
                        [disabled]="route.locations.length === 0 || route.startLocationId === null || route.endLocationId === null"><i class="fa fa-print" aria-hidden="true"></i> Print</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-9 no-pad-right page-break">
            <div class="panel map-wrapper no-print">
                <div [id]="targetId" class="map"></div>
            </div>
            <div class="print-map-container">
                <div class="panel print-map-wrapper">
                    <div [id]="targetId + '-print'" class="map" style="position: absolute; top: 0; bottom: 0; right: 0; left: 0;"></div>
                </div>
            </div>
        </div>
        <div class="col-md-3 no-pad-left no-print">
            <div id="directions-panel" class="panel directions-wrapper"></div>
        </div>
        <div class="col-12 print-map-container">
            <div id="directions-panel-print" class="panel directions-wrapper"></div>
        </div>
    </div>
</div>
