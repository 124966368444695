import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ResizeService {
    constructor() { }

    resizePanel(resizable: string, dragbar: string) {
        const p = document.getElementById(resizable);
        const dragElem = document.getElementById(dragbar);
        dragElem.addEventListener('mousedown', initDrag, false);

        let startX: any, startY: any, startWidth: any, startHeight: any;

        function initDrag(e: any) {
            startY = e.clientY;
            startHeight = parseInt(document.defaultView.getComputedStyle(p).height, 10);
            document.documentElement.addEventListener('mousemove', doDrag, false);
            document.documentElement.addEventListener('mouseup', stopDrag, false);
        }

        function doDrag(e: any) {
            p.style.width = (startWidth + e.clientX - startX) + 'px';
            p.style.minHeight = p.style.maxHeight = p.style.height = (startHeight + e.clientY - startY) + 'px';
        }

        function stopDrag(e: any) {
            document.documentElement.removeEventListener('mousemove', doDrag, false);
            document.documentElement.removeEventListener('mouseup', stopDrag, false);
        }
    }

}
