<div id="work-orders" class="panel margin-top-20">
    <div class="panel-header">
        <div class="flex-container flex-space-between">
            <h4><i class="fa fa-file"></i> Work Orders</h4>
            <div class="work-order-btns">
                <button class="btn btn-sm btn-primary" [ngClass]="{'btn-primary':!openWorkOrder, 'btn-warning':openWorkOrder}"(click)="openWorkOrder = !openWorkOrder"><span>{{openWorkOrder ? 'Close':'Open'}}</span> Work Order</button>
                <button class="btn btn-sm btn-danger" (click)="hide()">X</button>
            </div>
        </div>
    </div>
    <div class="work-order-panel-form padding-15" *ngIf="openWorkOrder">
        <form #orderPanelForm="ngForm" class="workOrderForm">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <label for="serviceType">Service Type</label>
                        <select name="Service Type"
                                id="serviceType"
                                class="form-control"
                                [(ngModel)]="workOrder.serviceTypeId"
                                required>
                            <option *ngFor="let type of serviceTypes" [value]="type.id">{{type.serviceTypeName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <label for="assignedTo">Assigned To</label>
                        <select name="Assigned To"
                                id="assignedTo"
                                class="form-control"
                                [(ngModel)]="workOrder.employeeToId"
                                required>
                            <option *ngFor="let emp of employees | active: true" [value]="emp.id">{{emp.lastName}}, {{emp.firstName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="orderDescription">Work Order Description</label>
                        <textarea name="Work Order Description"
                                  id="orderDescription"
                                  [(ngModel)]="workOrder.note"
                                  required
                                  rows="5"
                                  class="form-control"></textarea>
                    </div>
                </div>
                <ng-container *ngIf="workOrder.id">
                    <div class="col-12 col-sm-6" *ngIf="workOrder.WorkOrderDetails">
                        <h4><i class="fa fa-pencil"></i> Service Records</h4>
                        <div class="serviceRecordBox">
                            <h5 style="font-style: italic;" *ngIf="!workOrder.WorkOrderDetails || workOrder.WorkOrderDetails.length == 0">No Notes to Display</h5>
                            <div *ngFor="let note of workOrder.WorkOrderDetails" class="serviceRecords" (click)="editNote(note)">
                                <div class="margin-bottom-5"><h5>{{setEmployee(note.employeeId)}}</h5> <div class="border-bottom">Wrote on : {{note.createdAt | date}}</div></div>
                                <div>{{note.note}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-3">
                        <h5><strong>Updated By:</strong></h5>
                        <div>{{workOrder.updatedByEmployee ? workOrder.updatedByEmployee.firstName : '--'}} {{workOrder.updatedByEmployee ? workOrder.updatedByEmployee.lastName : ''}}</div>

                    </div>
                    <div class="col-6 col-sm-3">
                        <h5><strong>Closed By:</strong></h5>
                        <div>{{workOrder.closedByEmployee ? workOrder.closedByEmployee.firstName : '--'}} {{workOrder.closedByEmployee ? workOrder.closedByEmployee.lastName : ''}}</div>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="email-options" *ngIf="!workOrder.id">
                        <strong>Email Employees </strong>
                        <ng-select [multiple]="true"
                                   id="employeeOptions"
                                   name="Employees"
                                   [(ngModel)]="workOrder.emailEmployees"
                                   placeholder="Select Employees">
                            <ng-option *ngFor="let employee of employeeOptions" [value]="employee.id">{{employee.name}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="float-right mt-4">
                        <button *ngIf="workOrder.id" class="btn btn-danger btn-sm" (click)="deleteOrder.showPromptModal();orderPanelForm.reset();">Delete</button>
                        <button class="btn btn-warning btn-sm" (click)="clearForm(); orderPanelForm.reset();">Clear</button>
                        <button *ngIf="!workOrder.id" class="btn btn-success btn-sm" (click)="save(workOrder, orderPanelForm)">Save</button>
                        <button *ngIf="workOrder.id" class="btn btn-success btn-sm" (click)="updateWorkOrder.showPromptModal(workOrder)">Save</button>
                    </div>
                </div>
            </div>
        </form>

        <!--********** Service Record **********-->
        <div *ngIf="workOrder.id" class="row">
            <div class="container-fluid">
                <div class="col-12">
                    <span class="workOrderStatus">WORK ORDER STATUS: </span>
                    <button *ngIf="workOrder.workOrderStatusId == 2" class="btn btn-sm btn-danger" (click)="changeOrderStatus.showPromptModal(workOrder)">Closed</button>
                    <button *ngIf="workOrder.workOrderStatusId == 1" class="btn btn-sm btn-success" (click)="changeOrderStatus.showPromptModal(workOrder)">Open</button>
                </div>
                <div class="col-12">
                    <div id="addressWorkOrderNote" class="">
                        <form class="form" #addWorkOrderNote="ngForm" name="workOrder">
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label for="note">*Add a Service Record</label>
                                    <textarea #serviceNote="ngModel" required name="note" id="note" class="form-control" rows="1" [(ngModel)]="workOrderDetail.note"></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <button class="btn btn-success btn-sm" (click)="addServiceRecord.showPromptModal(workOrder)" [disabled]="!addWorkOrderNote.form.valid">Add</button>
                                    <div class="btn btn-danger btn-sm" (click)="addWorkOrderNote.reset()">Clear</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!--********** End Service Record **********-->

    </div>

    <div class="panel-content mobile-panel">
        <div *ngIf="workOrders.length === 0">
            <h5>No work orders for location</h5>
        </div>
        <div>
            <table class="table table-striped" id="recent-table">
                <thead>
                    <tr>
                        <td class="col-md-1"><a href="javascript:void(0);" (click)="sortById()">Order #</a></td>
                        <td class="col-md-1"><a href="javascript:void(0);" (click)="sortByType()">Service Type</a></td>
                        <td class="col-md-2"><a href="javascript:void(0);" (click)="sortByStatus()">Status</a></td>
                        <td class="col-md-4">Work Order</td>
                        <td class="col-md-1" ><a href="javascript:void(0);" (click)="sortByEmployee()">Opened By</a></td>
                        <td class="col-md-1"><a href="javascript:void(0)" (click)="sortByEmployeeTo()">Assigned To</a></td>
                        <td class="col-md-1"><a href="javascript:void(0);" (click)="sortByOpenDate()">Opened</a></td>
                        <td class="col-md-1"><a href="javascript:void(0);" (click)="sortByClosedDate()">Closed</a></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let order of workOrders" (click)="setWorkOrder(order)">
                        <td class="col-md-1">{{ order.id }}</td>
                        <td class="col-md-1">{{ order.ServiceType?.serviceTypeName }}</td>
                        <td class="col-md-2"><span [ngClass]="woStatus(order.workOrderStatusId)">{{ order.workOrderStatusId | orderStatus }}</span></td>
                        <td class="col-md-4"><div class="td-text-hide">{{ order.note }}</div></td>
                        <td class="col-md-1">{{ order.createdBy?.firstName }} {{ order.createdBy?.lastName }}</td>
                        <td class="col-md-1">{{ order.employeeTo?.firstName }} {{ order.employeeTo?.lastName }}</td>
                        <td class="col-md-1">{{ order.createdAt | date}}</td>
                        <td class="col-md-1">{{ order.completionDate | date}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-update-work-order #addServiceRecord (onUpdate)="addNote(workOrderDetail)" [update]="true"></app-update-work-order>
<app-update-work-order #updateWorkOrder (onUpdate)="save($event, orderPanelForm)" [update]="true"></app-update-work-order>
<app-update-work-order #changeOrderStatus (onUpdate)="changeStatus($event)"></app-update-work-order>

<prompt-modal #deleteOrder>
    <div class="modal-header">
        <h4 class="modal-title">Delete Work Order</h4>
    </div>
    <div class="modal-item">
        <div class="col-12">
            <p>Are you sure you would like to delete work order #{{workOrder.id}}?</p>
        </div>
    </div>
    <div class="modal-buttons">
        <button (click)="delete(workOrder);deleteOrder.hidePromptModal()" type="submit" class="btn btn-sm btn-danger">Delete</button>
        <button type="button" class="btn btn-sm btn-light" (click)="deleteOrder.hidePromptModal()">Cancel</button>
    </div>
</prompt-modal>
