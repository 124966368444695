import {Injectable} from '@angular/core';

import * as _ from 'lodash';

@Injectable()
export class S3FilterService {
    constructor() {}
    public filterCollection(items: any[], args: any, order: boolean): any[]{
        if(order){
            return this.ascFilter(items, args);
        }else{
            return this.descFilter(items, args);
        }
    }

    ascFilter(items: any[], args: any){
        return items.sort((a, b)=>{
            if (a[args] <= b[args]) {
                return -1;
            }
            if (a[args] > b[args]) {
                return 1;
            }
        });
    }

    descFilter(items: any[], args: any){
        return items.sort((a, b)=>{
            if (a[args] >= b[args]) {
                return -1;
            }
            if (a[args] < b[args]) {
                return 1;
            }
        });
    }
}
