<div id="edit-work-order">
    <h3>Edit Work Order</h3>
    <form #orderEditForm="ngForm" class="form" *ngIf="workOrder.Address.Client" name="workOrder">
        <div class="row">
            <div class="form-group col-sm-6">
                <label for="client">*Client</label>
                <input class="form-control"
                       id="client"
                       #orderClient="ngModel"
                       name="clientId"
                       required
                       readonly
                       [(ngModel)]="workOrder.Address.Client.name">
                <div *ngIf="orderClient.errors && (orderClient.dirty || orderClient.touched)"
                     class="alert alert-danger">
                    <div [hidden]="!orderClient.errors.required">
                        Client is required
                    </div>
                </div>
            </div>
            <div class="form-group col-sm-6">
                <label for="address">*Location</label>
                <select class="form-control" id="address" [(ngModel)]="workOrder.addressId" name="address">
                    <option *ngFor="let address of deliveryAddresses" [value]="address.id" name="addressId">{{address.alias}} - {{address.street}}</option>
                </select>
            </div>
            <!--<div class="form-group col-sm-2" >-->
                <!--<label for="employee">Opened By</label>-->
                <!--<select class="form-control" id="employee" [(ngModel)]="workOrder.employeeId" name="employee">-->
                    <!--<option *ngFor="let employee of employees" [value]="employee.id">{{employee.firstName}} {{employee.lastName}}</option>-->
                <!--</select>-->
            <!--</div>-->
        </div>
        <div class="row">
            <div class="form-group col-sm-6">
                <label for="service">*Service Type</label>
                <select class="form-control" id="service" [(ngModel)]="workOrder.serviceTypeId" name="serviceType">
                    <option *ngFor="let type of serviceTypes" [value]="type.id">{{type.serviceTypeName}}</option>
                </select>
            </div>
            <div class="form-group col-sm-6" >
                <label for="assignedEmployee">Assigned To</label>
                <select class="form-control" id="assignedEmployee" [(ngModel)]="workOrder.employeeToId" name="assignedEmployee">
                    <option *ngFor="let employee of employees | active:true" [value]="employee.id">{{employee.lastName}}, {{employee.firstName}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label for="description">Work Order</label>
                <textarea name="description" id="description" class="form-control" rows="10" [(ngModel)]="workOrder.note">
                </textarea>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12 shift-right">
                <button class="btn btn-success" (click)="updateWorkOrder.showPromptModal(workOrder);" [disabled]="!orderEditForm.form.valid">Update</button>
                <div class="btn btn-danger" (click)="cancel()">Cancel</div>
            </div>
        </div>
    </form>
    <small style="color:red">* Required</small>
</div>

<app-update-work-order #updateWorkOrder (onUpdate)="update($event)" [update]="true"></app-update-work-order>
