<div class="header" *ngIf="isLabsPage">
  <h2><i class="fa fa-flask"></i> Labs</h2>
</div>
<div
  class="panel margin-top-20 padding-15"
  [ngClass]="{ 'labs-page': isLabsPage }"
>
  <div class="row" [ngClass]="{ 'container-fluid': isLabsPage }">
    <div class="col-md-12">
      <h4 *ngIf="!isLabsPage" style="float: left">
        <i class="fa fa-flask"></i>Labwork
      </h4>
      <div class="pull-left" *ngIf="isLabsPage">
        <button
          class="btn btn-primary address-btn"
          (click)="toggleEdit(); clearLab()"
          [ngClass]="{ 'btn-primary': !displayEdit, 'btn-danger': displayEdit }"
        >
          {{ displayEdit ? "Cancel" : "Create New" }}
        </button>
      </div>
      <div class="pull-right">
        <form class="form-row margin-right-10" *ngIf="isLabsPage">
          <div class="form-group margin-right-10">
            <select
              name="Lab Tech"
              class="form-control"
              [(ngModel)]="query.labTech"
            >
              <option [ngValue]="undefined" selected>Lab Tech - Any</option>
              <option [value]="labTech.id" *ngFor="let labTech of labTechs">
                {{ labTech.lastName }}, {{ labTech.firstName }}
              </option>
            </select>
          </div>
          <div class="form-group margin-right-10">
            <select
              name="Service Region"
              class="form-control"
              [(ngModel)]="query.region"
            >
              <option [ngValue]="undefined" selected>
                Service Region - Any
              </option>
              <option [value]="region.id" *ngFor="let region of serviceRegions">
                {{ region.serviceRegionName }}
              </option>
            </select>
          </div>
          <div class="form-group margin-right-10">
            <app-date-picker
              [(date)]="query.fromDate"
              title="Start Date"
            ></app-date-picker>
          </div>
          <div class="form-group margin-right-10">
            <app-date-picker
              [(date)]="query.toDate"
              title="End Date"
            ></app-date-picker>
          </div>
          <div class="form-group">
            <button
              *ngIf="!searching"
              type="button"
              class="btn btn-primary btn-search"
              (click)="setFilters()"
            >
              <i class="fa fa-search"></i>
            </button>
            <button
              *ngIf="searching"
              type="button"
              class="btn btn-primary btn-search"
              disabled
            >
              f
              <i class="fa fa-spin fa-refresh"></i>
            </button>
          </div>
        </form>

        <button
          class="btn btn-success address-btn"
          (click)="toggleInfo()"
          *ngIf="!isLabsPage"
        >
          {{ displayInfo ? "Hide" : "Display" }} Labwork
        </button>
        <button
          class="btn btn-primary address-btn"
          (click)="toggleEdit(); clearLab()"
          [ngClass]="{ 'btn-primary': !displayEdit, 'btn-danger': displayEdit }"
          *ngIf="!isLabsPage"
        >
          {{ displayEdit ? "Cancel" : "Create New" }}
        </button>
      </div>
    </div>
  </div>

  <div class="row padding-15 gray-border" *ngIf="displayEdit">
    <form #labworkForm="ngForm" action="" class="form-inline">
      <div class="form-row">
        <div *ngIf="isLabsPage && !lab.id" id="myClientList">
          <div class="form-group btn-margin-right">
            <div class="input-group-prepend">
              <label class="input-group-text">Client &nbsp;</label>
            </div>
            <input
              #clientInput
              type="text"
              required
              autocomplete="off"
              placeholder="Client"
              [(ngModel)]="search"
              name="client"
              class="form-control"
              (focus)="showClients = true"
            />
          </div>
          <div class="client-dropdown" *ngIf="showClients" id="clientDropdown">
            <ul #clientList>
              <li *ngFor="let cd of clientsDairies | clientSearchPipe : search">
                <span>{{ cd.name }}</span>
                <div *ngFor="let d of cd.Addresses" class="dairy-info">
                  <div (click)="setAddress(d)">{{ d.alias }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="form-row mt-2">
        <div class="form-group btn-margin-right">
          <div class="input-group-prepend">
            <label for="testDate" class="input-group-text">Date &nbsp;</label>
          </div>
          <input
            type="text"
            class="form-control"
            id="date"
            name="Lab Date"
            placeholder="Month Day, Year"
            [ngModel]="labDate | date"
            (click)="setEditDate('Lab Date')"
            required
          />
          <div class="input-group-prepend">
            <button
              type="button"
              (click)="setEditDate('Lab Date')"
              class="btn btn-light btn-calendar input-group-text"
            >
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="form-group btn-margin-right">
          <div class="input-group-prepend">
            <label for="testDate" class="input-group-text">Time &nbsp;</label>
          </div>
          <input
            type="text"
            class="form-control"
            id="time"
            name="Lab Time"
            placeholder="HH:MM AM/PM"
            [ngModel]="labTimeString()"
            (click)="setEditDate('Lab Time')"
            required
          />
          <div class="input-group-append">
            <button
              type="button"
              (click)="setEditDate('Lab Time')"
              class="btn btn-light btn-calendar input-group-text"
            >
              <i class="fa fa-clock-o" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="form-group btn-margin-right">
          <label for="location">Location &nbsp; </label>
          <select
            name="location"
            id="location"
            class="form-control"
            [(ngModel)]="lab.tankLocation"
          >
            <option [ngValue]="undefined">--</option>
            <option value="north">North</option>
            <option value="south">South</option>
            <option value="east">East</option>
            <option value="west">West</option>
          </select>
        </div>
        <div class="form-group btn-margin-right">
          <label for="bath_cycle">Bath Cycle &nbsp;</label>
          <select
            name="bath_cycle"
            id="bath_cycle"
            class="form-control"
            [(ngModel)]="lab.bathCycle"
          >
            <option [ngValue]="undefined">--</option>
            <option value="fresh">Fresh</option>
            <option value="mid">Mid</option>
            <option value="end">End</option>
          </select>
        </div>
        <div class="form-group btn-margin-right">
          <label
            ><input
              class="form-check-input"
              type="checkbox"
              name="corrosive"
              [(ngModel)]="lab.isCorrosive"
            />
            &nbsp; Corrosive</label
          >
        </div>
        <div class="form-group btn-margin-right">
          <label
            ><input
              class="form-check-input"
              type="checkbox"
              name="hazardous"
              [(ngModel)]="lab.isHazardous"
            />
            &nbsp; Hazardous</label
          >
        </div>
        <div class="form-group btn-margin-right">
          <label
            ><input
              class="form-check-input"
              type="checkbox"
              name="esh"
              [(ngModel)]="lab.isESH"
            />
            &nbsp; ESH</label
          >
        </div>
        <div class="lab-notes">
          <label class="float-left">Lab Notes</label>
          <textarea
            type="text"
            class="form-control width-100"
            name="Lab Note"
            [(ngModel)]="lab.note"
            rows="5"
            autocomplete="off"
          ></textarea>
        </div>
      </div>
      <div class="d-flex">
        <div class="p-2" *ngIf="lab.createdBy || lab.updatedBy">
          <div *ngIf="lab.createdBy">
            Created By: {{ lab.createdBy?.firstName }}
            {{ lab.createdBy?.lastName }} on {{ lab.createdAt | date }}
          </div>
          <div *ngIf="lab.updatedBy">
            Updated By: {{ lab.updatedBy?.firstName }}
            {{ lab.updatedBy?.lastName }} on {{ lab.updatedAt | date }}
          </div>
        </div>
        <div class="p-2 ml-auto">
          <button
            type="submit"
            class="btn btn-primary"
            (click)="saveLab(lab, labworkForm.form)"
          >
            Save
          </button>
          <button type="button" class="btn btn-warning" (click)="clearLab()">
            Clear
          </button>
          <button
            *ngIf="isAdmin && lab.id"
            type="button"
            class="btn btn-danger"
            (click)="deletePrompt.showPromptModal()"
          >
            Delete
          </button>
        </div>
      </div>
    </form>
  </div>

  <div
    [ngClass]="{ 'container-fluid': isLabsPage, 'page-container': isLabsPage }"
    *ngIf="displayInfo"
  >
    <div *ngIf="labWorks.length === 0" class="col-12">
      <h5>No Lab info for location</h5>
    </div>
    <div class="table-contain" *ngIf="labWorks.length > 0">
      <table
        class="table table-striped"
        [ngClass]="{ 'table-responsive': isLabsPage }"
      >
        <thead>
          <tr>
            <td
              *ngFor="let header of headers"
              [ngClass]="{
                sorted: sortColumn === header.sortName,
                asc: sorting[header.sortName].asc,
                desc: !sorting[header.sortName].asc
              }"
              (click)="sort(header.sortName)"
              class="clickable {{ header.className }}"
            >
              {{ header.title }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let lab of labWorks"
            (click)="selectLab(lab)"
            [ngClass]="{ selected: isSelected(lab) }"
          >
            <td class="td-10" *ngIf="isLabsPage && lab.Address.alias !== null">
              {{ lab.Address.alias }}
            </td>
            <td class="td-10" *ngIf="isLabsPage && lab.Address.alias === null">
              No Dairy is associated to this lab.
            </td>
            <td class="td-5">{{ lab.id }}</td>
            <td class="td-10">{{ lab.date | date : "shortDate" }}</td>
            <td class="td-10">{{ lab.date | date : "shortTime" }}</td>
            <td class="td-10">{{ lab.tankLocation }}</td>
            <td class="td-10">{{ lab.bathCycle }}</td>
            <td class="td-10">
              <i
                *ngIf="lab.isCorrosive"
                class="fa fa-check is-checked"
                aria-hidden="true"
              ></i>
            </td>
            <td class="td-10">
              <i
                *ngIf="lab.isHazardous"
                class="fa fa-check is-checked"
                aria-hidden="true"
              ></i>
            </td>
            <td class="td-5">
              <i
                *ngIf="lab.isESH"
                class="fa fa-check is-checked"
                aria-hidden="true"
              ></i>
            </td>
            <td class="td-20">
              <div class="td-text-hide">{{ lab.note }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination">
      <pagination
        *ngIf="totalItems > query.pageSize"
        [totalItems]="totalItems"
        [itemsPerPage]="query.pageSize"
        [(ngModel)]="query.page"
        (pageChanged)="pageChanged($event)"
        [maxSize]="maxSize"
        [rotate]="rotate"
        previousText="&lsaquo;"
        nextText="&rsaquo;"
        firstText="&laquo;"
        lastText="&raquo;"
        [boundaryLinks]="true"
      ></pagination>
    </div>
  </div>
</div>

<prompt-modal #labDatePicker size="small" (onClosed)="this.editType = ''">
  <div class="modal-header">
    <h4 class="modal-title">{{ editType }}</h4>
  </div>

  <div class="modal-item row">
    <div class="col-12">
      <datepicker
        *ngIf="editType == 'Lab Date'"
        [(ngModel)]="labDate"
        class="date-modal"
        name="Lab Date"
        datepickerMode="day"
        [showWeeks]="false"
        (selectionDone)="labDatePicker.hidePromptModal()"
      >
      </datepicker>
      <timepicker
        class="time-modal"
        *ngIf="editType == 'Lab Time'"
        [(ngModel)]="labTime"
      >
      </timepicker>
    </div>
  </div>
  <div class="modal-buttons">
    <button
      *ngIf="editType === 'Lab Date'"
      type="button"
      class="btn btn-primary"
      (click)="labDatePicker.hidePromptModal()"
    >
      OK
    </button>
    <button
      *ngIf="editType === 'Lab Time'"
      type="button"
      class="btn btn-primary"
      (click)="setLabTime()"
    >
      OK
    </button>
  </div>
</prompt-modal>

<div class="loading-overlay" *ngIf="ftp">
  <img src="../../../../../assets/images/specialtylogo.png" />
  <i class="fa fa-spinner fa-spin fa-2x"></i>
  <h4>Loading ...</h4>
</div>

<prompt-modal #deletePrompt>
  <div class="modal-header">
    <h4 class="modal-title">Delete Lab</h4>
  </div>
  <div class="modal-item">
    <div class="col-12">
      <p>Are you sure you want to delete this lab?</p>
    </div>
  </div>
  <div class="modal-buttons">
    <button
      type="submit"
      class="btn btn-sm btn-primary"
      (click)="deleteLab(lab); deletePrompt.hidePromptModal()"
    >
      Delete
    </button>
    <button
      type="button"
      class="btn btn-sm btn-light"
      (click)="deletePrompt.hidePromptModal()"
    >
      Cancel
    </button>
  </div>
</prompt-modal>
