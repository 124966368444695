
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { State } from "src/app/models/state";
import { environment } from "src/environments/environment";

@Injectable()
export class StateService {
    constructor(
        private authHttp: HttpClient
    ) { }

    private apiUrl = environment.apiUrl;  // URL to web api

    getStates(): Promise<State> {
        return this.authHttp.get(`${this.apiUrl}states`)
                            .toPromise()
                            .then((response: any) => {
                                    return response as State;
                            })
                            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}