<div class="view-nav" (window:resize)="setMobile()">
    <div class="row">
        <div class="col-12 col-md-4">
            <ul class="nav">
                <li class="nav-item">
                    <a class="nav-link" (click)="changeDisplay(0)">
                        <i class="fa fa-th"></i> Card View
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="changeDisplay(1)">
                        <i class="fa fa-th-list"></i> List View
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-12 col-md-3">
            <div class="dairy-search">
                <input placeholder="Search By Name..." (ngModelChange)="searchClientModelChanged.next($event)" [(ngModel)]="searchString">
            </div>
        </div>
        <div *ngIf="!add" class="col-12 col-md-5">
            <button (click)="toggleType()" class="btn btn-success sales-btn" [ngClass]="{'btn-sm': tablet}">{{ salesLeads ? 'View Clients' : 'View Sales-Leads' }}</button>
            <button class="btn btn-primary add-btn" [disabled]="add" (click)="addClients()" [ngClass]="{'btn-sm': tablet}">Add Client</button>
        </div>

        <div class="col-12 col-md-5" *ngIf="add">
            <form #clientAdd="ngForm" (ngSubmit)="addNewClient(client)" class="form-inline pull-right">
                <div class="form-group new-client">
                    <input type="text"
                           placeholder="*Client Name"
                           [(ngModel)]="client.name"
                           name="name"
                           class="form-control new-client-input"
                           required
                           (keyup)="passString($event.target)"
                           id="name">
                    <button class="btn-success btn" type="submit"  [ngClass]="{'btn-sm': tablet}" [disabled]="!clientAdd.form.valid">Save</button>
                    <button class="btn-danger btn" type="button"  [ngClass]="{'btn-sm': tablet}" (click)="cancelSave()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>
