<div id="work-order-print" class="work-order-print route-content">
    <div>
        <div class="col-12 single-route-details">
            <div>
                <strong>Name:</strong> <span>{{ route.routeName }}</span>
            </div>
            <div>
                <strong>Scheduled:</strong>  <span>{{route.dateOfRoute | date}}</span>
            </div>
            <div>
                <strong>Type:</strong>  <span>{{ route.routeType }}</span>
            </div>
        </div>
        <div class="print-logo">
            <img src="../../../../assets/images/specialtylogo.png"/>
        </div>
    </div>
    <div *ngFor="let order of orders" class="row margin-top-25 margin-bottom-5 route-form">
        <div class="col-12 flex-column flex-space-between">
            <span class="mobile-display">Dairy Details:</span>
            <div class="driver-route-container">
                <address *ngIf="order?.Address?.length">
                    {{order?.Address[0]?.alias}}<br/>
                    {{ order?.Address[0]?.street }}, <br/>
                    {{ order?.Address[0]?.city}} {{ order?.Address[0]?.state }} {{ order?.Address[0]?.zip }}
                </address>
                <address *ngIf="!order?.Address?.length">
                    {{order?.Address?.alias}}<br/>
                    {{ order?.Address?.street }}, <br/>
                    {{ order?.Address?.city}} {{ order?.Address?.state }} {{ order?.Address?.zip }}
                </address>
            </div>
        </div>
        <div class="col-6">
            <span class="mobile-display">Order #</span>
            <span>{{order.id}}</span>
        </div>
        <div class="col-6">
            <span class="mobile-display">Service Type</span>
            <span *ngIf="!order.serviceTypeName">{{order.ServiceType.serviceTypeName}}</span>
            <span *ngIf="order.serviceTypeName">{{order.serviceTypeName}}</span>
        </div>
        <div class="col-6">
            <span class="mobile-display">Details</span>
            <span>{{order.note}}</span>
        </div>
        <div class="col-6">
            <span class="mobile-display">Status</span>
            <span>{{order.workOrderStatusId | orderStatus}}</span>
        </div>
    </div>
</div>