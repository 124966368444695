<div class="panel employee-type-list-panel">
    <table class="table table-hover">
        <thead>
            <tr>
                <td>Type</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let type of employeeTypes" (click)="selectEmployeeType(type)">
                <td>{{ type.employeeType }}</td>
            </tr>
        </tbody>
    </table>
</div>