import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';

import * as moment from 'moment';
import * as _ from 'lodash';

import { RouteService } from '@services/route.service';
import { MultiSelectService } from '../../multi-select/multi-select.service';
import { S3MapService } from '../../s3-gmap/s3-map.service';
import { TrailerService } from '@services/trailer.service';
import { TankService } from '@services/tank.service';
import { EmployeeService } from '@services/employee.service';
import { AddressService } from '@services/address.service';
import { FacilityService } from '@services/facility.service';

import { Tank } from '@models/tank';
import { Route } from '@models/route';
import { Trailer } from '@models/trailer';
import { Employee } from '@models/employee';
import { Facility } from '@models/facility';
import { Product } from '@models/product';
import { DeliveryOrderService } from '@services/delivery-order.service';
import { Subject } from 'rxjs';
import { ToastService } from '@services/toast.service';
import { RouteParams } from '@models/route-params';
import { WorkOrderService } from '@services/work-order.service';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'route-view',
  templateUrl: 'route-view.component.html',
  styleUrls: ['route-view.component.css'],
})
export class RouteViewComponent implements OnInit, OnDestroy {
  clientLocations: Tank[];
  route: Route;
  routeClone: Route;
  trailers: Trailer[];
  drivers: Employee[];
  routeOrder: any[] = [];
  totalGallons: Product[] = [];
  states: any[];
  // counties: any[];
  cities: any[];
  facilities: Facility[];
  products: Product[];
  saveSuccessful: boolean = null;
  saveError: boolean = null;
  deleteSuccessful: boolean = null;
  copying = false;
  saving = false;
  searching = false;
  setManual = true;
  query: any;
  routeParams: RouteParams;
  setParams = false;
  workOrder: false;
  tractors: any;
  directions: any;

  serviceRegions: any[];
  destroyed$ = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private addressService: AddressService,
    private routeService: RouteService,
    private deliveryOrderService: DeliveryOrderService,
    private facilityService: FacilityService,
    private multiSelectService: MultiSelectService,
    private s3MapService: S3MapService,
    private trailerService: TrailerService,
    private tankService: TankService,
    private toastService: ToastService,
    private employeeService: EmployeeService,
    private workOrderService: WorkOrderService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.serviceRegions = [];
    this.query = {
      states: [],
      county: null,
      productId: null,
      city: null,
      zip: null,
      maxCriticalPercent: null,
      selectedRegions: []
    };
    this.routeParams = new RouteParams();
    this.clientLocations = this.activatedRoute.snapshot.data['clientLocations'];
    this.workOrder = this.activatedRoute.snapshot.data['workOrder'];
    this.filterNotes(this.clientLocations);
    this.drivers = this.activatedRoute.snapshot.data['drivers'];
    this.trailerService.getTrailers()
      .then((response) => {
        this.trailers = response;
      });

    if (this.activatedRoute.snapshot.data['route']) {
      this.route = new Route(this.activatedRoute.snapshot.data['route']);
      this.routeService.setRoute(this.route, this.clientLocations, this.workOrder);
      this.route.dateOfRoute = moment(this.route.dateOfRoute).format('YYYY-MM-DD');
      this.getDriverIds(this.route);
      this.updateTotalGallons();
    } else {
      this.route = new Route();
    }

    this.cities = [];
    this.setServiceRegions();

    this.addressService.getUniqueStates()
      .then((res) => {
        this.states = res;
      });
    this.facilityService.getFacilities()
      .then((res) => {
        this.facilities = res;
      });
    this.products = this.activatedRoute.snapshot.data['products'];
    this.checkPrefs();

    this.router.events
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: any) => {

        const routeId = event.url ? +event.url.split('/')[2] : null;
        if (routeId) {
          this.setRouteParams();
        } else {
          this.setContainers();
        }

        if (!this.workOrder && event instanceof NavigationStart && !!routeId && routeId !== this.route.id) {
          this.tankService.getTanksForDelivery({ dateOffset: 0, maxCriticalPercent: 1 })
            .then((tank) => {

              this.clientLocations = tank;
            });

          this.employeeService.getEmployeesByType('Driver')
            .then(drivers => this.drivers = drivers);

          if (this.activatedRoute.snapshot.data['route']) {
            this.routeService.findRouteById(routeId)
              .then(route => {
                this.route = route;
                this.routeService.setRoute(this.route, this.clientLocations, this.workOrder);
                this.route.dateOfRoute = moment(this.route.dateOfRoute).format('YYYY-MM-DD');
                this.updateTotalGallons();
                // this.setRouteParams();
              });
          } else {
            this.route = new Route();
          }
        }
      });

    this.checkTotalGallons();
  }

  filterNotes(locs: any) {
    locs.forEach((l: any) => {
      l.Notes = _.uniqBy(l.Notes, 'id');
      _.remove(l.Notes, (n) => {
        // if(n.)
        return !n || n['notesTypeId'] !== 2;
      });
    });
  }

  setServiceRegions() {
    const arr: any[] = [];
    const regions = this.activatedRoute.snapshot.data['serviceRegions'];
    regions.forEach((region: any) => {
      const item = {
        id: region.id,
        name: `${region.serviceRegionName}${region.state ? ' - ' + region.state : ''}`
      };
      arr.push(item);
    });
    this.serviceRegions = arr;
  }

  /*
  * Method to navigate to new-route url
  * must clear params manually
  * */
  newRoute() {
    this.routeParams = new RouteParams();
    this.routeParams.selectedRegions = [];
    this.routeParams.states = [];
    this.router.navigate(['/new-route']);
    this.ngOnInit();
  }

  /*
  * Setter Method to initialize route parameters on the edit route view
  * */
  setRouteParams() {
    if (this.activatedRoute.snapshot.data['route']) {
      const rp = this.activatedRoute.snapshot.data['routeParams'];
      if (rp) {
        if (!rp.states || rp.states.length <= 0) {
          rp.states = [];
        } else if (typeof rp.states === 'string') {
          rp.states = rp.states.split(',');
        }

        if (!rp.selectedRegions || rp.selectedRegions.length <= 0) {
          rp.selectedRegions = [];
        } else if (typeof rp.selectedRegions === 'string') {
          rp.selectedRegions = rp.selectedRegions.split(',');
        }

        this.routeParams = rp;
      } else {
        this.routeParams = new RouteParams();
      }
    }
  }

  /*
   * Method to initally check for preferences
   * */
  checkPrefs() {
    const prefs = JSON.parse(localStorage.getItem('routePrefs'));
    if (prefs) {
      _.merge(this.query, prefs);
    }
    return prefs;
  }

  /*
  * Save the route parameters when saving a new route
  * grab the route options and send them to db for saving
  * req{*}
  * */
  saveParams() {
    const clone = _.clone(this.routeParams);
    clone.states.length > 0 ? clone.states = clone.states.toString() : clone.states = null;
    clone.selectedRegions > 0 ? clone.selectedRegions = clone.selectedRegions.toString() : clone.selectedRegions = null;
    clone.dateOfRoute = this.route.dateOfRoute;

    if (clone['id']) {
      return this.routeService.updateParams(clone)
        .then((res) => {
          if (res['id']) {
            res.selectedRegions === null ? res.selectedRegions = [] : res.selectedRegions = res.selectedRegions.split(',');
            !res.states ? res.states = [] : res.states = res.states.split(',');
            this.routeParams = res;
          } else {
            this.routeParams = new RouteParams();
          }
        });
    } else {
      return this.routeService.saveParams(clone)
        .then((res) => {
          if (res['id']) {
            res.selectedRegions === null ? res.selectedRegions = [] : res.selectedRegions = res.selectedRegions.split(',');
            !res.states ? res.states = [] : res.states = res.states.split(',');
            this.routeParams = res;
          } else {
            this.routeParams = new RouteParams();
          }
        });
    }
  }

  /*
   * Method for a user to save their preferences
   * */
  savePrefs() {
    if (localStorage.getItem('routePrefs')) {
      localStorage.removeItem('routePrefs');
      localStorage.setItem('routePrefs', JSON.stringify(this.query));
      this.toastService.toast({ message: 'Your preferences have been saved!', type: 'success' });
    } else {
      localStorage.setItem('routePrefs', JSON.stringify(this.query));
      this.toastService.toast({ message: 'Your preferences have been saved!', type: 'success' });
    }
  }

  /*
   * Method to clear a users preferences in localStorage
   * */
  clearPrefs() {
    localStorage.removeItem('routePrefs');
    this.clearParams();
    this.toastService.toast({ message: 'Your preferences have been cleared', type: 'success' });
  }

  /*
  * Sets Containers after view load
  * this method to solve the issue of no init on new-route menu option
  *
  * */
  setContainers() {
    this.multiSelectService.updateContainer('tanks', {
      name: 'tanks',
      containerParent: 'location-container',
      selectedClass: 'selected-location',
      idsToMove: [],
      itemId: 'client-location',
      targetArray: this.clientLocations,
    });
    this.multiSelectService.updateContainer('route', {
      name: 'route',
      containerParent: 'route-container',
      selectedClass: 'selected-location',
      idsToMove: [],
      itemId: 'route-location',
      targetArray: this.route.locations
    });
  }

  clearParams() {
    this.query = {
      states: [],
      county: null,
      productId: null,
      city: null,
      zip: null,
      maxCriticalPercent: null,
      selectedRegions: []
    };
  }


  saveRoute = (route: Route) => {
    if (this.saving) {
      return;
    }
    this.saveError = false;
    let promise: Promise<any> = null;
    this.routeService.hasChanges = false;
    this.setManualOrder(route);
    const clone = _.cloneDeep(route);
    clone.dateOfRoute = moment(route.dateOfRoute).format('YYYY-MM-DD');
    if (route.trailerId !== null) {
      const trailId = +route.trailerId;
      clone.Trailer = _.find(this.trailers, { id: trailId });
    }
    this.saving = true;
    if (this.workOrder) {
      promise = this.routeService.saveServiceRoute(clone);
    } else {
      promise = this.routeService.saveDeliveryRoute(clone);
    }
    return promise.then((res) => {
      this.saving = false;
      res.dateOfRoute = moment(res.dateOfRoute).format('YYYY-MM-DD');
      this.routeParams.routeId = res.id;
      // this.routeParams.dateOfRoute = res.dateOfRoute;
      this.saveParams();
      _.merge(this.route, res);
      this.saveSuccessful = true;
      setTimeout(() => {
        this.saveSuccessful = null;
      }, 3000);

      this.copying = false;
      if (this.workOrder) {
        this.location.go(`/edit-work-route/${this.route.id}`);
      } else {
        this.location.go(`/edit-route/${this.route.id}`);
      }
      return res;
    }, (error) => {
      this.saveError = true;
      this.saving = false;
    });
  }

  setManualOrder(route: Route) {
    this.setUniqueLocations(this.route);
    this.resetOrder(this.route);
    this.setManual = false;
  }
  mustSetOrder() {
    this.setManual = true;
    this.changeDetectorRef.detectChanges();
  }

  copyRoute(route: Route) {
    if (!this.workOrder) {
      this.routeClone = _.cloneDeep(route);
      this.copying = true;

      // removed ids before creating duplicate
      delete this.route.id;
      this.route.deliveryOrders = [];
      // set a unique route name
      this.route.routeName = this.route.routeName + ' (copy)';
    }
  }

  cancelCopy() {
    if (!this.workOrder) {
      this.route = this.routeClone;
      this.copying = false;
      this.updateContainers();
    }
  }


  getDriverIds(route: Route): void {
    route.EmployeeRoutes.forEach((empRoute: any) => {
      route.driverIds.push(empRoute.employeeId);
    });
  }
  checkTotalGallons() {
    if (!this.workOrder) {
      if (this.route.id) {
        this.updateTotalGallons();
      } else {
        this.totalGallons = [];
      }
    }
  }

  moveToRoute(event: any): void {
    const arr = this.multiSelectService.removeAndReturnItems('tanks', this.clientLocations);
    this.route.locations = this.route.locations.concat(arr);
    this.updateContainers();
    this.updateTotalGallons();
    this.setManual = true;
  }

  removeFromRoute(event: any): void {
    const arr = this.multiSelectService.removeAndReturnItems('route', this.route.locations);
    this.removeDeliveryOrders(arr);
    this.clientLocations = this.clientLocations.concat(arr);
    this.updateContainers();
    this.updateTotalGallons();
  }

  removeDeliveryOrders(arr: any) {
    if (!this.workOrder) {
      const orders: any = [];
      arr.forEach((o: any) => {
        const order = _.find(this.route.deliveryOrders, { tankId: o.id });
        if (order) {
          orders.push(order);
        }
      });
      orders.forEach((x: Object) => {
        this.deliveryOrderService.deleteDeliveryOrders(x['id'])
          .then(res => {
            _.remove(this.route.deliveryOrders, { id: x['id'] });
          });
      });
      this.deleteSuccessful = true;
      setTimeout(() => {
        this.deleteSuccessful = null;
      }, 3000);
      this.setManual = true;
    }
  }

  updateContainers(): void {
    this.multiSelectService.updateContainerArray('tanks', this.clientLocations);
    this.multiSelectService.updateContainerArray('route', this.route.locations);
  }


  cancelRoute(): void {
    const link = ['/routes'];
    this.router.navigate(link);
  }

  // Route Mapping Stuff
  getOptimizedRoute(route: Route): void {
    this.setUniqueLocations(this.route);
    const addresses = this.routeService.getRouteAddresses(this.route);
    const startAndEnd = this.routeService.getRouteStartAndEnd(this.route, this.facilities);

    this.s3MapService.mapRoute(addresses, true, 'map-1', startAndEnd, this.route['uniqueAddresses'])
      .then((res: any) => {
        if (res.status === 'ZERO_RESULTS') {
          return this.toastService.toast({ message: `There was an error. An address in your list could not be found`, type: 'danger' });
        }
        this.route['uniqueAddresses'] = this.reOrderRouteLocations(this.route['uniqueAddresses'], res).reverse();
        this.resetOrder(this.route);
        this.s3MapService
          .mapRoute(
            this.routeService
              .getRouteAddresses(this.route), false, 'map-1', startAndEnd, this.route['uniqueAddresses']
          );
        this.s3MapService
          .mapRoute(
            this.routeService
              .getRouteAddresses(this.route), false, 'map-1-print', startAndEnd, this.route['uniqueAddresses'], '-print'
          );
        this.scrollToMap(75);
        this.setManual = false;

      });
  }

  mapRoute(route: Route): void {
    this.setUniqueLocations(this.route);
    const addresses = this.routeService.getRouteAddresses(this.route);
    const startAndEnd = this.routeService.getRouteStartAndEnd(this.route, this.facilities);
    this.s3MapService.mapRoute(addresses, false, 'map-1', startAndEnd, this.route['uniqueAddresses'])
      .then((res: any) => {
        if (res.status === 'NOT_FOUND') {
          const notFound: any = [];
          for (let i = 0; i < res.geocoded_waypoints.length; i++) {
            if (res.geocoded_waypoints[i]['geocoder_status'] === 'ZERO_RESULTS') {
              notFound.push(i);
            }
          }
          const badObject: string = notFound.join(', ');
          return this.toastService.toast({
            message: `There was an error. Address ${badObject} in your list could not be found`,
            type: 'danger'
          });
        }
        this.resetOrder(this.route);
        this.s3MapService.mapRoute(addresses, false, 'map-1-print', startAndEnd, this.route['uniqueAddresses'], '-print');
        this.scrollToMap(75);
        this.setManual = false;
      });

  }

  reverseRoute(): void {
    this.setUniqueLocations(this.route);
    this.route['uniqueAddresses'].reverse();
    const startAndEnd = this.routeService.getRouteStartAndEnd(this.route, this.facilities);
    this.s3MapService.mapRoute(this.routeService.getRouteAddresses(this.route), false, 'map-1', startAndEnd, this.route['uniqueAddresses'])
      .then((res: any) => {

        if (res.status === 'NOT_FOUND') {
          return this.toastService.toast({ message: `There was an error. An Address in your list could not be found`, type: 'danger' });
        }

        this.s3MapService
          .mapRoute(
            this.routeService
              .getRouteAddresses(this.route), false, 'map-1-print', startAndEnd, this.route['uniqueAddresses'], '-print'
          );
        this.resetOrder(this.route);
        this.scrollToMap(75);
        this.setManual = false;
      });

  }

  reOrderRouteLocations(locations: any[], orderArr: number[]) {
    locations = orderArr.map((waypoint: number) => {
      return _.find(locations, (item) => {
        return locations.indexOf(item) === waypoint;
      });
    });
    locations.forEach((item) => {
      item['dropOrder'] = _.indexOf(locations, { id: item.id }) + 1;
    });
    return locations;
  }

  setUniqueLocations(route: Route) {
    // Set both route.locations and route.deliveryOrders with the delivery # that matches uniqAddresses
    route['uniqueAddresses'] = _.uniqBy(route.locations, (o) => {
      return o.addressId;
    });
    return route;
  }


  resetOrder(route: Route) {
    // requires a route with uniqueAddresses attached and it's locations
    // First will get the unique addresses and give each one a deliveryNumber
    // Then will go through the locations and will give each one a deliveryNumber based on it's uniqueAddress

    for (let i = 0; i < this.route['uniqueAddresses'].length; i++) {
      if (this.route.trailerId) {
        const trailer = _.find(this.trailers, { id: +this.route.trailerId });
        const _trailer = _.clone(trailer);

        if (_trailer.name === 'Jenny') { _trailer['name'] = 'j'; }

        this.route['uniqueAddresses'][i]['dropOrder'] = i + 1;
        this.route['uniqueAddresses'][i]['deliveryNumber'] = `${moment(this.route.dateOfRoute).format('YYMMDD')}-${_trailer.name}-${i + 1}`;
      } else {
        this.route['uniqueAddresses'][i]['dropOrder'] = i + 1;
        this.route['uniqueAddresses'][i]['deliveryNumber'] = `${moment(this.route.dateOfRoute).format('YYMMDD')}-0-${i + 1}`;
      }
    }

    this.route.locations.forEach((l: any) => {
      const uniq = _.find(this.route['uniqueAddresses'], { addressId: l.addressId });
      l['dropOrder'] = uniq['dropOrder'];
      return l['deliveryNumber'] = uniq['deliveryNumber'];
    });
    this.route.locations = _.orderBy(this.route.locations, ['dropOrder'], ['asc']);
    this.toastService.toast({ message: 'Route Delivery Order Set', type: 'success' });

  }

  updateTotalGallons(): void {
    if (!this.workOrder) {
      this.totalGallons = this.routeService.calculateRouteWeight(this.route.locations);
    }
  }

  // onSelectedStateChange(event: any): void {
  //     this.addressService.getUniqueCountiesByState(event).then(res=> this.counties = res);
  // }

  updateQuantities(event: Event) {
    if (!this.workOrder) {
      this.updateTotalGallons();
    }
  }

  moveLocationUp(tank: Tank) {
    const currentIndex = this.route.locations.indexOf(tank);
    const newIndex = currentIndex - 1;
    if (newIndex !== -1) {
      this.moveIndex(currentIndex, newIndex);
    }
  }

  moveLocationDown(tank: Tank) {
    const currentIndex = this.route.locations.indexOf(tank);
    const newIndex = currentIndex + 1;
    if (newIndex !== -1) {
      this.moveIndex(currentIndex, newIndex);
    }
  }

  moveIndex(originalIndex: number, newIndex: number): void {
    if (newIndex >= this.route.locations.length) return;
    this.route.locations.splice(newIndex, 0, this.route.locations.splice(originalIndex, 1)[0]);
  }

  // Component Functions

  searchLocations(event: any): void {
    let query = {};
    let promise: any = null;
    this.searching = true;
    const currentDate = moment();
    if (this.workOrder) {
      promise = this.workOrderService.getOpenWorkOrderLocations(event);
    } else {
      query = {
        dateOffset: moment(this.route.dateOfRoute).diff(currentDate, 'days')
      };
      _.merge(query, event);
      if (query['maxCriticalPercent']) {
        query['maxCriticalPercent'] = query['maxCriticalPercent'] / 100;
      }
      promise = this.tankService.getTanksForDelivery(query);
    }
    promise
      .then((res: any) => {
        this.clientLocations = res;
        this.filterNotes(this.clientLocations);
        this.routeService.setRoute(this.route, this.clientLocations, this.workOrder);
        this.multiSelectService.updateContainer('tanks', {
          name: 'tanks',
          containerParent: 'location-container',
          selectedClass: 'selected-location',
          idsToMove: [],
          itemId: 'client-location',
          targetArray: this.clientLocations,
        });

        this.multiSelectService.updateContainer('route', {
          name: 'route',
          containerParent: 'route-container',
          selectedClass: 'selected-location',
          idsToMove: [],
          itemId: 'route-location',
          targetArray: this.route.locations
        });

        this.searching = false;
      });
  }

  scrollToMap(duration: number): void {
    if (duration <= 0) return;
    const element = document.body;
    const to = document.getElementById('route-map').offsetTop;
    const difference = to - element.scrollTop;
    const perTick = difference / duration * 10;
    setTimeout(() => {
      element.scrollTop = element.scrollTop + perTick;
      if (element.scrollTop === to) return;
      this.scrollToMap(duration - 10);
    }, 10);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
