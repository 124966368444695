import {Component, Input, Output, EventEmitter} from "@angular/core";
import {Client} from "../../../models/client";
@Component({
    selector:'dairy-to-group',
    templateUrl:'dairy-to-group.component.html',
    styleUrls:['../client-group-management.component.css']
})

export class DairyToGroupComponent{
    @Input() clientGroup:Client;
    @Input() groupToGroup:Client;
    @Input() dairies:any[];
    @Input() defaultImage:string;
    @Output() setGroup = new EventEmitter<any>();
    @Output() setToGroup = new EventEmitter<any>();
    @Output() addressToExistingGroup = new EventEmitter<any>();
    @Output() removeFromExistingGroup = new EventEmitter<any>();
    @Output() showDairyToGroupModal = new EventEmitter<any>();
    @Output() cancelDairyToGroup = new EventEmitter<any>();

    constructor(){}

    groupSet(group:any){
        this.setGroup.emit(group);
    }

    toGroupSet(group:any) {
        this.setToGroup.emit(group);
    }

    setBG(path:string){
        return "url(" + path + ")";
    }

    addressToGroup(dairy:any){
        this.addressToExistingGroup.emit(dairy);
    }

    remove(dairy:any){
        this.removeFromExistingGroup.emit(dairy);
    }

    showModal(){
        this.showDairyToGroupModal.emit();
    }

    cancel(){
        this.cancelDairyToGroup.emit();
    }



}