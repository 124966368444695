import { Injectable } from '@angular/core';

declare var google: any;

import * as _ from 'lodash';

@Injectable()
export class S3MapService {
    map: any;
    directionsService: any;
    directionsDisplay: any;
    maps: any[] = [];

    constructor() { }

    initMap(targetId: string) {
        let map = {};
        map['name'] = targetId;
        map['directionsService'] = new google.maps.DirectionsService();
        map['directionsDisplay'] = new google.maps.DirectionsRenderer();
        map['map'] = new google.maps.Map(document.getElementById(targetId), {
            center: { lat: 36.7468, lng: -119.7726 },
            zoom: 8,
            scrollwheel: false
        });
        map['directionsDisplay'].setMap(map['map']);
        this.maps.push(map);
    }

    searchCounty(zip: number): Promise<string> {
        let county: string;
        let geocoder = new google.maps.Geocoder();
        return new Promise((resolve, reject) => {
            geocoder.geocode({ 'address': zip.toString() }, (res: any, status: any) => {
                if (status == 'OK') {
                    resolve(this.getCounty(res[0].address_components));
                } else {
                    resolve('N/A');
                }
            });
        });
    }

    getCounty(res: any): string {
        let countyInfo = _.find(res, (item) => {
            return item['types'][0] === "administrative_area_level_2";
        });
        return countyInfo ? countyInfo['long_name'] : '';
    }

    mapRoute(addresses: any[], optimize: boolean, mapName: string, startAndEnd: any, unique: any, print: string = ''): any {
        let map = _.find(this.maps, { name: mapName });
        let params = {
            origin: `${startAndEnd.origin}`,
            destination: `${startAndEnd.destination}`,
            travelMode: 'DRIVING',
            waypoints: addresses,
            optimizeWaypoints: optimize
        };
        return new Promise(
            (resolve, reject) => {
                map['directionsService'].route(params, (res: any, status: any) => {
                    if (status == 'OK') {
                        this.setDairyName(unique, res);
                        map['directionsDisplay'].setDirections(res);
                        map['directionsDisplay'].setPanel(document.getElementById('directions-panel' + print));
                        resolve(res.routes[0].waypoint_order);
                    } else {
                        resolve(res);
                    }
                });
            }
        );
    }

    setDairyName(addresses: any, res: any) {

        //    Goal to append a dairy name to the google returned address
        //    loop through the res addresses
        //    For each location then loop through the unique addresses and find the one that matches
        //    When found append unique.alias to res.end_address
        //    return the res with appended names
        res.routes[0].legs.forEach((l: any) => {
            let street = l.end_address.split(',')[0].toLowerCase();

            addresses.forEach((a: any) => {
                if (a.Address[0].street.toLowerCase() === street) {
                    return l.end_address = `${a.Address[0].alias} - ${l.end_address}`
                }
            });
        })

    }

    destroyMap(mapName: string): void {
        this.maps.splice(_.indexOf(this.maps, (item: any) => item.name === mapName));
    }


}
