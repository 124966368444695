import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

const loginRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: {
          view: 'login'
        }
    },
    {
        path: 'password-reset',
        component: ResetPasswordComponent,
        data: {
          view: 'password-reset'
        }
    }
        // /password-reset?hash=
];

export const loginRouting = RouterModule.forChild(loginRoutes);
