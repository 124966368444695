import { NgModule } from '@angular/core';
import { CommonModule }   from '@angular/common';
import { loginRouting } from "./login.routing";
import { LoginComponent } from "./login.component";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import {LoginFormComponent} from "./login-form/login-form.component";
import {FormsModule} from "@angular/forms";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {EqualValidator} from "../directives/equal-validator.directive";

@NgModule({
    imports: [
        CommonModule, loginRouting, FormsModule
    ],
    declarations: [
        LoginComponent, ForgotPasswordComponent, LoginFormComponent, ResetPasswordComponent, EqualValidator
    ],
    providers: []
})

export class LoginModule { }