<h5><i class="fa fa-file"></i> Recent Work Orders</h5>
<div class="shadow full-width recent-box">
    <table id="recent-table" class="table table-striped table-hover">
        <thead>
            <tr>
                <th class="td-10">Client Name</th>
                <th class="td-20">Order # / Service</th>
                <th class="td-10">Status</th>
                <th class="td-30">Description</th>
                <th class="td-10">Location</th>
                <th class="td-10">Assigned To</th>
                <th class="td-10">Created</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let workOrder of workOrders" [routerLink]="['/work-orders',workOrder.id]">
                <td class="td-10">{{workOrder.Client ? workOrder.Client.name : '--'}}</td>
                <td class="td-20">{{workOrder.id}} - {{statusType(workOrder.serviceTypeId)}}</td>
                <td class="td-10"><span class="" [ngClass]="woStatus(workOrder.workOrderStatusId)">{{workOrder.workOrderStatusId | orderStatus}}</span></td>
                <td class="td-30">{{workOrder.note}}</td>
                <td class="td-10">{{workOrder.Address ? workOrder.Address.street: '-'}},<br/>
                    {{workOrder.Address ? workOrder.Address.city: '-'}}
                    {{workOrder.Address ? workOrder.Address.state: '-'}}</td>
                <td class="td-10">{{workOrder.employeeTo?.firstName}} {{workOrder.employeeTo?.lastName}}</td>
                <td class="td-10"><em>{{workOrder.createdAt | date}}</em></td>
            </tr>
        </tbody>
    </table>
</div>